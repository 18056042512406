import React from "react";
import TableLoaderEffect from "../../../constant/TableLoader";
const maxWidthStyle300 = {
  maxWidth: "30rem",
  minWidth: "10rem",
  whiteSpace: "normal",
  display: "block",
};

const RSNTable = ({
  selectedStandards,
  data,
  isLoading,
  toggleCheckbox,
  handleSelectAll,
  navigate,
}) => {
  return (
    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
      <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
        <table
          id="empoloyees-tblwrapper"
          className="table ItemsCheckboxSec dataTable no-footer mb-0"
        >
          <thead>
            <tr>
              <th>
                <div className="form-check custom-checkbox checkbox-primary">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      selectedStandards.length === data.length &&
                      data.length !== 0
                    }
                    onChange={handleSelectAll}
                  />
                </div>
              </th>
              <th>RSN No.</th>
              <th>Volume</th>
              <th>Group</th>
              <th>No Of Sheet</th>
              <th>Status</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <TableLoaderEffect colLength={7} />
            ) : (
              data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="form-check custom-checkbox checkbox-primary">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={selectedStandards.includes(item.id)}
                        onChange={() => toggleCheckbox(item.id)}
                      />
                    </div>
                  </td>
                  <td>
                    <span
                      onClick={() => navigate(`/standards/${item.id}`)}
                      className="text-primary cursor-pointer fw-bolder"
                    >
                      {item.standard_no}
                    </span>
                  </td>
                  <td>{item.volume ? item.volume.name : "None"}</td>
                  <td> {item.group ? item.group.name : "None"}</td>
                  <td> {item.no_of_sheet}</td>
                  <td>
                    {item.is_approved === true ? (
                      <span
                        className={`badge light border-0 badge-success badge-sm`}
                      >
                        Approved
                      </span>
                    ) : (
                      <span
                        className={`badge light border-0 badge-info badge-sm`}
                      >
                        Pending
                      </span>
                    )}
                  </td>
                  <td>
                    <span style={maxWidthStyle300}> {item.description} </span>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RSNTable;
