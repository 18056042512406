import React, { useState, useEffect } from 'react'
import { getOpenNoticeList } from "../../../services/NoticeService";

const NoticeScroller = () => {
    const [data, setData] = useState('');
    useEffect(() => {
        getOpenNoticeList(1, 10)
            .then((resp) => {
                setData(resp.data.results);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data.message);
                } else {
                    console.log(error);
                }
            });
    }, []);



    return data ?
        <div className="marquee-text">
            <marquee>{data}</marquee>
        </div> : null

}

export default NoticeScroller