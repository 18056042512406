import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import logo from "../../../images/logo/intelilogo.png";
import logoText from "../../../images/logo/intelilogotext.png";
import GlobalSearch from "./GlobalSearch";
import { formatShortNumber } from "../../../utils";
import DateTimeDisplay from "./DateTimeDisplay";
import LogoutPage from "./Logout";
const Header = ({ visitorCount, userRole }) => {
  const [headerFix, setheaderFix] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 50);
    });
  }, []);

  return (
    <div className={`header ${headerFix ? "is-fixed" : ""}`}>
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <h4 className="department-name mb-0">{process.env.REACT_APP_DEPARTMENT_NAME || "Design Department"}</h4>
            <div className="header-left">
              <GlobalSearch />
            </div>
            {visitorCount && (
              <div className="visitor-count">
                <span className="c-pointer" onClick={()=> navigate('/total_user')}>Total Users <strong>{formatShortNumber(visitorCount.total_user)}</strong>
                </span>
                <span>Today Visitors <strong>{formatShortNumber(visitorCount.today_visitor)}</strong></span>
              </div>
            )}
            {visitorCount?.online_user > 0 &&
              <div className="visitor-count">
                <span className="text-success ms-3 c-pointer" onClick={()=> navigate('/online_user')}>Online <strong>{visitorCount.online_user}</strong></span>
              </div>
            }
            {userRole !== "User" &&
              <div className="visitor-count">
                <DateTimeDisplay />
              </div>
            }
            <div className="visitor-count">
              <LogoutPage position="HEADER" />
            </div>
            <ul className="navbar-nav header-right">
              <li className="nav-item ps-3">
                <div className="company-logo">
                  <img src={logo} alt="logo" width={50} height={50} />
                  {/* <span className="brand-title">LogShift</span> */}
                  <img className="brand-title" src={logoText} alt="logo" width={100} />

                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className="header-bar"></div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    visitorCount: state.auth.visitorCount,
    userRole: state.auth.user?.role || "User"
  };
};

export default connect(mapStateToProps)(Header);

