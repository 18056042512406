import React, { useState, useEffect, useRef } from "react";
import { formatFileSize } from "../../../utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { uploadDrawingFiles } from "../../../services/DrawingService";
import { getFileNameAndExtension } from "../../../utils";

function AttachFilePage({ drawingData }) {
  const {
    drawing_id,
    drawing_number,
    drawing_type,
    drawing_file_type,
    no_of_sheet,
    is_dwg_file_present
  } = drawingData;
  const [selectedFile, setSelectedFile] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      const confirmationMessage =
        "You have unsaved changes. Are you sure you want to leave?";
      e.returnValue = confirmationMessage; // Standard way of showing a confirmation dialog
      return confirmationMessage; // For older browsers
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [selectedFile]);

  const validFileExtension = (file_list) => {
    const fileExt = []
    const allowed_file_type = drawing_file_type === "TIF" ? ["TIF", "TIFF"] : ["PDF"]
    if (is_dwg_file_present) {
      allowed_file_type.push("DWG")
    }
    for (let file of file_list) {
      const file_ext = file.name.split('.').pop().toUpperCase()
      if (allowed_file_type.includes(file_ext)) {
        fileExt.push(file)
      }
    }
    return fileExt
  }

  const validFileName = (filelist) => {
    const fileNameList = []
    const file_list = validFileExtension(filelist)
    if (file_list.length > 0) {
      if (no_of_sheet > 1) {
        const actual_file_name_list = Array.from({ length: no_of_sheet }, (_, i) => `${drawing_type}-${drawing_number}SH${i + 1}`);
        for (let file of file_list) {
          const file_name = getFileNameAndExtension(file.name).name;
          if (actual_file_name_list.includes(file_name)) {
            fileNameList.push(file)
          }
        }
      } else {
        const actual_file_name = `${drawing_type}-${drawing_number}`;
        for (let file of file_list) {
          const file_name = getFileNameAndExtension(file.name).name;
          if (file_name === actual_file_name) {
            fileNameList.push(file)
          }
        }
      }
    }
    return fileNameList
  }

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const filelist = validFileName(file_list)
      if (filelist.length === 0) {
        toast.error("choose a valid file")
      }
      setSelectedFile([...filelist, ...selectedFile]);
    }
  }

  const fileInputRef = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    const filelist = Array.from(e.dataTransfer.files) || []
    const existingFileNames = selectedFile.map(file => file.name);
    const uniqueNewFiles = filelist.filter(file => !existingFileNames.includes(file.name));
    handleFiles(uniqueNewFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInputChange = (e) => {
    const filelist = Array.from(e.target.files) || []
    const existingFileNames = selectedFile.map(file => file.name);
    const uniqueNewFiles = filelist.filter(file => !existingFileNames.includes(file.name));
    handleFiles(uniqueNewFiles);
  };

  const handleFileRemove = (e, index) => {
    setSelectedFile((prevFiles) => {
      const updatedFiles = [...prevFiles]; // Create a copy of the current files array
      updatedFiles.splice(index, 1); // Remove the file at the specified index
      return updatedFiles;
    });
    e.stopPropagation();
  };

  const handleUpload = async () => {
    if (isSubmitLoading) {
      return false;
    }
    if (selectedFile.length > 0) {
      try {
        const reqBody = new FormData();
        reqBody.append("drawing_id", drawing_id);
        selectedFile.forEach((item) => {
          reqBody.append("file_list", item);
        });
        setIsSubmitLoading(true);
        const resp = await uploadDrawingFiles(reqBody);
        toast.success(resp.data.message);
        setSelectedFile([]);
        navigate(`/drawing/${drawing_id}`);
      } catch (error) {
        toast.error(error.response.data.message);
      }
      setIsSubmitLoading(false);
    }
  };

  return (
    <>
      <div className="form-group p-3">

        <div className="d-flex align-items-center justify-content-around mb-4 ">
          <div>
            <strong>Drawing Number : </strong>
            {drawingData.drawing_number}
          </div>
          <div>
            <strong>Drawing Type : </strong>
            {drawingData.drawing_type}
          </div>
          <div>
            
            <strong>Drawing File Type : </strong>
            {drawingData.drawing_file_type}
          </div>
          <div><strong>Number Of Sheet : </strong>
            {drawingData.no_of_sheet}</div>
        </div>
        <label htmlFor="fileInput" className="form-label">
          Attach File
        </label>
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className={`dropzone ${selectedFile.length === 0 && "no-files"}`}
          onClick={() => {
            fileInputRef.current.click();
          }}
        >
          <input
            type="file"
            ref={fileInputRef}
            multiple
            accept={`${drawing_file_type === "TIF" ? ".tif, .tiff" : ".pdf"} ${is_dwg_file_present ? ", .dwg":''}`}
            onChange={handleFileInputChange}
            style={{ display: "none" }}
          />
          {selectedFile.length ? (
            <div className="file-list">
              {selectedFile.map((item, index) => (
                <div key={index} className="file-item">
                  <span className="file-name">
                    {item.name} <small>{formatFileSize(item.size)}</small>
                  </span>
                  <span
                    className="file-remove"
                    onClick={(e) => handleFileRemove(e, index)}
                  >
                    <i className="fa-regular fa-circle-xmark text-danger"></i>
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <p>
              Drag and drop files here, or click to select files
              <br />
              only <strong>{drawing_file_type}</strong> {is_dwg_file_present && <> and <strong>DWG</strong></>} files are allowed.
            </p>
          )}
        </div>

        <div className="d-flex align-items-center justify-content-center mt-4">
          <button
            className="btn btn-primary btn-block w-50"
            disabled={isSubmitLoading || !selectedFile.length}
            onClick={handleUpload}
          >
            {isSubmitLoading ? (
              <span>
                <Spinner animation="border" size="sm" /> Uploading File ..
              </span>
            ) : (
              "Upload File"
            )}
          </button>
        </div>
      </div>
    </>
  );
}

export default AttachFilePage;
