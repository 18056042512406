import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { StaticSelectInput } from "../../constant/SelectInput";
import { manualDropdownOptions } from "../../constant/Variable";
import {
  getBulkManualList,
  updateBulkManualList,
} from "../../../services/ManualService";
import CommonManualArchivePage from "./Manual/CommonManual";
import ManualBulkArchivePage from "./Manual/Manuals";
import ReferenceBookArchivePage from "./Manual/ReferenceBook";
import TenderDocumentArchivePage from "./Manual/TenderDocument";
import CatalougeArchivePage from "./Manual/Catalouge";
import ProjectReportArchivePage from "./Manual/ProjectReport";

const ManualsBulkArchivePage = () => {
  const [selectedOption, setSelectedOption] = useState("MANUALS");
  const [manualList, setManualList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [selectedManuals, setSelectedManuals] = useState([]);
  const [isShowArchiveForm, setIsShowArchiveForm] = useState(false);
  const [archiveReason, setArchiveReason] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getBulkManualList(searchQuery, selectedOption)
      .then((resp) => {
        setManualList(resp.data.results);
        setIsLoading(false);
        setIsSearchLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
          setIsSearchLoading(false);
        } else {
          console.log(error);
        }
      });
  }, [searchQuery, selectedOption]);

  useEffect(() => {
    setSelectedManuals([]);
  }, [selectedOption]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (value) {
      setIsSearchLoading(true);
    }
    setSearchQuery(value);
  };

  const toggleArchiveForm = () => {
    setIsShowArchiveForm(!isShowArchiveForm);
    if (!isShowArchiveForm) {
      setArchiveReason("");
    }
  };

  const handleSelect = (name, selectedValue, _) => {
    setSelectedOption(selectedValue);
  };

  const toggleCheckbox = (id) => {
    setSelectedManuals((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedManuals.length === manualList.length) {
      setSelectedManuals([]);
    } else {
      setSelectedManuals(manualList.map((item) => item.id));
    }
  };

  const handleArchiveSubmit = () => {
    setIsSubmitLoading(true);
    updateBulkManualList({
      manual_id_list: selectedManuals,
      archive_reason: archiveReason,
    })
      .then((resp) => {
        toast.success(resp.data.message);
        setIsSubmitLoading(false);
        setSelectedManuals([]);
        toggleArchiveForm();
        navigate(`/archive-documents?manual_type=${selectedOption}`);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
          setIsSubmitLoading(false);
          setSelectedManuals([]);
          toggleArchiveForm();
        } else {
          console.log(error);
        }
      });
  };

  return (
    <>
      <div className="px-3 py-3 border-top">
        <div className="tbl-caption d-flex justify-content-between text-wrap align-items-start align-items-sm-center flex-column flex-sm-row mb-3">
        <div className="d-flex  align-items-center">
            <h5 className="mb-0 ms-2 fw-bold me-4">Bulk Archive Documents</h5>
            <div style={{ width: "250px" }}>
              <StaticSelectInput
                setParentInputValue={handleSelect}
                parentClassName="mb-0"
                optionsList={manualDropdownOptions}
                name="manual_type"
                id="manual_type"
                defaultValue={{
                  label: selectedOption,
                  value: selectedOption,
                }}
                isRequired={true}
              />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="input-group tp-input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={searchQuery}
                aria-describedby="basic-search"
                onChange={handleSearchChange}
              />
              <div className="input-group-text" id="basic-search">
                {isSearchLoading && <Spinner variant="primary" size="sm" />}
                {!isSearchLoading && searchQuery.length > 0 ? (
                  <i
                    className="fa-solid fa-xmark c-pointer text-primary"
                    onClick={() => {
                      setSearchQuery("");
                    }}
                  ></i>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {selectedManuals.length > 0 && (
          <div className="row my-3 ">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="bg-danger-light rounded p-2">
                <h4 className="text-center">
                  Total <strong>{selectedManuals.length}</strong> Documents
                  selected.
                </h4>
                {isShowArchiveForm ? (
                  <div className="px-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="archive_reason">
                        Archive Reason
                      </label>
                      <textarea
                        row="6"
                        style={{ height: "50px" }}
                        className="form-control"
                        id="archive_reason"
                        placeholder="Enter Archive Reason"
                        value={archiveReason}
                        onChange={(e) => setArchiveReason(e.target.value)}
                      ></textarea>
                    </div>
                    <center className="mt-3 mb-2">
                      <button
                        className="btn btn-primary btn-sm me-2"
                        disabled={isSubmitLoading || !archiveReason.length}
                        onClick={handleArchiveSubmit}
                      >
                        {isSubmitLoading ? (
                          <span>
                            <Spinner size="sm" className="me-2" />
                            Archive...
                          </span>
                        ) : (
                          "Archive"
                        )}
                      </button>
                      <button
                        className="btn btn-outline-danger btn-sm"
                        disabled={isSubmitLoading}
                        onClick={toggleArchiveForm}
                      >
                        Cancel
                      </button>
                    </center>
                  </div>
                ) : (
                  <>
                    <p className="text-center">
                      To archive selected drawings, please click below to
                      continue.
                    </p>
                    <center>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={toggleArchiveForm}
                      >
                        Continue
                      </button>
                    </center>
                  </>
                )}
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        )}
        {selectedOption === "MANUALS" ? (
          <ManualBulkArchivePage
            selectedManuals={selectedManuals}
            manualList={manualList}
            isLoading={isLoading}
            toggleCheckbox={toggleCheckbox}
            handleSelectAll={handleSelectAll}
            navigate={navigate}
          />
        ) : selectedOption === "REFERENCE BOOK" ? (
          <ReferenceBookArchivePage
            selectedManuals={selectedManuals}
            manualList={manualList}
            isLoading={isLoading}
            toggleCheckbox={toggleCheckbox}
            handleSelectAll={handleSelectAll}
            navigate={navigate}
          />
        ) : selectedOption === "TENDER DOCUMENT" ? (
          <TenderDocumentArchivePage
            selectedManuals={selectedManuals}
            manualList={manualList}
            isLoading={isLoading}
            toggleCheckbox={toggleCheckbox}
            handleSelectAll={handleSelectAll}
            navigate={navigate}
          />
        ) : selectedOption === "CATALOUGE" ? (
          <CatalougeArchivePage
            selectedManuals={selectedManuals}
            manualList={manualList}
            isLoading={isLoading}
            toggleCheckbox={toggleCheckbox}
            handleSelectAll={handleSelectAll}
            navigate={navigate}
          />
        ) : selectedOption === "PROJECT REPORT" ? (
          <ProjectReportArchivePage
            selectedManuals={selectedManuals}
            manualList={manualList}
            isLoading={isLoading}
            toggleCheckbox={toggleCheckbox}
            handleSelectAll={handleSelectAll}
            navigate={navigate}
          />
        ) : (
          <CommonManualArchivePage
            selectedManuals={selectedManuals}
            manualList={manualList}
            type={selectedOption}
            isLoading={isLoading}
            toggleCheckbox={toggleCheckbox}
            handleSelectAll={handleSelectAll}
            navigate={navigate}
          />
        )}
      </div>
    </>
  );
};

export default ManualsBulkArchivePage;
