import React from "react";
import { connect } from "react-redux";
import DepartmentUnit from "./Department&Unit";
import VolumeTitleGroup from "./VolumeTitleGroup";


const DepartmentUnitPage = ({userRole}) => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-5">
            <DepartmentUnit userRole={userRole} />
          </div>
          <div className="col-xl-7">
          <VolumeTitleGroup userRole={userRole} />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user?.role || "Admin"
  };
};

export default connect(mapStateToProps)(DepartmentUnitPage);
