import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner, Modal } from "react-bootstrap";
import { StaticSelectInput } from "../../constant/SelectInput";
import { getStandardCount } from "../../../services/StandardService";
import { standardDropdownOptions } from "../../constant/Variable";
import {
  archiveStandardList,
  removeArchiveStandardList,
  permanentDeleteStandardList,
} from "../../../services/StandardService";
import BISArchivePage from "./Standard/BIS";
import RSNArchivePage from "./Standard/RSN";
import IPSSArchivePage from "./Standard/IPSS";
import CommonStandardArchivePage from "./Standard/CommonStandard";

const ArchiveStandardPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultSelectedOption = queryParams.get("standard_type") || "BIS";
  const [selectedOption, setSelectedOption] = useState(defaultSelectedOption);
  const [standardList, setStandardList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStandards, setSelectedStandards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isShowArchiveForm, setIsShowArchiveForm] = useState(false);
  const [isShowDeleteForm, setIsShowDeleteForm] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitLoadingDel, setIsSubmitLoadingDel] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [standardTypeCount, setStandardTypeCount] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const standardType = queryParams.get("standard_type");
    if(standardType){
      setSelectedOption(standardType);
      navigate(location.pathname, { replace: true }); 
    }
  }, [location.search]);

  useEffect(() => {
    setIsLoading(true);
    archiveStandardList(searchQuery, selectedOption)
      .then((resp) => {
        setStandardList(resp.data.results);
        setIsLoading(false);
        setIsSearchLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
          setIsSearchLoading(false);
        } else {
          console.log(error);
        }
      });
  }, [searchQuery, selectedOption]);

  useEffect(() => {
    getStandardCount("Archived")
      .then((resp) => {
        setStandardTypeCount(resp.data.results);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
      });
  }, []);

  useEffect(() => {
    setSelectedStandards([]);
  }, [selectedOption]);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (value) {
      setIsSearchLoading(true);
    }
    setSearchQuery(value);
  };

  const toggleArchiveForm = () => {
    setIsShowArchiveForm(!isShowArchiveForm);
  };

  const toggleDeleteForm = () => {
    setIsShowDeleteForm(!isShowDeleteForm);
  };

  const toggleCheckbox = (id) => {
    setSelectedStandards((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleSelect = (name, selectedValue, _) => {
    setSelectedOption(selectedValue);
  };

  const handleSelectAll = () => {
    if (selectedStandards.length === standardList.length) {
      setSelectedStandards([]);
    } else {
      setSelectedStandards(standardList.map((item) => item.id));
    }
  };

  const handleArchiveSubmit = () => {
    if (isSubmitLoadingDel || isSubmitLoading) {
      return;
    }
    setIsSubmitLoading(true);
    removeArchiveStandardList({ standard_id_list: selectedStandards })
      .then((resp) => {
        toast.success(resp.data.message);
        setIsSubmitLoading(false);
        setSelectedStandards([]);
        toggleArchiveForm();
        navigate(`/standards?standard_type=${selectedOption}`);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
          setIsSubmitLoading(false);
          setSelectedStandards([]);
          toggleArchiveForm();
        } else {
          console.log(error);
        }
      });
  };

  const handleDeleteSubmit = () => {
    if (isSubmitLoadingDel || isSubmitLoading) {
      return;
    }
    setIsSubmitLoadingDel(true);
    permanentDeleteStandardList({ standard_id_list: selectedStandards })
      .then((resp) => {
        toast.success(resp.data.message);
        setIsSubmitLoadingDel(false);
        setSelectedStandards([]);
        toggleDeleteForm();
        navigate(`/standards?standard_type=${selectedOption}`);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
          setIsSubmitLoadingDel(false);
          setSelectedStandards([]);
          toggleDeleteForm();
        } else {
          console.log(error);
        }
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="px-3 py-3">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-start align-items-sm-center flex-column flex-sm-row">
                    <div className="d-flex  align-items-center">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-circle"
                        onClick={() => navigate("/dashboard")}
                      >
                        <i className="fa-solid fa-arrow-left"></i>
                      </button>
                      <h5 className="mb-0 ms-2 fw-bold me-4">
                        Archived Standards
                      </h5>
                      <div style={{ width: "200px" }} className="me-3">
                        <StaticSelectInput
                          setParentInputValue={handleSelect}
                          parentClassName="mb-0"
                          optionsList={standardDropdownOptions}
                          name="standard_type"
                          id="standard_type"
                          defaultValue={{
                            label: selectedOption,
                            value: selectedOption,
                          }}
                          isRequired={true}
                        />
                      </div>
                      <div className="text-primary c-pointer fs-3" onClick={toggleModal}>
                        <i class="fa-solid fa-circle-info"></i>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="input-group tp-input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          value={searchQuery}
                          aria-describedby="basic-search"
                          onChange={handleSearchChange}
                        />
                        <div className="input-group-text" id="basic-search">
                          {isSearchLoading && (
                            <Spinner variant="primary" size="sm" />
                          )}
                          {!isSearchLoading && searchQuery.length > 0 ? (
                            <i
                              className="fa-solid fa-xmark c-pointer text-primary"
                              onClick={() => {
                                setSearchQuery("");
                              }}
                            ></i>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  {selectedStandards.length > 0 && (
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="bg-success-light rounded p-2">
                          <h4 className="text-center">
                            Total <strong>{selectedStandards.length}</strong>
                            Standards selected.
                          </h4>
                          {isShowArchiveForm ? (
                            <div className="px-3">
                              <p className="text-center fw-bold text-success">
                                Are you sure, you want to unarchive the selected
                                standards.
                              </p>
                              <center className="mt-3 mb-2">
                                <button
                                  className="btn btn-success btn-sm me-2"
                                  disabled={isSubmitLoading}
                                  onClick={handleArchiveSubmit}
                                >
                                  {isSubmitLoading ? (
                                    <span>
                                      <Spinner size="sm" className="me-2" />
                                      Confirm...
                                    </span>
                                  ) : (
                                    "Confirm"
                                  )}
                                </button>
                                <button
                                  className="btn btn-outline-danger btn-sm"
                                  disabled={isSubmitLoading}
                                  onClick={toggleArchiveForm}
                                >
                                  Cancel
                                </button>
                              </center>
                            </div>
                          ) : (
                            <>
                              <p className="text-center fw-bold text-success">
                                To unarchive selected standards, please click
                                below to continue.
                              </p>
                              <center>
                                <button
                                  className="btn btn-success btn-sm"
                                  onClick={toggleArchiveForm}
                                >
                                  Continue
                                </button>
                              </center>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="bg-danger-light rounded p-2">
                          <h4 className="text-center">
                            Total <strong>{selectedStandards.length}</strong>
                            Standards selected.
                          </h4>
                          {isShowDeleteForm ? (
                            <div className="px-3">
                              <p className="text-center text-danger fw-bold">
                                Are you sure, you want to permanently delete the
                                selected standards.
                              </p>
                              <center className="mt-3 mb-2">
                                <button
                                  className="btn btn-primary btn-sm me-2"
                                  disabled={isSubmitLoadingDel}
                                  onClick={handleDeleteSubmit}
                                >
                                  {isSubmitLoadingDel ? (
                                    <span>
                                      <Spinner size="sm" className="me-2" />
                                      Confirm...
                                    </span>
                                  ) : (
                                    "Confirm"
                                  )}
                                </button>
                                <button
                                  className="btn btn-outline-danger btn-sm"
                                  disabled={isSubmitLoadingDel}
                                  onClick={toggleDeleteForm}
                                >
                                  Cancel
                                </button>
                              </center>
                            </div>
                          ) : (
                            <>
                              <p className="text-center fw-bold text-danger">
                                To permanently delete selected standards, please
                                click below to continue.
                              </p>
                              <center>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={toggleDeleteForm}
                                >
                                  Continue
                                </button>
                              </center>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {selectedOption === "BIS" ? (
                  <BISArchivePage
                    selectedStandards={selectedStandards}
                    standardList={standardList}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                ) : selectedOption === "RSN" ? (
                  <RSNArchivePage
                    selectedStandards={selectedStandards}
                    standardList={standardList}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                ) : selectedOption === "IPSS" ? (
                  <IPSSArchivePage
                    selectedStandards={selectedStandards}
                    standardList={standardList}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                ) : (
                  <CommonStandardArchivePage
                    selectedStandards={selectedStandards}
                    standardList={standardList}
                    type={selectedOption}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal fade"
        id="exampleModal1"
        show={showModal}
        onHide={toggleModal}
      >
        <div className="modal-content">
          <div className="modal-body">
            <table
              className="table table-sm table-bordered"
            >
              <thead>
                <tr>
                  <th>Standard Type</th>
                  <th>Count</th>
                </tr>
              </thead>
              {standardTypeCount && (
                <tbody>
                  <tr>
                    <td>BIS</td>
                    <td>{standardTypeCount.bis}</td>
                  </tr>
                  <tr>
                    <td>ASTM</td>
                    <td>{standardTypeCount.astm}</td>
                  </tr>
                  <tr>
                    <td>AWWA</td>
                    <td>{standardTypeCount.awwa}</td>
                  </tr>
                  <tr>
                    <td>BRITISH</td>
                    <td>{standardTypeCount.british}</td>
                  </tr>
                  <tr>
                    <td>DIN(GERMAN)</td>
                    <td>{standardTypeCount.din}</td>
                  </tr>
                  <tr>
                    <td>GOST(RUSSIAN)</td>
                    <td>{standardTypeCount.gost}</td>
                  </tr>
                  <tr>
                    <td>IEC</td>
                    <td>{standardTypeCount.iec}</td>
                  </tr>
                  <tr>
                    <td>ISO</td>
                    <td>{standardTypeCount.iso}</td>
                  </tr>
                  <tr>
                    <td>IRST</td>
                    <td>{standardTypeCount.irst}</td>
                  </tr>
                  <tr>
                    <td>API</td>
                    <td>{standardTypeCount.api}</td>
                  </tr>
                  <tr>
                    <td>PSN</td>
                    <td>{standardTypeCount.psn}</td>
                  </tr>
                  <tr>
                    <td>RSN</td>
                    <td>{standardTypeCount.rsn}</td>
                  </tr>
                  <tr>
                    <td>IPSS</td>
                    <td>{standardTypeCount.ipss}</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ArchiveStandardPage;
