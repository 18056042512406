import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?\d{4}$/;

export const NewUserSchema = Yup.object({
  personnel_number: Yup.string()
    .required("Personnel Number is required")
    .matches(/^[a-zA-Z0-9]+$/,"Personnel Number must contain only letters and numbers"),
  full_name: Yup.string().min(2).max(50).required("Please enter Full Name"),
  email: Yup.string().email().required("Please enter Email"),
  phone_number: Yup.string()
    .min(10)
    .max(10)
    .matches(phoneRegExp, "Phone Number is not valid")
    .required("Please enter Phone Number"),
  designation: Yup.string().required("Please enter Designation"),
  department: Yup.string().required("Please Select Department name"),
});
