import React, { useState } from "react";
import { Form, Spinner } from 'react-bootstrap';
import DynamicSelectInput from "../../../constant/SelectInput";
import { toast } from "react-toastify";


function IPSSForm({ handleSubmit, isLoading }) {
  const [standards, setStandards] = useState({
    standard_number: "",
    title: "",
    file_availability: "YES",
    description: "",
    file:"",
  });

  const [standardsInputError, setStandardsInputError] = useState({
    standard_number: false,
    file: false, 
  });

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const file = file_list[0];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (file_ext !== "PDF") {
        toast.error("Please choose a valid PDF file.");
      } else {
        setStandards({ ...standards, file: file }); 
      }
    }
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist);
  };

  const handleSelectChange = (name, value, _) => {
    setStandards({ ...standards, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "file_availability" && value === "NO") {
      setStandards({ ...standards, file_availability: value, file: undefined });
      setStandardsInputError({ ...standardsInputError, file: false });
    } else {
      setStandards({ ...standards, [name]: value });
    }
  
    if (name === "standard_number") {
      setStandardsInputError({ ...standardsInputError, standard_number: value.trim() === "" });
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (standards.standard_number.trim() === "") {
      setStandardsInputError({ ...standardsInputError, standard_number: true });
    } else if (standards.file_availability === "YES" && !standards.file) {
      setStandardsInputError({ ...standardsInputError, file: true }); 
    }
    else {
      handleSubmit(e, standards);
    }
  };


  return (
    <Form onSubmit={handleSubmitForm}>
      <div className="row">
        <div className="mb-3">
          <label htmlFor="standard_number" className="form-label">
            IPSS Number <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className={`form-control ${
                standardsInputError.standard_number && "is-invalid"
              }`}
            id="standard_number"
            placeholder="Enter IPSS Number"
            name="standard_number"
            value={standards.standard_number}
            onChange={handleChange}
          />
          {standardsInputError.standard_number && (
              <div className="invalid-feedback mt-2">IPSS No. is required.</div>
            )}
        </div>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Title
          </label>
          <DynamicSelectInput
            setParentInputValue={handleSelectChange}
            endpoint_name="search/ipsstitle"
            name={"title"}
            id={"title"}
            placeholder={"Select Title"}
            isRequired={true}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">
            Description
          </label>
          <textarea
            type="text"
            className="form-control"
            id="description"
            placeholder="Enter Description"
            name="description"
            value={standards.description}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="mb-3">
          <label>File Available</label>
          <br />
          <div className="form-check custom-checkbox mb-2 form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="file_availability"
              id="yes_file_availability"
              value="YES"
              checked={standards.file_availability === "YES"}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="yes_file_availability">
              YES
            </label>
          </div>
          <div className="form-check custom-checkbox mb-2 form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="file_availability"
              id="no_file_availability"
              value="NO"
              checked={standards.file_availability === "NO"}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="no_file_availability">
              NO
            </label>
          </div>
        </div>
        {standards.file_availability === "YES" && (
          <div className="mb-3">
            <label htmlFor="pdfFile" className="form-label">
              Upload File <span className="text-danger">*</span>
            </label>
            <input
              type="file"
              className={`form-control ${standardsInputError.file && "is-invalid"}`}
              id="file"
              name="file"
              onChange={handleFileInputChange}
              accept=".pdf"
            />
             {standardsInputError.file && (
              <div className="invalid-feedback mt-2">File is required.</div>
            )}
          </div>
        ) }
       
      </div>
      <div className="d-flex align-items-center justify-content-center mt-2">
      <button
          type="submit"
          disabled={isLoading}
          className="btn btn-primary me-1 w-50"
        >
         {isLoading ? (<span><Spinner size="sm" className="me-2" />Creating...</span>) : "Create"}
        </button>
      </div>
    </Form>
  );
}

export default IPSSForm;
