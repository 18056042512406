import React, { useState, useRef, useEffect } from 'react';
import { searchDrawing } from '../../../services/DrawingService';
import { useNavigate } from "react-router-dom";

const GlobalSearch = () => {
    const [inputValue, setInputValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setInputValue('');
                setSearchResults([]);
                setSelectedIndex(-1);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === '/') {
                event.preventDefault();
                inputRef.current.focus();
            }

            if (searchResults.length > 0) {
                if (event.key === 'ArrowDown') {
                    event.preventDefault();
                    setSelectedIndex((prevIndex) => (prevIndex + 1) % searchResults.length);
                } else if (event.key === 'ArrowUp') {
                    event.preventDefault();
                    setSelectedIndex((prevIndex) => (prevIndex - 1 + searchResults.length) % searchResults.length);
                } else if (event.key === 'Enter' && selectedIndex >= 0) {
                    event.preventDefault();
                    navigate(`/drawing/${searchResults[selectedIndex].value}`);
                    setInputValue('');
                    setSearchResults([]);
                    setSelectedIndex(-1);
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [searchResults, selectedIndex, navigate]);

    useEffect(() => {
        const getData = setTimeout(() => {
            if (inputValue.length > 0) {
                searchDrawing(inputValue).then((response) => {
                    if (response) {
                        const { results } = response.data
                        setSearchResults(results);
                        if (results && inputValue.toLowerCase() === `${results[0].label.toLowerCase()}`) {
                            setSelectedIndex(0);
                        } else {
                            setSelectedIndex(-1);
                        }
                    }
                }).catch((error) => {
                    console.log(error.response?.data?.message);
                });
            } else {
                setSearchResults([]);
            }
        }, 300)

        return () => clearTimeout(getData)
    }, [inputValue])
    const handleInputChange = (event) => {
        const newInputValue = event.target.value;
        setInputValue(newInputValue);
    };

    const handleResultClick = (drawingId) => {
        setInputValue('');
        setSearchResults([]);
        navigate(`/drawing/${drawingId}`);
    };

    return (
        <div className={`search-bar ${(inputValue.length > 0 && searchResults.length > 0) && 'show-result'}`} ref={dropdownRef}>
            <div className="input-group search-area">
                <span className='input-group-text search-icon'>
                    <span className=''>
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </span>
                </span>
                <input
                    type="text"
                    className="form-control rounded-0"
                    placeholder="Express Search Drawing Number"
                    onChange={handleInputChange}
                    value={inputValue}
                    ref={inputRef}
                />
                <span className="input-group-text rounded-0">
                    {inputValue.length > 0 ? (
                        <span onClick={() => {
                            setInputValue('');
                            setSearchResults([]);
                        }}>
                            <i className="fa-regular fa-circle-xmark text-danger c-pointer fa-xl"></i>
                        </span>
                    ) : (
                        <span className='ctrl-btn'>
                            <svg width="15" height="15"><path d="M4.505 4.496h2M5.505 5.496v5M8.216 4.496l.055 5.993M10 7.5c.333.333.5.667.5 1v2M12.326 4.5v5.996M8.384 4.496c1.674 0 2.116 0 2.116 1.5s-.442 1.5-2.116 1.5M3.205 9.303c-.09.448-.277 1.21-1.241 1.203C1 10.5.5 9.513.5 8V7c0-1.57.5-2.5 1.464-2.494.964.006 1.134.598 1.24 1.342M12.553 10.5h1.953" strokeWidth="1.2" stroke="currentColor" fill="none" strokeLinecap="square"></path></svg> /
                        </span>
                    )}
                </span>
            </div>
            <div className="search-area-result">
                {inputValue.length > 0 && searchResults.map((item, index) => (
                    <div
                        key={index}
                        className={`search-area-result-item ${index === selectedIndex ? 'selected' : ''}`}
                        onClick={() => handleResultClick(item.value)}
                    >
                        <span>{item.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GlobalSearch;
