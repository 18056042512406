import React, { useState } from "react";
import DynamicSelectInput, {
  StaticSelectInput,
} from "../../constant/SelectInput";
import { Collapse } from "react-bootstrap";
import { minusDaysToDate } from "../../../utils";
function AdvanceManualSearch({ open, onApplyChanges }) {
  const initialState = {
    manual_type: "",
    status: "",
    user: "",
    start_date: minusDaysToDate(0),
    end_date: minusDaysToDate(0),
  };

  const [filterInput, setFilterInput] = useState(initialState);
  const [isClearAll, setIsClearAll] = useState(false);

  const handleSelectChange = (name, value, _) => {
    const newFilter = { ...filterInput, [name]: value };
    setFilterInput(newFilter);
    onApplyChanges(newFilter);
    setIsClearAll(false);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setFilterInput({ ...filterInput, [name]: value });
    onApplyChanges({ ...filterInput, [name]: value });
  };

  const handleClear = () => {
    setIsClearAll(true);
    setFilterInput(initialState);
    onApplyChanges(initialState);
  };

  return (
    <Collapse in={open} className="border rounded px-3 pt-3">
      <div id="collapse-example">
        <div className="row">
          <div className="col-lg-2 col-md-3 col-sm-6 ">
            <StaticSelectInput
              optionsList={[
                { label: "MANUALS", value: "MANUALS" },
                { label: "REFERENCE BOOK", value: "REFERENCE BOOK" },
                { label: "TENDER DOCUMENT", value: "TENDER DOCUMENT" },
                { label: "CATALOUGE", value: "CATALOUGE" },
                { label: "TECHNICAL CALCULATION", value: "TECHNICAL CALCULATION" },
                { label: "TECHNICAL SPECIFICATION", value: "TECHNICAL SPECIFICATION" },
                { label: "TECHNICAL REPORT", value: "TECHNICAL REPORT" },
                { label: "PROJECT REPORT", value: "PROJECT REPORT" },
              ]}
              setParentInputValue={handleSelectChange}
              name={"manual_type"}
              isClearable={true}
              labelHint={false}
              defaultValue={
                filterInput.manual_type
                  ? {
                    label: filterInput.manual_type,
                    value: filterInput.manual_type,
                  }
                  : null
              }
              id={"manual_type"}
              label={"Manual Type"}
              is_multi={true}
              isSetEmpty={isClearAll}
            />
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 ">
            <DynamicSelectInput
              setParentInputValue={handleSelectChange}
              endpoint_name="search/user"
              labelHint={false}
              isClearable={true}
              label={"User"}
              name={"user"}
              is_multi={true}
              id={"user"}
              isSetEmpty={isClearAll}
            />
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 ">
            <StaticSelectInput
              optionsList={[
                { label: "View Document", value: "View Document" },
                { label: "Add Document", value: "Add Document" },
                { label: "Update Document", value: "Update Document" },
                { label: "Archive Document", value: "Archive Document" },
                { label: "Delete Document", value: "Delete Document" }
              ]}
              setParentInputValue={handleSelectChange}
              name={"status"}
              isClearable={true}
              labelHint={false}
              is_multi={true}
              defaultValue={
                filterInput.status
                  ? {
                    label: filterInput.status,
                    value: filterInput.status,
                  }
                  : null
              }
              id={"status"}
              label={"Status"}
              isSetEmpty={isClearAll}
            />
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6">
            <label htmlFor="start_date" className="form-label">
              From Date
            </label>
            <input
              type="date"
              className="form-control"
              name="start_date"
              id="start_date"
              value={filterInput.start_date}
              onChange={handleDateChange}
            />
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6">
            <label htmlFor="end_date" className="form-label">
              To Date
            </label>
            <input
              type="date"
              className="form-control"
              name="end_date"
              id="end_date"
              value={filterInput.end_date}
              onChange={handleDateChange}
            />
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 d-flex align-items-center my-3 my-md-0">
            <button
              type="button"
              className="btn btn-sm btn-outline-danger"
              onClick={handleClear}
            >
              Clear All
            </button>
          </div>
        </div>
      </div>
    </Collapse>
  );
}

export default AdvanceManualSearch;
