import React, { useState } from "react";
import { StaticSelectInput } from "../../constant/SelectInput";
import DrawingBulkArchivePage from "./DrawingBulkArchive";
import StandardBulkArchivePage from "./StandardBulkArchive";
import ManualsBulkArchivePage from "./ManualBulkArchive";
import SIRBulkArchivePage from "./SIRBulkArchive";
import SCBulkArchivePage from "./SCBulkArchive";
import ComplianceBulkArchivePage from "./ComplianceBulkArchive";

const BulkArchivePage = () => {
  const [reportType, setReportType] = useState("drawing");
  const handleChange = (e) => {
    setReportType(e.target.value);
  };
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="px-3 py-3">
                <div className="tbl-caption d-flex flex-column  flex-sm-row text-wrap align-items-start align-items-sm-center">
                  <h4 className="heading fw-bold mb-3 mb-md-1 me-3">
                    Bulk Archive
                  </h4>
                  <div className="form-group">
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="reportType"
                        id="drawingRadio"
                        value="drawing"
                        checked={reportType === "drawing"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="drawingRadio"
                      >
                        Drawings
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="reportType"
                        id="standardRadio"
                        value="standard"
                        checked={reportType === "standard"}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="standardRadio"
                      >
                        Standards
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="reportType"
                        id="manualRadio"
                        value="manual"
                        checked={reportType === "manual"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="manualRadio">
                        Documents
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="reportType"
                        id="sirRadio"
                        value="sir"
                        checked={reportType === "sir"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="sirRadio">
                      SIR
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="reportType"
                        id="scRadio"
                        value="sc"
                        checked={reportType === "sc"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="scRadio">
                        Stability Certificate
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="reportType"
                        id="complianceRadio"
                        value="compliance"
                        checked={reportType === "compliance"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="complianceRadio">
                      Compliance
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {reportType === "drawing" ? (
                <DrawingBulkArchivePage />
              ) : reportType === "standard" ? (
                <StandardBulkArchivePage />
              ) : reportType === "manual" ? (
                <ManualsBulkArchivePage />
              ) : reportType === "sir" ? (
               <SIRBulkArchivePage />) :
               reportType === "sc" ?
               (<SCBulkArchivePage />) : <ComplianceBulkArchivePage />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkArchivePage;
