import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import BISArchivePage from "./Standards/BIS";
import { standardDropdownOptions } from "../../constant/Variable";
import { StaticSelectInput } from "../../constant/SelectInput";
import {
  getBulkStandardList,
  updateBulkStandardList,
} from "../../../services/StandardService";
import CommonStandardArchivePage from "./Standards/CommonStandard";
import RSNArchivePage from "./Standards/RSN";
import IPSSArchivePage from "./Standards/IPSS";

const StandardBulkArchivePage = () => {
  const [selectedOption, setSelectedOption] = useState("BIS");
  const [standardList, setStandardList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [selectedStandards, setSelectedStandards] = useState([]);
  const [isShowArchiveForm, setIsShowArchiveForm] = useState(false);
  const [archiveReason, setArchiveReason] = useState("");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    getBulkStandardList(searchQuery, selectedOption)
      .then((resp) => {
        setStandardList(resp.data.results);
        setIsLoading(false);
        setIsSearchLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
          setIsSearchLoading(false);
        } else {
          console.log(error);
        }
      });
  }, [searchQuery, selectedOption]);

  useEffect(() => {
    setSelectedStandards([]);
  }, [selectedOption]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (value) {
      setIsSearchLoading(true);
    }
    setSearchQuery(value);
  };

  const toggleArchiveForm = () => {
    setIsShowArchiveForm(!isShowArchiveForm);
    if (!isShowArchiveForm) {
      setArchiveReason("");
    }
  };

  const handleSelect = (name, selectedValue, _) => {
    setSelectedOption(selectedValue);
  };

  const toggleCheckbox = (id) => {
    setSelectedStandards((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedStandards.length === standardList.length) {
      setSelectedStandards([]);
    } else {
      setSelectedStandards(standardList.map((item) => item.id));
    }
  };

  const handleArchiveSubmit = () => {
    setIsSubmitLoading(true);
    updateBulkStandardList({
      standard_id_list: selectedStandards,
      archive_reason: archiveReason,
    })
      .then((resp) => {
        toast.success(resp.data.message);
        setIsSubmitLoading(false);
        setSelectedStandards([]);
        toggleArchiveForm();
        navigate(`/archive-standard?standard_type=${selectedOption}`);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsSubmitLoading(false);
          setSelectedStandards([]);
          toggleArchiveForm();
          toast.error(error.response.data.message);
        } else {
          console.log(error);
        }
      });
  };

  return (
    <>
      <div className="px-3 py-3 border-top">
        <div className="tbl-caption d-flex justify-content-between text-wrap align-items-start align-items-sm-center flex-column flex-sm-row mb-3">
          <div className="d-flex  align-items-center">
            <h5 className="mb-0 ms-2 fw-bold me-4">Bulk Archive Standards</h5>
            <div style={{ width: "250px" }}>
              <StaticSelectInput
                setParentInputValue={handleSelect}
                parentClassName="mb-0"
                optionsList={standardDropdownOptions}
                name="standard_type"
                id="standard_type"
                defaultValue={{
                  label: selectedOption,
                  value: selectedOption,
                }}
                isRequired={true}
              />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="input-group tp-input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                value={searchQuery}
                aria-describedby="basic-search"
                onChange={handleSearchChange}
              />
              <div className="input-group-text" id="basic-search">
                {isSearchLoading && <Spinner variant="primary" size="sm" />}
                {!isSearchLoading && searchQuery.length > 0 ? (
                  <i
                    className="fa-solid fa-xmark c-pointer text-primary"
                    onClick={() => {
                      setSearchQuery("");
                    }}
                  ></i>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {selectedStandards.length > 0 && (
          <div className="row my-3 ">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="bg-danger-light rounded p-2">
                <h4 className="text-center">
                  Total <strong>{selectedStandards.length}</strong> Standards
                  selected.
                </h4>
                {isShowArchiveForm ? (
                  <div className="px-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="archive_reason">
                        Archive Reason
                      </label>
                      <textarea
                        row="6"
                        style={{ height: "50px" }}
                        className="form-control"
                        id="archive_reason"
                        placeholder="Enter Archive Reason"
                        value={archiveReason}
                        onChange={(e) => setArchiveReason(e.target.value)}
                      ></textarea>
                    </div>
                    <center className="mt-3 mb-2">
                      <button
                        className="btn btn-primary btn-sm me-2"
                        disabled={isSubmitLoading || !archiveReason.length}
                        onClick={handleArchiveSubmit}
                      >
                        {isSubmitLoading ? (
                          <span>
                            <Spinner size="sm" className="me-2" />
                            Archive...
                          </span>
                        ) : (
                          "Archive"
                        )}
                      </button>
                      <button
                        className="btn btn-outline-danger btn-sm"
                        disabled={isSubmitLoading}
                        onClick={toggleArchiveForm}
                      >
                        Cancel
                      </button>
                    </center>
                  </div>
                ) : (
                  <>
                    <p className="text-center">
                      To archive selected drawings, please click below to
                      continue.
                    </p>
                    <center>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={toggleArchiveForm}
                      >
                        Continue
                      </button>
                    </center>
                  </>
                )}
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        )}
        {selectedOption === "BIS" ? (
          <BISArchivePage
            selectedStandards={selectedStandards}
            standardList={standardList}
            isLoading={isLoading}
            toggleCheckbox={toggleCheckbox}
            handleSelectAll={handleSelectAll}
            navigate={navigate}
          />
        ) : selectedOption === "RSN" ? (
          <RSNArchivePage
            selectedStandards={selectedStandards}
            standardList={standardList}
            isLoading={isLoading}
            toggleCheckbox={toggleCheckbox}
            handleSelectAll={handleSelectAll}
            navigate={navigate}
          />
        ) : selectedOption === "IPSS" ? (
          <IPSSArchivePage
            selectedStandards={selectedStandards}
            standardList={standardList}
            isLoading={isLoading}
            toggleCheckbox={toggleCheckbox}
            handleSelectAll={handleSelectAll}
            navigate={navigate}
          />
        ) : (
          <CommonStandardArchivePage
            selectedStandards={selectedStandards}
            standardList={standardList}
            type={selectedOption}
            isLoading={isLoading}
            toggleCheckbox={toggleCheckbox}
            handleSelectAll={handleSelectAll}
            navigate={navigate}
          />
        )}
      </div>
    </>
  );
};

export default StandardBulkArchivePage;
