import Select from "react-select";
import React, { useState, useEffect } from "react";
import { getSelectOptionList } from "../../services/SearchService";
const DynamicSelectInput = ({
  name,
  id,
  setParentInputValue,
  parentClassName = "mb-3",
  endpoint_name = "area",
  defaultValue = null,
  label = null,
  labelHint = true,
  is_multi = false,
  isRequired = false,
  is_invalid = false,
  placeholder = "Select ...",
  isClearable = false,
  isSetEmpty=false,
  isDisabled=false,
  setClickValue=null
}) => {
  const [selectOption, setSelectOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectValue, setSelectValue] = useState();
  useEffect(() => {
    setIsLoading(true);
    getSelectOptionList(endpoint_name)
      .then((resp) => {
        if (resp) {
          setSelectOption(resp.data.results);
          setIsLoading(false);
          setSelectValue(defaultValue ? defaultValue : is_multi ? [] : null)
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
        } else {
          console.log(error);
        }
      });
  }, [endpoint_name]);

  useEffect(()=>{
    if(isSetEmpty){
      setSelectValue(is_multi ? [] : null)
    }
  },[isSetEmpty])

  useEffect(()=>{
    if(setClickValue !== selectValue){
      setSelectValue(setClickValue)
    }
  },[setClickValue])

  const handleSelectValueChange = (data) => {
    setSelectValue(data);
    if (data) {
      setParentInputValue(
        name,
        is_multi ? data.map((item) => item.value) : data.value, data
      ); // Notify parent component of select value change
    } else {
      setParentInputValue(name, is_multi ? [] : "", data); // Notify parent component of select value change
    }
    // Notify parent component of select value change
  };

  return (
    <div className={parentClassName}>
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
          {labelHint &&
            (isRequired ? (
              <span className="text-danger">*</span>
            ) : (
              <span className="text-light">(Optional)</span>
            ))}
        </label>
      )}
      <Select
        value={selectValue}
        onChange={(data) => handleSelectValueChange(data)}
        placeholder={placeholder}
        isLoading={isLoading}
        id={id}
        name={name}
        isDisabled={isDisabled}
        isClearable={isClearable}
        isMulti={is_multi}
        closeMenuOnSelect={is_multi ? false : true}
        options={selectOption}
        className={`react-select ${
          is_invalid && isRequired ? "is-invalid" : ""
        }`}
        classNamePrefix="select"
      />
    </div>
  );
};

export const StaticSelectInput = ({
  name,
  id,
  setParentInputValue,
  optionsList = [],
  defaultValue = null,
  parentClassName = "mb-3",
  label = null,
  labelHint = true,
  isRequired = false,
  is_invalid = false,
  is_multi = false,
  placeholder = "Select ...",
  isClearable = false,
  isSetEmpty=false,
  setClickValue = null
}) => {
  const [selectValue, setSelectValue] = useState(
    defaultValue ? defaultValue : is_multi ? [] : null
  );

  useEffect(()=>{
    if(isSetEmpty){
      setSelectValue(is_multi ? [] : null)
    }
  },[isSetEmpty])

  useEffect(()=>{
    if(setClickValue && setClickValue !== selectValue){
      setSelectValue(setClickValue)
    }
  },[setClickValue])

  const handleSelectValueChange = (data) => {
    setSelectValue(data);
    if (data) {
      setParentInputValue(
        name, is_multi ? data.map((item) => item.value) : data.value, data
      ); // Notify parent component of select value change
    } else {
      setParentInputValue(name, is_multi ? [] : "", data); // Notify parent component of select value change
    }
  };

  return (
    <div className={parentClassName}>
      {label && (
        <label htmlFor={id} className="form-label">
          {label}
          {labelHint &&
            (isRequired ? (
              <span className="text-danger">*</span>
            ) : (
              <span className="text-light">(Optional)</span>
            ))}
        </label>
      )}
      <Select
        value={selectValue}
        onChange={(data) => handleSelectValueChange(data)}
        placeholder={placeholder}
        isClearable={isClearable}
        id={id}
        name={name}
        isMulti={is_multi}
        closeMenuOnSelect={is_multi ? false : true}
        options={optionsList}
        className={`react-select ${
          is_invalid && isRequired ? "is-invalid" : ""
        }`}
        classNamePrefix="select"
      />
    </div>
  );
};

export default DynamicSelectInput;
