import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import DynamicSelectInput from "../../../constant/SelectInput";
import { updateManual } from "../../../../services/ManualService"; 
import { formatFileSize } from "../../../../utils";   
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function EditManualModal({ data, setData}) {
  const [manuals, setManuals] = useState({
    id: "",
    manual_number: "",
    department: "",
    unit: "",
    supplier: "",
    package_number: "",
    letter_number: "",
    registration_date: "",
    description: "",
    remarks: "",
    file: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const toggleModal = () => {
    if (showModal) {
      setManuals(manuals);
    } else {
      setManuals({
        id: data.id,
        manual_number: data.manual_no,
        department: data.department ? data.department.department_id : "",
        unit: data.unit ? data.unit.unit_id : "",
        supplier:data.supplier || "",
        package_number: data.package_no || "",
        letter_number: data.letter_no || "",
        registration_date: data.registration_date || "",
        remarks: data.remarks || "",
        description: data.description || "",
        file: "",
        file_data:data.file
      });
    }
    setShowModal(!showModal);
  };

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const file = file_list[0];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (file_ext !== "PDF") {
        toast.error("Please choose a valid PDF file.");
      } else {
        setManuals({ ...manuals, file: file });
      }
    }
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setManuals({ ...manuals, [name]: value });
  };
  
  const handleSelectChange = (name, value, _) => {
    setManuals({ ...manuals, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitLoading(true);
      const reqBody = new FormData();
      Object.entries(manuals).forEach(([fieldName, value]) => {
        if(fieldName !== "file_data"){
          reqBody.append(fieldName, value);
        }
      });
      reqBody.append("manual_type", "MANUALS");
      const resp = await updateManual(reqBody);
      const {results, message} = resp.data
      setData(results);
      toast.success(message);
      toggleModal();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
  };

  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm cursor-pointer fw-bolder"
        onClick={toggleModal}
      >
        <i className="fa-solid fa-pen-to-square"></i> Edit
      </button>
      <Modal
        show={showModal}
        onHide={toggleModal}
        className="modal fade modal-lg"
        placement="end"
      >
        <div className="modal-content">
          <div className="modal-header border-bottom">
            <h5 className="modal-title" id="#gridSystemModal">
              Edit Manual
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {manuals && (
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xl-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="manual_number" className="form-label">
                        Manual Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="manual_number"
                        placeholder="Enter Manual Number"
                        name="manual_number"
                        value={manuals.manual_number}
                        onChange={handleChange}
                        readOnly
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="department" className="form-label">
                        Department
                      </label>
                      <DynamicSelectInput
                        setParentInputValue={handleSelectChange}
                        endpoint_name="search/department"
                        name={"department"}
                        id={"department"}
                        placeholder={"Select Department"}
                        defaultValue={data.department ? 
                          {value: data.department.department_id,label: data.department.name} 
                          : null}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="unit" className="form-label">
                        Unit
                      </label>
                      <DynamicSelectInput
                        setParentInputValue={handleSelectChange}
                        endpoint_name="search/unit"
                        name={"unit"}
                        id={"unit"}
                        placeholder={"Select Unit"}
                        defaultValue={data.unit ? {
                          value: data.unit.unit_id,
                          label: data.unit.name
                        }:null}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="supplier" className="form-label">
                        Supplier
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="supplier"
                        placeholder="Enter Supplier"
                        name="supplier"
                        value={manuals.supplier}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="remarks" className="form-label">
                        Remarks
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="remarks"
                        placeholder="Enter Remarks"
                        name="remarks"
                        value={manuals.remarks}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12 mx-auto">
                    <div className="mb-3">
                      <label htmlFor="package_number" className="form-label">
                        Package Number 
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="package_number"
                        placeholder="Enter Package Number"
                        name="package_number"
                        value={manuals.package_number}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="letter_number" className="form-label">
                        Letter Number 
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="letter_number"
                        placeholder="Enter Letter Number"
                        name="letter_number"
                        value={manuals.letter_number}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="registration_date" className="form-label">
                        Registration Date 
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="registration_date"
                        placeholder="Enter Registration Date"
                        name="registration_date"
                        value={manuals.registration_date}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="description"
                        placeholder="Enter Description"
                        name="description"
                        value={manuals.description}
                        onChange={handleChange}
                      ></textarea>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="file" className="form-label">
                        {manuals.file_data ? "Upload New File" : "Upload File"}
                        {manuals.file_data ? (
                          <>
                            <span className="ms-2 me-2 text-dark">
                              {manuals.file_data.name}
                            </span>
                            <span className="text-dark">
                              {formatFileSize(manuals.file_data.size)}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleFileInputChange}
                        accept=".pdf"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <button type="submit" disabled={isSubmitLoading} className="btn btn-primary me-1 w-50">
                  {isSubmitLoading ? (<span><Spinner size="sm" className="me-2" />Updating...</span>) : "Update"}
                  </button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditManualModal;
