import React, { useState, useEffect } from 'react'
import { getOpenCountDashbaord } from "../../../services/NoticeService";
import StandardLogo from "../../../images/Icons/standardIcon.png";
import DrawingLogo from "../../../images/Icons/drawingIcon.png";
import DocumentLogo from "../../../images/Icons/documentIcon.jpg";
import SILogo from "../../../images/Icons/SIIcon.png";

const MiniDashboardCountPage = () => {
    const [data, setData] = useState(null);
    useEffect(() => {
        getOpenCountDashbaord()
            .then((resp) => {
                setData(resp.data.results);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data.message);
                } else {
                    console.log(error);
                }
            });
    }, []);



    return (
        <div className="text-white">
            <div className="d-flex flex-column">
                <div className="mb-2">
                    <div className="card py-4 px-2" style={{ background: "#1b1b1b80"}} title="Please login to access the portal.">
                        <div className="d-flex align-items-center">
                            <img src={DrawingLogo} alt="" width={"50px"} height={"50px"} className="me-4" />
                            <div className='d-flex flex-column'>
                                <strong className='fs-3'>Drawings</strong>
                                <span className='fs-4'>{data?.drawing_count}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-2">
                    <div className="card py-4 px-3" style={{ background: "#1b1b1b80"}} title="Please login to access the portal.">
                        <div className="d-flex align-items-center">
                            <img src={StandardLogo} alt="" width={"50px"} height={"50px"} className="me-4" />
                            <div className='d-flex flex-column'>
                                <strong className='fs-3'>Standards</strong>
                                <span className='fs-4'>{data?.standard_count}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-2">
                    <div className="card py-4 px-3" style={{ background: "#1b1b1b80"}} title="Please login to access the portal.">
                        <div className="d-flex align-items-center">
                            <img src={DocumentLogo} alt="" width={"50px"} height={"50px"} className="me-4" />
                            <div className='d-flex flex-column'>
                                <strong className='fs-3'>Documents</strong>
                                <span className='fs-4'>{data?.document_count}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="my-2">
                    <div className="card py-4 px-3" style={{ background: "#1b1b1b80"}} title="Please login to access the portal.">
                        <div className="d-flex align-items-center">
                            <img src={SILogo} alt="" width={"50px"} height={"50px"} className="me-4" />
                            <div className='d-flex flex-column'>
                                <strong className='fs-3'>SI</strong>
                                <span className='fs-4'>{data?.si_count}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default MiniDashboardCountPage