import React, { useState } from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({
  currentPage,
  recordsPage,
  dataLength,
  handlePagination,
  handleGoTo = undefined,
  handleAllRecords = undefined,
}) => {
  const [goToPage, setGoToPage] = useState(currentPage || 1);
  const lastIndex = currentPage * recordsPage;
  const count = Number(Math.ceil(dataLength / recordsPage));
  const handleGoToChange = (e) => {
    let inputValue = e.target.value;
    if (isNaN(inputValue)) {
      setGoToPage("");
    } else {
      let numericValue = parseInt(inputValue, 10);
      if (numericValue < 1) {
        numericValue = 1;
      }
      if (numericValue > count) {
        numericValue = count;
      }
      setGoToPage(numericValue.toString());
    }
  };

  const onHandleGoTO = () => {
    if (!isNaN(goToPage) && goToPage !== currentPage) {
      handleGoTo(Number(goToPage));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onHandleGoTO();
    }
  };

  return (
    <div className="d-sm-flex text-center justify-content-between align-items-center">
      <div className="dataTables_info ps-2">
        Showing {lastIndex - recordsPage + 1} to{" "}
        {dataLength < lastIndex ? dataLength : lastIndex} of {dataLength}{" "}
        entries
      </div>
      {handleGoTo !== undefined ? (
        <div className="d-flex align-items-center">
          <label className="form-lable mb-0" htmlFor="goto">
            Go To
          </label>
          <input
            value={goToPage}
            style={{ width: "6rem", minHeight: "2rem" }}
            className="form-control form-control-sm mx-2"
            type="number"
            id="goto"
            placeholder="Page No"
            onKeyDown={handleKeyDown}
            onChange={handleGoToChange}
          />
          <button
            className="btn btn-sm btn-outline-primary btn-circle"
            onClick={onHandleGoTO}
          >
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        </div>
      ) : null}

      {handleAllRecords !== undefined ? (
        <button className="btn btn-sm btn-outline-primary" onClick={handleAllRecords}>Get All Records</button>
      ) : null}
      <div
        className="dataTables_paginate paging_simple_numbers justify-content-center"
        id="example2_paginate"
      >
        <ReactPaginate
          previousLabel={<i className="fa-solid fa-angle-left" />}
          nextLabel={<i className="fa-solid fa-angle-right" />}
          pageCount={count || 1}
          activeClassName="active"
          forcePage={currentPage !== 0 ? currentPage - 1 : 0}
          onPageChange={(page) => handlePagination(page)}
          pageClassName={"page-item"}
          pageRangeDisplayed={3}
          nextLinkClassName={"page-link"}
          nextClassName={"page-item next"}
          previousClassName={"page-item prev"}
          previousLinkClassName={"page-link"}
          pageLinkClassName={"page-link"}
          containerClassName={
            "pagination pagination-xs react-paginate justify-content-end my-2 pe-1"
          }
        />
      </div>
    </div>
  );
};
export default Pagination;
