import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

function BISForm({ handleSubmit, isLoading }) {
  const [standards, setStandards] = useState({
    standard_number: "",
    part_number: "",
    section_number: "",
    document_year: "",
    division: "",
    division_code: "",
    committee_code: "",
    committee_title: "",
    description: "",
    file: "",
  });

  const [standardsInputError, setStandardsInputError] = useState({
    standard_number: false,
  });

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const file = file_list[0];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (file_ext !== "PDF") {
        toast.error("Please choose a valid PDF file.");
      } else {
        setStandards({ ...standards, file: file });
      }
    }
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStandards({ ...standards, [name]: value });

    if (name === "standard_number") {
      setStandardsInputError({ ...standardsInputError, standard_number: value.trim() === "" });
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (standards.standard_number.trim() === "") {
      setStandardsInputError({ ...standardsInputError, standard_number: true });
    } else {
      handleSubmit(e, standards);
    }
  };

  return (
    <Form onSubmit={handleSubmitForm}>
      <div className="row">
        <div className="col-xl-6 col-sm-12">
          <div className="mb-3">
            <label htmlFor="standard_number" className="form-label">
              BIS Number <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${
                standardsInputError.standard_number && "is-invalid"
              }`}
              id="standard_number"
              placeholder="Enter BIS Number"
              name="standard_number"
              value={standards.standard_number}
              onChange={handleChange}
            />
            {standardsInputError.standard_number && (
              <div className="invalid-feedback mt-2">BIS No. is required.</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="part_number" className="form-label">
              Part Number
            </label>
            <input
              type="text"
              className="form-control"
              id="part_number"
              placeholder="Enter Part Number"
              name="part_number"
              value={standards.part_number}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="section_number" className="form-label">
              Section Number
            </label>
            <input
              type="text"
              className="form-control"
              id="section_number"
              placeholder="Enter Section Number"
              name="section_number"
              value={standards.section_number}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="document_year" className="form-label">
              Document Year
            </label>
            <input
              type="text"
              className="form-control"
              id="document_year"
              placeholder="Enter Document Year"
              name="document_year"
              value={standards.document_year}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <textarea
              type="text"
              className="form-control"
              id="description"
              placeholder="Enter Description"
              name="description"
              value={standards.description}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className="col-xl-6 col-sm-12 mx-auto">
          <div className="mb-3">
            <label htmlFor="division" className="form-label">
              Division
            </label>
            <input
              type="text"
              className="form-control"
              id="division"
              placeholder="Enter Division"
              name="division"
              value={standards.division}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="division_code" className="form-label">
              Division Code
            </label>
            <input
              type="text"
              className="form-control"
              id="division_code"
              placeholder="Enter Division Code"
              name="division_code"
              value={standards.division_code}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="committee_code" className="form-label">
              Committee Code
            </label>
            <input
              type="text"
              className="form-control"
              id="committee_code"
              placeholder="Enter Committee Code"
              name="committee_code"
              value={standards.committee_code}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="committee_title" className="form-label">
              Committee Title
            </label>
            <input
              type="text"
              className="form-control"
              id="committee_title"
              placeholder="Enter Committee Title"
              name="committee_title"
              value={standards.committee_title}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="pdfFile" className="form-label">
              Upload File
            </label>
            <input
              type="file"
              className="form-control"
              id="file"
              name="file"
              onChange={handleFileInputChange}
              accept=".pdf"
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-2">
        <button
          type="submit"
          className="btn btn-primary me-1 w-50"
          disabled={isLoading}
        >
         {isLoading ? (<span><Spinner size="sm" className="me-2" />Creating...</span>) : "Create"}
        </button>
      </div>
    </Form>
  );
}

export default BISForm;
