import React, { useState, useEffect, useRef } from 'react';

const DateTimeDisplay = ({ isCard = false }) => {
  const [dateTime, setDateTime] = useState(new Date());
  const canvasRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const radius = canvas.width / 2;

    ctx.translate(radius, radius);

    const drawClock = () => {
      ctx.clearRect(-radius, -radius, canvas.width, canvas.height);
      drawFace(ctx, radius);
      drawNumbers(ctx, radius);
      drawTime(ctx, radius);
    };

    const drawFace = (ctx, radius) => {
      ctx.beginPath();
      ctx.arc(0, 0, radius * 0.9, 0, 2 * Math.PI);
      ctx.fillStyle = 'white';
      ctx.fill();

      ctx.strokeStyle = '#FF9F00';
      ctx.lineWidth = radius * 0.1;
      ctx.stroke();

      ctx.beginPath();
      ctx.arc(0, 0, radius * 0.1, 0, 2 * Math.PI);
      ctx.fillStyle = '#333';
      ctx.fill();
    };

    const drawNumbers = (ctx, radius) => {
      ctx.font = `${radius * 0.15}px Arial`;
      ctx.textBaseline = 'middle';
      ctx.textAlign = 'center';
      for (let num = 1; num < 13; num++) {
        const ang = num * Math.PI / 6;
        ctx.rotate(ang);
        ctx.translate(0, -radius * 0.75);
        ctx.rotate(-ang);
        ctx.fillText(num.toString(), 0, 0);
        ctx.rotate(ang);
        ctx.translate(0, radius * 0.75);
        ctx.rotate(-ang);
      }
    };

    const drawTime = (ctx, radius) => {
      const now = new Date();
      let hour = now.getHours();
      let minute = now.getMinutes();
      let second = now.getSeconds();

      hour = hour % 12;
      hour = (hour * Math.PI / 6) + (minute * Math.PI / (6 * 60)) + (second * Math.PI / (360 * 60));
      drawHand(ctx, hour, radius * 0.5, radius * 0.05);

      minute = (minute * Math.PI / 30) + (second * Math.PI / (30 * 60));
      drawHand(ctx, minute, radius * 0.8, radius * 0.05);

      second = (second * Math.PI / 30);
      drawHand(ctx, second, radius * 0.9, radius * 0.02, 'red');
    };

    const drawHand = (ctx, pos, length, width, color = '#333') => {
      ctx.beginPath();
      ctx.lineWidth = width;
      ctx.lineCap = 'round';
      ctx.strokeStyle = color;
      ctx.moveTo(0, 0);
      ctx.rotate(pos);
      ctx.lineTo(0, -length);
      ctx.stroke();
      ctx.rotate(-pos);
    };

    const clockIntervalId = setInterval(drawClock, 1000);
    drawClock();

    return () => clearInterval(clockIntervalId);
  }, []);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const renderCard = () => (
    <div className="card my-2 ms-2 p-2">
      <div className="d-flex text-primary align-items-center">
        <canvas ref={canvasRef} id="clockCanvas" width="80" height="80"></canvas>
        <div className="d-flex text-primary align-items-center flex-column ms-3">
          <span className="fw-bold fs-6">{dateTime.toLocaleTimeString()}</span>
          <span className="fw-semibold fs-6">{formatDate(dateTime)}</span>
        </div>
      </div>
    </div>
  );

  const renderSimple = () => (
    <div className="d-flex flex-column text-primary">
      <span className="fw-semibold">{dateTime.toLocaleTimeString()}</span>
      <span className="fw-semibold">{formatDate(dateTime)}</span>
    </div>
  );

  return isCard ? renderCard() : renderSimple();
};

export default DateTimeDisplay;
