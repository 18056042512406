import React, { useState, useEffect } from "react";
import {
  getSliderList,
  createSlider,
  deleteSlider,
  updateSlider,
} from "../../../services/NoticeService";
import { StaticSelectInput } from "../../constant/SelectInput";
import Pagination from "../../constant/Pagination";
import { Modal, Button, Spinner, Dropdown, Form } from "react-bootstrap";
import { toast } from "react-toastify";

function Slider() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [currentMedia, setCurrentMedia] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mediaFile, setMediaFile] = useState(null);
  const [slider, setSlider] = useState({
    file: "",
    is_published: "YES",
  });
  const [isPublished, setIsPublished] = useState("ALL");
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({ open: false, type: "Add" });
  const [sliderToDelete, setSliderToDelete] = useState(null);
  const [showMediaModal, setShowMediaModal] = useState(false);
  const recordsPage = 10;

  useEffect(() => {
    setIsLoading(true);
    getSliderList(currentPage, recordsPage, isPublished)
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching slider data:", error);
        setIsLoading(false);
      });
  }, [currentPage, isPublished]);

  const toggleModal = (type = "Add", item = null) => {
    let open = isModalOpen.open;

    if (type === "Add") {
      open = !open;
    } else if (type === "Edit" && item) {
      setSlider({
        id: item.id,
        is_published: item.is_published ? "YES" : "NO",
      });
      open = true;
    } else if (type === "Delete" && item) {
      setSliderToDelete(item);
      open = true;
    }
    if (isModalOpen.open && item === null) {
      setSlider({
        is_published: "YES",
      });
    }
    setIsModalOpen({ type, open });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const file_ext = file.name.split('.').pop().toLowerCase()
      if (['jpg', 'png', 'jpeg', 'mp4'].includes(file_ext)) {
        setMediaFile(file);
      } else{
        toast.error("invalid file extention, required 'jpg', 'png', 'jpeg', 'mp4'")
      }
    }
  };


  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const handleChangeRadio = (e) => {
    const { name, value } = e.target;
    setSlider({ ...slider, [name]: value });
  };

  const handleSelectChange = (_name, value, _) => {
    setIsPublished(value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isModalOpen.type === "Add") {
      if (!mediaFile) {
        toast.error("Please select a file");
        return;
      }
      const formData = new FormData();
      formData.append("media", mediaFile);
      formData.append("is_published", slider.is_published)
      try {
        setIsSubmitLoading(true);
        const resp = await createSlider(formData);
        const resp_data = resp.data.results;
        setData([resp_data, ...data]);
        setTotal(total + 1);
        toast.success(resp.data.message);
        toggleModal();
      } catch (error) {
        console.error("Error creating slider:", error);
        toast.error(error.response.data.message);
      } finally {
        setIsSubmitLoading(false);
      }
    } else {
      try {
        setIsSubmitLoading(true);
        const resp = await updateSlider(slider);
        const { results } = resp.data;
        setData(
          data.map((item) => {
            if (item.id === results.id) {
              return results;
            } else {
              return item;
            }
          })
        );
        toast.success(resp.data.message);
        toggleModal();
      } catch (error) {
        console.log(error);
        if (error && error.response) {
          toast.error(error.response.data.message);
        }
      } finally {
        setIsSubmitLoading(false);
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      setIsSubmitLoading(true);
      await deleteSlider(id);
      setData(data.filter((media) => media.id !== id));
      toast.success("Slider deleted successfully");
      toggleModal();
    } catch (error) {
      console.error("Error deleting slider:", error);
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const openMediaModal = (media) => {
    setCurrentMedia(media);
    setShowMediaModal(true);
  };

  const closeMediaModal = () => {
    setCurrentMedia(null);
    setShowMediaModal(false);
  };

  const isImagefile = (file_name) => {
    const file_ext = file_name.split('.').pop().toLowerCase()
    if (['jpg', 'png', 'jpeg'].includes(file_ext)) {
      return true
    }
    return false
  };

  return (
    <>
      <div className="card p-4 pt-3">
        <div className="d-flex align-items-center justify-content-between mb-5">
          <div className="d-flex align-items-center">
            <h4 className="heading fw-bold mb-0 me-3">Slider</h4>
            <div style={{ width: "150px" }}>
              <StaticSelectInput
                setParentInputValue={handleSelectChange}
                parentClassName="mb-0"
                optionsList={[
                  {
                    label: "All Notice",
                    value: "ALL",
                  },
                  {
                    label: "Publish",
                    value: "YES",
                  },
                  {
                    label: "Not Publish",
                    value: "NO",
                  },
                ]}
                name="isPublished"
                id="isPublished"
                placeholder="Status"
                isRequired={true}
              />
            </div>
          </div>
          <button className="btn btn-primary" onClick={() => toggleModal()}>
            Add
          </button>
        </div>
        <div className="row">
          {data &&
            data.map((item) => (
              <div key={item.id} className="col-md-6 mb-3 position-relative">
                {isImagefile(item.media) ? (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${item.media}`}
                    alt="Thumbnail"
                    className="img-thumbnail"
                    onClick={() => openMediaModal(item.media)}
                  />
                ) : (
                  <video
                    src={`${process.env.REACT_APP_BASE_URL}${item.media}`}
                    alt="Thumbnail"
                    className="img-thumbnail"
                    controls
                    onClick={() => openMediaModal(item.media)}
                  />
                )}

                <div className="position-absolute top-0 end-0">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="link"
                      id={`dropdown-${item.id}`}
                      style={{
                        background: "transparent",
                        border: "none",
                        color: "rgba(0,0,0,0)",
                      }}
                    >
                      <button
                        type="button"
                        className={`btn btn-${item.is_published ? "success" : "primary"} btn-sm rounded-circle`}
                      >
                        <i className="fa-solid fa-ellipsis-vertical"></i>
                      </button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className="border border-primary"
                      style={{ minWidth: "30px" }}
                    >
                      <Dropdown.Item
                        className="text-primary mb-2"
                        onClick={() => toggleModal("Edit", item)}
                      >
                        <i className="fa-regular fa-pen-to-square"></i> Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => toggleModal("Delete", item)}
                      >
                        <i class="fa-solid fa-trash"></i> Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            ))}
        </div>
        {isLoading ? (
          <div style={{ height: "4rem" }}></div>
        ) : (
          <Pagination
            currentPage={currentPage}
            recordsPage={recordsPage}
            dataLength={total}
            handlePagination={handlePagination}
          />
        )}
      </div>

      <Modal
        className="modal modal-md fade"
        show={isModalOpen.open && isModalOpen.type === "Add"}
        onHide={() => toggleModal()}
        backdrop="static"
        keyboard={false}
      >
        <div className="modal-content">
          <div className="modal-header border-bottom">
            <h5 className="modal-title">Upload Slider</h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={onSubmit}>
              <div className="mb-3">
                <label htmlFor="formFile" className="form-label">
                  Choose Media (Image or Video) <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  name="file"
                  accept=".jpg, .png, .jpeg, .mp4"
                  onChange={handleFileChange}
                />
              </div>
              <div className="mb-3">
                <label className="me-3">Publish</label>
                <div className="form-check custom-checkbox mb-2 form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="is_published"
                    id="yes_is_published"
                    value="YES"
                    checked={slider.is_published === "YES"}
                    onChange={handleChangeRadio}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="yes_is_published"
                  >
                    YES
                  </label>
                </div>
                <div className="form-check custom-checkbox mb-2 form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="is_published"
                    id="no_is_published"
                    value="NO"
                    checked={slider.is_published === "NO"}
                    onChange={handleChangeRadio}
                  />
                  <label className="form-check-label" htmlFor="no_is_published">
                    NO
                  </label>
                </div>
              </div>
              <Button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                disabled={isSubmitLoading}
              >
                {isSubmitLoading ? (
                  <span>
                    <Spinner animation="border" size="sm" className="me-2" />
                    Creating...
                  </span>
                ) : (
                  "Create"
                )}
              </Button>
            </Form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal modal-md fade"
        id="exampleModal1"
        show={isModalOpen.open && isModalOpen.type === "Edit"}
        onHide={() => toggleModal()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Edit Slider
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal()}
            ></button>
          </div>
          <div className="modal-body">
            {slider && (
              <Form onSubmit={onSubmit}>
                <div className="row">
                  <div className="mb-3">
                    <label className="me-3">Publish</label>
                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="is_published"
                        id="yes_is_published"
                        value="YES"
                        checked={slider.is_published === "YES"}
                        onChange={handleChangeRadio}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="yes_is_published"
                      >
                        YES
                      </label>
                    </div>
                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="is_published"
                        id="no_is_published"
                        value="NO"
                        checked={slider.is_published === "NO"}
                        onChange={handleChangeRadio}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="no_is_published"
                      >
                        NO
                      </label>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-block btn-sm btn-primary mt-3 float-end"
                  disabled={isSubmitLoading}
                >
                  {isSubmitLoading ? (
                    <span>
                      <Spinner variant="light" size="sm" className="me-2" />
                      Updating...
                    </span>
                  ) : (
                    "Update"
                  )}
                </button>
              </Form>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        className="modal modal-sm fade"
        backdrop="static"
        keyboard={false}
        centered={true}
        show={isModalOpen.open && isModalOpen.type === "Delete"}
        onHide={() => toggleModal()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Delete Slider
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal()}
            ></button>
          </div>
          <div className="modal-body">
            <p className="text-center">slider Will be Deleted</p>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <button
                type="button"
                onClick={() => handleDelete(sliderToDelete.id)}
                className="btn btn-primary"
                disabled={isSubmitLoading}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal modal-md fade"
        show={showMediaModal}
        onHide={closeMediaModal}
        backdrop="static"
        keyboard={false}
      >
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              onClick={closeMediaModal}
            ></button>
          </div>
          <div className="modal-body">
            {currentMedia && (
              <div>
                {isImagefile(currentMedia) ? (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${currentMedia}`}
                    alt="Full media"
                    className="img-fluid w-100"
                  />
                ) : (
                  <video
                    src={`${process.env.REACT_APP_BASE_URL}${currentMedia}`}
                    alt="Full media"
                    className="img-fluid w-100"
                    controls
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Slider;
