import React from "react";
import TableLoaderEffect from "../../../constant/TableLoader";
import { useNavigate } from "react-router-dom";
import FileDownloader from "../DownloadBtn";
const maxWidthStyle300 = {
  maxWidth: "30rem",
  minWidth: "10rem",
  whiteSpace: "normal",
  display: "block",
};


const ProjectSubmittedDrawingsPage = ({ data, isLoading, userRole }) => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
          <div
            id="employee-tbl_wrapper"
            className="dataTables_wrapper no-footer"
          >
            <table
              id="empoloyees-tblwrapper"
              className="table ItemsCheckboxSec dataTable no-footer mb-0 table-striped"
            >
              <thead>
                <tr>
                  <th>PSD Number</th>
                  <th>Department</th>
                  <th>Unit</th>
                  <th>Title</th>
                  <th>Description</th>
                  {userRole !== "User" && <th>Status</th>}
                  <th>Download</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <TableLoaderEffect colLength={userRole === "User" ? 6 : 7} />
                ) : (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <span
                          onClick={() => navigate(`/documents/${item.id}`)}
                          className="text-primary cursor-pointer fw-bolder"
                        >
                          {item.manual_no}
                        </span>
                      </td>
                      <td>{item.department ? item.department.name : "None"}</td>
                      <td>{item.unit ? item.unit.name : "None"}</td>
                      <td>{item.title}</td>
                      <td><span style={maxWidthStyle300}>{item.description}</span></td>
                      {userRole !== "User" &&
                        <td>
                          {item.is_approved ? (
                            <span className={`badge light border-0 badge-success badge-sm`}>APPROVED</span>
                          ) : (
                            <span className={`badge light border-0 badge-info badge-sm`}>PENDING</span>
                          )}
                        </td>
                      }
                      <td>
                      {item.is_file ? <span>
                      {userRole !== "User" ? <FileDownloader id={item.id} fileName={item.is_file} isDownload={true} />
                      : item.file_type === "PDF" ? <FileDownloader id={item.id} fileName={item.is_file} isDownload={true} /> 
                      : "Contact Admin"}
                      </span> : "File NP"}
                      </td>

                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectSubmittedDrawingsPage;
