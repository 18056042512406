import axiosInstance from "./AxiosInstance";
const apiUrl = "api/standard";  

export function getStandardList(page, page_size, query, standard_type, rsn_volume, group) {
  return axiosInstance.get(
    `${apiUrl}?page=${page}&page_size=${page_size}&query=${query}&standard_type=${standard_type}&rsn_volume=${rsn_volume}&group=${group}`
  );
}

export function getStandard(id) {
  return axiosInstance.get(`${apiUrl}/${id}`);
}

export function createStandard(data) {
  return axiosInstance.post(apiUrl, data);
}

export function updateStandard(data) {
  return axiosInstance.put(apiUrl, data);
}

export function uploadFile(id, file) {
  return axiosInstance.post(`api/standard_file_upload/${id}` , file)
}

export function downloadStandardPDFFiles(id) {
  return axiosInstance.get(`api/standard_file_download/${id}`, {
    responseType: "blob",
  });
}


export function getStandardLogList(id, page, page_size, query) {
  return axiosInstance.get(
    `api/standard_log/${id}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function updateStandardStatus(data) {
  return axiosInstance.post('api/standard_archive', data);
}

export function approveStandard(data) {
  return axiosInstance.post("api/standard_approve", data);
}

export function getBulkStandardList(query, standard_type) {
  return axiosInstance.get(
    `api/standard_bulk_archive?query=${query}&standard_type=${standard_type}`
  );
}

export function updateBulkStandardList(data) {
  return axiosInstance.put("api/standard_bulk_archive", data);
}

export function archiveStandardList(query, standard_type) {
  return axiosInstance.get(
    `api/standard_archive?query=${query}&standard_type=${standard_type}`
  );
}

export function removeArchiveStandardList(data) {
  return axiosInstance.put("api/standard_archive", data);
}

export function permanentDeleteStandardList(data) {
  return axiosInstance.post("api/standard_permanent_delete", data);
}

export function approveStandardList(standard_type) {
  return axiosInstance.get(`api/standard_approve?standard_type=${standard_type}`);
}

export function bulkApproveStandard(data) {
  return axiosInstance.put("api/standard_approve", data);
}

export function bulkUploadStandardData(data) {
  return axiosInstance.post('api/standard_bulk_upload' , data)
}

export function getStandardCount(status_type) {
  return axiosInstance.get(`api/standard_count?status_type=${status_type}`);
}