import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import TableLoaderEffect from "../../constant/TableLoader";
import Pagination from "../../constant/Pagination";
import {
  getSIRLogList,
  getStabilityCertificationLogList,
  getComplianceLogList,
  getDocumentLogList
} from "../../../services/SIService";
import { Spinner } from "react-bootstrap";

const LogPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const recordsPage = 10;
  const [showModal, setShowModal] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchQuery.length > 0) {
      setIsLoading(true);
    }
    if (id) {
      if (id.startsWith("sir_")) {
        getSIRLogList(id, currentPage, recordsPage, searchQuery)
          .then((resp) => {
            setData(resp.data.results);
            setTotal(resp.data.count);
            setIsLoading(false);
            setIsSearchLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.message);
            }
            setIsLoading(false);
            setIsSearchLoading(false);
          });
      } else if (id.startsWith("stc_")) {
        getStabilityCertificationLogList(
          id,
          currentPage,
          recordsPage,
          searchQuery
        )
          .then((resp) => {
            setData(resp.data.results);
            setTotal(resp.data.count);
            setIsLoading(false);
            setIsSearchLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.message);
            }
            setIsLoading(false);
            setIsSearchLoading(false);
          });
      } else if (id.startsWith("com_")) {
        getComplianceLogList(id, currentPage, recordsPage, searchQuery)
          .then((resp) => {
            setData(resp.data.results);
            setTotal(resp.data.count);
            setIsLoading(false);
            setIsSearchLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.message);
            }
            setIsLoading(false);
            setIsSearchLoading(false);
          });
      } else {
        getDocumentLogList(id, currentPage, recordsPage, searchQuery)
          .then((resp) => {
            setData(resp.data.results);
            setTotal(resp.data.count);
            setIsLoading(false);
            setIsSearchLoading(false);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data.message);
            }
            setIsLoading(false);
            setIsSearchLoading(false);
          });
      }
    }
  }, [id, currentPage, recordsPage, searchQuery]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setIsSearchLoading(true);
    setCurrentPage(1);
    setSearchQuery(value);
  };

  const toggleModal = (details = null) => {
    setSelectedDetails(details);
    setShowModal(!showModal);
  };

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="d-flex justify-content-between text-wrap flex-column  flex-sm-row align-items-start align-items-sm-center p-3">
                  <div className="d-flex align-items-center">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-circle me-3"
                      onClick={() => navigate(`/si${id.startsWith("stc_") ? "?type=stability_certificate" : id.startsWith("com_") ? "?type=compliance" : "?type=document"}`)}
                    >
                      <i className="fa-solid fa-arrow-left"></i>
                    </button>
                    <h4 className="heading mb-3 mb-md-0">
                      {id.startsWith("sir_")
                        ? "STRUCTURAL INSPECTION REPORT"
                        : id.startsWith("stc_")
                          ? "Stability Certificate"
                          : id.startsWith("com_")
                            ? "Compliance"
                            : "Document"}
                    </h4>
                  </div>
                  <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                    <div className="input-group tp-input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={searchQuery}
                        aria-describedby="basic-search"
                        onChange={handleSearchChange}
                      />
                      <div className="input-group-text" id="basic-search">
                        {isSearchLoading && (
                          <Spinner variant="primary" size="sm" />
                        )}
                        {!isSearchLoading && searchQuery.length > 0 && (
                          <span
                            className="c-pointer"
                            onClick={() => {
                              setCurrentPage(1);
                              setSearchQuery("");
                            }}
                          >
                            <i className="fa-solid fa-xmark text-primary"></i>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>Log</th>
                          <th>Message</th>
                          <th>User</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <TableLoaderEffect colLength={4} />
                        ) : (
                          data.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <span
                                  className="text-primary cursor-pointer fw-bolder"
                                  onClick={() => toggleModal(item)}
                                >
                                  {item.status}
                                </span>
                              </td>
                              <td>{item.message}</td>
                              <td>{item.user.full_name}</td>
                              <td>{item.action_time}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    recordsPage={recordsPage}
                    dataLength={total}
                    handlePagination={handlePagination}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal fade"
        id="exampleModal1"
        show={showModal}
        onHide={toggleModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Log Details
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {selectedDetails &&
              selectedDetails.details &&
              selectedDetails.details.split("|").map((detail, index) => (
                <div className="mb-3" key={index}>
                  {detail}
                </div>
              ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LogPage;
