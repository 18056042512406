import React from "react";
import TableLoaderEffect from "../../../constant/TableLoader";
const maxWidthStyle300 = {
  maxWidth: "30rem",
  minWidth: "10rem",
  whiteSpace: "normal",
  display: "block",
};

const BISArchivePage = ({
  selectedStandards,
  standardList,
  isLoading,
  toggleCheckbox,
  handleSelectAll,
  navigate,
}) => {
  return (
    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
      <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
        <table
          id="empoloyees-tblwrapper"
          className="table ItemsCheckboxSec dataTable no-footer mb-0"
        >
          <thead>
            <tr>
              <th>
                <div className="form-check custom-checkbox checkbox-primary">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      selectedStandards.length === standardList.length &&
                      standardList.length !== 0
                    }
                    onChange={handleSelectAll}
                  />
                </div>
              </th>
              <th>BIS No.</th>
              <th>Part No.</th>
              <th>Section No.</th>
              <th>Document Year</th>
              <th>Division</th>
              <th>Division Code</th>
              <th>Status</th>
              <th>Description</th>
              <th>Archive Reason</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
              <TableLoaderEffect colLength={6} />
              </tr>
            ) : (
              standardList.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="form-check custom-checkbox checkbox-primary">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={selectedStandards.includes(item.id)}
                        onChange={() => toggleCheckbox(item.id)}
                      />
                    </div>
                  </td>
                  <td>
                        <span
                          className="text-primary cursor-pointer fw-bolder"
                          onClick={() => navigate(`/standards/${item.id}`)}
                        >
                          {item.standard_no}
                        </span>
                      </td>
                      <td>{item.part_no}</td>
                      <td> {item.section_no}</td>
                      <td> {item.document_year}</td>
                      <td> {item.division}</td>
                      <td> {item.division_code}</td>
                      <td>
                        {item.is_approved === true ? (
                          <span
                            className={`badge light border-0 badge-success badge-sm`}
                          >
                            Approved
                          </span>
                        ) : (
                          <span
                            className={`badge light border-0 badge-info badge-sm`}
                          >
                            Pending
                          </span>
                        )}
                      </td>
                      <td>
                        <span style={maxWidthStyle300}>
                          
                          {item.description}
                        </span>
                      </td>
                      <td>
                  
                  <span style={maxWidthStyle300}>
                    {item.archive_reason}
                  </span>
                </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BISArchivePage;
