import React, { useState } from 'react'
import { Modal, Form, Button, Spinner } from 'react-bootstrap'
import { exportDatatoExcel } from '../../../services/LogService'
import { minusDaysToDate } from '../../../utils';
const DrawingFileDownloadModal = ({ exportType }) => {
    const DrawingTypeList = ["PDR", "CDBR", "PS", "RS", "FDR", "MISC"]
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)
    const [inputDate, setInputDate] = useState({ start_date: minusDaysToDate(30), end_date: minusDaysToDate(0) })
    const [drawingTypes, setDrawingTypes] = useState(DrawingTypeList)

    const toggleModal = () => setIsModalOpen(!isModalOpen)

    const handleOnSubmit = (e) => {
        e.preventDefault()
        setIsSubmitLoading(true)
        const {start_date, end_date} = inputDate
        const url =  `${process.env.REACT_APP_BASE_URL}/api/download_drawing_zip_file?&drawing_types=${drawingTypes}&start_date=${start_date}&end_date=${end_date}`
        window.open(url, '_blank')
        setIsSubmitLoading(false);
        toggleModal()
    }

    // const handleOnSubmit = (e) => {
    //     e.preventDefault();
    //     setIsSubmitLoading(true);
    //     const {start_date, end_date} = inputDate;
    //     const url = `${process.env.REACT_APP_BASE_URL}/api/download_drawing_zip_file?&drawing_types=${drawingTypes}&start_date=${start_date}&end_date=${end_date}`;
    //     // Create an iframe
    //     const iframe = document.createElement('iframe');
    //     iframe.style.display = 'none';
    //     iframe.src = url;
    
    //     // Append the iframe to the document body
    //     document.body.appendChild(iframe);
    //     iframe.onload = () => {
    //         document.body.removeChild(iframe);
    //         setIsSubmitLoading(false);
    //         toggleModal();
    //     };
        
    //     // incase iframe dos not perform onload 
    //     setTimeout(() => {
    //         if (document.body.contains(iframe)) {
    //             document.body.removeChild(iframe);
    //             setIsSubmitLoading(false);
    //             toggleModal();
    //         }
    //     }, 5000); // Adjust the timeout duration as needed
    // };
    

    const handleDateChange = (e) => {
        const { name, value } = e.target
        setInputDate({ ...inputDate, [name]: value })
    }

    const handleDrawingTypeChange = (e) => {
        const { checked, value } = e.target
        if (checked) {
            if (!drawingTypes.includes(value)) {
                setDrawingTypes((prevtype) => [...prevtype, value])
            }
        } else {
            if (drawingTypes.includes(value)) {
                setDrawingTypes((prevs) => prevs.filter(item => item !== value))
            }
        }
    }

    return (
        <div>
            {exportType === "Drawing" && <button className='btn btn-primary'
                onClick={toggleModal}><i className="fa-solid fa-file-zipper"></i> <span className="ms-2">Download Drawing Files</span></button>}
            <Modal
                className="modal modal-md fade"
                show={isModalOpen}
                onHide={() => toggleModal()}
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-content">
                    <div className="modal-header border-bottom">
                        <h5 className="modal-title">Download Drawing Files</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={toggleModal}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={handleOnSubmit}>
                            <div className='row'>
                                <div className="mb-3 col-sm-6">
                                    <label htmlFor="startDate" className="form-label">
                                        Start Date <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="startDate"
                                        name="start_date"
                                        value={inputDate.start_date}
                                        onChange={handleDateChange}
                                    />
                                </div>
                                <div className="mb-3 col-sm-6">
                                    <label htmlFor="endDate" className="form-label">
                                        End Date <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="endtDate"
                                        name="end_date"
                                        value={inputDate.end_date}
                                        onChange={handleDateChange}
                                    />
                                </div>
                            </div>

                            <div className='mb-3'>
                                <h5>Select Drawing Type</h5>
                                <div className='ps-2'>
                                    {DrawingTypeList.map(item => (
                                        <div className="form-check custom-checkbox checkbox-primary" key={item}>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={item}
                                                checked={drawingTypes.includes(item)}
                                                onChange={handleDrawingTypeChange}
                                            />
                                            <label className='form-label ms-1'>{item}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <Button
                                type="submit"
                                className="btn btn-block btn-primary mt-3 float-end"
                                disabled={isSubmitLoading}>
                                {isSubmitLoading ? (
                                    <span>
                                        <Spinner animation="border" size="sm" className="me-2" />
                                        Downloading...
                                    </span>
                                ) : (
                                    "Download"
                                )}
                            </Button>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>

    )
}

export default DrawingFileDownloadModal