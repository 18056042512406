import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import StabilityCertification from "./StabilityCertification";
import Compliance from "./Compliance";
import SIRPage from "./SIR";
import DocumentPage from "./Document";

const SIPage = () => {
  const [tabKey, setTabKey] = useState("sir");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const siType = queryParams.get("type");
    if (
      siType &&
      ["sir", "stability_certificate", "compliance", "document"].includes(siType)
    ) {
      setTabKey(siType);
      navigate(location.pathname, { replace: true });
    }
  }, [location.search]);

  const tabData = [
    { id:"sir", name: "STRUCTURAL INSPECTION REPORT", content: <SIRPage /> },
    {
      id:"stability_certificate", name: "STABILITY CERTIFICATE",
      content: <StabilityCertification />,
    },
    { id:"compliance", name: "COMPLIANCE", content: <Compliance /> },
    { id:"document", name: "DOCUMENT", content: <Compliance /> },
  ];

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="custom-btn-tab">
                <Tab.Container
                  activeKey={tabKey}
                  onSelect={(k) => setTabKey(k)}
                >
                  <Nav
                    as="ul"
                    className="nav-pills mb-2 light border-bottom px-4 pt-3"
                  >
                    {tabData.map((tab) => (
                      <Nav.Item as="li" key={tab.id}>
                        <Nav.Link
                          eventKey={tab.id}
                          className="pb-3 fw-semibold fs-5"
                        >
                          {tab.name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content className="">
                      <Tab.Pane eventKey="sir">
                        <SIRPage activeKey={tabKey} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="stability_certificate">
                        <StabilityCertification activeKey={tabKey} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="compliance">
                        <Compliance activeKey={tabKey} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="document">
                        <DocumentPage activeKey={tabKey} />
                      </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SIPage;
