import {
    createDrawing,
    getDrawing,
    getDrawingList,
    uploadDrawingFiles,
    getDrawingDecsriptionList,
    getDrawingLogList
} from '../../services/DrawingService';
export const CREATE_DRAWING_TYPE = '[Drawing Action] Create Drawing';
export const GET_DRAWING_TYPE = '[Drawing Action] Get DRAWING';
export const GET_DRAWING_LIST_TYPE = '[Drawing Action] Get Drawing List';
export const UPDATE_DRAWING_TYPE = '[Drawing Action] Update Drawing';
export const DRAWING_FILE_UPLOAD_TYPE = '[Drawing Action] Drawing File Upload';

export const GET_DRAWING_DESCRIPTION_LIST_TYPE = '[Drawing Action] Get Drawing Description List';
export const UPDATE_DRAWING_DESCRIPTION_TYPE = '[Drawing Action] Update Drawing Description';

export const GET_DRAWING_LOG_LIST_TYPE = '[Drawing Action] Get Drawing Log List';

export const LOADING_TYPE = '[Drawing Action] Handle Loading';
export const SHOW_TOAST_ERROR = '[Drawing Action] Show Error';
export const UPDATE_DRAWING_FILTER_TYPE = '[Drawing Action] Update Drawing Filter';
export const RESET_DRAWING_STATE_TYPE = '[Drawing Action] RESET DRAWING STATE';
//export const HANDLE_STANDARD_FILTER_STATE = '[FIlter Action] HANDLE STANDARD FILTER STATE';




function callbackAction(type, payload) {
    return { type, payload };
}

export function createDrawingAction(rackData) {
    return (dispatch, getState) => {
        createDrawing(rackData).then((response) => {
            dispatch(callbackAction(CREATE_DRAWING_TYPE, response.data.results));
        }).catch((error) => {
            dispatch(showToastError(error.response.data.message))
            dispatch(callbackAction(CREATE_DRAWING_TYPE, null));
        });
    };
}

export function updateDrawingAction(payload) {
    return { type: UPDATE_DRAWING_TYPE, payload };
}

export function getDrawingAction(id) {
    return (dispatch, getState) => {
        getDrawing(id).then((response) => {
            dispatch(callbackAction(GET_DRAWING_TYPE, response.data.results));
        }).catch((error) => {
            dispatch(showToastError(error.response.data.message))
            dispatch(callbackAction(GET_DRAWING_TYPE, null));
        });
    };
}

export function getDrawingListAction(page, page_size, query,
    department_id,
    unit_id,
    drawing_type, work_order_number, description, file_present='', status='') {
    return (dispatch, getState) => {
        getDrawingList(page, page_size, query,
            department_id, unit_id, drawing_type, work_order_number, description, file_present, status
        ).then((response) => {
            const data = response !== undefined ? response.data : "REQUEST_CANCELED"
            dispatch(callbackAction(GET_DRAWING_LIST_TYPE, data));
        }).catch((error) => {
            dispatch(callbackAction(GET_DRAWING_LIST_TYPE, null));
        });;
    };
}

export function DrawingFileUploadAction(stockData) {
    return (dispatch, getState) => {
        uploadDrawingFiles(stockData).then((response) => {
            dispatch(callbackAction(DRAWING_FILE_UPLOAD_TYPE, response.data.results));
        }).catch((error) => {
            console.log(error)
            dispatch(showToastError(error.response.data.message))
            dispatch(callbackAction(DRAWING_FILE_UPLOAD_TYPE, null));
        });
    };
}

export function getDrawingDescriptionListAction(id, page, page_size, query) {
    return (dispatch, getState) => {
        getDrawingDecsriptionList(id, page, page_size, query).then((response) => {
            const data = response ? response.data : null
            dispatch(callbackAction(GET_DRAWING_DESCRIPTION_LIST_TYPE, data));
        }).catch(error => {
            dispatch(callbackAction(GET_DRAWING_DESCRIPTION_LIST_TYPE, null));
        });
    };
}

export function getNewDrawingDescriptionListAction(payload) {
    return { type: GET_DRAWING_DESCRIPTION_LIST_TYPE, payload };
}
export function updateDrawingDescriptionAction(payload) {
    return { type: UPDATE_DRAWING_DESCRIPTION_TYPE, payload };
}

export function updateDrawingFilterAction(payload) {
    return { type: UPDATE_DRAWING_FILTER_TYPE, payload };
}

export function getDrawingLogListAction(id, page, page_size, query) {
    return (dispatch, getState) => {
        getDrawingLogList(id, page, page_size, query).then((response) => {
            const data = response ? response.data : null
            dispatch(callbackAction(GET_DRAWING_LOG_LIST_TYPE, data));
        }).catch(error => {
            dispatch(callbackAction(GET_DRAWING_LOG_LIST_TYPE, null));
        });;
    };
}

export function getNewDrawingLogListAction(payload) {
    return { type: GET_DRAWING_LOG_LIST_TYPE, payload };
}

export function handleLoadingAction(payload) {
    return { type: LOADING_TYPE, payload };
}

export function handleResetDrawingState() {
    return { type: RESET_DRAWING_STATE_TYPE };
}

export function showToastError(error) {
    return {
        type: SHOW_TOAST_ERROR,
        payload: error,
    };
}

