import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import DynamicSelectInput from "../../../constant/SelectInput";
import { toast } from "react-toastify";
import Pagination from "../../../constant/Pagination";
import {
    getSubVolumeList,
    createSubVolume,
    updateSubVolume,
    deleteSubVolume
} from "../../../../services/StaticDataListService";
import TableLoaderEffect from "../../../constant/TableLoader";
function SubVolumePage({ userRole }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [replaceSubVolume, setReplaceSubVolume] = useState(null);
    const [selectedVolume, setSelectedVolume] = useState(null)
    const [volumeInput, setVolumeInput] = useState({
        volume_id: "",
        name: "",
        sub_volume_no: "",
    });
    const [volumeInputError, setVolumeInputError] = useState({
        volume_id: false,
        name: false,
        sub_volume_no: false,
    });
    const [isModalOpen, setIsModalOpen] = useState({ open: false, type: "Add" });
    const [total, setTotal] = useState(0);
    const recordsPage = 10;

    useEffect(() => {
        if (!searchQuery.length > 0) {
            setIsLoading(true);
        }
        getSubVolumeList(currentPage, recordsPage, searchQuery)
            .then((resp) => {
                setData(resp.data.results);
                setTotal(resp.data.count);
                setIsLoading(false);
                setIsSearchLoading(false);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data.message);
                } else {
                    console.log(error);
                }
                setIsLoading(false);
                setIsSearchLoading(false);
            });
    }, [currentPage, searchQuery]);

    const toggleModal = (type = "Add", item = null) => {
        let open = isModalOpen.open;

        if (type === "Add") {
            open = !open;
        } else if (["Edit", "Delete"].includes(type) && item) {
            setSelectedVolume(item)
            setVolumeInput({
                id: item.id,
                volume_id: item.volume ? item.volume.id : "",
                name: item.name,
                sub_volume_no: item.sub_volume_no,
            });
            open = true;
        }
        if (isModalOpen.open && item === null) {
            setVolumeInput({
                volume_id: "",
                name: "",
                sub_volume_no: "",
            });
            setVolumeInputError({
                volume_id: false,
                name: false,
                sub_volume_no: false,
            });
            setSelectedVolume(null)
            setReplaceSubVolume(null)
        }
        setIsModalOpen({ type, open });
    };

    const handleSearchChange = (e) => {
        const { value } = e.target;
        setIsSearchLoading(true);
        setCurrentPage(1);
        setSearchQuery(value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedVolumeInput = { ...volumeInput, [name]: value };
        const updatedVolumeInputError = {
            ...volumeInputError,
            [name]: value.trim() === "",
        };
        setVolumeInput(updatedVolumeInput);
        setVolumeInputError(updatedVolumeInputError);
    };

    const handleSelectChange = (name, value, _) => {
        if (name === "volume_id") {
            const updatedVolumeInputError = { ...volumeInputError, [name]: value === null || value === undefined };
            setVolumeInputError(updatedVolumeInputError);
        }
        setVolumeInput({ ...volumeInput, [name]: value });
    };


    const handlePagination = (page) => {
        const new_page = page.selected + 1;
        setCurrentPage(new_page);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const errors = {};
        Object.keys(volumeInput).forEach((key) => {
            if (!volumeInput[key]) {
                errors[key] = true;
            }
        });
        setVolumeInputError(errors);

        if (Object.keys(errors).length > 0) {
            return;
        }

        if (isModalOpen.type === "Add") {
            try {
                setIsSubmitLoading(true);
                const resp = await createSubVolume(volumeInput);
                const resp_data = resp.data.results;
                setData([resp_data, ...data]);
                setTotal(total + 1);
                toast.success(resp.data.message);
                toggleModal();
            } catch (error) {
                toast.error(error.response.data.message);
            }
            finally {
                setIsSubmitLoading(false);
            }
        } else {
            try {
                setIsSubmitLoading(true);
                const response = await updateSubVolume(volumeInput);
                const resp_data = response.data.results;
                setData(
                    data.map((item) =>
                        item.id === resp_data.id ? { ...resp_data } : item
                    )
                );
                toast.success(response.data.message);
                toggleModal();
            } catch (error) {
                console.log(error);
                if (error && error.response) {
                    toast.error(error.response.data.message);
                }
            }
            finally {
                setIsSubmitLoading(false);
            }
        }
    };

    const handleDeleteSelectChange = (name, value, _) => {
        setReplaceSubVolume(value);
    };


    const onDeleteSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsSubmitLoading(true)
            const resp = await deleteSubVolume(volumeInput.id, replaceSubVolume);
            const resp_data = resp.data.results;
            setData(data.filter(item => item.id !== resp_data));
            setTotal(total - 1);
            toast.success(resp.data.message);
            toggleModal();
        } catch (error) {
            toast.error(error.response.data.message);
        }
        finally {
            setIsSubmitLoading(false)
        }
    }


    return (
        <>
            <div>
                <div className="d-flex justify-content-between text-wrap flex-column  flex-sm-row align-items-start align-items-sm-center p-3">
                    <h4 className="heading mb-3 mb-md-0">RS SubVolume</h4>
                    <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                        <div className="me-3 mb-md-0">
                            <div className="input-group tp-input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    value={searchQuery}
                                    aria-describedby="basic-search"
                                    onChange={handleSearchChange}
                                />
                                <div className="input-group-text" id="basic-search">
                                    {isSearchLoading && <Spinner variant="primary" size="sm" />}
                                    {!isSearchLoading && searchQuery.length > 0 && (
                                        <span
                                            className="c-pointer"
                                            onClick={() => {
                                                setCurrentPage(1);
                                                setSearchQuery("");
                                            }}
                                        >
                                            <i className="fa-solid fa-xmark text-primary"></i>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                        {userRole !== "User" && (
                            <button
                                className="btn btn-primary mt-3 mt-md-0"
                                onClick={() => toggleModal()}
                            >
                                Add
                            </button>
                        )}
                    </div>
                </div>
                <div className="card-body">
                    <div
                        className="widget-media dz-scroll  ps--active-y"
                        style={{ height: "70vh" }}
                    >
                        <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                            <div
                                id="employee-tbl_wrapper"
                                className="dataTables_wrapper no-footer"
                            >
                                <table
                                    id="empoloyees-tblwrapper"
                                    className="table ItemsCheckboxSec dataTable no-footer mb-0"
                                >
                                    <thead>
                                        <tr>
                                            <th>Sub Volume Number</th>
                                            <th>Volume</th>
                                            <th>Sub Volume Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {isLoading ? (
                                            <TableLoaderEffect colLength={3} />
                                        ) : (
                                            data.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.sub_volume_no}</td>
                                                    <td>
                                                        <span>{item.volume.name}</span>
                                                    </td>
                                                    <td>{item.name}</td>
                                                    <td>
                                                        <div className="d-flex">
                                                            <Button
                                                                onClick={() => toggleModal("Edit", item)}
                                                                variant="btn btn-primary tp-btn btn-circle"
                                                            >
                                                                <i className="fa-regular fa-pen-to-square"></i>
                                                            </Button>
                                                            <Button variant="btn btn-danger tp-btn btn-circle ms-2"
                                                                onClick={() => toggleModal("Delete", item)}>
                                                                <i className="fa-regular fa-trash-can"></i>
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        recordsPage={recordsPage}
                        dataLength={total}
                        handlePagination={handlePagination}
                    />
                </div>
            </div>
            {/* card end */}

            <Modal
                className="modal modal-sm fade"
                id="exampleModal1"
                show={isModalOpen.open && isModalOpen.type === "Add"}
                onHide={() => toggleModal()}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">
                            Add Volume
                        </h1>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => toggleModal()}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={onSubmit}>
                            <div className="row">
                                <div className="col-xl-12">
                                    <label htmlFor="rsn_volume" className="form-label">
                                        Volume<span className="text-danger">*</span>
                                    </label>
                                    <DynamicSelectInput
                                        setParentInputValue={handleSelectChange}
                                        endpoint_name="search/rs_volume"
                                        name={"volume_id"}
                                        id={"volume_id"}
                                        placeholder={"Select Volume"}
                                        isRequired={true}
                                        className={`mb-3 ${volumeInputError.volume_id ? "is-invalid" : ""
                                            }`}
                                        is_invalid={volumeInputError.volume_id}
                                    />
                                </div>
                                <div className="col-xl-12">
                                    <label className="form-label">
                                        Sub Volume<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        autoFocus
                                        id="name"
                                        name="name"
                                        placeholder="Enter Volume Name"
                                        value={volumeInput.name}
                                        onChange={handleChange}
                                        className={`form-control mb-2 ${volumeInputError.name ? "is-invalid" : ""
                                            }`}
                                    />
                                    {volumeInputError.name && (
                                        <div className="invalid-feedback mb-2">Sub Volume Name is required</div>
                                    )}
                                </div>
                                <div className="col-xl-12">
                                    <label className="form-label">
                                        Sub Volume No.<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="sub_volume_no"
                                        name="sub_volume_no"
                                        placeholder="Enter Sub Volume No."
                                        value={volumeInput.sub_volume_no}
                                        onChange={handleChange}
                                        className={`form-control mb-2 ${volumeInputError.sub_volume_no ? "is-invalid" : ""
                                            }`}
                                    />
                                    {volumeInputError.sub_volume_no && (
                                        <div className="invalid-feedback mb-2">Sub Volume Number is required</div>
                                    )}
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                                disabled={isSubmitLoading}
                            >
                                {isSubmitLoading ? (
                                    <span>
                                        <Spinner size="sm" className="me-2" />
                                        Creating...
                                    </span>
                                ) : (
                                    "Create"
                                )}
                            </button>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal
                className="modal modal-sm fade"
                id="exampleModal1"
                show={isModalOpen.open && isModalOpen.type === "Edit"}
                onHide={() => toggleModal()}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">
                            Edit Volume
                        </h1>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => toggleModal()}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={onSubmit}>
                            <div className="row">
                                <div className="col-xl-12 mb-3">
                                    <label htmlFor="rsn_volume" className="form-label">
                                        Volume<span className="text-danger">*</span>
                                    </label>
                                    <DynamicSelectInput
                                        parentClassName="mb-0"
                                        setParentInputValue={handleSelectChange}
                                        endpoint_name="search/rs_volume"
                                        name={"volume"}
                                        id={"volume"}
                                        placeholder={"Select Volume"}
                                        isRequired={true}
                                        defaultValue={
                                            selectedVolume?.volume ?
                                                {
                                                    value: selectedVolume.volume.id,
                                                    label: selectedVolume.volume.name
                                                } : null}
                                    />
                                </div>
                                <div className="col-xl-12">
                                    <label className="form-label">
                                        Sub Volume<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        autoFocus
                                        id="name"
                                        name="name"
                                        placeholder="Enter Sub Volume"
                                        value={volumeInput.name}
                                        onChange={handleChange}
                                        className={`form-control mb-2 ${volumeInputError.name ? "is-invalid" : ""
                                            }`}
                                    />
                                    {volumeInputError.name && (
                                        <div className="invalid-feedback mb-2">Sub Volume Name is required</div>
                                    )}
                                </div>
                                <div className="col-xl-12">
                                    <label className="form-label">
                                        Sub Volume No.<span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        id="sub_volume_no"
                                        name="sub_volume_no"
                                        placeholder="Enter Volume No."
                                        value={volumeInput.sub_volume_no}
                                        onChange={handleChange}
                                        className={`form-control mb-2 ${volumeInputError.sub_volume_no ? "is-invalid" : ""
                                            }`}
                                    />
                                    {volumeInputError.sub_volume_no && (
                                        <div className="invalid-feedback mb-2">Sub Volume Number is required</div>
                                    )}
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                                disabled={isSubmitLoading}
                            >
                                {isSubmitLoading ? (
                                    <span>
                                        <Spinner size="sm" className="me-2" />
                                        Updating...
                                    </span>
                                ) : (
                                    "Update"
                                )}
                            </button>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal
                className="modal fade"
                id="exampleModal1"
                show={isModalOpen.open && isModalOpen.type === "Delete"}
                onHide={() => toggleModal()}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">
                            Delete Sub Volume
                        </h1>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => toggleModal()}
                        ></button>
                    </div>
                    <div className="modal-body">
                        {volumeInput ?
                            <Form onSubmit={onDeleteSubmit}>
                                <h4>Are you sure, that you want to delete the Sub Volume <strong>{volumeInput.name} ({volumeInput.sub_volume_no})</strong></h4>
                                <h5 className="text-warning"><strong>Note</strong>: Before deleting the Sub Volume, you have to choose another sub volume to replace it.</h5>
                                <div className="my-3">
                                    <DynamicSelectInput
                                        setParentInputValue={handleDeleteSelectChange}
                                        endpoint_name={`search/rs_sub_volume?exclude_id=${volumeInput.id}`}
                                        name={"unit"}
                                        id={"unit"}
                                        placeholder={"Select Sub Volume"}
                                        isRequired={true}
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-block btn-sm btn-danger mt-3 float-end"
                                    disabled={isSubmitLoading}>
                                    {isSubmitLoading ? (<span><Spinner size="sm" className="me-2" />Deleting...</span>) : "Delete"}
                                </button>
                            </Form>
                            : "Wait..."}
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default SubVolumePage;
