
import axios from "axios";
const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL,
});


let cancelTokenSource;

// axiosInstance.interceptors.request.use((config) => {
//   if (config.method === "get") {
//     const URL = config.url.split("?")[0];
//     const getLastUrl = sessionStorage.getItem("last_url")
//     if (getLastUrl && getLastUrl === URL) {
//       console.log("ss")
//       cancelTokenSource = axios.CancelToken.source();
//       config.cancelToken = cancelTokenSource.token;
//       sessionStorage.setItem("last_url", URL)
//     } else {
//       console.log("not ss")
//       sessionStorage.setItem("last_url", URL)
//       const queryParams = new URLSearchParams(config.url.split("?")[1]);
//       const paramsObject = Object.fromEntries(queryParams.entries());
//       const searchParams = paramsObject?.query || null;
//       if (searchParams) {
//         cancelTokenSource = axios.CancelToken.source();
//         config.cancelToken = cancelTokenSource.token;
//       }
//     }

//   }
//   return config;
// });

axiosInstance.interceptors.request.use((config) => {
  if (config.method === "get") {
    const URL = config.url.split("?")[0];
    let lastUrl = sessionStorage.getItem("last_url");
    if (URL === "api/standard") {
      const queryParams = new URLSearchParams(config.url.split("?")[1]);
      const paramsObject = Object.fromEntries(queryParams.entries());
      const stanard_type = paramsObject?.standard_type
      const stanard_url =  `${URL}${stanard_type}`
      if (lastUrl && lastUrl === stanard_url) {
        if (cancelTokenSource) {
          cancelTokenSource.cancel("Request was canceled");
        }
        cancelTokenSource = axios.CancelToken.source();
        config.cancelToken = cancelTokenSource.token;
      } else {
        sessionStorage.setItem("last_url", stanard_url);
        cancelTokenSource = axios.CancelToken.source();
        config.cancelToken = cancelTokenSource.token;
      }
    } else {
      if (lastUrl && lastUrl === URL) {
        if (cancelTokenSource) {
          cancelTokenSource.cancel("Request was canceled");
        }
        cancelTokenSource = axios.CancelToken.source();
        config.cancelToken = cancelTokenSource.token;
      } else {
        sessionStorage.setItem("last_url", URL);
        cancelTokenSource = axios.CancelToken.source();
        config.cancelToken = cancelTokenSource.token;
      }
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if ("Request was canceled" === error?.message) {
      return;
    }
    if ([401, 403].includes(error?.response?.status)) {
      if (!["api/auth/authorized", "api/visitor_count"].includes(error.config.url)) {
        window.location.href = "/";
      }
      return Promise.reject(error)
    }
    return Promise.reject(error);
  }
);

export function CancelRequest() {
  if (cancelTokenSource) {
    cancelTokenSource.cancel("Request was canceled");
  }
}

export default axiosInstance;
