import React, { useState } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { updateManualStatus } from "../../../services/ManualService";

function ArchiveManualModal({ data, setData }) {
  const [confirmMSG, setConfirmMSG] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const navigate = useNavigate();

  const toggleModal = (show) => {
    setShowModal(show);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitLoading(true);
    try {
      const resp = await updateManualStatus({
        id: data.id,
        archive: !data.is_archive,
        archive_reason: confirmMSG,
      });
      toast.success(resp.data.message);
      setConfirmMSG("");
      if (!data.is_archive) {
        navigate("/archive-documents");
      } else {
        setData({ ...data, is_archive: !data.is_archive });
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
    toggleModal(false);
  };

  return data ? (
    <>
      <h5 className="mb-md-0">
        {data?.is_archive ? "Unarchive" : "Archive"} Document
      </h5>
      <button
        className={`btn ${
          data?.is_archive ? "btn-success" : "btn-danger"
        } btn-sm cursor-pointer fw-bolder`}
        onClick={() => toggleModal(true)}
      >
        {data?.is_archive ? "Unarchive" : "Archive"}
      </button>
      <Modal
        className="modal modal-sm fade"
        id="exampleModal1"
        show={showModal}
        centered={true}
        onHide={() => toggleModal(false)}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              {data?.is_archive ? "Unarchive" : "Archive"} Document
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal(false)}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleOnSubmit}>
              <div className="row">
                <div className="mb-3">
                  <label htmlFor="confirmMSG" className="form-label">
                    {data?.is_archive ? "Type 'confirm'" : "Archive Reason"}
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="confirmMSG"
                    name="confirmMSG"
                    placeholder={`${
                      data?.is_archive
                        ? "Type 'confirm' to unarchive"
                        : "Reason For Archive"
                    }`}
                    value={confirmMSG}
                    onChange={(e) => setConfirmMSG(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                disabled={
                  isSubmitLoading ||
                  confirmMSG.trim() === "" ||
                  (data?.is_archive
                    ? confirmMSG.toLocaleLowerCase() !== "confirm"
                    : false)
                }
              >
                {isSubmitLoading ? (
                  <span>
                    <Spinner size="sm" className="me-2" />
                    {data?.is_archive ? "Unarchive" : "Archive"}
                  </span>
                ) : data?.is_archive ? (
                  "Unarchive"
                ) : (
                  "Archive"
                )}
              </button>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  ) : null;
}

export default ArchiveManualModal;
