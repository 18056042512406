import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Modal, Form, Spinner } from "react-bootstrap";
import { updateDrawingDescriptionAction } from "../../../store/actions/DrawingActions";
import { updateDrawingDecsription } from "../../../services/DrawingService";
const initialDescriptionEditInput = { id: "", description: "" };

const maxWidthStyle400 = {
  maxWidth: "30rem",
  minWidth:"10rem",
  whiteSpace: "normal",
  display:"block"
}

function EditDescriptionModal({ data, drawing_id, userRole }) {
  const [descriptionInput, setDescriptionInput] = useState(initialDescriptionEditInput);
  const [showModal, setShowModal] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const dispatch = useDispatch()

  const toggleModal = () => {
    if (!showModal) {
      setDescriptionInput({ id: data.id, description: data.description });
    } else {
      setDescriptionInput(initialDescriptionEditInput)
    }
    setShowModal(!showModal);
  };

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;
    setDescriptionInput({ ...descriptionInput, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitLoading(true);
      const resp = await updateDrawingDecsription(drawing_id, descriptionInput);
      toast.success(resp.data.message);
      dispatch(updateDrawingDescriptionAction(resp.data.results))
      toggleModal()
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
  };

  return (
    <>
    {userRole !== "User" ? (
      <span style={maxWidthStyle400} className="text-primary cursor-pointer fw-bolder"
        onClick={toggleModal}>
        {data.description}
      </span> 
    )
    : (<span style={maxWidthStyle400}>
        {data.description}
      </span> )}
      
      

      <Modal
        className="modal fade"
        id="exampleModal1"
        show={showModal}
        onHide={toggleModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Edit Description
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {data ? (
              <Form onSubmit={(e) => handleSubmit(e)}>
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    rows="3"
                    className="form-control"
                    id="description"
                    name="description"
                    value={descriptionInput.description}
                    onChange={handleChangeAdd}
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-block btn-sm btn-primary mt-3 float-end" disabled={isSubmitLoading}>
                  {isSubmitLoading ? (<span><Spinner size="sm" className="me-2" />Updating...</span>) : "Update"}
                  </button>
              </Form>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user?.role || "Admin",
  };
};
export default connect(mapStateToProps)(EditDescriptionModal);
