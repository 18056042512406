import React, { useState, useEffect } from "react";
import { getTotalUserList } from "../../../services/UserService";
import TableLoaderEffect from "../../constant/TableLoader";
import { Spinner } from "react-bootstrap";

const TotalUserListPage = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchLoading, setIsSearchLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        getTotalUserList(searchQuery)
            .then((resp) => {
                setData(resp.data.results);
                setIsLoading(false);
                setIsSearchLoading(false)
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data.message);
                } else {
                    console.log(error);
                }
                setIsLoading(false);
                setIsSearchLoading(false)
            });
    }, [searchQuery]);

    const handleSearchChange = (e) => {
        const { value } = e.target;
        setSearchQuery(value);
        setIsSearchLoading(true)
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="tbl-caption d-flex justify-content-between text-wrap flex-column  flex-sm-row align-items-start align-items-sm-center p-3">
                                    <h4 className="heading mb-3 mb-md-0 fw-bold">TOTAL USER</h4>
                                    <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                                        <div className="me-3 mb-3 mb-md-0">
                                            <div className="input-group tp-input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search"
                                                    value={searchQuery}
                                                    aria-describedby="basic-search"
                                                    onChange={handleSearchChange}
                                                />
                                                <div className="input-group-text" id="basic-search">
                                                    {isSearchLoading && (
                                                        <Spinner variant="primary" size="sm" />
                                                    )}
                                                    {!isSearchLoading && searchQuery.length > 0 && (
                                                        <i
                                                            className="fa-solid fa-xmark c-pointer text-primary"
                                                            onClick={() => {
                                                                setSearchQuery("");
                                                            }}
                                                        ></i>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div
                                        id="employee-tbl_wrapper"
                                        className="dataTables_wrapper no-footer"
                                    >
                                        <table
                                            id="empoloyees-tblwrapper"
                                            className="table ItemsCheckboxSec dataTable no-footer mb-0 table-striped"
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Personnel No.</th>
                                                    <th>Full Name</th>
                                                    <th>Designation</th>
                                                    <th>Department</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ? (
                                                    <TableLoaderEffect colLength={4} />
                                                ) : (
                                                    data.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <span>
                                                                    {item.personnel_number}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span>{item.full_name}</span>
                                                            </td>
                                                            <td>
                                                                <span>{item.designation}</span>
                                                            </td>
                                                            <td>
                                                                <span>{item.department}</span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TotalUserListPage;
