import axiosInstance from "./AxiosInstance";
const apiUrl = "api/drawing";

export function getDrawingList(
  page,
  page_size,
  query,
  department_id,
  unit_id,
  drawing_type,
  work_order_number, 
  description,
  file_present,
  status
) {
  return axiosInstance.get(
    `${apiUrl}?page=${page}&page_size=${page_size}&query=${query}&department_id=${department_id}&unit_id=${unit_id}&drawing_type=${drawing_type}&work_order_number=${work_order_number}&description=${description}&file_present=${file_present}&status=${status}`
  );
}

export function getDrawing(id) {
  return axiosInstance.get(`${apiUrl}/${id}`);
}

export function editDrawing(id) {
  return axiosInstance.get(`api/drawing_edit/${id}`);
}

export function createDrawing(data) {
  return axiosInstance.post(apiUrl, data);
}

export function updateDrawing(id, data) {
  return axiosInstance.put(`api/drawing_edit/${id}`, data);
}

export function deleteDrawing(id) {
  return axiosInstance.delete(`${apiUrl}/${id}`);
}


export function uploadDrawingFiles(data) {
  return axiosInstance.post("api/drawing_file_upload", data);
}

export function uploadReviseDrawingFiles(data) {
  return axiosInstance.put("api/drawing_file_upload", data);
}

export function downloadDrawingFiles(id, action = "view", isDWGDownload=false) {
  return axiosInstance.get(`api/drawing_file_download/${id}?action=${action}${isDWGDownload?"&dwg_download=yes":''}`, {
    responseType: "blob",
  });
}

export function getDrawingDecsriptionList(drawing_id, page, page_size, query) {
  return axiosInstance.get(
    `api/drawing_description/${drawing_id}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function updateDrawingDecsription(drawing_id, data) {
  return axiosInstance.put(`api/drawing_description/${drawing_id}`, data);
}

export function updateDrawingStatus(data) {
  return axiosInstance.post('api/drawing_archive', data);
}

export function getDrawingLogList(drawing_id, page, page_size, query) {
  return axiosInstance.get(
    `api/drawing_log/${drawing_id}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function bulkUploadDrawingData(data) {
  return axiosInstance.post('api/drawing_bulk_upload' , data)
}
export function bulkUploadDrawingFile(data) {
  return axiosInstance.put('api/drawing_bulk_upload', data);
}


export function searchDrawing(query) {
  return axiosInstance.get(`api/search/drawing?query=${query}`);
}


export function approveDrawingList() {
  return axiosInstance.get("api/drawing_approve");
}

export function approveDrawing(data) {
  return axiosInstance.post("api/drawing_approve", data);
}

export function bulkApproveDrawing(data) {
  return axiosInstance.put("api/drawing_approve", data);
}

export function archiveDrawingList(
  query,
  department_id,
  unit_id,
  drawing_type,
  work_order_number, 
  description,
) {
  return axiosInstance.get(
    `api/drawing_archive?query=${query}&department_id=${department_id}&unit_id=${unit_id}&drawing_type=${drawing_type}&work_order_number=${work_order_number}&description=${description}`
  );
}

export function removeArchiveDrawingList(data) {
  return axiosInstance.put("api/drawing_archive", data);
}

export function permanentDeleteDrawingList(data) {
  return axiosInstance.post("api/drawing_permanent_delete", data);
}


export function getBulkDrawingList(
  query,
  department_id,
  unit_id,
  drawing_type,
  work_order_number, 
  description,
) {
  return axiosInstance.get(
    `api/bulk_archive_drawing?page=1&page_size=500&query=${query}&department_id=${department_id}&unit_id=${unit_id}&drawing_type=${drawing_type}&work_order_number=${work_order_number}&description=${description}`
  );
}


export function updateBulkDrawingList(data) {
  return axiosInstance.put("api/bulk_archive_drawing", data);
}