import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { updateUserProfilePassword } from "../../../services/UserService";
import { changeProfilePasswordSchema } from "../../constant/schemas/ChangeProfilePassword";

function ChangePassword() {
  const initialValues = {
    current_password: "",
    new_password: "",
    confirmPassword: "",
  };
  const [showPassword, setShowPassword] = useState({
    cPass: false,
    newPass: false,
    newCPass: false,
  });

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleTogglePassword = (name) => {
    setShowPassword({ ...showPassword, [name]: !showPassword[name] });
  };

  const formikProps = useFormik({
    initialValues,
    validationSchema: changeProfilePasswordSchema,
    onSubmit: async (values) => {
      try {
        const resp = await updateUserProfilePassword(values);
        toast.success(resp.data.message);
        navigate("/")
        toggleModal(false);
        formikProps.resetForm();
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });

  const toggleModal = (show) => {
    if (!show) {
      formikProps.resetForm();
    }
    setShowModal(show);
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formikProps;

  return (
    <>
      <button
        className="btn btn-danger btn-sm cursor-pointer fw-bolder"
        onClick={() => toggleModal(true)}
      >
        Change Password
      </button>
      <Modal
        className="modal fade modal-sm"
        id="exampleModal1"
        show={showModal}
        centered={true}
        onHide={() => toggleModal(false)}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Change User Password
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal(false)}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="mb-3">
                  <label htmlFor="current_password" className="form-label">
                    Current Password <span className="text-danger">*</span>
                  </label>
                  <div className="input-group ">
                    <input
                      type={showPassword.cPass ? "text" : "password"}
                      className="form-control border border-end-0"
                      id="current_password"
                      placeholder="Enter Current Password" 
                      name="current_password"
                      autoComplete="new-password"
                      value={values.current_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <button
                      className="btn border border-start-0 border-light-subtle pb-2 "
                      type="button"
                      onClick={() => handleTogglePassword("cPass")}
                    >
                      {showPassword.cPass ? (
                        <i className="fa-regular fa-eye-slash"></i>
                      ) : (
                        <i className="fa-regular fa-eye"></i>
                      )}
                    </button>
                  </div>
                  {errors.current_password && touched.current_password ? (
                    <div className="text-danger small mt-1">
                      {errors.current_password}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label htmlFor="new_password" className="form-label">
                    New Password <span className="text-danger">*</span>
                  </label>
                  <div className="input-group ">
                    <input
                      type={showPassword.newPass ? "text" : "password"}
                      className="form-control border border-end-0"
                      id="new_password"
                      placeholder="Enter New Password"
                      name="new_password"
                      autoComplete="new-password"
                      value={values.new_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <button
                      className="btn border border-start-0 border-light-subtle pb-2 "
                      type="button"
                      onClick={() => handleTogglePassword("newPass")}
                    >
                      {showPassword.newPass ? (
                        <i className="fa-regular fa-eye-slash"></i>
                      ) : (
                        <i className="fa-regular fa-eye"></i>
                      )}
                    </button>
                  </div>
                  {errors.new_password && touched.new_password ? (
                    <div className="text-danger small mt-1">
                      {errors.new_password}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label">
                    Confirm New Password <span className="text-danger">*</span>
                  </label>
                  <div className="input-group ">
                    <input
                      type={showPassword.newCPass ? "text" : "password"}
                      className="form-control border border-end-0"
                      id="confirmPassword"
                      placeholder="Enter Confirm New Password"
                      name="confirmPassword"
                      autoComplete="new-password"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <button
                      className="btn border border-start-0 border-light-subtle pb-2 "
                      type="button"
                      onClick={() => handleTogglePassword("newCPass")}
                    >
                      {showPassword.newCPass ? (
                        <i className="fa-regular fa-eye-slash"></i>
                      ) : (
                        <i className="fa-regular fa-eye"></i>
                      )}
                    </button>
                  </div>
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <div className="text-danger small mt-1">
                      {errors.confirmPassword}
                    </div>
                  ) : null}
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
              >
                Update Password
              </button>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ChangePassword;
