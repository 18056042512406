import React, { useState, useEffect } from 'react'
import { getOpenSliderList } from "../../../services/NoticeService";
import { Carousel } from 'react-bootstrap';

const ImageSlider = () => {
    const [dataList, setDataList] = useState([]);
    useEffect(() => {
        getOpenSliderList(1, 10)
            .then((resp) => {
                setDataList(resp.data.results);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data.message);
                } else {
                    console.log(error);
                }
            });
    }, []);

    const isImagefile = (file_name) => {
        const file_ext = file_name.split('.').pop().toLowerCase()
        if (['jpg', 'png', 'jpeg'].includes(file_ext)) {
            return true
        }
        return false
    };

    return (
        <div className='slider-containter'>
            <Carousel
                controls={true}
                interval={2000}
                slide={true}>
                {dataList.map((item) => (
                    <Carousel.Item key={item.id}>
                        {isImagefile(item.media) ? (
                            <img
                                className="d-block w-100"
                                src={`${process.env.REACT_APP_BASE_URL}${item.media}`}
                                alt={`Slide ${item.id}`}
                            />
                        ) : (
                            <div>
                                <video
                                    autoPlay
                                    loop
                                    muted
                                    loading="lazy">
                                    <source
                                        src={`${process.env.REACT_APP_BASE_URL}${item.media}`}
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        )}
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    )
}

export default ImageSlider