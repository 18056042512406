import React, { useState } from "react";
import { Modal } from "react-bootstrap";

function ViewPermission({ ...props }) {
  const [showModal, setShowModal] = useState(false)


  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const PermissionCheckIcon = ({ label, checkValue }) => {
    return (
      <div className="mb-2 ms-3">
        <div className="d-flex align-itesm-center">
          <span>
            {checkValue ? <i class="fa-solid fa-square-check fa-xl text-primary"></i> : <i class="fa-regular fa-square fa-xl"></i>}
          </span>
          <h5 className="mb-0 ms-3">{label}</h5>
        </div>
      </div>
    )
  }

  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm"
        onClick={toggleModal}
      >
        View All
      </button>
      <Modal
        className="modal fade modal-xl"
        id="exampleModal1"
        show={showModal}
        centered={true}
        onHide={() => toggleModal()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              View User Permission
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal()}
            ></button>
          </div>
          <div className="modal-body">
            <div className="row my-3 px-2">
              <div className="col-lg-4 col-md-6 col-sm-12 p-2">
                <div className="d-flex align-items-center mb-3">
                  <h5 className="fst-italic mb-0 ms-2 fw-semibold">Drawing Permission:</h5>
                </div>
                <PermissionCheckIcon
                  label={"Disable DWG File"}
                  checkValue={props?.drawing_permission?.disable_dwg_file}
                />
                <PermissionCheckIcon
                  label={"Download Drawing"}
                  checkValue={props?.drawing_permission?.download_drawing}
                  disabled={props?.drawing_permission?.disable_dwg_file}
                />

                <PermissionCheckIcon
                  label={"View Layout"}
                  checkValue={props?.drawing_permission?.view_layout}
                />

                <PermissionCheckIcon
                  label={"View PS Drawing"}
                  checkValue={props?.drawing_permission?.ps}
                />

                <PermissionCheckIcon
                  label={"View FDR Drawing"}
                  checkValue={props?.drawing_permission?.fdr}
                />

                <PermissionCheckIcon
                  label={"View MISC Drawing"}
                  checkValue={props?.drawing_permission?.misc}
                />
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 p-2">
                <div className="d-flex align-items-center mb-3">
                  <h5 className="fst-italic mb-0 ms-2 fw-semibold">Standard Permission:</h5>
                </div>
                <PermissionCheckIcon
                  label={"View AWWA"}
                  checkValue={props?.standard_permission?.awwa}
                />
                <PermissionCheckIcon
                  label={"View ASTM"}
                  checkValue={props?.standard_permission?.astm}
                />

                <PermissionCheckIcon
                  label={"View API"}
                  checkValue={props?.standard_permission?.api}
                />

                <PermissionCheckIcon
                  label={"View BRITISH"}
                  checkValue={props?.standard_permission?.british}
                />

                <PermissionCheckIcon
                  label={"View DIN(GERMAN)"}
                  checkValue={props?.standard_permission?.din_german}
                />

                <PermissionCheckIcon
                  label={"View GOST(RUSSIAN)"}
                  checkValue={props?.standard_permission?.gost_russian}
                />

                <PermissionCheckIcon
                  label={"View IEC"}
                  checkValue={props?.standard_permission?.iec}
                />

                <PermissionCheckIcon
                  label={"View ISO"}
                  checkValue={props?.standard_permission?.iso}
                />
                <PermissionCheckIcon
                  label={"View IRST"}
                  checkValue={props?.standard_permission?.irst}
                />
                <PermissionCheckIcon
                  label={"View PSN"}
                  checkValue={props?.standard_permission?.psn}
                />
                <PermissionCheckIcon
                  label={"View OTHER STANDARD"}
                  checkValue={props?.standard_permission?.other_standard}
                />
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 p-2">
                <div className="d-flex align-items-center mb-3">
                  <h5 className="fst-italic mb-0 ms-2 fw-semibold">Document Permission:</h5>
                </div>
                <PermissionCheckIcon
                  label={"View REFERENCE BOOK"}
                  checkValue={props?.document_permission?.reference_book}
                />
                <PermissionCheckIcon
                  label={"View TENDER DOCUMENT"}
                  checkValue={props?.document_permission?.tender_document}
                />
                <PermissionCheckIcon
                  label={"View CATALOUGE"}
                  checkValue={props?.document_permission?.catalouge}
                />
                <PermissionCheckIcon
                  label={"View TECHNICAL CALCULATION"}
                  checkValue={props?.document_permission?.technical_calculation}
                />
                <PermissionCheckIcon
                  label={"View TECHNICAL SPECIFICATION"}
                  checkValue={props?.document_permission?.technical_specification}

                />
                <PermissionCheckIcon
                  label={"View TECHNICAL REPORT"}
                  checkValue={props?.document_permission?.technical_report}
                />
                <PermissionCheckIcon
                  label={"View PROJECT REPORT"}
                  checkValue={props?.document_permission?.project_report}
                />
                <PermissionCheckIcon
                  label={"View PORJECT SUBMITTED DRAWING"}
                  checkValue={props?.document_permission?.project_submitted_drawings}
                />
              </div>

            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ViewPermission;
