import React, { useState, useRef, useEffect } from "react";
import { StaticSelectInput } from "../../../constant/SelectInput";
import { Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { formatFileSize } from "../../../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import ManualSampleExcel from "../../../../images/ExcelFile/Manual/Manual_sample_excel_file.xlsx";
import { manualDropdownOptions } from "../../../constant/Variable";
import PRSampleExcel from "../../../../images/ExcelFile/Manual/PR_sample_excel_file.xlsx";
import CatalougeSampleExcel from "../../../../images/ExcelFile/Manual/Catalouge_sample_excel_file.xlsx";
import TDSampleExcel from "../../../../images/ExcelFile/Manual/TD_sample_excel_file.xlsx";
import TSSampleExcel from "../../../../images/ExcelFile/Manual/TS_sample_excel_file.xlsx";
import TCSampleExcel from "../../../../images/ExcelFile/Manual/TC_sample_excel_file.xlsx";
import TRSampleExcel from "../../../../images/ExcelFile/Manual/TR_sample_excel_file.xlsx";
import RBSampleExcel from "../../../../images/ExcelFile/Manual/RB_sample_excel_file.xlsx";
import PSDExcel from "../../../../images/ExcelFile/Manual/PSD_sample_excel_file.xlsx"
import { bulkUploadManualData } from "../../../../services/ManualService";

const UploadManualPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultSelectedOption = queryParams.get("manual_type") || "MANUALS";
  const [selectedOption, setSelectedOption] = useState(defaultSelectedOption);  const [jsonData, setJsonData] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const fileInputRef = useRef(null);
  const navigate = useNavigate()

  const requiredHeaders = {
    MANUALS: [
      "manual_number",
      "department_id",
      "unit_id",
      "supplier",
      "package_number",
      "letter_number",
      "registration_date",
      "description",
      "remarks",
      "file",
    ],
    REFERENCE_BOOK: [
      "manual_type",
      "manual_number",
      "editor",
      "source",
      "author",
      "description",
      "file",
    ],
    TENDER_DOCUMENT: [
      "manual_type",
      "manual_number",
      "department_id",
      "unit_id",
      "description",
      "file",
    ],
    CATALOUGE: [
      "manual_type",
      "manual_number",
      "source",
      "description",
      "file",
    ],
    PROJECT_REPORT: [
      "manual_type",
      "manual_number",
      "supplier",
      "capacity",
      "year",
      "description",
      "remarks",
      "file",
    ],
    PROJECT_SUBMITTED_DRAWINGS: [
      "manual_type",
      "manual_number",
      "department_id",
      "unit_id",
      "description",
      "title",
      "file_type",
      "file",
    ],
    default: [
      "manual_type",
      "manual_number",
      "supplier",
      "department_id",
      "description",
      "file",
    ],
  };

  const handleSelect = (name, selectedValue, _) => {
    setSelectedOption(selectedValue);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const manualType = queryParams.get("manual_type") || "MANUALS";
    setSelectedOption(manualType);
  }, [location.search]);

  useEffect(() => {
    navigate(`/upload-documents?manual_type=${selectedOption}`);
  }, [selectedOption, navigate]);


  function convertUnixTime(value) {
    try {
      const baseDate = new Date(1899, 11, 30);
      const date = new Date(baseDate.getTime() + value * 24 * 60 * 60 * 1000);
      return date.toISOString().slice(0, 10);
    } catch (error) {
      return null;
    }
  }

  const validateData = (obj, row) => {
    const errors = [];
    Object.entries(obj).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        if (key === "manual_number") {
          errors.push(`Data is required in row ${row + 1}, column ${key}`);
        }
        else if (selectedOption === "MANUALS" && key === "registration_date") {
          errors.push(
            `Invalid date format in row ${row + 1}, in ${key}`
          );
        }
      }
    });
    return errors;
  };

  const handleFileInputChange = (files) => {
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const dataJson = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headers = dataJson[0].map((header) => header.trim());

        let requiredHeadersForOption;
        switch (selectedOption) {
          case "MANUALS":
            requiredHeadersForOption = requiredHeaders.MANUALS;
            break;
          case "REFERENCE BOOK":
            requiredHeadersForOption = requiredHeaders.REFERENCE_BOOK;
            break;
          case "TENDER DOCUMENT":
            requiredHeadersForOption = requiredHeaders.TENDER_DOCUMENT;
            break;
          case "CATALOUGE":
            requiredHeadersForOption = requiredHeaders.CATALOUGE;
            break;
          case "PROJECT REPORT":
            requiredHeadersForOption = requiredHeaders.PROJECT_REPORT;
            break;
          case "PROJECT SUBMITTED DRAWINGS":
            requiredHeadersForOption = requiredHeaders.PROJECT_SUBMITTED_DRAWINGS;
            break;
          default:
            requiredHeadersForOption = requiredHeaders.default;
            break;
        }

        const missingHeaders = requiredHeadersForOption.filter(
          (header) => !headers.includes(header)
        );
        if (missingHeaders.length > 0) {
          toast.error(
            `Missing headers in Excel file: ${missingHeaders.join(", ")}`
          );
          return;
        }

        let jsonData = [];
        let errors = [];
        for (var i = 1; i < dataJson.length; i++) {
          const obj = {};
          if (dataJson[i].length === 0) {
            continue;
          }
          for (var j = 0; j < headers.length; j++) {
            const key = headers[j];
            const value = dataJson[i][j];
            if (key === "date_of_registration" || key === "registration_date") {
              obj[key] = convertUnixTime(value);
            }
            else {
              obj[key] = value;
            }
          }
          jsonData.push(obj);
          const error = validateData(obj, i);
          if (error.length) {
            errors = errors.concat([...error]);
          }
        }
        setJsonData(jsonData);
        setErrorMessages(errors);
      };
      setErrorMessages([]);
      reader.readAsArrayBuffer(file);
    }
  };

  const handleFileRemove = (e, index) => {
    const updatedFiles = [...selectedFile];
    const removedfile = updatedFiles.splice(index, 1);
    const file_name = removedfile[0].name;
    setSelectedFile(updatedFiles);
    if (errorMessages.length > 0) {
      const updatedErrorMessage = errorMessages.filter(
        (item) => !item.includes(file_name)
      );
      setErrorMessages(updatedErrorMessage);
    }
    e.stopPropagation();
  };

  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    if (selectedOption === "MANUALS") {
      downloadLink.href = ManualSampleExcel;
    } else if (selectedOption === "REFERENCE BOOK") {
      downloadLink.href = RBSampleExcel;
    } else if (selectedOption === "TENDER DOCUMENT") {
      downloadLink.href = TDSampleExcel;
    } else if (selectedOption === "CATALOUGE") {
      downloadLink.href = CatalougeSampleExcel;
    } else if (selectedOption === "PROJECT REPORT") {
      downloadLink.href = PRSampleExcel;
    }else if (selectedOption === "TECHNICAL CALCULATION") {
      downloadLink.href = TCSampleExcel;
    }else if (selectedOption === "TECHNICAL SPECIFICATION") {
      downloadLink.href = TSSampleExcel;
    }else if (selectedOption === "PROJECT SUBMITTED DRAWINGS") {
      downloadLink.href = PSDExcel;
    } else {
      downloadLink.href = TRSampleExcel;
    }
    downloadLink.download = `${selectedOption}_sample_Manual_data.xlsx`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      const formBody = new FormData();
      selectedFile.forEach((item) => {
        formBody.append("file_list", item);
      });
      formBody.append("data_list", JSON.stringify(jsonData));

      const response = await bulkUploadManualData(formBody);
      const { message, results } = response.data;

      toast.success(message);
        setSelectedFile([]);
        setJsonData([]);
        navigate(`/documents?manual_type=${selectedOption}`)   
       } catch (error) {
      if (error.response) {
        const { message, results } = error.response.data;
        setErrorMessages(
          results.error_data_set.map(
            (item) => `Row ${item.row}. Error: ${item.message}`
          )
        );
        toast.error(message);
      }
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const handlePDFFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist)
  };
  
  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFiles(droppedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const validFileName = (filelist) => {
    const fileNameList = [];
    for (let file of filelist) {
      if (file.name.split(".").pop().toLowerCase() === "pdf") {
        fileNameList.push(file);
      }
    }
    return fileNameList;
  };

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const filelist = validFileName(file_list);
      if (filelist.length === 0) {
        toast.error("choose a valid file");
      }
      setSelectedFile([...filelist, ...selectedFile]);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="tbl-caption d-flex justify-content-between text-wrap flex-column  flex-sm-row align-items-start align-items-sm-center p-3">
                <div className="d-flex align-items-center">
                    <h4 className="heading fw-bold mb-0 me-3">
                    Upload {selectedOption === "REFERENCE BOOK"
                        ? "RB"
                        : selectedOption === "TENDER DOCUMENT"
                        ? "TD"
                        : selectedOption === "TECHNICAL CALCULATION"
                        ? "TC"
                        : selectedOption === "TECHNICAL SPECIFICATION"
                        ? "TS"
                        : selectedOption === "TECHNICAL REPORT"
                        ? "TR"
                        : selectedOption === "PROJECT REPORT"
                        ? "PR"
                        : selectedOption === "PROJECT SUBMITTED DRAWINGS"
                        ? "PSD"
                        : selectedOption} Documents
                    </h4>

                  <div style={{ width: "200px" }}>
                    <StaticSelectInput
                      setParentInputValue={handleSelect}
                      parentClassName="mb-0"
                      optionsList={manualDropdownOptions}
                      name="manual_type"
                      id="manual_type"
                      defaultValue={{
                        label: selectedOption,
                        value: selectedOption,
                      }}
                      isRequired={true}
                    />
                  </div>

                </div>
                <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                  <Button
                    variant="outline-primary"
                    className="btn btn-sm cursor-pointer fw-bolder me-4"
                    type="button"
                    onClick={handleDownload}
                  >
                    <i className="fa-solid fa-file-excel me-2"></i>Sample {selectedOption === "REFERENCE BOOK"
                      ? "RB"
                      : selectedOption === "TENDER DOCUMENT"
                      ? "TD"
                      : selectedOption === "TECHNICAL CALCULATION"
                      ? "TC"
                      : selectedOption === "TECHNICAL SPECIFICATION"
                      ? "TS"
                      : selectedOption === "TECHNICAL REPORT"
                      ? "TP"
                      : selectedOption === "PROJECT REPORT"
                      ? "PR"
                      : selectedOption === "PROJECT SUBMITTED DRAWINGS"
                      ? "PSD"
                      : selectedOption} Excel File
                  </Button>
                </div>
              </div>
              <div className="card p-3 m-5 mt-0">
                <div className="form-group mb-3">
                  <label className="form-label">Excel File</label>
                  <input
                    type="file"
                    accept=".xlsx, .xls"
                    className="form-control"
                    onChange={(e) => handleFileInputChange(e.target.files)}
                  />
                </div>
                {errorMessages.length > 0 && (
                  <ul className="mx-4 my-2">
                    {errorMessages.map((error, index) => (
                      <li
                        style={{ listStyleType: "disc" }}
                        className="text-danger mb-2"
                        key={index}
                        role="alert"
                      >
                        {error}
                      </li>
                    ))}
                  </ul>
                )}

                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  className={`dropzone ${
                    selectedFile.length === 0 && "no-files"
                  }`}
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                >
                  <input
                    type="file"
                    ref={fileInputRef}
                    multiple
                    accept=".pdf"
                    onChange={handlePDFFileInputChange}
                    style={{ display: "none" }}
                  />
                  {selectedFile.length ? (
                    <div className="file-list">
                      {selectedFile.map((item, index) => (
                        <div key={index} className="file-item">
                          <span className="file-name">
                            {item.name}
                            <small>{formatFileSize(item.size)}</small>
                          </span>
                          <span
                            className="file-remove"
                            onClick={(e) => handleFileRemove(e, index)}
                          >
                            <i className="fa-regular fa-circle-xmark text-danger"></i>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>
                      Drag and drop files here, or click to select files
                      <br />
                      only <strong>PDF</strong> files are allowed.
                    </p>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-center mt-4 ">
                  <button
                    className="btn btn-primary btn-block w-50"
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span>
                        <Spinner variant="light" size="sm" className="ms-2" />
                        Upload
                      </span>
                    ) : (
                      "Upload"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadManualPage;