import React from 'react'
import { Tab, Nav } from "react-bootstrap";
import DepartmentPage from './Department';
import UnitPage from './Unit';
import VolumePage from './Volume';
import SubVolumePage from './SubVolume';

function DepartmentUnit() {
    
    const tabData = [
    { name: "Department", content: <DepartmentPage /> },
    { name: "Unit", content: <UnitPage /> },
    { name: "RS SubVolume", content: <SubVolumePage /> },
    { name: "RS Volume", content: <VolumePage /> },
  ];

  return (
    <div className='card'>
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
          <Nav
            as="ul"
            className="nav-pills mb-2 light border-bottom px-4 pt-3"
          >
            {tabData.map((tab, i) => (
              <Nav.Item as="li" key={i}>
                <Nav.Link
                  eventKey={tab.name.toLowerCase()}
                  className="pb-3 fw-semibold fs-5"
                >
                  {tab.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Tab.Content className="">
            {tabData.map((tab, i) => (
              <Tab.Pane eventKey={tab.name.toLowerCase()} key={i}>
                {tab.content}
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </div>
      </div>
  )
}

export default DepartmentUnit
