import {
  CREATE_DRAWING_TYPE,
  UPDATE_DRAWING_TYPE,
  GET_DRAWING_TYPE,
  GET_DRAWING_LIST_TYPE,
  DRAWING_FILE_UPLOAD_TYPE,
  GET_DRAWING_DESCRIPTION_LIST_TYPE,
  UPDATE_DRAWING_DESCRIPTION_TYPE,
  GET_DRAWING_LOG_LIST_TYPE,
  LOADING_TYPE,
  SHOW_TOAST_ERROR,
  UPDATE_DRAWING_FILTER_TYPE,
  RESET_DRAWING_STATE_TYPE
} from "../actions/DrawingActions";
import { toast } from "react-toastify";

const initialState = {
  drawingList: [],
  drawingCount: 0,
  currentPage:1,
  isDrawingListLoading: false,
  isDrawingSearchQueryLoading: false,

  selectedDrawing: null,
  isDrawingLoading: false,

  drawingDescriptionList: [],
  drawingDescriptionCount: 0,
  isDrawingDescriptionListLoading: false,
  isDescriptionSearchQueryLoading: false,
  openADSearch:false,
  filterInput:{
    searchQuery:"",
    department_id: null,
    unit_id: null,
    drawing_type: "",
    description: "",
    work_order_number: "",
    file_present: "",
    status: "",
  },
  drawingLogList: [],
  drawingLogCount: 0,
  isDrawingLogListLoading: false,
  isDrawingDownLoading: "",

  isModalOpen: false,
  isReviseFileUpload: false,
  modalType: "Add",
  isLoading: false,
  isLoadingSingleProduct: false,
  standardFilter:{
    rsn:{
      currentPage:1,
      searchQuery:"",
      rsnVolume:null,
      rsnGroup:null
    },
    ipss:{
      currentPage:1,
      searchQuery:"",

    },
    bis:{
      currentPage:1,
      searchQuery:"",

    },
    awwa:{
      currentPage:1,
      searchQuery:"",

    },
    astm:{
      currentPage:1,
      searchQuery:"",

    },
    api:{
      currentPage:1,
      searchQuery:"",

    },
    british:{
      currentPage:1,
      searchQuery:"",

    },
    din_german:{
      currentPage:1,
      searchQuery:"",

    },
    gost_russian:{
      currentPage:1,
      searchQuery:"",

    },
    iec:{
      currentPage:1,
      searchQuery:"",

    },
    iso:{
      currentPage:1,
      searchQuery:"",

    },
    isrt:{
      currentPage:1,
      searchQuery:"",

    },
    psn:{
      currentPage:1,
      searchQuery:"",
    }

  },
  manualFilter:{
    manuals:{
      currentPage:1,
      searchQuery:"",
    },
    reference_book:{
      currentPage:1,
      searchQuery:"",
    },
    tender_document:{
      currentPage:1,
      searchQuery:"",
    },
    catalouge:{
      currentPage:1,
      searchQuery:"",
    },
    technical_calculation:{
      currentPage:1,
      searchQuery:"",
    },
    technical_specification:{
      currentPage:1,
      searchQuery:"",
    },
    technical_report:{
      currentPage:1,
      searchQuery:"",
    },
    project_report:{
      currentPage:1,
      searchQuery:"",
    },
    project_submitted_drawings:{
      currentPage:1,
      searchQuery:"",
    }
  }
};

function DrawingReducer(state = initialState, actions) {
  if (actions.type === LOADING_TYPE) {
    return { ...state, ...actions.payload };
  } else if (actions.type === UPDATE_DRAWING_TYPE) {
    return {
      ...state,
      selectedDrawing: actions.payload,
    };
  } else if (actions.type === CREATE_DRAWING_TYPE) {
    return {
      ...state,
      selectedDrawing: [actions.payload, ...state.products],
      isModalOpen: !state.isModalOpen,
    };
  } else if (actions.type === GET_DRAWING_LIST_TYPE) {
    if(actions.payload === "REQUEST_CANCELED"){
      return {...state};
    }
    else if (actions.payload) {
      return {
        ...state,
        drawingList: actions.payload.results,
        drawingCount: actions.payload.count,
        isDrawingListLoading: false,
      };
    }
    return {
      ...state, isDrawingListLoading: false,
    };
  } else if (actions.type === GET_DRAWING_TYPE) {
    if (actions.payload) {
      return {
        ...state,
        selectedDrawing: actions.payload,
        isDrawingLoading: false,
      };
    } return {
      ...state,
      selectedDrawing: null,
      isDrawingLoading: false,
      drawingDescriptionList: [],
      drawingDescriptionCount: 0,
      isDrawingDescriptionListLoading: false,
      isDescriptionSearchQueryLoading: false,
      drawingLogList: [],
      drawingLogCount: 0,
      isDrawingLogListLoading: false,
      isLogSearchQueryLoading: false,
    };
  } else if (actions.type === DRAWING_FILE_UPLOAD_TYPE) {
    return {
      ...state,
    };
  } else if (actions.type === GET_DRAWING_DESCRIPTION_LIST_TYPE) {
    if (actions.payload) {
      return {
        ...state,
        drawingDescriptionList: actions.payload.results,
        drawingDescriptionCount: actions.payload.count,
        isDrawingDescriptionListLoading: false,
        isDescriptionSearchQueryLoading: false
      };
    }
    return {
      ...state, isDrawingDescriptionListLoading: false,
      isDescriptionSearchQueryLoading: false
    };
  } else if (actions.type === UPDATE_DRAWING_DESCRIPTION_TYPE) {
    if (actions.payload) {
      return {
        ...state,
        drawingDescriptionList: state.drawingDescriptionList.map((item) => {
          if (item.id === actions.payload.id) {
            return actions.payload;
          }
          return item;
        }),
      };
    } return { ...state };
  }
  else if (actions.type === GET_DRAWING_LOG_LIST_TYPE) {
    if (actions.payload) {
      return {
        ...state,
        drawingLogList: actions.payload.results,
        drawingLogCount: actions.payload.count,
        isDrawingLogListLoading: false,
        isLogSearchQueryLoading: false,
      };
    }
    return {
      ...state, isDrawingLogListLoading: false,
      isLogSearchQueryLoading: false
    };
  }
  else if (actions.type === UPDATE_DRAWING_FILTER_TYPE) {
    if (actions.payload) {
      return {
        ...state,
        filterInput:actions.payload
      };
    }
    return {...state};
  }
  else if (actions.type === SHOW_TOAST_ERROR) {
    toast.error(actions.payload);
    return { ...state };
  }
  else if (actions.type === RESET_DRAWING_STATE_TYPE) {
    return { ...initialState };
  }

  return state;
}

export default DrawingReducer;
