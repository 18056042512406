import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Dropdown, Nav, Spinner } from "react-bootstrap";
import { siLogGraph } from "../../../../services/DashboardService";

const chartFilterData = [
    { title: 'W', type: 'weekly' },
    { title: 'M', type: 'monthly' },
];
const SiTypeList = [ "SIR", "STABILITY CERTIFICATE", "COMPLIANCE"]

const SILogGraphPage = ({loginLogdata}) => {
    const [timePeriod, setTimePeriod] = useState("weekly")
    const [siType, setSiType] = useState(SiTypeList[0])
    const [series, setSeries] = useState([])
    const [labels, setLabels] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if(loginLogdata){
        setIsLoading(true);
        siLogGraph(timePeriod, siType).then((resp) => {
            const { si_count, labels } = resp.data.results
            const series = [si_count, timePeriod === "weekly"? loginLogdata.weekly: loginLogdata.monthly]
            setSeries(series);
            setLabels(labels)
            setIsLoading(false);
        }).catch((error) => {
            if (error.response) {
                console.log(error.response.data.message);
                setIsLoading(false);
            } else {
                console.log(error);
            }
        });
    }
    }, [siType, timePeriod, loginLogdata])

    const options = {
        chart: {
            type: 'area',
            height: 180,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        colors: ['#00457b', "#3ac977"],
        legend: {
            horizontalAlign: 'center'
        },
        labels
    };

    return (
        <div className="card">
            <div className="card-header border-0 pb-0 flex-wrap">
                <div className="d-flex align-items-center">
                    <h3 className="mb-0 me-3">SI Log</h3>
                    <Dropdown className='global-drop border border-primary rounded'>
                        <Dropdown.Toggle as="div" className='i-false global-drop-toggle'>
                            {siType}
                            <i className="fa-solid fa-chevron-down ms-2" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='global-drop-menu'>
                            {SiTypeList.filter(item => item !== siType).map(item => (
                                <Dropdown.Item onClick={() => setSiType(item)} key={item}>{item}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <Nav as="ul" className="nav nav-pills mix-chart-tab">
                    {chartFilterData.map((item, index) => (
                        <Nav.Item as="li" className="nav-item" key={index} onClick={() => setTimePeriod(item.type)}>
                            <Nav.Link className={timePeriod === item.type && ' active'}>{item.title}</Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
            </div>
            <div className="card-body  p-0">
                <div id="overiewChartsi">
                    {isLoading ? <div className="d-flex align-items-center justify-content-center">
                        <h3 className="text-primary">Loading .. <Spinner size="sm" /></h3>
                    </div> : null}
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="area"
                        height={180}
                    />
                </div>
            </div>
        </div>
    );

}

export default SILogGraphPage;