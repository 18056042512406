import React, { useState } from 'react'
import { Modal, Form, Button, Spinner } from 'react-bootstrap'
import { exportDatatoExcel } from '../../../services/LogService'
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { minusDaysToDate } from '../../../utils';
const ExportDataModal = ({ exportType }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)
    const [inputDate, setInputDate] = useState({ start_date: minusDaysToDate(30), end_date: minusDaysToDate(0) })

    const toggleModal = () => setIsModalOpen(!isModalOpen)

    const handleOnSubmit = (e) => {
        e.preventDefault()
        setIsSubmitLoading(true)
        exportDatatoExcel(exportType, inputDate.start_date, inputDate.end_date).then((resp) => {
            const excelData = resp.data.results
            if (excelData.length > 0) {
                const ws = XLSX.utils.json_to_sheet(excelData);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
                const fileName = `${exportType}_data_${new Date().getTime()}.xlsx`;
                const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                const blob = new Blob([wbout], { type: "application/octet-stream" });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            } else {
                toast.error("No Data found.")
            }
            setIsSubmitLoading(false);
        })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data.message);
                } else {
                    console.log(error);
                }
                setIsSubmitLoading(false);
            });
    }

    const handleDateChange = (e) => {
        const { name, value } = e.target
        console.log("sss", value)
        setInputDate({ ...inputDate, [name]: value })
    }

    return (
        <div>
            {exportType === "Drawing" && <button className='btn btn-outline-success'
                onClick={toggleModal}>Export {exportType} Data</button>}
            <Modal
                className="modal modal-md fade"
                show={isModalOpen}
                onHide={() => toggleModal()}
                backdrop="static"
                keyboard={false}
            >
                <div className="modal-content">
                    <div className="modal-header border-bottom">
                        <h5 className="modal-title">Export {exportType} Data</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={toggleModal}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={handleOnSubmit}>
                            <div className='row'>
                                <div className="mb-3 col-sm-6">
                                    <label htmlFor="startDate" className="form-label">
                                        Start Date <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="startDate"
                                        name="start_date"
                                        value={inputDate.start_date}
                                        onChange={handleDateChange}
                                    />
                                </div>
                                <div className="mb-3 col-sm-6">
                                    <label htmlFor="endDate" className="form-label">
                                        End Date <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        className="form-control"
                                        type="date"
                                        id="endtDate"
                                        name="end_date"
                                        value={inputDate.end_date}
                                        onChange={handleDateChange}
                                    />
                                </div>
                            </div>
                            <Button
                                type="submit"
                                className="btn btn-block btn-primary mt-3 float-end"
                                disabled={isSubmitLoading}>
                                {isSubmitLoading ? (
                                    <span>
                                        <Spinner animation="border" size="sm" className="me-2" />
                                        Exporting...
                                    </span>
                                ) : (
                                    "Export"
                                )}
                            </Button>
                        </Form>
                    </div>
                </div>
            </Modal>
        </div>

    )
}

export default ExportDataModal