import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import DynamicSelectInput from "../../constant/SelectInput";
import { useFormik } from "formik";
import { editUserSchema } from "../../constant/schemas/EditUserSchema";
import { toast } from "react-toastify";
import { updateUser } from "../../../services/UserService";
import { Spinner } from "react-bootstrap";

function EditUserModal({ data, setData, userRole }) {
  const initialValues = {
    id: "",
    personnel_number: "",
    full_name: "",
    email: "",
    phone_number: "",
    role: userRole !== "SuperAdmin" ? "User" : "Admin",
    designation: "",
    department_id: "",
    drawing_permission: { ps: false, fdr: false, misc: false, disable_dwg_file: true, download_drawing: false, view_layout: false },
    standard_permission: { awwa: false, astm: false, api: false, british: false, din_german: false, gost_russian: false, iec: false, iso: false, irst: false, psn: false, other_standard: false },
    document_permission: { reference_book: false, tender_document: false, catalouge: false, technical_calculation: false, technical_specification: false, technical_report: false, project_report: false, project_submitted_drawings: false }
  };

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleModal = () => {
    if (showModal) {
      setValues(initialValues);
    } else {
      setValues({
        id: data.id,
        personnel_number: data.personnel_number,
        full_name: data.full_name,
        email: data.email,
        phone_number: data.phone_number,
        role: data.role,
        designation: data.designation,
        department_id: data.department.department_id,
        drawing_permission: data.drawing_permission,
        standard_permission: data.standard_permission,
        document_permission: data.document_permission
      });
    }
    setShowModal(!showModal);
  };

  const handleSelectChange = (name, value, _) => {
    setValues({ ...values, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    try {
      setIsLoading(true);
      const resp = await updateUser(values);
      toast.success(resp.data.message);
      setData(resp.data.results);
      toggleModal();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsLoading(false);
  };

  const handleDrawingPermission = (e) => {
    const { name, checked } = e.target
    if (name === "disable_dwg_file" && checked) {
      setValues({ ...values, drawing_permission: { ...values.drawing_permission, [name]: checked, download_drawing: false } });
    } else {
      setValues({ ...values, drawing_permission: { ...values.drawing_permission, [name]: checked } });
    }
  }

  const handleStandardPermission = (e) => {
    const { name, checked } = e.target
    setValues({ ...values, standard_permission: { ...values.standard_permission, [name]: checked } });
  }

  const handleDocumentPermission = (e) => {
    const { name, checked } = e.target
    setValues({ ...values, document_permission: { ...values.document_permission, [name]: checked } });
  }

  const handleAllPermission = (name, checked, defaultValue) => {
    let permValue
    if (['drawing_permission', 'standard_permission', 'document_permission'].includes(name)) {
      if (checked) {
        Object.keys(defaultValue).forEach(key => defaultValue[key] = !defaultValue[key]);
        permValue = defaultValue
      } else {
        permValue = defaultValue
      }
    }
    if (permValue !== undefined) {
      setValues({ ...values, [name]: permValue });
    }
  }

  const PermissionRadioButton = ({ label, name, checkValue, handleChange, disabled = false }) => {
    return (
      <div className="mb-2 ms-3">
        <div className="form-check custom-checkbox mb-2 form-check-inline">
          <input
            className="form-check-input"
            type="checkbox"
            name={name}
            id={`is_view_${name}`}
            checked={checkValue}
            disabled={disabled}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor={`is_view_${name}`}>{label}</label>
        </div>
      </div>
    )
  }

  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: editUserSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm cursor-pointer fw-bolder"
        onClick={toggleModal}
      >
        <i className="fa-solid fa-pen-to-square"></i> Edit
      </button>
      <Modal
        className="modal modal-xl fade"
        id="exampleModal1"
        show={showModal}
        scrollable
        onHide={toggleModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Edit User
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {data ? (
              <Form onSubmit={handleSubmit} className="px-4 py-2">
                <div className="row">
                  <div className="col-xl-6 col-sm-12">
                    <div className="mb-3">
                      <label
                        htmlFor="personnel_number"
                        className="form-label"
                      >
                        Personnel No. <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="personnel_number"
                        placeholder="Enter Personnel Number"
                        name="personnel_number"
                        value={values.personnel_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.personnel_number && touched.personnel_number ? (
                        <div className="text-danger small mt-1">
                          {errors.personnel_number}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="full_name" className="form-label">
                        Full Name<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="full_name"
                        name="full_name"
                        placeholder="Enter Full Name"
                        value={values.full_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.full_name && touched.full_name ? (
                        <div className="text-danger small mt-1">
                          {errors.full_name}
                        </div>
                      ) : null}
                    </div>

                    {userRole === "SuperAdmin" && (
                      <div className="mb-3">
                        <label>
                          Role <span className="text-danger">*</span>
                        </label>
                        <br />
                        <div className="form-check custom-checkbox mb-2 form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="role"
                            id="adminRadio"
                            value="Admin"
                            checked={values.role === "Admin"}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="adminRadio"
                          >
                            Admin
                          </label>
                        </div>
                        <div className="form-check custom-checkbox mb-2 form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="role"
                            id="userRadio"
                            value="User"
                            checked={values.role === "User"}
                            onChange={handleChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="userRadio"
                          >
                            User
                          </label>
                        </div>
                      </div>
                    )}

                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.email && touched.email ? (
                        <div className="text-danger small mt-1">
                          {errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="phone_number" className="form-label">
                        Phone Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="phone_number"
                        placeholder="Enter Phone Number"
                        name="phone_number"
                        value={values.phone_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.phone_number && touched.phone_number ? (
                        <div className="text-danger small mt-1">
                          {errors.phone_number}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <DynamicSelectInput
                        setParentInputValue={handleSelectChange}
                        endpoint_name="search/department"
                        name={"department_id"}
                        id={"department_id"}
                        label={"Department"}
                        placeholder={"Select Department"}
                        isRequired={true}
                        defaultValue={data.department ? {
                          value: data.department.department_id,
                          label: data.department.name,
                        } : null}
                      />
                      {errors.department_id && touched.department_id ? (
                        <div className="text-danger small mt-1">
                          {errors.department_id}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="designation" className="form-label">
                        Designation<span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="designation"
                        placeholder="Enter Designation"
                        name="designation"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.designation}
                      />
                      {errors.designation && touched.designation ? (
                        <div className="text-danger small mt-1">
                          {errors.designation}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {values.role === "User" ? (
                  <div className="row my-3 px-2">
                    <div className="col-lg-4 col-md-6 col-sm-12 p-2">
                      <div className="d-flex align-items-center mb-3">
                        <input type="checkbox"
                          className="form-check-input"
                          name="drawing_permission"
                          checked={Object.keys(values.drawing_permission).filter(key => key !== "disable_dwg_file").every(key => values.drawing_permission[key])}
                          onChange={(e) => handleAllPermission('drawing_permission', e.target.checked, initialValues.drawing_permission)}
                        />
                        <h5 className="fst-italic mb-0 ms-2 fw-semibold">Drawing Permission:</h5>
                      </div>
                      <PermissionRadioButton
                        label={"Disable DWG File"}
                        name={'disable_dwg_file'}
                        checkValue={values.drawing_permission.disable_dwg_file}
                        handleChange={handleDrawingPermission}
                      />
                      <PermissionRadioButton
                        label={"Download Drawing"}
                        name={'download_drawing'}
                        checkValue={values.drawing_permission.download_drawing}
                        handleChange={handleDrawingPermission}
                        disabled={values.drawing_permission.disable_dwg_file}
                      />

                      <PermissionRadioButton
                        label={"View Layout"}
                        name={'view_layout'}
                        checkValue={values.drawing_permission.view_layout}
                        handleChange={handleDrawingPermission}
                      />

                      <PermissionRadioButton
                        label={"View PS Drawing"}
                        name={'ps'}
                        checkValue={values.drawing_permission.ps}
                        handleChange={handleDrawingPermission}
                      />

                      <PermissionRadioButton
                        label={"View FDR Drawing"}
                        name={'fdr'}
                        checkValue={values.drawing_permission.fdr}
                        handleChange={handleDrawingPermission}
                      />

                      <PermissionRadioButton
                        label={"View MISC Drawing"}
                        name={'misc'}
                        checkValue={values.drawing_permission.misc}
                        handleChange={handleDrawingPermission}
                      />
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 p-2">
                      <div className="d-flex align-items-center mb-3">
                        <input type="checkbox"
                          className="form-check-input"
                          name="standard_permission"
                          checked={Object.keys(values.standard_permission).every(key => values.standard_permission[key])}
                          onChange={(e) => handleAllPermission('standard_permission', e.target.checked, initialValues.standard_permission)}
                        />
                        <h5 className="fst-italic mb-0 ms-2 fw-semibold">Standard Permission:</h5>
                      </div>
                      <PermissionRadioButton
                        label={"View AWWA"}
                        name={'awwa'}
                        checkValue={values.standard_permission.awwa}
                        handleChange={handleStandardPermission}
                      />
                      <PermissionRadioButton
                        label={"View ASTM"}
                        name={'astm'}
                        checkValue={values.standard_permission.astm}
                        handleChange={handleStandardPermission}
                      />

                      <PermissionRadioButton
                        label={"View API"}
                        name={'api'}
                        checkValue={values.standard_permission.api}
                        handleChange={handleStandardPermission}
                      />

                      <PermissionRadioButton
                        label={"View BRITISH"}
                        name={'british'}
                        checkValue={values.standard_permission.british}
                        handleChange={handleStandardPermission}
                      />

                      <PermissionRadioButton
                        label={"View DIN(GERMAN)"}
                        name={'din_german'}
                        checkValue={values.standard_permission.din_german}
                        handleChange={handleStandardPermission}
                      />

                      <PermissionRadioButton
                        label={"View GOST(RUSSIAN)"}
                        name={'gost_russian'}
                        checkValue={values.standard_permission.gost_russian}
                        handleChange={handleStandardPermission}
                      />

                      <PermissionRadioButton
                        label={"View IEC"}
                        name={'iec'}
                        checkValue={values.standard_permission.iec}
                        handleChange={handleStandardPermission}
                      />

                      <PermissionRadioButton
                        label={"View ISO"}
                        name={'iso'}
                        checkValue={values.standard_permission.iso}
                        handleChange={handleStandardPermission}
                      />
                      <PermissionRadioButton
                        label={"View IRST"}
                        name={'irst'}
                        checkValue={values.standard_permission.irst}
                        handleChange={handleStandardPermission}
                      />
                      <PermissionRadioButton
                        label={"View PSN"}
                        name={'psn'}
                        checkValue={values.standard_permission.psn}
                        handleChange={handleStandardPermission}
                      />
                      <PermissionRadioButton
                        label={"View OTHER STANDARD"}
                        name={'other_standard'}
                        checkValue={values.standard_permission.other_standard}
                        handleChange={handleStandardPermission}
                      />
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 p-2">
                      <div className="d-flex align-items-center mb-3">
                        <input type="checkbox"
                          className="form-check-input"
                          name="document_permission"
                          checked={Object.keys(values.document_permission).every(key => values.document_permission[key])}
                          onChange={(e) => handleAllPermission('document_permission', e.target.checked, initialValues.document_permission)}
                        />
                        <h5 className="fst-italic mb-0 ms-2 fw-semibold">Document Permission:</h5>
                      </div>
                      <PermissionRadioButton
                        label={"View REFERENCE BOOK"}
                        name={'reference_book'}
                        checkValue={values.document_permission.reference_book}
                        handleChange={handleDocumentPermission}
                      />
                      <PermissionRadioButton
                        label={"View TENDER DOCUMENT"}
                        name={'tender_document'}
                        checkValue={values.document_permission.tender_document}
                        handleChange={handleDocumentPermission}
                      />
                      <PermissionRadioButton
                        label={"View CATALOUGE"}
                        name={'catalouge'}
                        checkValue={values.document_permission.catalouge}
                        handleChange={handleDocumentPermission}
                      />
                      <PermissionRadioButton
                        label={"View TECHNICAL CALCULATION"}
                        name={'technical_calculation'}
                        checkValue={values.document_permission.technical_calculation}
                        handleChange={handleDocumentPermission}
                      />
                      <PermissionRadioButton
                        label={"View TECHNICAL SPECIFICATION"}
                        name={'technical_specification'}
                        checkValue={values.document_permission.technical_specification}
                        handleChange={handleDocumentPermission}
                      />
                      <PermissionRadioButton
                        label={"View TECHNICAL REPORT"}
                        name={'technical_report'}
                        checkValue={values.document_permission.technical_report}
                        handleChange={handleDocumentPermission}
                      />
                      <PermissionRadioButton
                        label={"View PROJECT REPORT"}
                        name={'project_report'}
                        checkValue={values.document_permission.project_report}
                        handleChange={handleDocumentPermission}
                      />
                      <PermissionRadioButton
                        label={"View PROJECT SUBMITTED DRAWING"}
                        name={'project_submitted_drawings'}
                        checkValue={values.document_permission.project_submitted_drawings}
                        handleChange={handleDocumentPermission}
                      />
                    </div>

                  </div>
                ) : null}
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <button type="submit" className="btn btn-primary me-1 w-50" disabled={isLoading}>
                    {isLoading ? (<span><Spinner size="sm" className="me-2" />Updating...</span>) : "Update"}
                  </button>
                </div>
              </Form>
            ) : null}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditUserModal;
