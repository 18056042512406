import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Pagination from "../../constant/Pagination";
import TableLoaderEffect from "../../constant/TableLoader";
import {
  getDrawingListAction,
  handleLoadingAction,
  updateDrawingFilterAction,
} from "../../../store/actions/DrawingActions";
import { Spinner, Dropdown } from "react-bootstrap";
import DynamicSelectInput, {
  StaticSelectInput,
} from "../../constant/SelectInput";

const maxWidthStyle = {
  maxWidth: "150px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  display: "block",
};

const maxWidthStyle300 = {
  maxWidth: "40rem",
  minWidth: "10rem",
  whiteSpace: "normal",
  display: "block",
};

const DRAWING_TYPE_OPTIONS = [
  { label: "PDR", value: "PDR" },
  { label: "CDBR", value: "CDBR" },
  { label: "RS", value: "RS" },
  { label: "PS", value: "PS" },
  { label: "FDR", value: "FDR" },
  { label: "MISC", value: "MISC" },
];

const initialState = {
  searchQuery: "",
  department_id: null,
  unit_id: null,
  drawing_type: "",
  description: "",
  work_order_number: "",
  file_present: "",
  status: "",
};

const DrawingListPage = (props) => {
  const {
    drawingList,
    drawingCount,
    isDrawingListLoading,
    isDrawingDownLoading,
    filterInput,
    userRole,
    drawingPerm,
    currentPage
  } = props;


  const [isClearAll, setIsClearAll] = useState(false);
  const [isEmptySelect, setIsEmptySelect] = useState({ department_id: false, unit_id: false });
  const [isAnySearchApply, setIsAnySearchApply] = useState(false)
  const [selectedDropWownvalue, setSelectedDropWownvalue] = useState({drawing_type:null, department_id:null, unit_id:null, status:null});
  const [recordsPage, setRecordsPage] = useState(10);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchAreaRef = useRef(null);
  const handleAnySerachApply = (filterData) => {
    const anySearchApply = [
      filterData.department_id?.value || "",
      filterData.unit_id?.value || "",
      filterData.drawing_type,
      filterData.work_order_number,
      filterData.description,
      filterData.searchQuery,
      filterData.status,
      filterData.file_present
    ].some((item) => item !== "");
    setIsAnySearchApply(anySearchApply)
  }

  useEffect(() => {
    dispatch(handleLoadingAction({ isDrawingListLoading: true }));
    dispatch(
      getDrawingListAction(
        currentPage,
        recordsPage,
        filterInput.searchQuery,
        filterInput.department_id?.value || "",
        filterInput.unit_id?.value || "",
        filterInput.drawing_type,
        filterInput.work_order_number,
        filterInput.description,
        filterInput.file_present,
        filterInput.status
      )
    );
    handleAnySerachApply(filterInput)
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");
    const drawing_type = queryParams.get("drawing_type");
    if (status && status === "Approved") {
      dispatch(
        updateDrawingFilterAction({ ...filterInput, status: "Approved" })
      );
      setSelectedDropWownvalue({...selectedDropWownvalue, status:{value:status, label:status}})
      dispatch(handleLoadingAction({ isDrawingListLoading: true }));
      dispatch(
        getDrawingListAction(
          1,
          recordsPage,
          filterInput.searchQuery,
          filterInput.department_id?.value || "",
          filterInput.unit_id?.value || "",
          filterInput.drawing_type,
          filterInput.work_order_number,
          filterInput.description,
          filterInput.file_present,
          status
        )
      );
      navigate(location.pathname, { replace: true });
    } else if(drawing_type && ["CDBR", "PDR", "RS", "PS", "FDR", "MISC"].includes(drawing_type)){
      dispatch(updateDrawingFilterAction({ ...filterInput, drawing_type }));
      setSelectedDropWownvalue({...selectedDropWownvalue, drawing_type:{value:drawing_type, label:drawing_type}})
      dispatch(handleLoadingAction({ isDrawingListLoading: true }));
      dispatch(
        getDrawingListAction(
          1,
          recordsPage,
          filterInput.searchQuery,
          filterInput.department_id?.value || "",
          filterInput.unit_id?.value || "",
          drawing_type,
          filterInput.work_order_number,
          filterInput.description,
          filterInput.file_present,
          filterInput.status
        )
      );
      navigate(location.pathname, { replace: true });
    }
  }, [location.search]);

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    dispatch(handleLoadingAction({ currentPage: new_page }));
    dispatch(handleLoadingAction({ isDrawingListLoading: true }));
    dispatch(
      getDrawingListAction(
        new_page,
        recordsPage,
        filterInput.searchQuery,
        filterInput.department_id?.value || "",
        filterInput.unit_id?.value || "",
        filterInput.drawing_type,
        filterInput.work_order_number,
        filterInput.description,
        filterInput.file_present,
        filterInput.status
      )
    );
  };

  const handleGoTo = (page_no) => {
    dispatch(handleLoadingAction({ currentPage: page_no }));
    dispatch(handleLoadingAction({ isDrawingListLoading: true }));
    dispatch(
      getDrawingListAction(
        page_no,
        recordsPage,
        filterInput.searchQuery,
        filterInput.department_id?.value || "",
        filterInput.unit_id?.value || "",
        filterInput.drawing_type,
        filterInput.work_order_number,
        filterInput.description,
        filterInput.file_present,
        filterInput.status
      )
    );
  };

  const handleAllRecord = () => {
    dispatch(handleLoadingAction({ currentPage: 1 }));
    dispatch(handleLoadingAction({ isDrawingListLoading: true }));
    setRecordsPage(drawingCount)
    dispatch(
      getDrawingListAction(
        1,
        drawingCount,
        filterInput.searchQuery,
        filterInput.department_id?.value || "",
        filterInput.unit_id?.value || "",
        filterInput.drawing_type,
        filterInput.work_order_number,
        filterInput.description,
        filterInput.file_present,
        filterInput.status
      )
    );
  };

  const handleClear = () => {
    setIsClearAll(true);
    dispatch(updateDrawingFilterAction(initialState));
    handleAnySerachApply(initialState)
    setRecordsPage(10)
    dispatch(handleLoadingAction({ isDrawingListLoading: true }));
    dispatch(
      getDrawingListAction(
        1,
        10,
        initialState.searchQuery,
        initialState.department_id?.value || "",
        initialState.unit_id?.value || "",
        initialState.drawing_type,
        initialState.work_order_number,
        initialState.description,
        initialState.file_present,
        initialState.status
      )
    );
    dispatch(handleLoadingAction({ currentPage: 1 }));
    setTimeout(() => setIsClearAll(false), 500);
  };

  const handleOnSearch = () => {
    dispatch(handleLoadingAction({ isDrawingListLoading: true }));
    setRecordsPage(10)
    dispatch(
      getDrawingListAction(
        1,
        10,
        filterInput.searchQuery,
        filterInput.department_id?.value || "",
        filterInput.unit_id?.value || "",
        filterInput.drawing_type,
        filterInput.work_order_number,
        filterInput.description,
        filterInput.file_present,
        filterInput.status
      )
    );
    dispatch(handleLoadingAction({ currentPage: 1 }));
  };

  const handleSelectChange = (name, value, _data) => {
    let newFilter;
    if (["department_id", "unit_id"].includes(name)) {
      newFilter = { ...filterInput, [name]: _data };
    } else {
      newFilter = { ...filterInput, [name]: value };
    }
    if(value === null){
      setSelectedDropWownvalue({...selectedDropWownvalue, [name]:null})
    }
    dispatch(updateDrawingFilterAction(newFilter));
    handleAnySerachApply(newFilter)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFilter = { ...filterInput, [name]: value };
    dispatch(updateDrawingFilterAction(newFilter));
    handleAnySerachApply(newFilter)
  };

  const DetailDrawingView = ({ item }) => {
    if (
      ["Admin", "SuperAdmin"].includes(userRole) ||
      !item.is_layout ||
      (item.is_layout && drawingPerm?.view_layout)
    ) {
      return (
        <span
          className="text-primary cursor-pointer fw-bolder"
          onClick={() => navigate(`/drawing/${item.id}`)}
        >
          {item.drawing_type}-{item.drawing_number}
        </span>
      );
    }
    return (
      <span className="fw-bolder">
        {item.drawing_type}-{item.drawing_number}{" "}
        <span className="ms-2 fw-normal">Layout</span>
      </span>
    );
  };

  const handleDrawingTypeClick = (drawingType) => {
    let department_id = filterInput.department_id
    let unit_id = filterInput.unit_id
    if (['RS', 'PS'].includes(drawingType)) {
      department_id = null
      unit_id = null
      setIsEmptySelect({ department_id: true, unit_id: true })
    } else if (drawingType === "FDR") {
      unit_id = null
      setIsEmptySelect({ ...isEmptySelect, unit_id: true })
    }
    const newFilter = { ...filterInput, drawing_type: drawingType, department_id, unit_id }
    dispatch(updateDrawingFilterAction(newFilter))
    dispatch(
      getDrawingListAction(
        1,
        recordsPage,
        filterInput.searchQuery,
        newFilter.department_id?.value || "",
        newFilter.unit_id?.value || "",
        newFilter.drawing_type,
        newFilter.work_order_number,
        newFilter.description,
        newFilter.file_present,
        newFilter.status
      ));
    dispatch(handleLoadingAction({ currentPage: 1 }));
    setSelectedDropWownvalue({department_id, unit_id, drawing_type:{label: drawingType, value: drawingType}})
    handleAnySerachApply(newFilter)
  }

  function extractSheetNumber(filename) {
    const match = filename.match(/SH(\d+)/);
    if (match) {
      const sheetNumber = parseInt(match[1], 10);
      return sheetNumber;
    }
    return 1;
  }
  
  const viewDrawingFile = async (id) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/api/drawing_file_download/${id}?action=view`
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error fetching or opening file:', error);
    }
  };

  const handleSearchAreaKeyDown = (e) => {
    if (e.key === 'Enter') {
      const handlesearch = () => {
        dispatch(handleLoadingAction({ isDrawingListLoading: true }));
        setRecordsPage(10)
        dispatch(
          getDrawingListAction(
            1,
            10,
            filterInput.searchQuery,
            filterInput.department_id?.value || "",
            filterInput.unit_id?.value || "",
            filterInput.drawing_type,
            filterInput.work_order_number,
            filterInput.description,
            filterInput.file_present,
            filterInput.status
          )
        );
        dispatch(handleLoadingAction({ currentPage: 1 }));
      }
      setTimeout(() => handlesearch(), 500);
    }
  }

  const orderFileName = (fileList) => {
    fileList.sort((a, b) => a.file_name.localeCompare(b.file_name, undefined, { numeric: true, sensitivity: 'base' }));
    return fileList
  }

  const getPermData = () => {
    if(userRole !== "User"){
      return ["PDR", "CDBR", "RS", "PS", "FDR", "MISC"]
    }
    const perm = ["PDR", "CDBR", "RS"]
    if(drawingPerm?.ps){
      perm.push('PS')
    }
    if(drawingPerm?.fdr){
      perm.push('FDR')
    }
    if(drawingPerm?.misc){
      perm.push('MISC')
    }
    return perm
  }
  
  return (
    <>
      <div className="container-fluid">
        {userRole === "User" && (
          <div className="d-flex">
            {getPermData().map((text, index) => (
                <div
                  key={index}
                  className="card cursor-pointer fs-4 fw-semibold py-3 px-5 mx-2 flex-fill text-center"
                  style={{
                    background: "linear-gradient(120deg, #a1c4fd 0%, #a1cee3 100%)",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={() => handleDrawingTypeClick(text)}
                >
                  <span>{text}</span>
                </div>
            ))}
          </div>
        )}
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="px-3 py-3">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-start align-items-sm-center flex-column flex-sm-row">
                    <h4 className="heading mb-md-0 fw-bold">Drawings</h4>
                    <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                      {userRole !== "User" && (
                        <button
                          className="btn btn-primary mb-3 mb-md-0"
                          onClick={() => navigate("/add-drawing")}
                        >
                          Add Drawing
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="mt-4 border rounded p-3" ref={searchAreaRef} onKeyDown={handleSearchAreaKeyDown}>
                    <div className="row">
                      <div className="col-lg-2 col-md-3 col-sm-6 ">
                        <StaticSelectInput
                          optionsList={DRAWING_TYPE_OPTIONS.filter((item) => getPermData().includes(item.value))}
                          setParentInputValue={handleSelectChange}
                          name={"drawing_type"}
                          isClearable={true}
                          labelHint={false}
                          defaultValue={
                            filterInput.drawing_type
                              ? {
                                label: filterInput.drawing_type,
                                value: filterInput.drawing_type,
                              }
                              : null
                          }
                          setClickValue={selectedDropWownvalue.drawing_type}
                          id={"drawing_type"}
                          label={"Drawing Type"}
                          isSetEmpty={isClearAll}
                        />
                      </div>

                      <div className="col-lg-3 col-md-3 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="work_order_number"
                            className="form-label"
                          >
                            Drawing Number
                          </label>
                          <div className="input-group tp-input-group">
                            <input
                              type="text"
                              name="searchQuery"
                              className="form-control"
                              placeholder="Drawing Number"
                              value={filterInput.searchQuery}
                              aria-describedby="basic-search"
                              onChange={handleChange}
                            />
                            <div className="input-group-text" id="basic-search">
                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#888888" viewBox="0 0 256 256"><path d="M184,104v32a8,8,0,0,1-8,8H99.31l10.35,10.34a8,8,0,0,1-11.32,11.32l-24-24a8,8,0,0,1,0-11.32l24-24a8,8,0,0,1,11.32,11.32L99.31,128H168V104a8,8,0,0,1,16,0Zm48-48V200a16,16,0,0,1-16,16H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56ZM216,200V56H40V200H216Z"></path></svg>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-2 col-md-3 col-sm-6 ">
                        <DynamicSelectInput
                          setParentInputValue={handleSelectChange}
                          endpoint_name="search/department"
                          labelHint={false}
                          isClearable={true}
                          label={"Department"}
                          name={"department_id"}
                          id={"department_id"}
                          isDisabled={["RS", "PS"].includes(filterInput.drawing_type)}
                          defaultValue={filterInput.department_id || null}
                          setClickValue={selectedDropWownvalue.department_id}
                          isSetEmpty={isClearAll || isEmptySelect.department_id}
                        />
                      </div>

                      <div className="col-lg-2 col-md-3 col-sm-6 ">
                        <DynamicSelectInput
                          setParentInputValue={handleSelectChange}
                          endpoint_name="search/unit"
                          labelHint={false}
                          isClearable={true}
                          label={"Unit"}
                          name={"unit_id"}
                          id={"unit_id"}
                          isDisabled={["RS", "PS", "FDR"].includes(filterInput.drawing_type)}
                          defaultValue={filterInput.unit_id || null}
                          setClickValue={selectedDropWownvalue.unit_id}
                          isSetEmpty={isClearAll || isEmptySelect.unit_id}
                        />
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="description"
                            className="form-label"
                          >
                            Description
                          </label>
                          <input
                            className="form-control"
                            id="description"
                            placeholder="Enter Description"
                            name="description"
                            value={filterInput.description}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 d-flex flex-md-row flex-column align-items-md-center align-items-start justify-content-md-between justify-content-start">
                        <div className="d-flex d-flex flex-md-row flex-column">
                          {userRole !== "User" && (
                            <>
                              <div style={{ width: '15rem' }}>
                                <StaticSelectInput
                                  optionsList={[
                                    {
                                      label: "Pending",
                                      value: "Pending",
                                    },
                                    {
                                      label: "Approved",
                                      value: "Approved",
                                    },
                                  ]}
                                  setParentInputValue={handleSelectChange}
                                  name={"status"}
                                  isClearable={true}
                                  labelHint={false}
                                  defaultValue={
                                    filterInput.status
                                      ? {
                                        label: filterInput.status,
                                        value: filterInput.status,
                                      }
                                      : null
                                  }
                                  setClickValue={selectedDropWownvalue.status}
                                  id={"status"}
                                  label={"Status"}
                                  isSetEmpty={isClearAll}
                                />
                              </div>

                              <div style={{ width: '15rem' }} className="ms-md-3 ms-0">

                                <StaticSelectInput
                                  parentClassName="mb-3"
                                  optionsList={[
                                    {
                                      label: "Present",
                                      value: "Present",
                                    },
                                    {
                                      label: "Not Present",
                                      value: "Not Present",
                                    },
                                  ]}
                                  setParentInputValue={handleSelectChange}
                                  name={"file_present"}
                                  isClearable={true}
                                  labelHint={false}
                                  defaultValue={
                                    filterInput.file_present
                                      ? {
                                        label: filterInput.file_present,
                                        value: filterInput.file_present,
                                      }
                                      : null
                                  }
                                  id={"file_present"}
                                  label={"File Present"}
                                  isSetEmpty={isClearAll}
                                />
                              </div>
                            </>
                          )}

                          <div className="mb-md-0 mb-3 ms-md-3 ms-0">
                            <label
                              htmlFor="work_order_number"
                              className="form-label"
                            >
                              Work Order Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="work_order_number"
                              placeholder="Enter Work Order No."
                              name="work_order_number"
                              value={filterInput.work_order_number}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="d-flex align-items-center d-flex flex-sm-row flex-column">
                          <button
                            type="button"
                            className="btn btn-primary mb-md-0 mb-3"
                            disabled={isDrawingListLoading}
                            onClick={handleOnSearch}
                          >
                            {(isAnySearchApply && isDrawingListLoading) ? <span><Spinner size="sm" className="me-2" /> Searching ...</span> : "Search"}
                          </button>

                          <button
                            type="button"
                            className="btn btn-outline-danger ms-sm-3 ms-0"
                            onClick={handleClear}
                            disabled={!isAnySearchApply}
                          >
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* end filter area */}
                  </div>
                </div>

                <div className="table-responsive drawing-list-table active-projects style-1 ItemsCheckboxSec shorting">
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0 table-striped"
                    >
                      <thead>
                        <tr>
                          <th>Drawing Number</th>
                          <th>Department / Volume</th>
                          <th>Unit / Sub-volume</th>
                          <th>Work Order No</th>
                          <th>Revision</th>
                          <th>Description</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isDrawingListLoading ? (
                          <TableLoaderEffect colLength={7} />
                        ) : (
                          drawingList.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <DetailDrawingView item={item} />
                              </td>
                              {item.drawing_type === "RS" ? (
                                <>
                                  <td>{item.volume?.volume_name || ""}</td>
                                  <td>
                                    <span style={maxWidthStyle}>
                                      {item.volume?.name || ""}
                                    </span>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>{item.department?.name || ""}</td>
                                  <td>{item.unit?.name || ""}</td>
                                </>
                              )}
                              <td>{item.work_order_number || "None"}</td>
                              <td>{item.revision_version}</td>
                              <td>
                                {" "}
                                <span style={maxWidthStyle300}>
                                  {item.default_description}
                                </span>
                              </td>
                              <td>
                                {item.files.length ?
                                  item.files.length === 1 ?
                                    <button
                                      className="btn btn-outline-primary btn-sm mb-3 mb-md-0"
                                      disabled={isDrawingDownLoading && isDrawingDownLoading === item.id}
                                      onClick={() => viewDrawingFile(item.files[0].id)}
                                    >
                                      {(isDrawingDownLoading && isDrawingDownLoading === item.id) ? <span><Spinner size="sm" className="me-2" /> View...</span> : <span><i className="fa-solid fa-eye me-2" /> View</span>}
                                    </button> :
                                    <Dropdown className=''>
                                      <Dropdown.Toggle as="button" className='i-false btn btn-outline-primary btn-sm'
                                        disabled={isDrawingDownLoading && isDrawingDownLoading === item.id}                                                                        >
                                        {(isDrawingDownLoading && isDrawingDownLoading === item.id) ? <span><Spinner size="sm" className="me-2" /> View...</span> : "View"}
                                        <i className="fa-solid fa-chevron-down ms-2" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className='global-drop-menu'>
                                        {orderFileName(item.files).map(file => (
                                          <Dropdown.Item
                                            key={file.id}
                                            onClick={() => viewDrawingFile(file.id)}
                                          ><i className="fa-solid fa-eye me-2" /> Sheet {extractSheetNumber(file.file_name)}</Dropdown.Item>
                                        ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  : "File NP"
                                }
                              </td>
                            </tr>
                          ))
                        )}
                        {drawingList.length === 0 &&
                          userRole === "User" &&
                          !isAnySearchApply ? (
                          <tr>
                            <td colSpan={7}>
                              <h4 className="text-center my-5">
                                To access drawings, utilize the search or
                                advanced search options.
                              </h4>
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {isDrawingListLoading ? (
                <div style={{ height: "4rem" }}></div>
              ) : (
                <Pagination
                  currentPage={currentPage}
                  recordsPage={recordsPage}
                  dataLength={drawingCount}
                  handlePagination={handlePagination}
                  handleGoTo={handleGoTo}
                  handleAllRecords={[filterInput.searchQuery,
                    filterInput.department_id?.value || "",
                    filterInput.unit_id?.value || "",
                    filterInput.work_order_number,
                    filterInput.description].some(item => item) ? handleAllRecord : undefined}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    drawingList: state.drawing.drawingList,
    drawingCount: state.drawing.drawingCount,
    currentPage: state.drawing.currentPage,
    isDrawingListLoading: state.drawing.isDrawingListLoading,
    isDrawingDownLoading: state.drawing.isDrawingDownLoading,
    filterInput: state.drawing.filterInput,
    userRole: state.auth.user?.role || "Admin",
    drawingPerm: state.auth.user?.drawing_permission || {}
  };
};

export default connect(mapStateToProps)(DrawingListPage);
