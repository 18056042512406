import React, { useState, useEffect } from "react";
import TableLoaderEffect from "../../constant/TableLoader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  approveDrawingList,
  bulkApproveDrawing,
} from "../../../services/DrawingService";

const maxWidthStyle300 = {
  maxWidth: "40rem",
  minWidth: "10rem",
  whiteSpace: "normal",
  display: "block"
}

const maxWidthStyle = {
  maxWidth: "150px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  display: "block"
}

const PendingDrawingPage = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDrawings, setSelectedDrawings] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true);
    approveDrawingList()
      .then((resp) => {
        setData(resp.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
        } else {
          console.log(error);
        }
      });
  }, []);

  const toggleCheckbox = (id) => {
    setSelectedDrawings((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedDrawings.length === data.length) {
      setSelectedDrawings([]);
    } else {
      setSelectedDrawings(data.map((item) => item.id));
    }
  };

  const handleApprove = () => {
    bulkApproveDrawing({ drawing_id_list: selectedDrawings })
      .then((resp) => {
        setData(data.filter((item) => !selectedDrawings.includes(item.id)));
        setSelectedDrawings([]);
        toast.success(resp.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption d-flex justify-content-between text-wrap flex-column  flex-sm-row align-items-start align-items-sm-center p-3">
                  <div className="d-flex  align-items-center">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-circle"
                      onClick={() => navigate("/dashboard")}
                    >
                      <i className="fa-solid fa-arrow-left"></i>
                    </button>
                    <h4 className="mb-0 ms-2">Pending Drawing</h4>
                  </div>
                  <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                    <span className="badge badge-lg badge-circle badge-outline-info me-3">
                      selected {selectedDrawings.length}
                    </span>
                    {selectedDrawings.length > 0 && <button className="btn btn-sm btn-primary" onClick={handleApprove}>
                      Approve
                    </button>}
                  </div>
                </div>
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>
                            <div className="form-check custom-checkbox checkbox-primary">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={selectedDrawings.length === data.length && data.length !== 0}
                                onChange={handleSelectAll}
                              />
                            </div>
                            {/* <label htmlFor="" className="ms-2">All</label> */}
                          </th>
                          <th>Drawing.</th>
                          <th>Department / Volume</th>
                          <th>Unit / Sub-Volume</th>
                          <th>Work Order No</th>
                          <th>No Of Sheet</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <TableLoaderEffect colLength={7} />
                        ) : (
                          data.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="form-check custom-checkbox checkbox-primary">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={selectedDrawings.includes(item.id)}
                                    onChange={() => toggleCheckbox(item.id)}
                                  />
                                </div>
                              </td>

                              <td>
                                <span className="text-primary c-pointer fw-bolder" onClick={()=>navigate(`/drawing/${item.id}`)}>
                                  {item.drawing_type}-{item.drawing_number}
                                </span>
                              </td>
                              {item.drawing_type === "RS" ? (<>
                                <td>{item.volume?.volume_name || ""}</td>
                                <td><span style={maxWidthStyle}>{item.volume?.name || ""}</span></td>
                              </>) : (<>
                                <td>{item.department?.name || ""}</td>
                                <td>{item.unit?.name || ""}</td>
                              </>)}
                              <td>
                                {item.work_order_number || "None"}
                              </td>
                              <td>{item.no_of_sheet}</td>
                              <td><span style={maxWidthStyle300}>{item.default_description}</span></td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingDrawingPage;
