import React, { useState, useEffect } from "react";
import TableLoaderEffect from "../../constant/TableLoader";
import { useParams } from "react-router-dom";
import Pagination from "../../constant/Pagination";
import { Spinner, Modal } from "react-bootstrap";
import { getUserSIRLogList } from "../../../services/UserService";

const SIRLogPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const recordsPage = 10;
  const [showModal, setShowModal] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    if (!searchQuery.length > 0) {
      setIsLoading(true)
    }
    getUserSIRLogList(id, currentPage, recordsPage, searchQuery)
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
        setIsSearchLoading(false)
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
        setIsSearchLoading(false)
      });
  }, [currentPage, recordsPage, searchQuery]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setIsSearchLoading(true)
    setCurrentPage(1)
    setSearchQuery(value);
  };

  const toggleModal = (details = null) => {
    setSelectedDetails(details)
    setShowModal(!showModal);
  };

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  return (
    <>
      <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
        <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
          <h4 className="heading mb-0 fw-bold">SIR (Structural Inspection Report) Log</h4>
          <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
            <div className="me-3 mb-3 mb-md-0">
              <div className="input-group tp-input-group">
                <input
                  type="text"
                  name="search"
                  style={{ minWidth: "15rem" }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="form-control form-control-sm"
                  placeholder="Search"
                />
                <div className="input-group-text" id="basic-search">
                  {isSearchLoading && <Spinner variant="primary" size="sm" />}
                  {!isSearchLoading && searchQuery.length > 0 && (
                    <i
                      className="fa-solid fa-xmark c-pointer text-primary"
                      onClick={() => {
                        setCurrentPage(1);
                        setSearchQuery("");
                      }}
                    ></i>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
          <table
            id="empoloyees-tblwrapper"
            className="table ItemsCheckboxSec dataTable no-footer mb-0"
          >
            <thead>
              <tr>
                <th>SIR Number</th>
                <th>Log</th>
                <th>Message</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <TableLoaderEffect colLength={4} />
              ) : (
                data.map((item, index) => (
                  <tr key={index}>
                    {item.sir ? (
                      <>
                        <td> <span className="text-primary cursor-pointer fw-bolder"
                          onClick={() => toggleModal(item)}
                        >{item.sir.sir_number}</span></td>
                      </>
                    ) : <>
                      <td>None</td>
                    </>}
                    <td>{item.status}</td>
                    <td>{item.message}</td>
                    <td>{item.action_time}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isLoading ? (
        <div style={{ height: "4rem" }}></div>
      ) : (
        <Pagination
          currentPage={currentPage}
          recordsPage={recordsPage}
          dataLength={total}
          handlePagination={handlePagination}
        />
      )}

      <Modal
        className="modal fade modal-sm"
        id="exampleModal1"
        show={showModal}
        onHide={toggleModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Log Details
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {selectedDetails && selectedDetails.details && selectedDetails.details.split('|').map((detail, index) => (
              <div className="mb-3" key={index}>{detail}</div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default SIRLogPage;