import React, { useState, useRef } from "react";
import { Button, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import PDRSampleExcel from "../../../../images/ExcelFile/Drawing/PDR_sample_drawing_data.xlsx";
import CDBRSampleExcel from "../../../../images/ExcelFile/Drawing/CDBR_sample_drawing_data.xlsx";
import PSSampleExcel from "../../../../images/ExcelFile/Drawing/PS_sample_drawing_data.xlsx";
import RSSampleExcel from "../../../../images/ExcelFile/Drawing/RS_sample_drawing_data.xlsx";
import FDRSampleExcel from "../../../../images/ExcelFile/Drawing/FDR_sample_drawing_data.xlsx";
import MISCSampleExcel from "../../../../images/ExcelFile/Drawing/MISC_sample_drawing_data.xlsx";

import { bulkUploadDrawingData } from "../../../../services/DrawingService";
import { useNavigate } from "react-router-dom";

const UploadDrawingPage = () => {
  const [drawingType, setDrawingType] = useState("PDR");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [jsonData, setJsonData] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate()

  const requiredHeaders = {
    PS: [
      "drawing_number",
      "drawing_type",
      "supplier_name",
      "work_order_number",
      "vendor_number",
      "client_number",
      "package_number",
      "revision",
      "drawing_size",
      "drawing_file_type",
      "is_layout",
      "date_of_registration",
      "certification",
      "no_of_sheet",
      "remarks",
      "description",
      "dwg_file",
    ],
    RS: [
      "drawing_number",
      "drawing_type",
      "volume_no",
      "supplier_name",
      "work_order_number",
      "vendor_number",
      "client_number",
      "package_number",
      "revision",
      "drawing_size",
      "drawing_file_type",
      "is_layout",
      "date_of_registration",
      "certification",
      "no_of_sheet",
      "remarks",
      "description",
      "dwg_file",
    ],
    FDR: [
      "drawing_number",
      "drawing_type",
      "department_id",
      "supplier_name",
      "pdr_number",
      "package_number",
      "revision",
      "drawing_size",
      "drawing_file_type",
      "is_layout",
      "no_of_sheet",
      "remarks",
      "description",
      "dwg_file",
      "letter_number_date",
      "approved_date",
    ],
    MISC: [
      "drawing_number",
      "drawing_type",
      "department_id",
      "unit_id",
      "supplier_name",
      "revision",
      "drawing_size",
      "drawing_file_type",
      "is_layout",
      "no_of_sheet",
      "remarks",
      "description",
      "dwg_file",
    ],
    default: [
      "drawing_number",
      "drawing_type",
      "department_id",
      "unit_id",
      "supplier_name",
      "work_order_number",
      "vendor_number",
      "client_number",
      "package_number",
      "revision",
      "drawing_size",
      "drawing_file_type",
      "is_layout",
      "date_of_registration",
      "certification",
      "no_of_sheet",
      "remarks",
      "description",
      "dwg_file",
    ],
  };

  const handleChange = (e) => {
    setDrawingType(e.target.value);
  };

  const formatVolumeNo = (input) => {
    const num = typeof input === 'string' ? parseFloat(input) : input;
    if (isNaN(num)) {
      return null;
    }
    const formattedNumber = num.toFixed(2);
    return formattedNumber;
  }


  const checkFileExtension = (fileName) =>
    /\.(xlsx|xls)$/.test(fileName.toLowerCase());

  function convertUnixTime(value) {
    try {
      const baseDate = new Date(1899, 11, 30);
      const date = new Date(baseDate.getTime() + value * 24 * 60 * 60 * 1000);
      return date.toISOString().slice(0, 10);
    } catch (error) {
      return null;
    }
  }

  const validateData = (obj, row) => {
    const errors = [];
    const commonKeys = [
      "drawing_type",
      "drawing_number",
      "drawing_size",
      "drawing_file_type",
      "is_layout",
      "no_of_sheet",
      "remarks",
      "description",
      "dwg_file",
    ];

    let requiredKeys = [];

    switch (drawingType) {
      case "PS":
        requiredKeys = [
          ...commonKeys,
          "date_of_registration",
          "certification",
          "no_of_sheet",
        ];
        break;
      case "RS":
        requiredKeys = [
          ...commonKeys,
          "volume_no",
          "date_of_registration",
          "certification",
          "no_of_sheet",
        ];
        break;
      case "FDR":
        requiredKeys = [...commonKeys, "approved_date"];
        break;
      case "MISC":
        requiredKeys = commonKeys;
        break;
      default:
        requiredKeys = [
          ...commonKeys,
          "department_id",
          "unit_id",
          "date_of_registration",
          "certification",
          "no_of_sheet",
        ];
        break;
    }

    Object.entries(obj).forEach(([key, value], col) => {
      if (value === null || value === undefined) {
        if (requiredKeys.includes(key)) {
          errors.push(
            `Data is required in row ${row + 1}, column ${col + 1} in ${key}`
          );
        } else if (key === "date_of_registration" && drawingType !== "MISC") {
          errors.push(
            `Invalid date format in row ${row + 1}, column ${col + 1} in ${key}`
          );
        } else if (drawingType === "FDR" && key === "approved_date") {
          errors.push(
            `Invalid date format in row ${row + 1}, column ${col + 1} in ${key}`
          );
        }
      } else if (isNaN(value)) {
        if (key === "no_of_sheet") {
          errors.push(
            `Invalid no of sheet format, required number in row ${row + 1}, column ${col + 1
            } in ${key}`
          );
        } else if (key === "revision") {
          errors.push(
            `Invalid revision format, in required number in row ${row + 1}, column ${col + 1
            } in ${key}`
          );
        }
      } else if (value === undefined) {
        obj[key] = null;
      }
    });
    return errors;
  };

  const handleFileInputChange = (files) => {
    const file = files[0];
    if (file) {
      const fileName = file.name;
      if (!checkFileExtension(fileName)) {
        toast.error("Please select a valid Excel file (.xlsx or .xls)");
        return;
      }

      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const dataJson = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headers = dataJson[0].map((header) => header.trim());
        const requiredHeadersForOption =
          requiredHeaders[drawingType] || requiredHeaders.default;

        const missingHeaders = requiredHeadersForOption.filter(
          (header) => !headers.includes(header)
        );
        if (missingHeaders.length > 0) {
          toast.error(
            `Missing headers in Excel file: ${missingHeaders.join(", ")}`
          );
          return;
        }

        const keys = dataJson[0];
        let jsonData = [];
        let errors = [];
        for (var i = 1; i < dataJson.length; i++) {
          if (dataJson[i].length === 0) {
            continue;
          }
          var obj = { description: [] };
          const description_list = [];
          for (var j = 0; j < keys.length; j++) {
            const key = keys[j];
            const value = dataJson[i][j];
            if (key === "date_of_registration" || key === "approved_date") {
              if (typeof value === "string") {
                try {
                  obj[key] = new Date(value).toISOString().slice(0, 10);
                } catch (err) {
                  obj[key] = null
                }
              } else {
                obj[key] = convertUnixTime(value);
              }
            } else if (key === "description" && value !== undefined) {
              description_list.push(value);
            } else if (key === "volume_no") {
              obj[key] = formatVolumeNo(value)
            } else {
              obj[key] = value;
            }
          }
          if (description_list.length) {
            obj = { ...obj, description: description_list };
          }
          jsonData.push(obj);
          const error = validateData(obj, i);
          if (error.length) {
            errors = errors.concat([...error]);
          }
        }
        setJsonData(jsonData);
        setErrorMessages(errors);
        setSelectedFileName(fileName);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleCloseButton = (e) => {
    setSelectedFileName("");
    setErrorMessages([]);
    fileInputRef.current.value = "";
    e.stopPropagation();
  };

  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    if (drawingType === "PDR") {
      downloadLink.href = PDRSampleExcel;
    } else if (drawingType === "CDBR") {
      downloadLink.href = CDBRSampleExcel;
    } else if (drawingType === "PS") {
      downloadLink.href = PSSampleExcel;
    } else if (drawingType === "RS") {
      downloadLink.href = RSSampleExcel;
    } else if (drawingType === "FDR") {
      downloadLink.href = FDRSampleExcel;
    } else {
      downloadLink.href = MISCSampleExcel;
    }
    downloadLink.download = `${drawingType}_sample_drawing_data.xlsx`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await bulkUploadDrawingData({ data_list: jsonData });
      console.log("Response:", response);
      const { message, results } = response.data;
      toast.success(message);
      removeFile();
      navigate("/drawing")
    } catch (error) {
      if (error.response) {
        const { message, results } = error.response.data;
        setErrorMessages(
          results.error_data_set.map(
            (item) => `Row ${item.row}. Error: ${item.message}`
          )
        );
        toast.error(message);
      }
      console.error("Error:", error);
    }
    setIsLoading(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFileInputChange(droppedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const removeFile = () => {
    setSelectedFileName("");
    setErrorMessages([]);
    fileInputRef.current.value = "";
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="tbl-caption d-flex justify-content-between text-wrap flex-column  flex-sm-row align-items-start align-items-sm-center p-3">
                <div className="d-flex align-items-center my-2 justify-content-between">
                  <h4 className="heading fw-bold mb-2 mb-md-1 me-3">
                    Upload {drawingType} Drawings
                  </h4>
                  <div className="form-group">
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="drawingType"
                        id="PDRRadio"
                        value="PDR"
                        checked={drawingType === "PDR"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="PDRRadio">
                        PDR
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="drawingType"
                        id="CDBRRadio"
                        value="CDBR"
                        checked={drawingType === "CDBR"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="CDBRRadio">
                        CDBR
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="drawingType"
                        id="PSRadio"
                        value="PS"
                        checked={drawingType === "PS"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="PSRadio">
                        PS
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="drawingType"
                        id="RSRadio"
                        value="RS"
                        checked={drawingType === "RS"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="RSRadio">
                        RS
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="drawingType"
                        id="FDRRadio"
                        value="FDR"
                        checked={drawingType === "FDR"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="PRadio">
                        FDR
                      </label>
                    </div>
                    <div className="form-check custom-checkbox form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="drawingType"
                        id="MISCRadio"
                        value="MISC"
                        checked={drawingType === "MISC"}
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="MISCadio">
                        MISC
                      </label>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                  <Button
                    variant="outline-primary"
                    className="btn btn-sm cursor-pointer fw-bolder me-4"
                    type="button"
                    onClick={handleDownload}
                  >
                    <i className="fa-solid fa-file-excel me-2"></i>Sample {drawingType} Excel File
                  </Button>
                </div>
              </div>
              <div className="card p-3 mx-5">
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  className="dropzone no-files "
                  style={{ height: "300px" }}
                  onClick={() => fileInputRef.current.click()}
                >
                  {selectedFileName ? (
                    <div className="card" style={{ width: "20rem" }}>
                      <div className="card-body d-flex justify-content-between">
                        <h5 className="card-title">{selectedFileName}</h5>
                        <Button
                          variant="btn btn-primary tp-btn btn-circle"
                          type="button"
                          onClick={handleCloseButton}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </Button>
                      </div>
                    </div>
                  ) : (
                    "Drag and drop files here, or click to select files Only Excel files allowed! (.xls, .xlsx)"
                  )}
                  <input
                    type="file"
                    ref={fileInputRef}
                    accept=".xlsx, .xls"
                    onChange={(e) => handleFileInputChange(e.target.files)}
                    capture="filesystem"
                    style={{ display: "none" }}
                  />
                </div>
                {errorMessages.length > 0 && (
                  <ul className="mx-4 mt-4">
                    {errorMessages.map((error, index) => (
                      <li
                        style={{ listStyleType: "disc" }}
                        className="text-danger mb-2"
                        key={index}
                        role="alert"
                      >
                        {error}
                      </li>
                    ))}
                  </ul>
                )}
                <div className="d-flex align-items-center justify-content-center mt-4 ">
                  <button
                    className="btn btn-primary btn-block w-50"
                    onClick={handleSubmit}
                    disabled={isLoading || !selectedFileName}
                  >
                    {isLoading ? (
                      <span>
                        <Spinner variant="light" size="sm" className="ms-2" />
                        Upload
                      </span>
                    ) : (
                      "Upload"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDrawingPage;
