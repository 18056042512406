export const dateFomatYMD = (dateObject) => {
  const isoString = dateObject.toISOString();
  return isoString.split("T")[0];

}


export function formatFileSize(bytes) {
  if (bytes === 0) return '0 Bytes';
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const fileSize = bytes / Math.pow(1024, i);
  // Limit to one decimal place and convert to string
  const formattedSize = fileSize.toFixed(1).replace(/\.0$/, '');
  return formattedSize + ' ' + sizes[i];
}


export function getFileNameAndExtension(filename) {
  const match = /^(.*?)\.([^.]*)$/.exec(filename);
  if (match) {
    const name = match[1];
    const extension = match[2];
    return { name, extension };
  } else {
    return { name: filename, extension: '' };
  }
}

export function formatShortNumber(number) {
  if (number >= 10000000) {
    const crore = (number / 10000000).toFixed(2);
    return crore.endsWith('.00') ? crore.slice(0, -3) + ' Cr' : crore + ' Cr';
  } else if (number >= 100000) {
    const lakh = (number / 100000).toFixed(2);
    return lakh.endsWith('.00') ? lakh.slice(0, -3) + ' L' : lakh + ' L';
  } else if (number >= 1000) {
    const thousand = (number / 1000).toFixed(2);
    return thousand.endsWith('.00') ? thousand.slice(0, -3) + ' K' : thousand + ' K';
  } else {
    return number.toString();
  }
}

export const getUserNameIcon = (fullName) => {
  const nameArray = fullName.split(" ");
  const firstNameInitial = nameArray[0].charAt(0);
  const lastNameInitial =
    nameArray.length > 1 ? nameArray[nameArray.length - 1].charAt(0) : "";
  const initials = firstNameInitial + lastNameInitial;
  return initials.toUpperCase(); // Convert to uppercase if needed
};



export const minusDaysToDate = (days = 0) => {
  const today = new Date(); // Get the current date
  let futureDate = new Date();
  if (days > 0) {
    futureDate.setDate(today.getDate() - days);
  }
  const year = futureDate.getFullYear();
  const month = String(futureDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(futureDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate
}