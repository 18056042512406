import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import DynamicSelectInput from "../../../constant/SelectInput";
import { toast } from "react-toastify";

const ManualsForm = ({ handleSubmit, isLoading }) => {
  const [manuals, setManuals] = useState({
    manual_number: "",
    department_id: "",
    unit_id: "",
    supplier: "",
    package_number: "",
    letter_number: "",
    registration_date: "",
    description: "",
    remarks: "",
    file: "",
  });
  const [manualsInputError, setManualsInputError] = useState({
    manual_number: false,
  });

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const file = file_list[0];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (file_ext !== "PDF") {
        toast.error("Please choose a valid PDF file.");
      } else {
        setManuals({ ...manuals, file: file });
      }
    }
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist);
  };

  const handleSelectChange = (name, value) => {
    setManuals({ ...manuals, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setManuals({ ...manuals, [name]: value });

    if (name === "manual_number") {
      setManualsInputError({
        ...manualsInputError,
        manual_number: value.trim() === "",
      });
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (manuals.manual_number.trim() === "") {
      setManualsInputError({ ...manualsInputError, manual_number: true });
    } else {
      handleSubmit(e, manuals);
    }
  };

  return (
    <Form onSubmit={handleSubmitForm}>
      <div className="row">
        <div className="col-xl-6 col-sm-12">
          <div className="mb-3">
            <label htmlFor="manual_number" className="form-label">
              Manual Number <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${
            manualsInputError.manual_number && "is-invalid"
          }`}
              id="manual_number"
              placeholder="Enter Manual Number"
              name="manual_number"
              value={manuals.manual_number}
              onChange={handleChange}
            />
            {manualsInputError.manual_number && (
          <div className="invalid-feedback mt-2">Manual No. is required.</div>
        )}
          </div>
          <div className="mb-3">
            <label htmlFor="department_id" className="form-label">
              Department
            </label>
            <DynamicSelectInput
              setParentInputValue={handleSelectChange}
              endpoint_name="search/department"
              name={"department_id"}
              id={"department_id"}
              placeholder={"Select Department"}
              isRequired={true}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="unit" className="form-label">
              Unit
            </label>
            <DynamicSelectInput
              setParentInputValue={handleSelectChange}
              endpoint_name="search/unit"
              name={"unit_id"}
              id={"unit_id"}
              placeholder={"Select Unit"}
              isRequired={true}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="supplier" className="form-label">
              Supplier
            </label>
            <input
              type="text"
              className="form-control"
              id="supplier"
              placeholder="Enter Supplier"
              name="supplier"
              value={manuals.supplier}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="remarks" className="form-label">
              Remarks
            </label>
            <textarea
              type="text"
              className="form-control"
              id="remarks"
              placeholder="Enter Remarks"
              name="remarks"
              value={manuals.remarks}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className="col-xl-6 col-sm-12 mx-auto">
          <div className="mb-3">
            <label htmlFor="package_number" className="form-label">
              Package Number 
            </label>
            <input
              type="text"
              className="form-control"
              id="package_number"
              placeholder="Enter Package Number"
              name="package_number"
              value={manuals.package_number}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="letter_number" className="form-label">
              Letter Number 
            </label>
            <input
              type="text"
              className="form-control"
              id="letter_number"
              placeholder="Enter Letter Number"
              name="letter_number"
              value={manuals.letter_number}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="registration_date" className="form-label">
              Registration Date 
            </label>
            <input
              type="date"
              className="form-control"
              id="registration_date"
              placeholder="Enter Registration Date"
              name="registration_date"
              value={manuals.registration_date}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <textarea
              type="text"
              className="form-control"
              id="description"
              placeholder="Enter Description"
              name="description"
              value={manuals.description}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="mb-3">
            <label htmlFor="pdfFile" className="form-label">
              Upload File
            </label>
            <input
              type="file"
              className="form-control"
              id="file"
              name="file"
              onChange={handleFileInputChange}
              accept=".pdf"
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-2">
      <button
          type="submit"
          disabled={isLoading}
          className="btn btn-primary me-1 w-50"
        >
         {isLoading ? (<span><Spinner size="sm" className="me-2" />Creating...</span>) : "Create"}
        </button>
      </div>
    </Form>
  );
};

export default ManualsForm;
