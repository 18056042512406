import React, { useState, useEffect } from "react";
import StandardLogo from "../../../images/Icons/standardIcon.png";
import DrawingLogo from "../../../images/Icons/drawingIcon.png";
import DocumentLogo from "../../../images/Icons/documentIcon.jpg";
import SILogo from "../../../images/Icons/SIIcon.png";
import { useNavigate } from "react-router-dom";
import { Placeholder } from "react-bootstrap";
import { connect } from "react-redux";
import { dashboard, LoginLogGraph, pieChartApi, barChartApi } from "../../../services/DashboardService";
import { formatShortNumber } from "../../../utils";
import SILogGraphPage from "./Graph/SILogGraph";
import DrawingLogGraphPage from "./Graph/DrawingLogGraph";
import StandardLogGraphPage from "./Graph/StandardLogGraph";
import DocumentLogGraphPage from "./Graph/DocumentLogGraph";
import PieChart from "./Graph/PieChart";
import BarChart from "./Graph/BarChart";

const DashboardLoading = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12 wid-100">
          <div className="row">
            <div className="col-xl-3 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body  p-4">
                  <div className="d-flex align-item-center justify-content-center">
                    <div className="w-50 text-center ">
                      <Placeholder animation="glow">
                        <Placeholder
                          style={{ height: "60px", width: "60px" }}
                          className="rounded-circle my-2"
                        />
                      </Placeholder>
                    </div>
                    <div className="w-100">
                      <Placeholder animation="glow">
                        <Placeholder
                          xs={12}
                          style={{ height: "20px" }}
                          className="rounded my-2"
                        />
                        <Placeholder
                          xs={12}
                          style={{ height: "20px" }}
                          className="rounded my-2"
                        />
                      </Placeholder>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body  p-4">
                  <div className="d-flex align-item-center justify-content-center">
                    <div className="w-50 text-center">
                      <Placeholder animation="glow">
                        <Placeholder
                          style={{ height: "60px", width: "60px" }}
                          className="rounded-circle my-2"
                        />
                      </Placeholder>
                    </div>
                    <div className="w-100">
                      <Placeholder animation="glow">
                        <Placeholder
                          xs={12}
                          style={{ height: "20px" }}
                          className="rounded my-2"
                        />
                        <Placeholder
                          xs={12}
                          style={{ height: "20px" }}
                          className="rounded my-2"
                        />
                      </Placeholder>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body  p-4">
                  <div className="d-flex align-item-center justify-content-center">
                    <div className="w-50 text-center">
                      <Placeholder animation="glow">
                        <Placeholder
                          style={{ height: "60px", width: "60px" }}
                          className="rounded-circle my-2"
                        />
                      </Placeholder>
                    </div>
                    <div className="w-100">
                      <Placeholder animation="glow">
                        <Placeholder
                          xs={12}
                          style={{ height: "20px" }}
                          className="rounded my-2"
                        />
                        <Placeholder
                          xs={12}
                          style={{ height: "20px" }}
                          className="rounded my-2"
                        />
                      </Placeholder>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-sm-6 same-card">
              <div className="widget-stat card">
                <div className="card-body  p-4">
                  <div className="d-flex align-item-center justify-content-center">
                    <div className="w-50 text-center">
                      <Placeholder animation="glow">
                        <Placeholder
                          style={{ height: "60px", width: "60px" }}
                          className="rounded-circle my-2"
                        />
                      </Placeholder>
                    </div>
                    <div className="w-100">
                      <Placeholder animation="glow">
                        <Placeholder
                          xs={12}
                          style={{ height: "20px" }}
                          className="rounded my-2"
                        />
                        <Placeholder
                          xs={12}
                          style={{ height: "20px" }}
                          className="rounded my-2"
                        />
                      </Placeholder>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="d-flex align-item-center justify-content-between">
            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>

            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>

            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>

            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>

            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Home = ({ userRole, todayLogin }) => {
  const [data, setData] = useState(null);
  const [loginLogdata, setLoginLogData] = useState(null);
  const [peiChartData, setPieChartData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const today = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const todayDate = today.toLocaleDateString(undefined, options);

  useEffect(() => {
    setIsLoading(true);
    dashboard()
      .then((response) => {
        if (response) {
          setData(response.data.results);
        }
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });

    pieChartApi().then((response) => {
      setPieChartData(response.data.results);
    }).catch((error) => {
      console.log(error.response.data.message);
    });
    
    barChartApi().then((response) => {
      setBarChartData(response.data.results);
    }).catch((error) => {
      console.log(error.response.data.message);
    });

    if (userRole === "User") {
      LoginLogGraph().then((response) => {
        setLoginLogData(response.data.results);
      })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    }
    setIsLoading(false);
  }, []);


  return isLoading ? (
    <DashboardLoading />
  ) : (
    <div className="container-fluid">
      {userRole === "SuperAdmin" && (
        <div className="row">
          {/* DRAWING COUNT */}
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-4">
            <div className="card d-flex flex-column">
              <h4 className="py-2 text-center text-primary fw-bold">
                DRAWINGS
              </h4>
              <div className="d-flex flex-column px-2">
                {/* pending count */}
                <div
                  className="card bg-warning c-pointer mb-2"
                  onClick={() => navigate("/pending-drawing")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-warning-light p-3 rounded-circle">
                        <i className="fa-solid fa-circle-exclamation"></i>
                      </span>
                      <div className="d-flex flex-column align-items-center text-white flex-fill">
                        <p className="mb-1 fs-5">PENDING</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            data?.drawing_count?.pending || 0
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* approved count */}
                <div
                  className="card bg-success c-pointer mb-2"
                  onClick={() => navigate("/drawing?status=Approved")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-success-light p-3 rounded-circle">
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                      <div className="d-flex flex-column align-items-center text-white flex-fill">
                        <p className="mb-1 fs-5">APPROVED</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            data?.drawing_count?.approved || 0
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card bg-danger mb-2 c-pointer"
                  onClick={() => navigate("/archive-drawing")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-danger-light p-3 rounded-circle">
                        <i className="fa-solid fa-box-archive"></i>
                      </span>
                      <div className="flex-fill d-flex flex-column align-items-center text-white">
                        <p className="mb-1 fs-5">ARCHIVED</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            data?.drawing_count?.archived || 0
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card bg-primary mb-2 c-pointer"
                  onClick={() => navigate("/drawing")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-info-light p-3 rounded-circle">
                        <i className="fa-solid fa-box-tissue"></i>
                      </span>
                      <div className="flex-fill d-flex flex-column align-items-center text-white">
                        <p className="mb-1 fs-5">TOTAL</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            (data?.drawing_count?.pending || 0) +
                            (data?.drawing_count?.approved || 0) +
                            (data?.drawing_count?.archived || 0)
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end total */}
              </div>
            </div>
          </div>
          {/* END DRAWING COUNT */}

          {/* STANDARD COUNT */}
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-4">
            <div className="card d-flex flex-column">
              <h4 className="py-2 text-center text-primary fw-bold">
                STANDARDS
              </h4>
              <div className="d-flex flex-column px-2">
                {/* pending count */}
                <div
                  className="card bg-warning c-pointer mb-2"
                  onClick={() => navigate("/pending-standard")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-warning-light p-3 rounded-circle">
                        <i className="fa-solid fa-circle-exclamation"></i>
                      </span>
                      <div className="d-flex flex-column align-items-center text-white flex-fill">
                        <p className="mb-1 fs-5">PENDING</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            data?.standard_count?.pending || 0
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* approved count */}
                <div
                  className="card bg-success c-pointer mb-2"
                  onClick={() => navigate("/standards")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-success-light p-3 rounded-circle">
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                      <div className="d-flex flex-column align-items-center text-white flex-fill">
                        <p className="mb-1 fs-5">APPROVED</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            data?.standard_count?.approved || 0
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* archive count */}
                <div
                  className="card bg-danger mb-2 c-pointer"
                  onClick={() => navigate("/archive-standard")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-danger-light p-3 rounded-circle">
                        <i className="fa-solid fa-box-archive"></i>
                      </span>
                      <div className="flex-fill d-flex flex-column align-items-center text-white">
                        <p className="mb-1 fs-5">ARCHIVED</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            data?.standard_count?.archived || 0
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card bg-primary mb-2 c-pointer"
                  onClick={() => navigate("/standards")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-info-light p-3 rounded-circle">
                        <i className="fa-solid fa-box-tissue"></i>
                      </span>
                      <div className="flex-fill d-flex flex-column align-items-center text-white">
                        <p className="mb-1 fs-5">TOTAL</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            (data?.standard_count?.pending || 0) +
                            (data?.standard_count?.approved || 0) +
                            (data?.standard_count?.archived || 0)
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end total */}
              </div>
            </div>
          </div>
          {/* END STANDARD COUNT */}

          {/* MANUAL COUNT */}
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-4">
            <div className="card d-flex flex-column">
              <h4 className="py-2 text-center text-primary fw-bold">
                DOCUMENTS
              </h4>
              <div className="d-flex flex-column px-2">
                {/* pending count */}
                <div
                  className="card bg-warning c-pointer mb-2"
                  onClick={() => navigate("/pending-documents")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-warning-light p-3 rounded-circle">
                        <i className="fa-solid fa-circle-exclamation"></i>
                      </span>
                      <div className="d-flex flex-column align-items-center text-white flex-fill">
                        <p className="mb-1 fs-5">PENDING</p>
                        <h3 className="text-white">
                          {formatShortNumber(data?.manual_count?.pending || 0)}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* approved count */}
                <div
                  className="card bg-success c-pointer mb-2"
                  onClick={() => navigate("/documents")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-success-light p-3 rounded-circle">
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                      <div className="d-flex flex-column align-items-center text-white flex-fill">
                        <p className="mb-1 fs-5">APPROVED</p>
                        <h3 className="text-white">
                          {formatShortNumber(data?.manual_count?.approved || 0)}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card bg-danger mb-2 c-pointer"
                  onClick={() => navigate("/archive-documents")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-danger-light p-3 rounded-circle">
                        <i className="fa-solid fa-box-archive"></i>
                      </span>
                      <div className="flex-fill d-flex flex-column align-items-center text-white">
                        <p className="mb-1 fs-5">ARCHIVED</p>
                        <h3 className="text-white">
                          {formatShortNumber(data?.manual_count?.archived || 0)}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card bg-primary mb-2 c-pointer"
                  onClick={() => navigate("/documents")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-info-light p-3 rounded-circle">
                        <i className="fa-solid fa-box-tissue"></i>
                      </span>
                      <div className="flex-fill d-flex flex-column align-items-center text-white">
                        <p className="mb-1 fs-5">TOTAL</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            (data?.manual_count?.pending || 0) +
                            (data?.manual_count?.approved || 0) +
                            (data?.manual_count?.archived || 0)
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end total */}
              </div>
            </div>
          </div>
          {/* END MANUAL CUNT */}

          {/* SIR COUNT */}
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-4">
            <div className="card d-flex flex-column">
              <h4 className="py-2 text-center text-primary fw-bold">SIR</h4>
              <div className="d-flex flex-column px-2">
                {/* pending count */}
                <div
                  className="card bg-warning c-pointer mb-2"
                  onClick={() => navigate("/pending-sir")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-warning-light p-3 rounded-circle">
                        <i className="fa-solid fa-circle-exclamation"></i>
                      </span>
                      <div className="d-flex flex-column align-items-center text-white flex-fill">
                        <p className="mb-1 fs-5">PENDING</p>
                        <h3 className="text-white">
                          {formatShortNumber(data?.sir_count?.pending || 0)}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* approved count */}
                <div
                  className="card bg-success c-pointer mb-2"
                  onClick={() => navigate("/si")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-success-light p-3 rounded-circle">
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                      <div className="d-flex flex-column align-items-center text-white flex-fill">
                        <p className="mb-1 fs-5">APPROVED</p>
                        <h3 className="text-white">
                          {formatShortNumber(data?.sir_count?.approved || 0)}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card bg-danger mb-2 c-pointer"
                  onClick={() => navigate("/archive-sir")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-danger-light p-3 rounded-circle">
                        <i className="fa-solid fa-box-archive"></i>
                      </span>
                      <div className="flex-fill d-flex flex-column align-items-center text-white">
                        <p className="mb-1 fs-5">ARCHIVED</p>
                        <h3 className="text-white">
                          {formatShortNumber(data?.sir_count?.archived || 0)}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card bg-primary mb-2 c-pointer"
                  onClick={() => navigate("/si")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-info-light p-3 rounded-circle">
                        <i className="fa-solid fa-box-tissue"></i>
                      </span>
                      <div className="flex-fill d-flex flex-column align-items-center text-white">
                        <p className="mb-1 fs-5">TOTAL</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            (data?.sir_count?.pending || 0) +
                            (data?.sir_count?.approved || 0) +
                            (data?.sir_count?.archived || 0)
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end total */}
              </div>
            </div>
          </div>
          {/* END SIR COUNT */}

          {/* STABILIYY CERTIFICATE COUNT */}
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-4">
            <div className="card d-flex flex-column">
              <h4 className="py-2 text-center text-primary fw-bold">
                STABILITY CERTIFICATE
              </h4>
              <div className="d-flex flex-column px-2">
                {/* pending count */}
                <div
                  className="card bg-warning c-pointer mb-2"
                  onClick={() => navigate("/pending-stability-certificate")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-warning-light p-3 rounded-circle">
                        <i className="fa-solid fa-circle-exclamation"></i>
                      </span>
                      <div className="d-flex flex-column align-items-center text-white flex-fill">
                        <p className="mb-1 fs-5">PENDING</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            data?.stability_certificate_count?.pending || 0
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* approved count */}
                <div
                  className="card bg-success c-pointer mb-2"
                  onClick={() => navigate("/si?type=stability_certificate")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-success-light p-3 rounded-circle">
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                      <div className="d-flex flex-column align-items-center text-white flex-fill">
                        <p className="mb-1 fs-5">APPROVED</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            data?.stability_certificate_count?.approved || 0
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card bg-danger mb-2 c-pointer"
                  onClick={() => navigate("/archive-stability-certificate")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-danger-light p-3 rounded-circle">
                        <i className="fa-solid fa-box-archive"></i>
                      </span>
                      <div className="flex-fill d-flex flex-column align-items-center text-white">
                        <p className="mb-1 fs-5">ARCHIVED</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            data?.stability_certificate_count?.archived || 0
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card bg-primary mb-2 c-pointer"
                  onClick={() => navigate("/si?type=stability_certificate")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-info-light p-3 rounded-circle">
                        <i className="fa-solid fa-box-tissue"></i>
                      </span>
                      <div className="flex-fill d-flex flex-column align-items-center text-white">
                        <p className="mb-1 fs-5">TOTAL</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            (data?.stability_certificate_count?.pending || 0) +
                            (data?.stability_certificate_count?.approved ||
                              0) +
                            (data?.stability_certificate_count?.archived || 0)
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end total */}
              </div>
            </div>
          </div>
          {/* END STABILITY COUNT */}

          {/* COMPLIANCE COUNT */}
          <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 mb-4">
            <div className="card d-flex flex-column">
              <h4 className="py-2 text-center text-primary fw-bold">
                COMPLIANCE
              </h4>
              <div className="d-flex flex-column px-2">
                {/* pending count */}
                <div
                  className="card bg-warning c-pointer mb-2"
                  onClick={() => navigate("/pending-compliance")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-warning-light p-3 rounded-circle">
                        <i className="fa-solid fa-circle-exclamation"></i>
                      </span>
                      <div className="d-flex flex-column align-items-center text-white flex-fill">
                        <p className="mb-1 fs-5">PENDING</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            data?.compliance_count?.pending || 0
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* approved count */}
                <div
                  className="card bg-success c-pointer mb-2"
                  onClick={() => navigate("/si?type=compliance")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-success-light p-3 rounded-circle">
                        <i className="fa-solid fa-circle-check"></i>
                      </span>
                      <div className="d-flex flex-column align-items-center text-white flex-fill">
                        <p className="mb-1 fs-5">APPROVED</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            data?.compliance_count?.approved || 0
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card bg-danger mb-2 c-pointer"
                  onClick={() => navigate("/archive-compliance")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-danger-light p-3 rounded-circle">
                        <i className="fa-solid fa-box-archive"></i>
                      </span>
                      <div className="flex-fill d-flex flex-column align-items-center text-white">
                        <p className="mb-1 fs-5">ARCHIVED</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            data?.compliance_count?.archived || 0
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card bg-primary mb-2 c-pointer"
                  onClick={() => navigate("/si?type=compliance")}
                >
                  <div className="card-body py-2 px-3">
                    <div className="d-flex align-items-center">
                      <span className="me-3 text-white fs-3 d-flex bg-info-light p-3 rounded-circle">
                        <i className="fa-solid fa-box-tissue"></i>
                      </span>
                      <div className="flex-fill d-flex flex-column align-items-center text-white">
                        <p className="mb-1 fs-5">TOTAL</p>
                        <h3 className="text-white">
                          {formatShortNumber(
                            (data?.compliance_count?.pending || 0) +
                            (data?.compliance_count?.approved || 0) +
                            (data?.compliance_count?.archived || 0)
                          )}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end total */}
              </div>
            </div>
          </div>
          {/* END COMPLIANCE COUNT */}

          <div className={"col-md-9"}>
            <div className="card">
              <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                  <h4 className="heading mb-0 fw-bold">
                    Recent Drawing Activity
                  </h4>
                  <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                    <div className="me-3 mb-3 mb-md-0"></div>
                  </div>
                </div>

                <div
                  id="employee-tbl_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="empoloyees-tblwrapper"
                    className="table ItemsCheckboxSec dataTable no-footer mb-0"
                  >
                    <thead>
                      <tr>
                        <th>Drawing</th>
                        <th>Log</th>
                        <th>Message</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.drawing_log.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item.drawing ? (
                              <span
                                className="text-primary cursor-pointer fw-bolder"
                                onClick={() =>
                                  navigate(`/drawing/${item.drawing.id}`)
                                }
                              >
                                {item.drawing.drawing_type}-
                                {item.drawing.drawing_number}
                              </span>
                            ) : (
                              "None"
                            )}
                          </td>
                          <td>{item.status}</td>
                          <td>{item.message}</td>
                          <td>{item.action_time}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* col end */}
          <div className="col-md-3">
            <div className="card">
              <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                  <h4 className="heading mb-0 fw-bold">
                    Top Users by Login Activity
                  </h4>
                  <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                    <div className="me-3 mb-3 mb-md-0"></div>
                  </div>
                </div>

                <div
                  id="employee-tbl_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <table
                    id="empoloyees-tblwrapper"
                    className="table ItemsCheckboxSec dataTable no-footer mb-0"
                  >
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.most_user_login.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <span
                              className="text-primary cursor-pointer fw-bolder"
                              onClick={() => navigate(`/user/${item.id}`)}
                            >
                              {item.full_name}
                            </span>
                          </td>
                          <td>{item.log_count}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* drawing count */}

      <div className="row">
        <div className="col-xl-12 wid-100">
          <div className="row">
            {userRole === "User" && (
              <>
                <div className="col-xl-6 col-sm-6">
                  <div className="card bg-primary">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <span className="me-5 text-white fs-1 d-flex bg-info-light p-3 rounded-circle">
                            <i className="fa-solid fa-eye"></i>
                          </span>
                          <h3 className="text-white text-center fs-1">
                            {formatShortNumber((data?.log_count?.drawing || 0) + (data?.log_count?.document || 0) + (data?.log_count?.standard || 0) + (data?.log_count?.si || 0))}
                          </h3>
                        </div>
                        <div className="text-end">
                          <p className="mb-0 text-white fw-bold">{todayDate}</p>
                        </div>
                      </div>
                      <div className="text-end mt-2">
                        <p className="mb-0 text-white fw-bold">
                          Log Count
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-sm-6">
                  <div className="card bg-success">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <span className="me-5 text-white fs-1 d-flex bg-success-light p-3 rounded-circle">
                            <i className="fa-regular fa-user"></i>
                          </span>
                          <h3 className="text-white text-center fs-1">
                            {todayLogin}
                          </h3>
                        </div>
                        <div className="text-end">
                          <p className="mb-0 text-white fw-bold">{todayDate}</p>
                        </div>
                      </div>
                      <div className="text-end mt-2">
                        <p className="mb-0 text-white fw-bold">
                          User Login
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {userRole !== "User" ? (
              <>
                <div className="col-xl-3 col-sm-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <span className="me-3 text-white fs-4 d-flex bg-info-light p-3 rounded">
                          <img src={DrawingLogo} alt="" width={"35px"} />
                        </span>
                        <div className="d-flex flex-column align-items-center">
                          <h4 className="mb-1 text-primary fw-bold">
                            Today Drawings Log
                          </h4>
                          <h3 className="text-primary">
                            {formatShortNumber(data?.log_count?.drawing || 0)}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-sm-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <span className="me-3 text-white fs-4 d-flex bg-info-light p-3 rounded">
                          <img src={StandardLogo} alt="" width={"35px"} />
                        </span>
                        <div className="d-flex flex-column align-items-center">
                          <h4 className="mb-1 text-primary fw-bold">
                            Today Standards Log
                          </h4>
                          <h3 className="text-primary">{formatShortNumber(data?.log_count?.standard || 0)}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-sm-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <span className="me-3 text-white fs-4 d-flex bg-info-light p-3 rounded">
                          <img src={DocumentLogo} alt="" width={"35px"} />
                        </span>
                        <div className="d-flex flex-column align-items-center">
                          <h4 className="mb-1 text-primary fw-bold">
                            Today Document Log
                          </h4>
                          <h3 className="text-primary">{formatShortNumber(data?.log_count?.document || 0)}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-sm-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <span className="me-3 text-white fs-4 d-flex bg-info-light p-3 rounded">
                          <img src={SILogo} alt="" width={"35px"} />
                        </span>
                        <div className="d-flex flex-column align-items-center">
                          <h4 className="mb-1 text-primary fw-bold">
                            Today SI Log
                          </h4>
                          <h3 className="text-primary">{formatShortNumber(data?.log_count?.si || 0)}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6">
          <PieChart level={peiChartData?.drawing?.lavel || []}
            series={peiChartData?.drawing?.series || []}
            title={"Drawing Count"}
            isLoading={isLoading}
          />
        </div>
        <div className="col-lg-3 col-md-6">
          <PieChart level={peiChartData?.standard?.lavel || []}
            series={peiChartData?.standard?.series || []}
            title={"Standard Count"}
            isLoading={isLoading}
          />
        </div>
        <div className="col-lg-3 col-md-6">
          <PieChart level={peiChartData?.document?.lavel || []}
            series={peiChartData?.document?.series || []}
            title={"Document Count"}
            isLoading={isLoading}
          />
        </div>
        <div className="col-lg-3 col-md-6">
          <PieChart
            level={peiChartData?.si?.lavel || []}
            series={peiChartData?.si?.series || []}
            title={"SI Count"}
            isLoading={isLoading}
          />
        </div>
        <div className="col-lg-12">
          <BarChart
            label={barChartData?.labels || []}
            series={barChartData?.series || []} 
            isLoading={isLoading} />
        </div>
      </div>
      {/* drawing log count */}
      {userRole === "User" && (
        <div>
          <div className="row">
            <div className="col-md-6">
              <DrawingLogGraphPage loginLogdata={loginLogdata} />
            </div>
            <div className="col-md-6">
              <StandardLogGraphPage loginLogdata={loginLogdata} />
            </div>
            <div className="col-md-6">
              <DocumentLogGraphPage loginLogdata={loginLogdata} />
            </div>
            <div className="col-md-6">
              <SILogGraphPage loginLogdata={loginLogdata} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div
                className="card cursor-pointer py-4 px-3"
                style={{
                  background: "linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                onClick={() => navigate("/drawing")}
              >
                <span className="fs-3">
                  <img src={DrawingLogo} alt="" width={"35px"} className="me-5" />
                  <strong>Drawings</strong>
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="card cursor-pointer py-4 px-3"
                style={{
                  background: "linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                onClick={() => navigate("/standards")}
              >
                <span className="fs-3">
                  <img
                    src={StandardLogo}
                    alt=""
                    width={"35px"}
                    className="me-5"
                  />
                  <strong>Standards</strong>
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="card cursor-pointer py-4 px-3"
                style={{
                  background:
                    "radial-gradient( circle farthest-corner at 10% 20%,  rgba(147,230,241,1) 0%, rgba(145,192,241,1) 45.5% )",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                onClick={() => navigate("/documents")}

              >
                <span className="fs-3">
                  <img
                    src={DocumentLogo}
                    alt=""
                    width={"35px"}
                    className="me-5"
                  />
                  <strong>Documents</strong>
                </span>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="card cursor-pointer py-4 px-3"
                style={{
                  background: "linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                onClick={() => navigate("/si")}
              >
                <span className="fs-3">
                  <img src={SILogo} alt="" width={"35px"} className="me-5" />
                  <strong>SI</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user?.role || "User",
    todayLogin: state.auth.visitorCount?.today_visitor || 0
  };
};

export default connect(mapStateToProps)(Home);
