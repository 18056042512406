import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import DynamicSelectInput from "../../constant/SelectInput";
import { formatFileSize } from "../../../utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Pagination from "../../constant/Pagination";
import {
  getSIRList,
  createSIR,
  updateSIR,
  updateSIRStatus,
} from "../../../services/SIService";
import TableLoaderEffect from "../../constant/TableLoader";
const maxWidthStyle = {
  maxWidth: "40rem",
  minWidth: "20rem",
  whiteSpace: "normal",
  display: "block"
}

function SIRPage({ userRole }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [selectedSIR, setSelectedSIR] = useState(null);
  const [confirmMSG, setConfirmMSG] = useState("");
  const [sirInput, setSirInput] = useState({
    sir_number: "",
    department_id: "",
    unit_id: "",
    year_of_inspection: "",
    compliance: "YES",
    description: "",
    agency: "",
    file: "",
  });

  const [sirInputError, setSirInputError] = useState({
    sir_number: false,
    department_id: false,
    year_of_inspection: false,
    file: false,
  });
  const [isModalOpen, setIsModalOpen] = useState({ open: false, type: "Add" });
  const [total, setTotal] = useState(0);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const recordsPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    if (!searchQuery.length > 0) {
      setIsLoading(true);
    }
    getSIRList(currentPage, recordsPage, searchQuery)
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
        setIsSearchLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
        setIsSearchLoading(false);
      });
  }, [currentPage, searchQuery]);

  const toggleModal = (type = "Add", item = null) => {
    let open = isModalOpen.open;

    if (type === "Add") {
      open = !open;
    } else if (type === "Edit" && item) {
      setSelectedSIR(item);
      setSirInput({
        id: item.id,
        sir_number: item.sir_number,
        department_id: item.department ? item.department.department_id : "",
        unit_id: item.unit ? item.unit.unit_id : "",
        compliance: item.compliance || "",
        year_of_inspection: item.year_of_inspection || "",
        description: item.description || "",
        agency: item.agency || "",
        file: "",
        file_data: item.attachment,
      });
      open = true;
    }
    if (isModalOpen.open && item === null) {
      setSirInput({
        sir_number: "",
        department_id: "",
        unit_id: "",
        year_of_inspection: "",
        compliance: "YES",
        description: "",
        agency: "",
        file: "",
      });

      setSirInputError({
        sir_number: false,
        department_id: false,
        year_of_inspection: false,
        file: false,
      });
      setSelectedSIR(null);
    }
    setIsModalOpen({ type, open });
  };

  const toggleArchiveModal = () => {
    if (showArchiveModal) {
      setSelectedSIR(null);
    }
    setShowArchiveModal(!showArchiveModal);
  };
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setIsSearchLoading(true);
    setCurrentPage(1);
    setSearchQuery(value);
  };

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const file = file_list[0];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (file_ext !== "PDF") {
        toast.error("Please choose a valid PDF file.");
      } else {
        setSirInput({ ...sirInput, file: file });
        setSirInputError({ ...sirInputError, file: false })

      }
    }
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedSirInput = { ...sirInput, [name]: value };
    const updatedSirInputError = {
      ...sirInputError,
      [name]: value.trim() === "",
    };
    setSirInput(updatedSirInput);
    setSirInputError(updatedSirInputError);
  };

  const handleSelectChange = (name, value, _) => {
    if (name === "department_id") {
      const updatedSirInputError = { ...sirInputError, [name]: !value };
      setSirInputError(updatedSirInputError);
    }
    setSirInput({ ...sirInput, [name]: value });
  };

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const sirInputErrorCopy = { ...sirInputError };
    let hasError = false;
    Object.keys(sirInput).forEach((key) => {
      if (sirInput[key] === "" && !["description", "unit_id"].includes(key)) {
        if (key === "file" && isModalOpen.type === "Edit") {
          return
        }
        sirInputErrorCopy[key] = true;
        hasError = true;
      }
    });
    if (hasError) {
      setSirInputError(sirInputErrorCopy);
      return;
    }

    if (isModalOpen.type === "Add") {
      try {
        setIsSubmitLoading(true);
        const reqBody = new FormData();
        Object.entries(sirInput).forEach(([fieldName, value]) => {
          reqBody.append(fieldName, value);
        });
        const resp = await createSIR(reqBody);
        const { message, results } = resp.data;
        toast.success(message);
        setData([results, ...data]);
        toggleModal();
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setIsSubmitLoading(false);
      }
    } else {
      try {
        setIsSubmitLoading(true);
        const reqBody = new FormData();
        Object.entries(sirInput).forEach(([fieldName, value]) => {
          if (fieldName !== "file_data") {
            reqBody.append(fieldName, value);
          }
        });
        const resp = await updateSIR(reqBody);
        const { results, message } = resp.data;
        setData(data.map((item) => item.id === results.id ? { ...results } : item));
        toast.success(message);
        toggleModal();
      } catch (error) {
        toast.error(error.response.data.message);
      } finally {
        setIsSubmitLoading(false);
      }
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitLoading(true);
    try {
      const resp = await updateSIRStatus({
        id: selectedSIR.id,
        archive: !selectedSIR.is_archive,
        archive_reason: confirmMSG,
      });
      toast.success(resp.data.message);
      setConfirmMSG("");
      if (!data.is_archive) {
        navigate("/archive-sir");
      } else {
        setData({ ...data, is_archive: !data.is_archive });
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
    toggleModal(false);
  };



  const viewPDFFile = async (id) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/api/sir_file_download/${id}`
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error fetching or opening file:', error);
    }
  };

  return (
    <>
      <div>
        <div className="card-body p-0">
          <div className="d-flex justify-content-between text-wrap flex-column  flex-sm-row align-items-start align-items-sm-center p-3">
            <h4 className="heading mb-3 mb-md-0">
              STRUCTURAL INSPECTION REPORT
            </h4>
            <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
              <div className="me-3 mb-md-0">
                <div className="input-group tp-input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    value={searchQuery}
                    aria-describedby="basic-search"
                    onChange={handleSearchChange}
                  />
                  <div className="input-group-text" id="basic-search">
                    {isSearchLoading && <Spinner variant="primary" size="sm" />}
                    {!isSearchLoading && searchQuery.length > 0 && (
                      <span
                        className="c-pointer"
                        onClick={() => {
                          setCurrentPage(1);
                          setSearchQuery("");
                        }}
                      >
                        <i className="fa-solid fa-xmark text-primary"></i>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {userRole !== "User" && (
                <button
                  className="btn btn-primary mt-3 mt-md-0"
                  onClick={() => toggleModal()}
                >
                  Add
                </button>
              )}
            </div>
          </div>
          <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
            <div
              id="employee-tbl_wrapper"
              className="dataTables_wrapper no-footer"
            >
              <table
                id="empoloyees-tblwrapper"
                className="table ItemsCheckboxSec dataTable no-footer mb-0 table-striped"
              >
                <thead>
                  <tr>
                    <th>SIR Number</th>
                    <th>Department</th>
                    <th>Unit</th>
                    <th>FY</th>
                    <th>Compliance</th>
                    <th>Description</th>
                    <th>Agency</th>
                    {userRole !== "User" && <th>Status</th>}
                    <th>Attachment</th>
                    {userRole !== "User" && <th>Action</th>}
                  </tr>
                </thead>

                <tbody>
                  {isLoading ? (
                    <TableLoaderEffect colLength={userRole === "User" ? 7 : 9} />
                  ) : (
                    data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.sir_number}</td>
                        <td>{item.department?.name || ""}</td>
                        <td>{item.unit?.name || ""}</td>
                        <td>{item.year_of_inspection}</td>
                        <td>{item.compliance}</td>
                        <td><span style={maxWidthStyle}>{item.description}</span></td>
                        <td>{item.agency}</td>

                        {userRole !== "User" && <td>
                          {item.is_approved === true ? (
                            <span
                              className={`badge light border-0 badge-success badge-sm`}
                            >
                              Approved
                            </span>
                          ) : (
                            <span
                              className={`badge light border-0 badge-info badge-sm`}
                            >
                              Pending
                            </span>
                          )}
                        </td>}
                        <td>
                          {item.attachment ? (
                            <Button
                              variant="btn btn-primary tp-btn btn-sm"
                              onClick={() => viewPDFFile(item.id)}
                            >
                              <span className="d-flex align-items-center">
                                <i className="fa-regular fa-eye me-2"></i>
                                <span>View </span>
                              </span>
                            </Button>
                          ) : null}
                        </td>
                        <td>
                          {userRole !== "User" && (
                            <>
                              <Button
                                onClick={() => toggleModal("Edit", item)}
                                variant="btn btn-primary tp-btn btn-sm"
                              >
                                <i className="fa-regular fa-pen-to-square"></i>
                              </Button>
                              <Button
                                variant="btn btn-primary tp-btn btn-sm"
                                onClick={() => navigate(`/si/${item.id}`)}
                              >
                                <i className="fa-solid fa-clock-rotate-left me-1"></i> Log
                              </Button>
                              <Button
                                onClick={() => {
                                  setSelectedSIR(item);
                                  toggleArchiveModal();
                                }}
                                variant="btn btn-danger tp-btn btn-sm"
                              >
                                {item?.is_archive ? <i className="fa-solid fa-trash-arrow-up"></i> : <i className="fa-solid fa-box-archive"></i>}
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              currentPage={currentPage}
              recordsPage={recordsPage}
              dataLength={total}
              handlePagination={handlePagination}
            />
          </div>
        </div>
      </div>
      {/* card end */}

      <Modal
        className="modal fade"
        id="exampleModal1"
        show={isModalOpen.open && isModalOpen.type === "Add"}
        onHide={() => toggleModal()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Add STRUCTURAL INSPECTION REPORT
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal()}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xl-12">
                  <label className="form-label">
                    SIR Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    autoFocus
                    id="sir_number"
                    name="sir_number"
                    className={`form-control mb-3 ${sirInputError.sir_number ? "is-invalid" : ""
                      }`}
                    placeholder="Enter SIR Number"
                    value={sirInput.sir_number}
                    onChange={handleChange}
                  />
                  {sirInputError.sir_number && (
                    <div className="text-danger mb-3">
                      SIR No. is required.
                    </div>
                  )}
                </div>

                <div className="col-xl-12">
                  <label htmlFor="department_id" className="form-label">
                    Department <span className="text-danger">*</span>
                  </label>
                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/department"
                    name={"department_id"}
                    id={"department_id"}
                    is_invalid={sirInputError.department_id}
                    placeholder={"Select Department"}
                    isRequired={true}
                  />
                  {sirInputError.department_id && (
                    <div className="text-danger mb-3">Department is required.</div>
                  )}
                </div>

                <div className="col-xl-12">
                  <label htmlFor="unit" className="form-label">
                    Unit
                  </label>
                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/unit"
                    name={"unit_id"}
                    id={"unit_id"}
                    placeholder={"Select Unit"}
                    isRequired={true}
                  />
                </div>

                <div className="col-xl-12">
                  <label className="form-label">
                  Financial Year <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="year_of_inspection"
                    name="year_of_inspection"
                    className={`form-control mb-3 ${sirInputError.year_of_inspection ? "is-invalid" : ""
                      }`}
                    placeholder="Enter year of inspection"
                    value={sirInput.year_of_inspection}
                    onChange={handleChange}
                  />
                  {sirInputError.year_of_inspection && (
                    <div className="text-danger mb-3">
                      Financial Year is required.
                    </div>
                  )}
                </div>

                <div className="col-xl-12 mb-3">
                  <label>Compliance <span className="text-danger">*</span></label><br />
                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="compliance"
                      id="cYes"
                      value="YES"
                      checked={sirInput.compliance === "YES"}
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="cYes"
                    >
                      YES
                    </label>
                  </div>
                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="compliance"
                      id="cNo"
                      value="NO"
                      checked={sirInput.compliance === "NO"}
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="cNO"
                    >
                      NO
                    </label>
                  </div>
                </div>

                <div className="col-xl-12 mb-3">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Enter Description"
                    name="description"
                    value={sirInput.description}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="col-xl-12">
                  <label className="form-label">Agency</label>
                  <input
                    type="text"
                    id="agency"
                    name="agency"
                    className={`form-control mb-3`}
                    placeholder="Enter agency"
                    value={sirInput.agency}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-xl-12">
                  <label htmlFor="pdfFile" className="form-label">
                    Attachment <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    className={`form-control ${sirInputError.file && "is-invalid"
                      }`}
                    id="file"
                    name="file"
                    onChange={handleFileInputChange}
                    accept=".pdf"
                  />
                  {sirInputError.file && (
                    <div className="text-danger mb-3">
                      File is required.
                    </div>
                  )}
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                disabled={isSubmitLoading}
              >
                {isSubmitLoading ? (
                  <span>
                    <Spinner size="sm" className="me-2" />
                    Creating...
                  </span>
                ) : (
                  "Create"
                )}
              </button>
            </Form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal fade"
        id="exampleModal1"
        show={isModalOpen.open && isModalOpen.type === "Edit"}
        onHide={() => toggleModal()}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Edit STRUCTURAL INSPECTION REPORT
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal()}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={onSubmit}>
              <div className="row">
                <div className="col-xl-12">
                  <label className="form-label">
                    SIR Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    autoFocus
                    id="sir_number"
                    name="sir_number"
                    className={`form-control mb-3 ${sirInputError.sir_number ? "is-invalid" : ""}`}
                    placeholder="Enter SIR Number"
                    value={sirInput.sir_number}
                    onChange={handleChange}
                  />
                  {sirInputError.sir_number && (
                    <div className="text-danger mb-3">
                      SIR No. is required.
                    </div>
                  )}
                </div>

                <div className="col-xl-12">
                  <label htmlFor="department_id" className="form-label">
                    Department <span className="text-danger">*</span>
                  </label>
                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/department"
                    name={"department_id"}
                    id={"department_id"}
                    placeholder={"Select Department"}
                    is_invalid={sirInputError.department_id}
                    defaultValue={
                      selectedSIR?.department
                        ? {
                          value: selectedSIR.department.department_id,
                          label: selectedSIR.department.name,
                        }
                        : null
                    }
                  />
                  {sirInputError.department_id && (
                    <div className="text-danger mb-3">Department is required.</div>
                  )}
                </div>
                <div className="col-xl-12">
                  <label htmlFor="unit" className="form-label">
                    Unit
                  </label>
                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/unit"
                    name={"unit_id"}
                    id={"unit_id"}
                    placeholder={"Select Unit"}
                    isRequired={true}
                    defaultValue={
                      selectedSIR?.unit
                        ? {
                          value: selectedSIR.unit.unit_id,
                          label: selectedSIR.unit.name,
                        }
                        : null
                    }
                  />
                </div>

                <div className="col-xl-12">
                  <label className="form-label">
                  Financial Year <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    autoFocus
                    id="year_of_inspection"
                    name="year_of_inspection"
                    className={`form-control mb-3 ${sirInputError.year_of_inspection ? "is-invalid" : ""
                      }`}
                    placeholder="Enter year of inspection"
                    value={sirInput.year_of_inspection}
                    onChange={handleChange}
                  />
                  {sirInputError.year_of_inspection && (
                    <div className="text-danger mb-3">
                      Financial Year is required.
                    </div>
                  )}
                </div>

                <div className="col-xl-12 mb-3">
                  <label>Compliance <span className="text-danger">*</span></label><br />
                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="compliance"
                      id="cYes"
                      value="YES"
                      checked={sirInput.compliance === "YES"}
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="cYes"
                    >
                      YES
                    </label>
                  </div>
                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="compliance"
                      id="cNo"
                      value="NO"
                      checked={sirInput.compliance === "NO"}
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="cNO"
                    >
                      NO
                    </label>
                  </div>
                </div>

                <div className="col-xl-12 mb-3">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Enter Description"
                    name="description"
                    value={sirInput.description}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="col-xl-12">
                  <label className="form-label">Agency</label>
                  <input
                    type="text"
                    id="agency"
                    name="agency"
                    className={`form-control mb-3`}
                    placeholder="Enter agency"
                    value={sirInput.agency}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-xl-12">
                  <label for="file" className="form-label">
                    Attachment
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="file"
                    name="file"
                    onChange={handleFileInputChange}
                    accept=".pdf"
                  />
                  {sirInput.file_data ? (
                    <div className="text-warning mt-1 fst-italic">
                      Existing File: {sirInput.file_data.name}
                      <span className="ms-2">
                        ({formatFileSize(sirInput.file_data.size)})
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                disabled={isSubmitLoading}
              >
                {isSubmitLoading ? (
                  <span>
                    <Spinner size="sm" className="me-2" />
                    Updating...
                  </span>
                ) : (
                  "Update"
                )}
              </button>
            </Form>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal modal-sm fade"
        id="archiveModal"
        show={showArchiveModal}
        centered={true}
        onHide={toggleArchiveModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              {selectedSIR?.is_archive ? "Unarchive" : "Archive"} STRUCTURAL
              INSPECTION REPORT
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleArchiveModal}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleOnSubmit}>
              <div className="row">
                <div className="mb-3">
                  <label htmlFor="confirmMSG" className="form-label">
                    {selectedSIR?.is_archive
                      ? "Type 'confirm' to unarchive"
                      : "Reason For Archive"}
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="confirmMSG"
                    name="confirmMSG"
                    placeholder={
                      selectedSIR?.is_archive
                        ? "Type 'confirm' to unarchive"
                        : "Reason For Archive"
                    }
                    value={confirmMSG}
                    onChange={(e) => setConfirmMSG(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                disabled={
                  isSubmitLoading ||
                  confirmMSG.trim() === "" ||
                  (selectedSIR?.is_archive
                    ? confirmMSG.toLocaleLowerCase() !== "confirm"
                    : false)
                }
              >
                {isSubmitLoading ? (
                  <span>
                    <Spinner variant="light" size="sm" className="me-2" />
                    {selectedSIR?.is_archive ? "Unarchive" : "Archive"}
                  </span>
                ) : selectedSIR?.is_archive ? (
                  "Unarchive"
                ) : (
                  "Archive"
                )}
              </button>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user?.role || "User",
  };
};
export default connect(mapStateToProps)(SIRPage);
