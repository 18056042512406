import { applyMiddleware, combineReducers, compose,createStore,} from 'redux';
import DrawingReducer from './reducers/DrawingReducer';
import thunk from 'redux-thunk';
import { AuthReducer, toggleMenu } from './reducers/AuthReducer';
const middleware = applyMiddleware(thunk);

const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
    sideMenu: toggleMenu,
    drawing:DrawingReducer,
    auth: AuthReducer,	
	
});

export const store = createStore(reducers,  composeEnhancers(middleware));
