import React, { useState, useEffect } from "react";
import { Spinner, Modal, Tab, Nav, NavDropdown } from "react-bootstrap";
import RSNGroupSelectInput from "./RSN/RSNGroupSelect";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import BISPage from "./BIS";
import IPSSPage from "./IPSS";
import RSNPage from "./RSN";
import CommonStandardPage from "./CommonStandards";
import { handleLoadingAction } from "../../../store/actions/DrawingActions";
import {
  getStandardList,
  createStandard,
  downloadStandardPDFFiles
} from "../../../services/StandardService";
import DynamicSelectInput from "../../constant/SelectInput";
import Pagination from "../../constant/Pagination";
import BISForm from "./BIS/Form";
import RSNForm from "./RSN/Form";
import IPSSForm from "./IPSS/Form";
import CommonForm from "./CommonStandards/CommonForm";

const MainheaderCompo = (props) => {
  const { activeKey, standardType, userRole, standardFilter } = props;
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [rsnDropdown, setRsnDropDown] = useState({ rsn_volume: null, group: null });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitLoading, setIsSUbmitLoading] = useState(false);
  const recordsPage = 10
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const standardTypeKey = () => {
    if (["RSN", "IPSS", "BIS", "AWWA", "ASTM", "API", "BRITISH", "IEC", "ISO", "ISRT", "PSN"].includes(activeKey)) {
      return activeKey.toLowerCase()
    } else if (activeKey === "DIN(GERMAN)") {
      return "din_german"
    } else {
      return "gost_russian"
    }
  }


  useEffect(() => {
    if (activeKey === standardType) {
      const { currentPage, searchQuery, rsnVolume, rsnGroup } = standardFilter[standardTypeKey()]
      if (activeKey === "RSN") {
        setRsnDropDown({ rsn_volume: rsnVolume, group: rsnGroup })
      }
      setIsLoading(true);
      getStandardList(
        currentPage,
        recordsPage,
        searchQuery,
        standardType,
        rsnVolume?.value || "",
        rsnGroup?.value || ""
      ).then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
        setIsSearchLoading(false);
      }).catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setData([])
          setTotal(0)
        } else {
          console.log(error);
        }
        setIsLoading(false);
        setIsSearchLoading(false);
      });
    }
  }, [activeKey]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const updateFilter = (currentPage, perPage = 10, searchQuery = null, rsnVolume = undefined, rsnGroup = undefined) => {
    const key = standardTypeKey()
    const standardTypeFilter = standardFilter[key]
    if (searchQuery === null) {
      searchQuery = standardTypeFilter.searchQuery
    }
    if (rsnVolume === undefined) {
      rsnVolume = standardTypeFilter.rsnVolume
    }
    if (rsnVolume === null) {
      rsnGroup = null
    }
    if (rsnGroup === undefined) {
      rsnGroup = standardTypeFilter.rsnGroup
    }
    const newFilter = { ...standardFilter, [key]: { currentPage, searchQuery, rsnVolume, rsnGroup } }
    dispatch(handleLoadingAction({ standardFilter: newFilter }));
    setIsLoading(true);
    getStandardList(
      currentPage,
      perPage,
      searchQuery,
      standardType,
      rsnVolume?.value || "",
      rsnGroup?.value || ""
    ).then((resp) => {
      setData(resp.data.results);
      setTotal(resp.data.count);
      setIsLoading(false);
      setIsSearchLoading(false);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response.data.message);
        setData([])
        setTotal(0)
      } else {
        console.log(error);
      }
      setIsLoading(false);
      setIsSearchLoading(false);
    });
  }

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setIsSearchLoading(true);
    updateFilter(1, recordsPage, value, null, null)
  };

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    updateFilter(new_page)
  };

  const handleGoTo = (page_no) => {
    updateFilter(page_no)
  };

  const handleAllRecords = () => {
    updateFilter(1, total)
  }


  const handleSelectChange = (name, value, _data) => {
    let rsnVolume = name === "rsn_volume" ? _data : null
    let rsnGroup = name === "group" ? _data : null
    if (name === "rsn_volume") {
      rsnGroup = null
      setRsnDropDown({ ...rsnDropdown, [name]: _data, group: null })
    } else {
      setRsnDropDown({ ...rsnDropdown, [name]: _data })
      rsnVolume = undefined
    }
    updateFilter(1, recordsPage, null, rsnVolume, rsnGroup)
  };

  const standardComponent = () => {
    if (activeKey === "BIS") {
      return <BISPage data={data} isLoading={isLoading} userRole={userRole} />;
    } else if (activeKey === "IPSS") {
      return <IPSSPage data={data} isLoading={isLoading} userRole={userRole} />;
    } else if (activeKey === "RSN") {
      return <RSNPage data={data} isLoading={isLoading} userRole={userRole} />;
    } else {
      return (
        <CommonStandardPage
          data={data}
          setData={setData}
          isLoading={isLoading}
          type={activeKey}
          userRole={userRole}
        />
      );
    }
  };

  const handleSubmit = async (e, form_data) => {
    e.preventDefault();
    try {
      setIsSUbmitLoading(true);
      const reqBody = new FormData();
      Object.entries(form_data).forEach(([fieldName, value]) => {
        reqBody.append(fieldName, value);
      });
      reqBody.append("standard_type", activeKey);
      const resp = await createStandard(reqBody);
      const { message, results } = resp.data;
      toast.success(message);
      setData([results, ...data]);
      toggleModal();
      setIsSUbmitLoading(false);
      navigate(`/standards/${results.id}`);
    } catch (error) {
      toast.error(error.response.data.message);
      setIsSUbmitLoading(false);
    }
  };



  return (
    <>
      <div className="card-body p-0">
        <div className="tbl-caption d-flex justify-content-between text-wrap flex-column  flex-sm-row align-items-start align-items-sm-center p-3">
          <div className="d-flex align-items-center">
            <h4 className="heading fw-bold mb-0 me-3">{activeKey} Standards</h4>
          </div>
          <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
            {activeKey === "RSN" && (
              <>
                <div className="me-3 mt-3" style={{ minWidth: "15rem" }}>
                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/rsn_volume"
                    name={"rsn_volume"}
                    id={"rsn_volume"}
                    isClearable={true}
                    placeholder={"Select Volume"}
                    defaultValue={rsnDropdown.rsn_volume || null}
                    setClickValue={rsnDropdown.rsn_volume || null}
                    isRequired={true}
                  />
                </div>
                <div className="me-3" style={{ minWidth: "15rem" }}>
                  <RSNGroupSelectInput
                    volume_id={rsnDropdown.rsn_volume?.value || ""}
                    setParentInputValue={handleSelectChange}
                    defaultValue={rsnDropdown.group || null}
                    isClearable={true}
                    isSetEmpty={rsnDropdown.rsn_volume === null}
                  />
                </div>
              </>
            )}
            <div className="me-3 mb-3 mb-md-0">
              <div className="input-group tp-input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={standardFilter[standardTypeKey()].searchQuery}
                  aria-describedby="basic-search"
                  onChange={handleSearchChange}
                />
                <div className="input-group-text" id="basic-search">
                  {isSearchLoading && <Spinner variant="primary" size="sm" />}
                  {!isSearchLoading && standardFilter[standardTypeKey()].searchQuery.length > 0 && (
                    <i
                      className="fa-solid fa-xmark c-pointer text-primary"
                      onClick={() => {
                        updateFilter(1, recordsPage, "", null, null);
                      }}
                    ></i>
                  )}
                </div>
              </div>
            </div>
            {userRole !== "User" && (
              <button className="btn btn-primary" onClick={toggleModal}>
                Add {activeKey}
              </button>
            )}
          </div>
        </div>
        {/* selected compo */}
        {standardComponent()}
        {isLoading ? (
          <div style={{ height: "4rem" }}></div>
        ) : (
          <Pagination
            currentPage={standardFilter[standardTypeKey()].currentPage}
            recordsPage={recordsPage}
            dataLength={total}
            handleGoTo={handleGoTo}
            handlePagination={handlePagination}
            handleAllRecords={
              (
                (activeKey === "RSN" && standardFilter[standardTypeKey()]?.rsnVolume) ||
                standardFilter[standardTypeKey()].searchQuery.length > 0
              ) ? handleAllRecords : undefined
            }
          />
        )}
      </div>
      <Modal
        show={isModalOpen}
        onHide={toggleModal}
        className="modal fade modal-lg"
        placement="end"
      >
        <div className="modal-content">
          <div className="modal-header border-bottom">
            <h5 className="modal-title" id="#gridSystemModal">
              Add {activeKey}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {activeKey === "BIS" ? (
              <BISForm
                handleSubmit={handleSubmit}
                isLoading={isSubmitLoading}
              />
            ) : activeKey === "RSN" ? (
              <RSNForm
                handleSubmit={handleSubmit}
                isLoading={isSubmitLoading}
              />
            ) : activeKey === "IPSS" ? (
              <IPSSForm
                handleSubmit={handleSubmit}
                isLoading={isSubmitLoading}
              />
            ) : (
              <CommonForm
                handleSubmit={handleSubmit}
                isLoading={isSubmitLoading}
                type={activeKey}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

const StandardPage = ({ userRole, standardPerm, standardFilter }) => {
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("RSN");
  const handleSelect = (eventKey) => setActiveKey(eventKey);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const standardType = queryParams.get("standard_type");
    if (standardType) {
      setActiveKey(standardType);
      navigate(location.pathname, { replace: true });
    }
  }, [location.search]);

  const getStandardPermData = () => {
    if (userRole !== "User") {
      return ["RSN", "IPSS", "BIS", "AWWA", "ASTM", "API", "BRITISH", "DIN(GERMAN)", "GOST(RUSSIAN)", "IEC", "ISO", "ISRT", "PSN"]
    }
    const perm = []
    if (standardPerm?.awwa) {
      perm.push('AWWA')
    }
    if (standardPerm?.astm) {
      perm.push('ASTM')
    }
    if (standardPerm?.api) {
      perm.push('API')
    }
    if (standardPerm?.british) {
      perm.push('BRITISH')
    }
    if (standardPerm?.din_german) {
      perm.push('DIN(GERMAN)')
    }
    if (standardPerm?.gost_russian) {
      perm.push('GOST(RUSSIAN)')
    }
    if (standardPerm?.iec) {
      perm.push('IEC')
    }
    if (standardPerm?.iso) {
      perm.push('ISO')
    }
    if (standardPerm?.irst) {
      perm.push('ISRT')
    }
    if (standardPerm?.psn) {
      perm.push('PSN')
    }
    // if (standardPerm?.other_standard) {
    //   perm.push('OTHER STANDARD')
    // }
    return perm
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="custom-btn-tab">
              <Tab.Container activeKey={activeKey} onSelect={handleSelect}>
                <Nav
                  as="ul"
                  className="nav-pills mb-2 light border-bottom px-4 pt-3"
                >
                  <Nav.Item as="li">
                    <Nav.Link eventKey="RSN" className="pb-3 fw-semibold fs-5">
                      RSN
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="IPSS" className="pb-3 fw-semibold fs-5">
                      IPSS
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Nav.Link eventKey="BIS" className="pb-3 fw-semibold fs-5">
                      BIS
                    </Nav.Link>
                  </Nav.Item>
                  {getStandardPermData().length > 0 &&
                    <NavDropdown
                      title="OTHERS"
                      id="nav-dropdown"
                      className={`custom-nav-dropdown ${[
                        "AWWA",
                        "ASTM",
                        "API",
                        "BRITISH",
                        "DIN(GERMAN)",
                        "GOST(RUSSIAN)",
                        "IEC",
                        "ISO",
                        "IRST",
                        "PSN",
                      ].includes(activeKey)
                        ? "active"
                        : ""
                        }`}
                    >
                      {getStandardPermData().filter(it => !['RSN', 'IPSS', 'BIS'].includes(it)).map(item => (
                        <NavDropdown.Item eventKey={item} key={item}>{item}</NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  }
                </Nav>
                <Tab.Content className="">
                  <Tab.Pane eventKey={"RSN"}>
                    <MainheaderCompo
                      activeKey={activeKey}
                      standardType={"RSN"}
                      standardFilter={standardFilter}
                      userRole={userRole} />
                  </Tab.Pane>
                  <Tab.Pane eventKey={"IPSS"}>
                    <MainheaderCompo
                      activeKey={activeKey}
                      standardType={"IPSS"}
                      standardFilter={standardFilter}
                      userRole={userRole} />
                  </Tab.Pane>
                  <Tab.Pane eventKey={"BIS"}>
                    <MainheaderCompo
                      activeKey={activeKey}
                      standardType={"BIS"}
                      standardFilter={standardFilter}
                      userRole={userRole} />
                  </Tab.Pane>
                  {getStandardPermData().filter(it => !['RSN', 'IPSS', 'BIS'].includes(it)).map(item => (
                    <Tab.Pane eventKey={item} key={item}>
                      <MainheaderCompo
                        activeKey={activeKey}
                        standardType={item}
                        standardFilter={standardFilter}
                        userRole={userRole} />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user?.role || "Admin",
    standardPerm: state.auth.user?.standard_permission || {},
    standardFilter: state.drawing?.standardFilter || {}
  };
};

export const viewPDFFile = (id) => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}/api/standard_file_download/${id}`
    window.open(url, '_blank');
  } catch (error) {
    console.error('Error fetching or opening file:', error);
  }
};

export default connect(mapStateToProps)(StandardPage);
