import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import {
    updateStandard,
  } from "../../../../services/StandardService"; 
import { formatFileSize } from "../../../../utils";   
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function EditBisModal({ data, setData}) {
  const [standards, setStandards] = useState({
    id: "",
    standard_number: "",
    part_number: "",
    section_number: "",
    document_year: "",
    division: "",
    division_code: "",
    committee_code: "",
    committee_title: "",
    description: "",
    file: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const toggleModal = () => {
    if (showModal) {
      setStandards(standards);
    } else {
      setStandards({
        id: data.id,
        standard_number: data.standard_no,
        part_number: data.part_no || "",
        section_number: data.section_no || "",
        document_year: data.document_year || "",
        division: data.division || "",
        division_code: data.division_code || "",
        committee_code: data.committee_code || "",
        committee_title: data.committee_title || "",
        description: data.description || "",
        file: "",
        file_data:data.file
      });
    }
    setShowModal(!showModal);
  };

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const file = file_list[0];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (file_ext !== "PDF") {
        toast.error("Please choose a valid PDF file.");
      } else {
        setStandards({ ...standards, file: file });
      }
    }
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStandards({ ...standards, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitLoading(true);
      const reqBody = new FormData();
      Object.entries(standards).forEach(([fieldName, value]) => {
        if(fieldName !== "file_data"){
          reqBody.append(fieldName, value);
        }
      });
      reqBody.append("standard_type", "BIS");
      const resp = await updateStandard(reqBody);
      const {results, message} = resp.data
      setData(results);
      toast.success(message);
      toggleModal();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
  };

  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm cursor-pointer fw-bolder"
        onClick={toggleModal}
      >
        <i className="fa-solid fa-pen-to-square"></i> Edit
      </button>
      <Modal
        show={showModal}
        onHide={toggleModal}
        className="modal fade modal-lg"
        placement="end"
      >
        <div className="modal-content">
          <div className="modal-header border-bottom">
            <h5 className="modal-title" id="#gridSystemModal">
              Edit BIS
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {standards && (
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xl-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="standard_number" className="form-label">
                        BIS Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="standard_number"
                        placeholder="Enter BIS Number"
                        name="standard_number"
                        value={standards.standard_number}
                        onChange={handleChange}
                        readOnly
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="part_number" className="form-label">
                        Part Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="part_number"
                        placeholder="Enter Part Number"
                        name="part_number"
                        value={standards.part_number}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="section_number" className="form-label">
                        Section Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="section_number"
                        placeholder="Enter Section Number"
                        name="section_number"
                        value={standards.section_number}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="document_year" className="form-label">
                        Document Year
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="document_year"
                        placeholder="Enter Document Year"
                        name="document_year"
                        value={standards.document_year}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="description"
                        placeholder="Enter Description"
                        name="description"
                        value={standards.description}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12 mx-auto">
                    <div className="mb-3">
                      <label htmlFor="division" className="form-label">
                        Division
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="division"
                        placeholder="Enter Division"
                        name="division"
                        value={standards.division}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="division_code" className="form-label">
                        Division Code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="division_code"
                        placeholder="Enter Division Code"
                        name="division_code"
                        value={standards.division_code}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="committee_code" className="form-label">
                        Committee Code
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="committee_code"
                        placeholder="Enter Committee Code"
                        name="committee_code"
                        value={standards.committee_code}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="committee_title" className="form-label">
                        Committee Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="committee_title"
                        placeholder="Enter Committee Title"
                        name="committee_title"
                        value={standards.committee_title}
                        onChange={handleChange}
                      />
                    </div>
                    <div class="mb-3">
                      <label for="file" class="form-label">
                        {standards.file_data ? "Upload New File" : "Upload File"}
                        {standards.file_data ? (
                          <>
                            <span className="ms-2 me-2 text-dark">
                              {standards.file_data.name}
                            </span>
                            <span className="text-dark">
                              {formatFileSize(standards.file_data.size)}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleFileInputChange}
                        accept=".pdf"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <button type="submit" className="btn btn-primary me-1 w-50" disabled={isSubmitLoading}>
                  {isSubmitLoading ? (<span><Spinner size="sm" className="me-2" />Updating...</span>) : "Update"}
                  </button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditBisModal;
