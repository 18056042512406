import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import AdvanceLoginSearch from "./AdvanceLoginSearch";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { getLoginLogReportList, downloadLoginLogExcel } from "../../../services/LogService";
import Pagination from "../../constant/Pagination";
import TableLoaderEffect from "../../constant/TableLoader";
import { minusDaysToDate } from "../../../utils";
const LoginLogReport = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [openADSearch, setOpenADSearch] = useState(false);
  const recordsPage = 10;
  const [showModal, setShowModal] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [advanceFilterInput, setAdvanceFilterInput] = useState({
    status: "",
    user: "",
    start_date: minusDaysToDate(15),
    end_date: minusDaysToDate(0),
  });

  useEffect(() => {
    if (!searchQuery.length > 0) {
      setIsLoading(true)
    }
    getLoginLogReportList(
      currentPage,
      recordsPage,
      searchQuery,
      advanceFilterInput.status,
      advanceFilterInput.user,
      advanceFilterInput.start_date,
      advanceFilterInput.end_date
    )
      .then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
        setIsSearchLoading(false)
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
        setIsSearchLoading(false)
      });
  }, [
    currentPage,
    searchQuery,
    advanceFilterInput.status,
    advanceFilterInput.user,
    advanceFilterInput.start_date,
    advanceFilterInput.end_date,
  ]);


  const convertToExcel = async () => {
    try {
      setDownloading(true);
      const resp = await downloadLoginLogExcel(
        searchQuery,
        advanceFilterInput.status,
        advanceFilterInput.user,
        advanceFilterInput.start_date,
        advanceFilterInput.end_date
      );
      const excelData = resp.data.results
      if (excelData.length > 0) {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        const fileName = `compliance_mis_report_${new Date().getTime()}.xlsx`;
        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([wbout], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        toast.error("No Data found.")
      }
      setDownloading(false);
    } catch (error) {
      console.error("Error converting data to Excel:", error);
      setDownloading(false);
    }
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setIsSearchLoading(true)
    setCurrentPage(1)
    setSearchQuery(value);
  };

  const handleFilterChange = (filterInput) => {
    setAdvanceFilterInput(filterInput);
    setCurrentPage(1);
  };

  const toggleModal = (details = null) => {
    setSelectedDetails(details);
    setShowModal(!showModal);
  };

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    setCurrentPage(new_page);
  };

  const toggleADSearch = () => {
    setOpenADSearch(!openADSearch);
  };

  return (
    <>
      <div className="px-3 pt-3 border-top">
        <div className="tbl-caption d-flex justify-content-between flex-column  flex-sm-row text-wrap align-items-start align-items-sm-center">
          <h4 className="heading fw-bold mb-3 mb-md-0">LOGIN REPORT</h4>
          <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
            <div className="me-3 mb-3 mb-md-0">
              <div className="input-group tp-input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={searchQuery}
                  aria-describedby="basic-search"
                  onChange={handleSearchChange}
                />
                <div className="input-group-text" id="basic-search">
                  {isSearchLoading && (
                    <Spinner variant="primary" size="sm" />
                  )}
                  {!isSearchLoading && searchQuery.length > 0 && (
                    <i
                      className="fa-solid fa-xmark c-pointer text-primary"
                      onClick={() => {
                        setCurrentPage(1);
                        setSearchQuery("");
                      }}
                    ></i>
                  )}
                </div>
              </div>
            </div>
            <button
              className="btn btn-outline-primary me-2 mb-3 mb-md-0"
              onClick={toggleADSearch}
            >
              Advance Search
            </button>
            <button
              type="button"
              className="btn btn-outline-success me-2 mb-3 mb-md-0"
              onClick={convertToExcel}
            >
              {downloading ? (
                <><Spinner variant="success" size="sm" /><span className="ms-2">Exporting</span></>
              ) : (<><i className="fa-solid fa-download"></i> <span className="ms-2">Export</span> </>)}
            </button>
          </div>
        </div>
        <div className="mt-4">
          <AdvanceLoginSearch
            open={openADSearch}
            toggle={toggleADSearch}
            onApplyChanges={handleFilterChange}
          />
        </div>
      </div>
      <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting mt-4">
        <div
          id="employee-tbl_wrapper"
          className="dataTables_wrapper no-footer"
        >
          <table
            id="empoloyees-tblwrapper"
            className="table ItemsCheckboxSec dataTable no-footer mb-0"
          >
            <thead>
              <tr>
                <th>User</th>
                <th>Status</th>
                <th>Device</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <TableLoaderEffect colLength={4} />
              ) : (
                data.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <span
                        className="text-primary cursor-pointer fw-bolder"
                        onClick={() => toggleModal(item)}
                      >
                        {item.user.full_name}
                      </span>
                    </td>
                    <td>{item.message}</td>
                    <td>{item.device_info}</td>
                    <td>{item.action_time}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isLoading ? (
        <div style={{ height: "4rem" }}></div>
      ) : (
        <Pagination
          currentPage={currentPage}
          recordsPage={recordsPage}
          dataLength={total}
          handlePagination={handlePagination}
        />
      )}
      <Modal
        className="modal fade modal-sm"
        id="exampleModal1"
        show={showModal}
        onHide={toggleModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Log Details
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {selectedDetails && selectedDetails.details && selectedDetails.details.split('|').map((detail, index) => (
              <div className="mb-3" key={index}>{detail}</div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LoginLogReport;
