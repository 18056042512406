import { login, logoutApi, whoami, getVisitorCountApi } from "../../services/AuthService";
import { RESET_DRAWING_STATE_TYPE } from "./DrawingActions";

export const AUTH_CHECK_CONFIRMED_ACTION = "[Auth Action] auth ckeck confirmed";
export const AUTH_CHECK_FAILED_CONFIRMED_ACTION = "[Auth Action] auth ckeck failed confirmed";
export const LOGIN_CONFIRMED_ACTION = "[Auth Action] confirmed login";
export const LOGIN_FAILED_ACTION = "[Auth Action] failed login";
export const LOADING_TOGGLE_ACTION = "[Auth Action] toggle loading";
export const LOGOUT_ACTION = "[Auth Action] logout action";
export const UPDATE_PROFILE = "UPDATE PROFILE";
export const GET_VISITOR_COUNT = "GET VISITOR COUNT";
export const UPDATE_LAST_ACTIVITY_TIME = "UPDATE LAST ACTIVITY TIME";
export const NAVTOGGLE = "NAVTOGGLE";
export function checkAutoLoginAction(navigate) {
  return (dispatch, getState) => {
    const user = getState().auth.user;
    if (!user) {
      dispatch(authCheckConfirmAction(true));
      whoami().then((response) => {
        if (response === undefined) {
          dispatch(authCheckFailedConfirmAction("CANCELED"))
        } else {
          const { data } = response.data;
          dispatch(loginConfirmedAction(data));
        }
      })
        .catch((error) => {
          dispatch(authCheckFailedConfirmAction())
          navigate('/')
        });
    }
  };
}

export function Logout(navigate) {
  function dispatchCallBack(type) {
    return { type };
  }
  return (dispatch) => {
    logoutApi()
      .then((response) => {
        dispatch(dispatchCallBack(LOGOUT_ACTION));
        dispatch(dispatchCallBack(RESET_DRAWING_STATE_TYPE))
        navigate("/"); //login
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getVisitorsCountAction() {
  function dispatchCallBack(payload) {
    return { type: GET_VISITOR_COUNT, payload };
  }
  return (dispatch) => {
    getVisitorCountApi().then((response) => {
      const { results } = response.data;
      dispatch(dispatchCallBack(results));
    })
      .catch((error) => {
        dispatch(dispatchCallBack(null));
      });
  };
}

export function loginAction(personnel_number, password, navigate) {
  return (dispatch) => {
    login(personnel_number, password)
      .then((response) => {
        const { data } = response.data;
        dispatch(loginConfirmedAction({ ...data }));
        navigate("/dashboard");
      })
      .catch((error) => {
        dispatch(loginFailedAction(error.response.data.message));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function authCheckConfirmAction(payload) {
  return {
    type: AUTH_CHECK_CONFIRMED_ACTION,
    payload,
  };
}

export function authCheckFailedConfirmAction(payload=null) {
  return { type: AUTH_CHECK_FAILED_CONFIRMED_ACTION, payload };
}


export function updateLastActivityTimeAction() {
  return { type: UPDATE_LAST_ACTIVITY_TIME };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function updateProfileAction(data) {
  return {
    type: UPDATE_PROFILE,
    payload: data,
  };
}

export const navtoggle = () => {
  return {
    type: "NAVTOGGLE",
  };
};
