import { SVGICON } from "../../constant/theme";

export const MenuList = [
  {
    title: "Dashboard",
    iconStyle: SVGICON.MenuDashboard,
    to: "/dashboard",
    accessRole: ["SuperAdmin", "Admin", "User"]
  },

  {
    title: "Drawings",
    iconStyle: SVGICON.MenuDrawing,
    to: "/drawing",
    accessRole: ["SuperAdmin", "Admin", "User"]
  },
  {
    title: "Standards",
    iconStyle: SVGICON.MenuStandard,
    to: "/standards",
    accessRole: ["SuperAdmin", "Admin", "User"]
  },
  {
    title: "Documents",
    iconStyle: SVGICON.MenuManual,
    to: "/documents",
    accessRole: ["SuperAdmin", "Admin", "User"]
  },
   {
    title: "SI",
    iconStyle: SVGICON.MenuSI,
    to: "/si",
    accessRole: ["SuperAdmin", "Admin", "User"]
  },
  {
    title: "Users",
    iconStyle: SVGICON.MenuUsers,
    to: "/user",
    accessRole: ["SuperAdmin", "Admin"]
  },

  {
    title: "Department & Unit",
    iconStyle: SVGICON.MenuDepartment,
    to: "/department-unit",
    accessRole: ["SuperAdmin", "Admin"]
  },

  {
    title: "Bulk Upload",
    classsChange: "mm-collapse",
    iconStyle: SVGICON.MenuBulkUpload,
    content: [
      {
        title: "Upload Drawing Data",
        to: "/upload-drawing-data",
      },
      {
        title: "Upload Drawing Files",
        to: "/upload-drawing-files",
      },
      {
        title: "Upload Standards",
        to: "/upload-standards",
      },
      {
        title: "Upload Documents",
        to: "/upload-documents",
      },
      
    ],
    accessRole: ["SuperAdmin", "Admin"]
  },
  {
    title: "MIS Report",
    iconStyle: SVGICON.MenuMISReport,
    to: "/mis-report",
    accessRole: ["SuperAdmin", "Admin"]
  },
  {
    title: "Bulk Archive",
    iconStyle: SVGICON.MenuBulkArchive,
    to: "/bulk-archive",
    accessRole: ["SuperAdmin"]
  },

  {
    title: "Notice",
    iconStyle: SVGICON.MenuNotice,
    to: "/notice",
    accessRole: ["SuperAdmin"]
  },

];
