// src/BarChart.js
import React from 'react';
import Chart from 'react-apexcharts';
import { Spinner } from 'react-bootstrap';

const BarChart = ({ label, series, isLoading }) => {
    const chartOptions = {
        chart: {
            type: 'bar',
            toolbar: {
                show: false // Hide the toolbar
              }
        },
        xaxis: {
            categories: label,
            title: {
                text: 'Upload Date',
            },
        },
        yaxis: {
            title: {
                text: 'Count of Drawings',
            },
        },
    };

    return (
        <div className="card">
            <div className="card-header border-0 pb-0">
                <h4 className="mb-0 text-center">Count of Drawings Uploaded by Date</h4>
            </div>
            <div className="card-body  p-0">
                {isLoading ? <div className="d-flex align-items-center justify-content-center">
                    <h3 className="text-primary">Loading .. <Spinner size="sm" /></h3>
                </div> : <Chart options={chartOptions} series={series} type="bar" width="100%" height="400" />}
            </div>
        </div>
    );
};

export default BarChart;
