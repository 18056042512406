import React, { useState } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import { updateManual } from "../../../../services/ManualService";
import { formatFileSize } from "../../../../utils";
import { toast } from "react-toastify";

function EditReferenceBookModal({ data, setData }) {
  const [manuals, setManuals] = useState({
    id: "",
    manual_number: "",
    editor: "",
    source: "",
    author: "",
    description: "",
    file: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const toggleModal = () => {
    if (showModal) {
      setManuals(manuals);
    } else {
      setManuals({
        id: data.id,
        manual_number: data.manual_no,
        editor: data.editor || "",
        source: data.source || "",
        author: data.author || "",
        description: data.description || "",
        file: "",
        file_data: data.file,
      });
    }
    setShowModal(!showModal);
  };

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const file = file_list[0];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (file_ext !== "PDF") {
        toast.error("Please choose a valid PDF file.");
      } else {
        setManuals({ ...manuals, file: file });
      }
    }
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setManuals({ ...manuals, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitLoading(true);
      const reqBody = new FormData();
      Object.entries(manuals).forEach(([fieldName, value]) => {
        reqBody.append(fieldName, value);
      });
      reqBody.append("manual_type", "REFERENCE BOOK");
      const resp = await updateManual(reqBody);
      const { results, message } = resp.data;
      setData(results);
      toast.success(message);
      toggleModal();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
  };

  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm cursor-pointer fw-bolder"
        onClick={toggleModal}
      >
        <i className="fa-solid fa-pen-to-square"></i> Edit
      </button>
      <Modal
        show={showModal}
        onHide={toggleModal}
        className="modal fade modal-lg"
        placement="end"
      >
        <div className="modal-content">
          <div className="modal-header border-bottom">
            <h5 className="modal-title" id="#gridSystemModal">
              Edit Reference Book
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {manuals && (
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xl-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="manual_number" className="form-label">
                        Book Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="manual_number"
                        placeholder="Enter Book Number"
                        name="manual_number"
                        value={manuals.manual_number}
                        onChange={handleChange}
                        readOnly
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="editor" className="form-label">
                        Editor
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="editor"
                        placeholder="Enter Editor"
                        name="editor"
                        value={manuals.editor}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="description"
                        placeholder="Enter Description"
                        name="description"
                        value={manuals.description}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12 mx-auto">
                    <div className="mb-3">
                      <label htmlFor="source" className="form-label">
                        Source
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="source"
                        placeholder="Enter Source"
                        name="source"
                        value={manuals.source}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="author" className="form-label">
                        Author
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="author"
                        placeholder="Enter Author"
                        name="author"
                        value={manuals.author}
                        onChange={handleChange}
                      />
                    </div>
                    <div class="mb-3">
                      <label for="file" class="form-label">
                        {manuals.file_data ? "Upload New File" : "Upload File"}
                        {manuals.file_data ? (
                          <>
                            <span className="ms-2 me-2 text-dark">
                              {manuals.file_data.name}
                            </span>
                            <span className="text-dark">
                              {formatFileSize(manuals.file_data.size)}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleFileInputChange}
                        accept=".pdf"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-2">
                <button type="submit" disabled={isSubmitLoading} className="btn btn-primary me-1 w-50">
                {isSubmitLoading ? (<span><Spinner size="sm" className="me-2" />Updating...</span>) : "Update"}
                  </button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditReferenceBookModal;
