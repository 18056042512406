import React, { useState, useRef, useEffect } from "react";
import { StaticSelectInput } from "../../../constant/SelectInput";
import { Button, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { standardDropdownOptions } from "../../../constant/Variable";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { formatFileSize } from "../../../../utils";
import BISSampleExcel from "../../../../images/ExcelFile/Standard/BIS_sample_excel_file.xlsx";
import IPSSSampleExcel from "../../../../images/ExcelFile/Standard/IPSS_sample_excel_file.xlsx";
import RSNSampleExcel from "../../../../images/ExcelFile/Standard/RSN_sample_excel_file.xlsx";
import AWWASampleExcel from "../../../../images/ExcelFile/Standard/AWWA_sample_excel_file.xlsx";
import ASTMSampleExcel from "../../../../images/ExcelFile/Standard/ASTM_sample_excel_file.xlsx";
import BRITISHSampleExcel from "../../../../images/ExcelFile/Standard/BRITISH_sample_excel_file.xlsx";
import DINSampleExcel from "../../../../images/ExcelFile/Standard/DIN_sample_excel_file.xlsx";
import GOSTSampleExcel from "../../../../images/ExcelFile/Standard/GOST_sample_excel_file.xlsx";
import IECSampleExcel from "../../../../images/ExcelFile/Standard/IEC_sample_excel_file.xlsx";
import ISOSampleExcel from "../../../../images/ExcelFile/Standard/ISO_sample_excel_file.xlsx";
import IRSTSampleExcel from "../../../../images/ExcelFile/Standard/IRST_sample_excel_file.xlsx";
import APISampleExcel from "../../../../images/ExcelFile/Standard/API_sample_excel_file.xlsx";
import PSNSampleExcel from "../../../../images/ExcelFile/Standard/PSN_sample_excel_file.xlsx";
import { bulkUploadStandardData } from "../../../../services/StandardService";

const UploadStandardPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultSelectedOption = queryParams.get("standard_type") || "BIS";
  const [selectedOption, setSelectedOption] = useState(defaultSelectedOption);  const [jsonData, setJsonData] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const fileInputRef = useRef(null);
  const excelFileInputRef = useRef(null);
  const navigate = useNavigate()

  const requiredHeaders = {
    BIS: [
      "standard_type",
      "standard_number",
      "part_number",
      "section_number",
      "document_year",
      "division",
      "division_code",
      "committee_code",
      "committee_title",
      "description",
      "file",
    ],
    IPSS: [
      "standard_type",
      "standard_number",
      "title_id",
      "file_availability",
      "description",
      "file",
    ],
    RSN: [
      "standard_type",
      "standard_number",
      "group_id",
      "no_of_sheet",
      "description",
      "file",
    ],
    default: [
      "standard_type",
      "standard_number",
      "part_number",
      "section_number",
      "document_year",
      "description",
      "file",
    ],
  };

  const handleSelect = (name, selectedValue, _) => {
    setSelectedOption(selectedValue);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const standardType = queryParams.get("standard_type") || "BIS";
    setSelectedOption(standardType);
  }, [location.search]);

  useEffect(() => {
    navigate(`/upload-standards?standard_type=${selectedOption}`);
  }, [selectedOption, navigate]);

  const validateData = (obj, row) => {
    const errors = [];
    Object.entries(obj).forEach(([key, value], col) => {
      if (value === null || value === undefined) {
        if (key === "standard_number") {
          errors.push(
            `Data is required in row ${row + 1}, column ${col + 1} in ${key}`
          );
        }
      } else if (value === undefined) {
        obj[key] = null;
      }
    });
    return errors;
  };

  const handleFileInputChange = (files) => {
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const dataJson = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headers = dataJson[0].map((header) => header.trim());

        const requiredHeadersForOption =
          requiredHeaders[selectedOption] || requiredHeaders.default;

        const missingHeaders = requiredHeadersForOption.filter(
          (header) => !headers.includes(header)
        );
        if (missingHeaders.length > 0) {
          toast.error(
            `Missing headers in Excel file: ${missingHeaders.join(", ")}`
          );
          return;
        }

        let jsonData = [];
        let errors = [];
        for (var i = 1; i < dataJson.length; i++) {
          const obj = {};
          if (dataJson[i].length === 0) {
            continue;
          }
          for (var j = 0; j < headers.length; j++) {
            const key = headers[j];
            const value = dataJson[i][j];
            obj[key] = value;
          }
          jsonData.push(obj);
          const error = validateData(obj, i);
          if (error.length) {
            errors = errors.concat([...error]);
          }
        }
        setJsonData(jsonData);
        setErrorMessages(errors);
      };
      setErrorMessages([]);
      reader.readAsArrayBuffer(file);
    }
  };

  const handleFileRemove = (e, index) => {
    const updatedFiles = [...selectedFile];
    const removedfile = updatedFiles.splice(index, 1);
    const file_name = removedfile[0].name;
    setSelectedFile(updatedFiles);
    if (errorMessages.length > 0) {
      const updatedErrorMessage = errorMessages.filter(
        (item) => !item.includes(file_name)
      );
      setErrorMessages(updatedErrorMessage);
    }
    e.stopPropagation();
  };

  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    if (selectedOption === "BIS") {
      downloadLink.href = BISSampleExcel;
    } else if (selectedOption === "IPSS") {
      downloadLink.href = IPSSSampleExcel;
    } else if (selectedOption === "RSN") {
      downloadLink.href = RSNSampleExcel;
    }else if (selectedOption === "AWWA") {
      downloadLink.href = AWWASampleExcel;
    }else if (selectedOption === "ASTM") {
      downloadLink.href = ASTMSampleExcel;
    }else if (selectedOption === "API") {
      downloadLink.href = APISampleExcel;
    }else if (selectedOption === "BRITISH") {
      downloadLink.href = BRITISHSampleExcel;
    }else if (selectedOption === "DIN(GERMAN)") {
      downloadLink.href = DINSampleExcel;
    }else if (selectedOption === "GOST(RUSSIAN)") {
      downloadLink.href = GOSTSampleExcel;
    }else if (selectedOption === "IEC") {
      downloadLink.href = IECSampleExcel;
    } else if (selectedOption === "ISO") {
      downloadLink.href = ISOSampleExcel;
    } else if (selectedOption === "IRST") {
      downloadLink.href = IRSTSampleExcel;
    } else {
      downloadLink.href = PSNSampleExcel;
    }
    downloadLink.download = `${selectedOption}_sample_standard_data.xlsx`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);

      const formBody = new FormData();
      selectedFile.forEach((item) => {
        formBody.append("file_list", item);
      });
      formBody.append("data_list", JSON.stringify(jsonData));

      const response = await bulkUploadStandardData(formBody);
      const { message, results } = response.data;
      toast.success(message);
      setSelectedFile([]);
      setJsonData([]);
      excelFileInputRef.current.value = "";
      navigate(`/standards?standard_type=${selectedOption}`)   
    } catch (error) {
      if (error.response) {
        const { message, results } = error.response.data;
        setErrorMessages(
          results.error_data_set.map(
            (item) => `Row ${item.row}. Error: ${item.message}`
          )
        );
        console.log("Error Response:", error.response);
        toast.error(message);
      }
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePDFFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist)
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFiles(droppedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const validFileName = (filelist) => {
    const fileNameList = [];
    for (let file of filelist) {
      if (file.name.split(".").pop().toLowerCase() === "pdf") {
        fileNameList.push(file);
      }
    }
    return fileNameList;
  };

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const filelist = validFileName(file_list);
      if (filelist.length === 0) {
        toast.error("choose a valid file");
      }
      setSelectedFile([...filelist, ...selectedFile]);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="tbl-caption d-flex justify-content-between text-wrap flex-column  flex-sm-row align-items-start align-items-sm-center p-3">
              <div className="d-flex align-items-center">
                    <h4 className="heading fw-bold mb-0 me-3">
                      Upload {selectedOption} Standards
                    </h4>

                  <div style={{ width: "200px" }}>
                    <StaticSelectInput
                      setParentInputValue={handleSelect}
                      parentClassName="mb-0"
                      optionsList={standardDropdownOptions}
                      name="standard_type"
                      id="standard_type"
                      defaultValue={{
                        label: selectedOption,
                        value: selectedOption,
                      }}
                      isRequired={true}
                    />
                  </div>

                </div>
                <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                  <Button
                    variant="outline-primary"
                    className="btn btn-sm cursor-pointer fw-bolder me-4"
                    type="button"
                    onClick={handleDownload}
                  >
                    <i className="fa-solid fa-file-excel me-2"></i>Sample {selectedOption} Excel File
                  </Button>
                </div>
              </div>
              <div className="card p-3 m-5 mt-0">
                <div className="form-group mb-3">
                  <label className="form-label">Excel File</label>
                  <input
                    type="file"
                    id="excel_file"
                    ref={excelFileInputRef}
                    accept=".xlsx, .xls"
                    className="form-control"
                    onChange={(e) => handleFileInputChange(e.target.files)}
                  />
                </div>
                {errorMessages.length > 0 && (
                  <ul className="mx-4 my-2">
                    {errorMessages.map((error, index) => (
                      <li
                        style={{ listStyleType: "disc" }}
                        className="text-danger mb-2"
                        key={index}
                        role="alert"
                      >
                        {error}
                      </li>
                    ))}
                  </ul>
                )}

                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  className={`dropzone ${
                    selectedFile.length === 0 && "no-files"
                  }`}
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                >
                  <input
                    type="file"
                    ref={fileInputRef}
                    multiple
                    accept=".pdf"
                    onChange={handlePDFFileInputChange}
                    style={{ display: "none" }}
                  />
                  {selectedFile.length ? (
                    <div className="file-list">
                      {selectedFile.map((item, index) => (
                        <div key={index} className="file-item">
                          <span className="file-name">
                            {item.name}
                            <small>{formatFileSize(item.size)}</small>
                          </span>
                          <span
                            className="file-remove"
                            onClick={(e) => handleFileRemove(e, index)}
                          >
                            <i className="fa-regular fa-circle-xmark text-danger"></i>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>
                      Drag and drop files here, or click to select files
                      <br />
                      only <strong>PDF</strong> files are allowed.
                    </p>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-center mt-4 ">
                  <button
                    className="btn btn-primary btn-block w-50"
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span>
                        <Spinner variant="light" size="sm" className="ms-2" />
                        Upload
                      </span>
                    ) : (
                      "Upload"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadStandardPage;