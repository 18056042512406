import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { NewUserSchema } from "../../constant/schemas/NewUserSchema";
import { createNewUserRequest, getNewUserRequest } from "../../../services/UserService";
import { Spinner } from "react-bootstrap";

function NewUserRequest() {
  const initialValues = {
    personnel_number: "",
    full_name: "",
    email: "",
    phone_number: "",
    designation: "",
    department: "",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isPrintingOldForm, setIsPrintingOldForm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [personnelNumberInput, setPersonnelNumberInput] = useState("")
  const [userRequestData, setUserRequestData] = useState(null)

  const toggleModal = () => {
    if (isModalOpen) {
      setIsLoading(false);
      setIsPrintingOldForm(false);
      setPersonnelNumberInput("")
      setUserRequestData(null)
    }
    setIsModalOpen(!isModalOpen)
  }
  const toggleOldForm = () => {
    if (isPrintingOldForm) {
      setPersonnelNumberInput("")
      setUserRequestData(null)
    } else {
      
    }
    setIsPrintingOldForm(!isPrintingOldForm)
  }

  const printContent = (result) => {
    const printWindow = window.open('', '');
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>${result.personnel_number}_new_user_request</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
            }
            .print-area{
              position: absolute;
              top: 5%;
              left: 40%;
              transform: translate(-40%, -5%);
            }
            .print-area h3 {
              text-align:center
            }
            .print-row{
              margin-top: 2rem;
            }
            .print-row table{
              border-collapse: collapse;
              text-align:left
            }
            .print-row table td, th {
              border: 1px solid black;
              padding: 10px 15px;
            }
            .mt-5 {
              margin-top: 50px;
            }
          </style>
        </head>
        <body>
        <div id="printdiv">
            <h6>${result.created_at}</h6>
            <div class="print-area">
              <h3>New User Request Form</h3>
              <div class="print-row">
                <table>
                  <tbody>
                  <tr>
                    <th>Full Name</th>
                    <td>${result.full_name}</td>
                  </tr>
                  <tr>
                    <th>Department</th>
                    <td>${result.department}</td>
                  </tr>
                  <tr>
                    <th>Designation</th>
                    <td>${result.designation}</td>
                  </tr>
                  <tr>
                    <th>Personnel Number</th>
                    <td>${result.personnel_number}</td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>${result.phone_number}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>${result.email}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
    // Listen for afterprint event to close the print window after printing is done
    printWindow.addEventListener('afterprint', () => {
      printWindow.close();
      toggleModal()
    });

    // Listen for unload event to close the print window if the user cancels printing
    printWindow.addEventListener('unload', () => {
      printWindow.close();
    });
  };


  const handleFormSubmit = async (values, action) => {
    try {
      setIsLoading(true);
      const resp = await createNewUserRequest(values);
      const { results, message } = resp.data
      toast.success(message);
      printContent(results)
      action.resetForm();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleGetData = async () => {
    try {
      setIsLoading(true);
      const resp = await getNewUserRequest(personnelNumberInput);
      const { results, message } = resp.data
      toast.success(message);
      setUserRequestData(results)
    } catch (error) {
      toast.error(error.response.data.message);
      setUserRequestData(null)
    }
    finally {
      setIsLoading(false);
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: NewUserSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <>
      <div className="d-flex align-items-center justify-content-center my-4">
        <strong className="text-primary text-underline c-pointer" onClick={toggleModal}>New User Request</strong>
      </div>
      <Modal
        className="modal modal-md fade"
        id="exampleModal1"
        show={isModalOpen}
        onHide={() => toggleModal()}
        size="lg"
        centered={true}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="text-primary fw-bold fs-5" id="exampleModalLabel1">
              {isPrintingOldForm ? "Print Existing Request" : "New User Request"}
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal()}
            ></button>
          </div>
          <div className="modal-body">
            {isPrintingOldForm ? <div style={{ minHeight: "40vh" }}>
              <div className="mb-3 d-flex  align-items-center">
                <button
                  type="button"
                  className="btn btn-outline-primary btn-circle"
                  onClick={toggleOldForm}
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </button>
                <div className="form-group flex-fill px-3">
                  <input
                    type="text"
                    className="form-control"
                    id="personnel_number"
                    name="personnel_number"
                    placeholder="Enter Your Personnel Number"
                    value={personnelNumberInput}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleGetData()
                      }
                    }}
                    onChange={(e) => setPersonnelNumberInput(e.target.value)}
                  />
                </div>
                <div className="">
                  <button type="button" onClick={handleGetData} className="btn btn-primary" disabled={isLoading}>
                    {isLoading ? (<span><Spinner size="sm" className="me-2" />Submiting...</span>) : "Submit"}
                  </button>
                </div>
              </div>
              {userRequestData !== null ? (
                <div className="my-2">
                  <div className="row mb-3">
                    <label htmlFor="full_name" className="col-form-label col-sm-3">
                      Full Name<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="full_name"
                        name="full_name"
                        placeholder="Enter Full Name"
                        defaultValue={userRequestData.full_name}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="department" className="col-form-label col-sm-3">
                      Department<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="department"
                        name="department"
                        placeholder="Enter Department Name"
                        defaultValue={userRequestData.department}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="designation" className="col-form-label col-sm-3">
                      Designation<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="designation"
                        placeholder="Enter Designation"
                        name="designation"
                        defaultValue={userRequestData.designation}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label
                      htmlFor="personnel_number"
                      className="col-form-label col-sm-3">
                      Personnel No. <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        className="form-control"
                        id="personnel_number"
                        placeholder="Enter Personnel Number"
                        name="personnel_number"
                        defaultValue={userRequestData.personnel_number}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="phone_number" className="col-form-label col-sm-3">
                      Phone Number <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="number"
                        className="form-control"
                        id="phone_number"
                        placeholder="Enter Phone Number"
                        name="phone_number"
                        defaultValue={userRequestData.phone_number}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <label htmlFor="email" className="col-form-label col-sm-3">
                      Email <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="Enter Email"
                        name="email"
                        defaultValue={userRequestData.email}
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-center mt-2 flex-column">
                    <button onClick={() => {
                      printContent(userRequestData)
                    }} className="btn btn-primary me-1 w-25">
                      Print
                    </button>
                  </div>

                  <div className="mt-4">
                    <h4>Note:</h4>
                    <ul>
                      <li className="my-2">🔯 PLEASE FILL THE ABOVE FORM AND SEND IT TO DESIGN DEPARTMENT THROUGH CONCERNED HOD VIA "E-NOTE SHEET".</li>
                      <li className="my-2">🔯 INITIALLY THE LOGIN ID & PASSWORD SHALL BE  "RSP PERSONNEL NO." OF THE USER.</li>
                    </ul>
                  </div>
                </div>
              ) : null}
            </div> :
              <Form onSubmit={handleSubmit}>
                <div className="row mb-3">
                  <label htmlFor="full_name" className="col-form-label col-sm-3">
                    Full Name<span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="full_name"
                      name="full_name"
                      placeholder="Enter Full Name"
                      value={values.full_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.full_name && touched.full_name ? (
                      <div className="text-danger small mt-1">
                        {errors.full_name}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row mb-3">
                  <label htmlFor="department" className="col-form-label col-sm-3">
                    Department<span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="department"
                      name="department"
                      placeholder="Enter Department Name"
                      value={values.department}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.department && touched.department ? (
                      <div className="text-danger small mt-1">
                        {errors.department}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row mb-3">
                  <label htmlFor="designation" className="col-form-label col-sm-3">
                    Designation<span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="designation"
                      placeholder="Enter Designation"
                      name="designation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.designation}
                    />
                    {errors.designation && touched.designation ? (
                      <div className="text-danger small mt-1">
                        {errors.designation}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row mb-3">
                  <label
                    htmlFor="personnel_number"
                    className="col-form-label col-sm-3">
                    Personnel No. <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="number"
                      className="form-control"
                      id="personnel_number"
                      placeholder="Enter Personnel Number"
                      name="personnel_number"
                      value={values.personnel_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.personnel_number && touched.personnel_number ? (
                      <div className="text-danger small mt-1">
                        {errors.personnel_number}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row mb-3">
                  <label htmlFor="phone_number" className="col-form-label col-sm-3">
                    Phone Number <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="number"
                      className="form-control"
                      id="phone_number"
                      placeholder="Enter Phone Number"
                      name="phone_number"
                      value={values.phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.phone_number && touched.phone_number ? (
                      <div className="text-danger small mt-1">
                        {errors.phone_number}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row mb-3">
                  <label htmlFor="email" className="col-form-label col-sm-3">
                    Email <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Enter Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <div className="text-danger small mt-1">
                        {errors.email}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center mt-2 flex-column">
                  <button type="submit" className="btn btn-primary me-1 w-25" disabled={isLoading}>
                    {isLoading ? (<span><Spinner size="sm" className="me-2" />Printing...</span>) : "Print"}
                  </button>

                  <button type="submit" className="btn btn-primary tp-btn mt-3 w-50" onClick={toggleOldForm}>
                    Print Existing Request
                  </button>
                </div>

                <div className="mt-4">
                  <h4>Note:</h4>
                  <ul>
                      <li className="my-2">🔯 PLEASE FILL THE ABOVE FORM AND SEND IT TO DESIGN DEPARTMENT THROUGH CONCERNED HOD VIA "E-NOTE SHEET".</li>
                      <li className="my-2">🔯 INITIALLY THE LOGIN ID & PASSWORD SHALL BE  "RSP PERSONNEL NO." OF THE USER.</li>
                    </ul>
                </div>
              </Form>
            }
          </div>
        </div>
      </Modal>
    </>
  );
}


export default NewUserRequest;
