import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const PieChart = ({ level, series, title, isLoading }) => {
  const navigate = useNavigate()
  const SINameObj = {
    "SIR": "sir",
    "STABILITY CERTIFICATE": "stability_certificate",
    "COMPLIANCE": "compliance"
  }
  
  const documentObj = {
    "MANUALS": "MANUALS", 
    "RB":"REFERENCE BOOK",
    "TD":"TENDER DOCUMENT", 
    "CATALOUGE":"CATALOUGE", 
    "TC":"TECHNICAL CALCULATION", 
    "TS":"TECHNICAL SPECIFICATION", 
    "TR":"TECHNICAL REPORT", 
    "PR":"PROJECT REPORT", 
    "PSD":"PROJECT SUBMITTED DRAWINGS"
  }
  const handleRedirect = (type) => {
    if (title === "Drawing Count") {
      setTimeout(() => {
        navigate(`/drawing?drawing_type=${type}`)
      }, 0);
    } else if (title === "Standard Count") {
      setTimeout(() => { navigate(`/standards?standard_type=${type}`) }, 0)
    } else if (title === "Document Count") {
      setTimeout(() => { navigate(`/documents?manual_type=${documentObj[type]}`) }, 0)
    } else if (title === "SI Count") {
      setTimeout(() => { navigate(`/si?type=${SINameObj[type]}`) }, 0)
    }
  }

  const options = {
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const type = config.w.config.labels[config.dataPointIndex];
          handleRedirect(type)
        },
      },
    },
    labels: level,
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 300
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    legend: {
      show: false,
      position: 'bottom',
      horizontalAlign: 'center'
    }
  };

  return (
    <div className="card">
      <div className="card-header border-0 pb-0">
        <h4 className="mb-0 text-center">{title}</h4>
      </div>
      <div className="card-body  p-0">
        <div id="overiewChartdoc">
          {isLoading ? <div className="d-flex align-items-center justify-content-center">
            <h3 className="text-primary">Loading .. <Spinner size="sm" /></h3>
          </div> : null}
          <ReactApexChart
            options={options}
            series={series}
            type="pie"
            width={300}
          />
        </div>
      </div>
    </div>
  );
};

export default PieChart;
