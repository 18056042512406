import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal, Form, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { updateUserPassword } from "../../../services/UserService";
import { changePasswordSchema } from "../../constant/schemas/ChangePassword";

function ChangePassword() {
  const initialValues = {
    new_password: "",
    confirmPassword: "",
  };
  const [showPassword, setShowPassword] = useState({
    newPass: false,
    newCPass: false,
  });

  const [submitLoading,setSubmitLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();

  const handleTogglePassword = (name) => {
    setShowPassword({ ...showPassword, [name]: !showPassword[name] });
  };

  const formikProps = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: async (values) => {
      const { confirmPassword, new_password } = values;
      if(!submitLoading){
        try {
          setSubmitLoading(true)
          const resp = await updateUserPassword(id, confirmPassword, new_password);
          toast.success(resp.data.message);
          toggleModal(false);
          setSubmitLoading(false)
          formikProps.resetForm();
        } catch (error) {
          toast.error(error.response.data.message);
          setSubmitLoading(false)
        }
      }
    },
  });

  const toggleModal = (show) => {
    if (!show) {
      formikProps.resetForm();
    }
    setShowModal(show);
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formikProps;

  return (
    <>
      <button
        className="btn btn-danger btn-sm cursor-pointer fw-bolder"
        onClick={() => toggleModal(true)}
      >
        Change Password
      </button>
      <Modal
        className="modal fade modal-sm"
        id="exampleModal1"
        show={showModal}
        centered={true}
        onHide={() => toggleModal(false)}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Change User Password
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal(false)}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="mb-3">
                  <label htmlFor="new_password" className="form-label">
                    New Password <span className="text-danger">*</span>
                  </label>
                  <div className="input-group ">
                    <input
                      type={showPassword.newPass ? "text" : "password"}
                      className="form-control border border-end-0"
                      id="new_password"
                      placeholder="Enter New Password"
                      name="new_password"
                      autoComplete="new-password"
                      value={values.new_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <button
                      className="btn border border-start-0 border-light-subtle pb-2 "
                      type="button"
                      onClick={() => handleTogglePassword("newPass")}
                    >
                      {showPassword.newPass ? (
                        <i className="fa-regular fa-eye-slash"></i>
                      ) : (
                        <i className="fa-regular fa-eye"></i>
                      )}
                    </button>
                  </div>
                  {errors.new_password && touched.new_password ? (
                    <div className="text-danger small mt-1">
                      {errors.new_password}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <label htmlFor="confirmPassword" className="form-label">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <div className="input-group ">
                    <input
                      type={showPassword.newCPass ? "text" : "password"}
                      className="form-control border border-end-0"
                      id="confirmPassword"
                      placeholder="Enter Confirm Password"
                      name="confirmPassword"
                      autoComplete="new-password"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <button
                      className="btn border border-start-0 border-light-subtle pb-2 "
                      type="button"
                      onClick={() => handleTogglePassword("newCPass")}
                    >
                      {showPassword.newCPass ? (
                        <i className="fa-regular fa-eye-slash"></i>
                      ) : (
                        <i className="fa-regular fa-eye"></i>
                      )}
                    </button>
                  </div>
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <div className="text-danger small mt-1">
                      {errors.confirmPassword}
                    </div>
                  ) : null}
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                disabled={submitLoading}
              >
              {submitLoading ? (<span><Spinner size="sm" className="me-2" />Updating...</span>) : "Update Password"}
              </button>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ChangePassword;
