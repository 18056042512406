import React, { useState, useEffect } from 'react'
import { getOpenNoticeList } from '../../../services/NoticeService';

const NoticeMarquee = () => {
    const [data, setData] = useState('');
    useEffect(() => {
        getOpenNoticeList(1, 10)
            .then((resp) => {
                setData(resp.data.results);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data.message);
                } else {
                    console.log(error);
                }
            });
    }, []);

    return data ?
        <div className="card ms-2 my-1">
            <div className="border-bottom pt-2  pb-1">
                <span className='d-flex align-items-center justify-content-start'>
                    <i className="fa-solid fa-bullhorn fa-beat ms-2 text-danger"></i>
                    <span className='d-flex align-items-center justify-content-center flex-fill'>

                        <strong className='text-center text-primary fw-bold'>NOTICE</strong>
                    </span>
                </span>
            </div>
            <div className="py-2 px-3 text-danger">
                <marquee
                    direction="up"
                    height="120px"
                    behavior="scroll"
                    scrollamount="2"
                    className="fs-5 fw-semibold">{data}
                </marquee>
            </div>
        </div> : null

}

export default NoticeMarquee