import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import DynamicSelectInput from "../../../constant/SelectInput";
import { toast } from "react-toastify";

const TenderDocumentForm = ({ handleSubmit, isLoading }) => {
  const [manuals, setManuals] = useState({
    manual_number: "",
    department: "",
    unit: "",
    description: "",
    file: "",
  });

  const [manualsInputError, setManualsInputError] = useState({
    manual_number: false,
  });

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const file = file_list[0];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (file_ext !== "PDF") {
        toast.error("Please choose a valid PDF file.");
      } else {
        setManuals({ ...manuals, file: file });
      }
    }
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist);
  };

  const handleSelectChange = (name, value, _) => {
    setManuals({ ...manuals, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setManuals({ ...manuals, [name]: value });

    if (name === "manual_number") {
      setManualsInputError({
        ...manualsInputError,
        manual_number: value.trim() === "",
      });
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (manuals.manual_number.trim() === "") {
      setManualsInputError({ ...manualsInputError, manual_number: true });
    } else {
      handleSubmit(e, manuals);
    }
  };

  return (
    <Form onSubmit={handleSubmitForm}>
      <div className="mb-3">
        <label htmlFor="manual_number" className="form-label">
          TD Number <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className={`form-control ${manualsInputError.manual_number && "is-invalid"
            }`}
          id="manual_number"
          placeholder="Enter TD Number"
          name="manual_number"
          value={manuals.manual_number}
          onChange={handleChange}
        />
        {manualsInputError.manual_number && (
          <div className="invalid-feedback mt-2">TD No. is required.</div>
        )}
      </div>

      <div className="mb-3">
        <label htmlFor="department_id" className="form-label">
          Department
        </label>
        <DynamicSelectInput
          setParentInputValue={handleSelectChange}
          endpoint_name="search/department"
          name={"department_id"}
          id={"department_id"}
          placeholder={"Select Department"}
          isRequired={true}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="unit" className="form-label">
          Unit
        </label>
        <DynamicSelectInput
          setParentInputValue={handleSelectChange}
          endpoint_name="search/unit"
          name={"unit_id"}
          id={"unit_id"}
          placeholder={"Select Unit"}
          isRequired={true}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          Description
        </label>
        <textarea
          type="text"
          className="form-control"
          id="description"
          placeholder="Enter Description"
          name="description"
          value={manuals.description}
          onChange={handleChange}
        ></textarea>
      </div>
      <div className="mb-3">
        <label htmlFor="pdfFile" className="form-label">
          Upload File
        </label>
        <input
          type="file"
          className="form-control"
          id="file"
          name="file"
          onChange={handleFileInputChange}
          accept=".pdf"
        />
      </div>
      <div className="d-flex align-items-center justify-content-center mt-2">
        <button
          type="submit"
          disabled={isLoading}
          className="btn btn-primary me-1 w-50">
          {isLoading ? (<span><Spinner size="sm" className="me-2" />Creating...</span>) : "Create"}
        </button>
      </div>
    </Form>
  );
};

export default TenderDocumentForm;
