import React from "react";
import TableLoaderEffect from "../../../constant/TableLoader";
import { useNavigate } from "react-router-dom";
import { viewManulPDFFile } from "..";
import FileDownloader from "../DownloadBtn";
const maxWidthStyle300 = {
  maxWidth: "30rem",
  minWidth: "10rem",
  whiteSpace: "normal",
  display: "block",
};

const CatalougePage = ({ data, isLoading, userRole }) => {
  const navigate = useNavigate();


  return (
    <>
      <div>
        <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
          <div
            id="employee-tbl_wrapper"
            className="dataTables_wrapper no-footer"
          >
            <table
              id="empoloyees-tblwrapper"
              className="table ItemsCheckboxSec dataTable no-footer mb-0 table-striped"
            >
              <thead>
                <tr>
                  <th>Catalouge Number</th>
                  <th>Source</th>
                  {userRole !== "User" && <th>Status</th>}
                  <th>Description</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <TableLoaderEffect colLength={userRole === "User" ? 4 : 5} />
                ) : (
                  data.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <span
                          onClick={() => navigate(`/documents/${item.id}`)}
                          className="text-primary cursor-pointer fw-bolder"
                        >
                          {item.manual_no}
                        </span>
                      </td>
                      <td> {item.source}</td>
                      {userRole !== "User" &&
                        <td>
                          {item.is_approved === true ? (
                            <span className={`badge light border-0 badge-success badge-sm`}>APPROVED</span>
                          ) : (
                            <span className={`badge light border-0 badge-info badge-sm`}>PENDING</span>
                          )}
                        </td>
                      }
                      <td>
                        <span style={maxWidthStyle300}>{item.description}</span>
                      </td>
                      <td>
                      {item.is_file ? <FileDownloader id={item.id} fileName={item.is_file} /> : "File NP"}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CatalougePage;
