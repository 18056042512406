import React, { useState, useEffect } from "react";
import { getNotice, createNotice } from "../../../services/NoticeService";
import { StaticSelectInput } from "../../constant/SelectInput";
import Pagination from "../../constant/Pagination";
import { Modal, Form, Spinner, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";

function Notice() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [notice, setNotice] = useState("");

  useEffect(() => {
    setIsLoading(true);
    getNotice()
      .then((resp) => {
        setNotice(resp.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
        setIsLoading(false);
      });
  }, []);


  const handleNoticeChange = (e) => {
    const { value } = e.target;
    setNotice(value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitLoading(true);
      const resp = await createNotice({notice});
      const resp_data = resp.data.results;
      toast.success(resp.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  return (
    <div className="card p-4 pt-3">
      <div className="d-flex align-items-center">
        <h4 className="heading fw-bold me-3">Notice</h4>
      </div>
      <Form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-xl-12">
                <textarea
                  style={{ height: "15rem" }}
                  id="notice"
                  name="notice"
                  className="form-control mb-3"
                  placeholder="Enter Notice"
                  value={notice}
                  onChange={handleNoticeChange}
                ></textarea>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-block btn-sm btn-primary mt-3 float-end"
              disabled={isSubmitLoading}
            >
              {isSubmitLoading ? (
                <span>
                  <Spinner variant="light" size="sm" className="me-2" />
                  Submit...
                </span>
              ) : (
                "Submit"
              )}
            </button>
          </Form>
    </div>

  );
}

export default Notice;
