import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import {
    updateStandard,
  } from "../../../../services/StandardService";  
  import { formatFileSize } from "../../../../utils";
import DynamicSelectInput from "../../../constant/SelectInput";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function EditIPSSModal({ data, setData, userRole }) {
  const [standards, setStandards] = useState({
    id: "",
    standard_number: "",
    title: "",
    file_availability: "",
    description: "",
    file:"",
  });
  const [showModal, setShowModal] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const toggleModal = () => {
    if (showModal) {
      setStandards(standards);
    } else {
      setStandards({
        id: data.id,
        standard_number: data.standard_no,
        title: data.title ? data.title.id : "",
        file_availability: data.file_availability? "YES" : "NO",
        description: data.description || "",
        file: "",
        file_data:data.file,
      });
    }
    setShowModal(!showModal);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStandards({ ...standards, [name]: value });
  };

  const handleFileChange = (e) => {
    const value = e.target.value;
    setStandards({ ...standards, file_availability: value });
}

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const file = file_list[0];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (file_ext !== "PDF") {
        toast.error("Please choose a valid PDF file.");
      } else {
        setStandards({ ...standards, file: file });
      }
    }
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist);
  };

  const handleSelectChange = (name, value, _) => {
    setStandards({ ...standards, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitLoading(true);
      const reqBody = new FormData();
      Object.entries(standards).forEach(([fieldName, value]) => {
        if(fieldName !== "file_data"){
          reqBody.append(fieldName, value);
        }
      });
      reqBody.append("standard_type", "IPSS");
      const resp = await updateStandard(reqBody);
      const {results, message} = resp.data
      setData(results);
      toast.success(message);
      toggleModal();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
  };

  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm cursor-pointer fw-bolder"
        onClick={toggleModal}
      >
        <i className="fa-solid fa-pen-to-square"></i> Edit
      </button>
      <Modal
        show={showModal}
        onHide={toggleModal}
        className="modal fade modal-lg"
        placement="end"
      >
        <div className="modal-content">
          <div className="modal-header border-bottom">
            <h5 className="modal-title" id="#gridSystemModal">
              Edit IPSS
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {standards && (
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="mb-3">
                    <label htmlFor="standard_number" className="form-label">
                      IPSS Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="standard_number"
                      placeholder="Enter IPSS Number"
                      name="standard_number"
                      value={standards.standard_number}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="title" className="form-label">
                      Title
                    </label>
                    <DynamicSelectInput
                      setParentInputValue={handleSelectChange}
                      endpoint_name="search/ipsstitle"
                      name={"title"}
                      id={"title"}
                      placeholder={"Select Title"}
                      isRequired={true}
                      defaultValue={data.title ? 
                          {value: data.title.id,
                          label: data.title.title
                        } : null}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="description"
                      placeholder="Enter Description"
                      name="description"
                      value={standards.description}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <label>File Available</label>
                    <br />
                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="file_availability"
                        id="yes_file_availability"
                        value="YES"
                        checked={standards.file_availability === "YES"}
                        onChange={handleFileChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="yes_file_availability"
                      >
                        YES
                      </label>
                    </div>
                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="file_availability"
                        id="no_file_availability"
                        value="NO"
                        checked={standards.file_availability === "NO"}
                        onChange={handleFileChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="no_file_availability"
                      >
                        NO
                      </label>
                    </div>
                  </div>
                  {standards.file_availability === "YES" && (
                    <div class="mb-3">
                      <label for="file" class="form-label">
                      {standards.file_data ? "Upload New File" : "Upload File"} <span className="text-danger">*</span>
                        {standards.file_data ? (
                          <>
                            <span className="ms-2 me-2 text-dark">
                              {standards.file_data.name}
                            </span>
                            <span className="text-dark">
                              {formatFileSize(standards.file_data.size)}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleFileInputChange}
                        accept=".pdf"
                      />
                    </div>
                  )}
                  
                </div>
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <button type="submit" className="btn btn-primary me-1 w-50" disabled={isSubmitLoading}>
                  {isSubmitLoading ? (<span><Spinner size="sm" className="me-2" />Updating...</span>) : "Update"}
                  </button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </Modal>

    </>
  );
}

export default EditIPSSModal;
