import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import RSNGroupSelectInput from "./RSNGroupSelect";
import DynamicSelectInput from "../../../constant/SelectInput";
import { toast } from "react-toastify";

function RSNForm({ handleSubmit, isLoading }) {
  const [standards, setStandards] = useState({
    standard_number: "",
    rsn_volume: "",
    group: "",
    no_of_sheet: "",
    description: "",
    file: "",
  });

  const [standardsInputError, setStandardsInputError] = useState({
    standard_number: false,
  });

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const file = file_list[0];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (file_ext !== "PDF") {
        toast.error("Please choose a valid PDF file.");
      } else {
        setStandards({ ...standards, file: file });
      }
    }
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist);
  };

  const handleSelectChange = (name, value, _) => {
    setStandards({ ...standards, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStandards({ ...standards, [name]: value });

    if (name === "standard_number") {
      setStandardsInputError({
        ...standardsInputError,
        standard_number: value.trim() === "",
      });
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (standards.standard_number.trim() === "") {
      setStandardsInputError({ ...standardsInputError, standard_number: true });
    } else {
      handleSubmit(e, standards);
    }
  };

  return (
    <Form onSubmit={handleSubmitForm}>
      <div className="row">
        <div className="col-xl-6 col-sm-12">
          <div className="mb-3">
            <label htmlFor="standard_number" className="form-label">
              RSN Number <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${
                standardsInputError.standard_number && "is-invalid"
              }`}
              id="standard_number"
              placeholder="Enter RSN Number"
              name="standard_number"
              value={standards.standard_number}
              onChange={handleChange}
            />
            {standardsInputError.standard_number && (
              <div className="invalid-feedback mt-2">RSN No. is required.</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="rsn_volume" className="form-label">
              Volume
            </label>
            <DynamicSelectInput
              setParentInputValue={handleSelectChange}
              endpoint_name="search/rsn_volume"
              name={"rsn_volume"}
              id={"rsn_volume"}
              placeholder={"Select Volume"}
              isRequired={true}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Group</label>
            <RSNGroupSelectInput
              volume_id={standards.rsn_volume}
              setParentInputValue={handleSelectChange}
              defaultValue={null}
            />
          </div>
        </div>

        <div className="col-xl-6 col-sm-12 mx-auto">
          <div className="mb-3">
            <label htmlFor="no_of_sheet" className="form-label">
              No. Of Sheet
            </label>
            <input
              type="text"
              className="form-control"
              id="no_of_sheet"
              placeholder="Enter No. Of Sheet"
              name="no_of_sheet"
              value={standards.no_of_sheet}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <textarea
              type="text"
              className="form-control"
              id="description"
              placeholder="Enter Description"
              name="description"
              value={standards.description}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="mb-3">
            <label htmlFor="pdfFile" className="form-label">
              Upload File
            </label>
            <input
              type="file"
              className="form-control"
              id="file"
              name="file"
              onChange={handleFileInputChange}
              accept=".pdf"
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-2">
        <button
          type="submit"
          disabled={isLoading}
          className="btn btn-primary me-1 w-50"
        >
          {isLoading ? (
            <span>
              <Spinner size="sm" className="me-2" />
              Creating...
            </span>
          ) : (
            "Create"
          )}
        </button>
      </div>
    </Form>
  );
}

export default RSNForm;
