import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?\d{4}$/;

export const editUserSchema = Yup.object({
  email: Yup.string().email().required("Please enter email"),
  phone_number: Yup.string()
    .min(10)
    .max(10)
    .matches(phoneRegExp, "Phone number is not valid")
    .required("Please enter phone number"),
  designation: Yup.string().min(2).max(25).required("Please enter designation"),
});
