import React from "react";
import { Row, Col } from "react-bootstrap";

import logo from "../../../images/logo/logo.png";
import bg6 from "../../../images/background/bgilu.jpg";
import leftLogo from "../../../images/logo/MeraSAILMeraGauravbg.png";
import rightLogo from "../../../images/logo/Greatplacetoworkwithbg.png";

import NoticeScroller from "./NoticeScroller";
import ImageSlider from "./ImageSlider";
import LoginPage from "./Login";
import MiniDashboardCountPage from "./MiniDahsbordCount";

function HomePage() {

    return (
        <div className="home-containter"
            style={{ backgroundImage: `linear-gradient(#04233b, #2ebf91), url(${bg6})` }}>
            <div className="header-logo">
                <div className="d-flex align-items-center">
                    <img
                        src={rightLogo}
                        alt=""
                        width={"100px"}
                        height={"100px"}
                        className="width-200 light-logo img"
                    />
                </div>
                <div className="d-flex align-items-center">
                    <img
                        src={logo}
                        alt=""
                        width={"100px"}
                        height={"100px"}
                        className="width-200 light-logo img me-4"
                    />
                    <div className="text-center text-primary"><span style={{ fontSize: "2.5rem" }}>
                        ROURKELA STEEL PLANT</span> <br /><span className="fs-1 text-secondary">Design Department</span>
                    </div>
                </div>

                <div className="d-flex align-items-center">
                    <img
                        src={leftLogo}
                        alt=""
                        width={"100px"}
                        height={"100px"}
                        className="width-200 light-logo img"
                    />
                </div>
            </div>
            
            <div className="main-content">
                <Row className="main-content-row">
                    <Col className="" lg={2}>
                        <MiniDashboardCountPage />
                    </Col>

                    <Col className="" lg={7}>
                        <ImageSlider />
                    </Col>

                    <Col className="" lg={3}>
                        <LoginPage />
                    </Col>
                </Row>
            </div>
            <div className="home-footer">
                <NoticeScroller />
            </div>
        </div>
    );
}

export default HomePage;