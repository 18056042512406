import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner, Collapse } from "react-bootstrap";
import { archiveSIRList, removeArchiveSIRList, permanentDeleteSIRList } from "../../../services/SIService";
import TableLoaderEffect from "../../constant/TableLoader";
import DynamicSelectInput from "../../constant/SelectInput";

const maxWidthStyle300 = {
  maxWidth: "30rem",
  minWidth: "10rem",
  whiteSpace: "normal",
  display: "block"
}


const ArchiveSIRPage = () => {
  const [sirList, setSIRList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openADSearch, setOpenADSearch] = useState(false);
  const [advanceFilterInput, setAdvanceFilterInput] = useState({
    description: "",
    department_id: "",
    unit_id: "",
  });
  const [isClearAll, setIsClearAll] = useState(false);
  const [selectedSIR, setSelectedSIR] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isShowArchiveForm, setIsShowArchiveForm] = useState(false);
  const [isShowDeleteForm, setIsShowDeleteForm] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitLoadingDel, setIsSubmitLoadingDel] = useState(false);


  const navigate = useNavigate();
  useEffect(() => {
    setIsLoading(true);
    archiveSIRList(searchQuery,
      advanceFilterInput.department_id,
      advanceFilterInput.unit_id,
      advanceFilterInput.description
    ).then((resp) => {
      setSIRList(resp.data.results);
      setIsLoading(false);
      setIsSearchLoading(false)
    })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
          setIsSearchLoading(false)
        } else {
          console.log(error);
        }
      });
    }, [searchQuery, advanceFilterInput.description,
      advanceFilterInput.department_id,
      advanceFilterInput.unit_id]);
      
      
      const handleSearchChange = (e) => {
        const { value } = e.target;
        if (value) {
          setIsSearchLoading(true)
        }
        setSearchQuery(value);
      };
      

      const toggleADSearch = () => {
        setOpenADSearch(!openADSearch);
      };
      
      const toggleArchiveForm = () => {
        setIsShowArchiveForm(!isShowArchiveForm);
      } 
      
      const toggleDeleteForm = () => {
        setIsShowDeleteForm(!isShowDeleteForm);
      };
      
      
      const toggleCheckbox = (id) => {
        setSelectedSIR((prevSelected) => {
          if (prevSelected.includes(id)) {
            return prevSelected.filter((item) => item !== id);
          } else {
            return [...prevSelected, id];
          }
        });
      };
      
      const handleSelectAll = () => {
        if (selectedSIR.length === sirList.length) {
          setSelectedSIR([]);
        } else {
          setSelectedSIR(sirList.map((item) => item.id));
        }
      };
      
      const handleSelectChange = (name, value, _) => {
        const newFilter = { ...advanceFilterInput, [name]: value }
        setAdvanceFilterInput(newFilter);
        setIsClearAll(false)
      };
      
      const handleArchiveSubmit = () => {
        if(isSubmitLoadingDel || isSubmitLoading){
          return 
        }
        setIsSubmitLoading(true);
        removeArchiveSIRList({ sir_id_list: selectedSIR }).then((resp) => {
          toast.success(resp.data.message);
          setIsSubmitLoading(false);
          setSelectedSIR([])
          toggleArchiveForm()
          navigate('/si')
        })
        .catch((error) => {
          if (error.response) {
          toast.error(error.response.data.message);
          console.log(error.response.data.message);
          setIsSubmitLoading(false);
          setSelectedSIR([])
          toggleArchiveForm()
        } else {
          console.log(error);
        }
      });
  }


  const handleDeleteSubmit = () => {
    if(isSubmitLoadingDel || isSubmitLoading){
      return 
    }
    setIsSubmitLoadingDel(true);
    permanentDeleteSIRList({ sir_id_list: selectedSIR }).then((resp) => {
      setIsSubmitLoadingDel(false);
      setSelectedSIR([])
      toggleDeleteForm()
      toast.success(resp.data.message);
      navigate('/si')
    })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
          setIsSubmitLoadingDel(false);
          setSelectedSIR([])
          toggleDeleteForm()
        } else {
          console.log(error);
        }
      });
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    const newFilter = { ...advanceFilterInput, [name]: value }
    setAdvanceFilterInput(newFilter);
    setIsClearAll(false)
  };

  const handleClear = () => {
    setIsClearAll(true)
    setAdvanceFilterInput({
      description: "",
      department_id: "",
      unit_id: "",
    });
  };



  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="px-3 py-3">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-start align-items-sm-center flex-column flex-sm-row">
                    <div className="d-flex  align-items-center">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-circle"
                        onClick={() => navigate("/dashboard")}
                      >
                        <i className="fa-solid fa-arrow-left"></i>
                      </button>
                      <h5 className="mb-0 ms-2 fw-bold">Archived STRUCTURAL INSPECTION REPORT</h5>
                    </div>
                    <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                      <div className="me-3 mb-3 mb-md-0">
                        <div className="input-group tp-input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            value={searchQuery}
                            aria-describedby="basic-search"
                            onChange={handleSearchChange}
                          />
                          <div className="input-group-text" id="basic-search">
                            {isSearchLoading && (
                              <Spinner variant="primary" size="sm" />
                            )}
                            {!isSearchLoading &&
                              searchQuery.length > 0 ? (
                              <i
                                className="fa-solid fa-xmark c-pointer text-primary"
                                onClick={() => {
                                  setSearchQuery("");
                                }}
                              ></i>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <button
                        className="btn btn-outline-primary me-2 mb-3 mb-md-0"
                        onClick={toggleADSearch}
                      >
                        Advance Search
                      </button>
                    </div>
                  </div>
                  <div className="mt-4">
                    <Collapse in={openADSearch} className="border rounded px-3 pt-3">
                      <div id="collapse-example">
                        <div className="row">
                          <div className="col-lg-2 col-md-4 col-sm-6 ">
                            <DynamicSelectInput
                              setParentInputValue={handleSelectChange}
                              endpoint_name="search/department"
                              labelHint={false}
                              isClearable={true}
                              label={"Department"}
                              name={"department_id"}
                              id={"department_id"}
                              isSetEmpty={isClearAll}
                            />
                          </div>
                          <div className="col-lg-2 col-md-4 col-sm-6 ">
                            <DynamicSelectInput
                              setParentInputValue={handleSelectChange}
                              endpoint_name="search/unit"
                              labelHint={false}
                              isClearable={true}
                              label={"Unit"}
                              name={"unit_id"}
                              id={"unit_id"}
                              isSetEmpty={isClearAll}
                            />
                          </div>
                          <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="form-group">
                              <label htmlFor="description" className="form-label">
                                Description
                              </label>
                              <textarea
                                className="form-control"
                                id="description"
                                placeholder="Enter Description"
                                name="description"
                                value={advanceFilterInput.description}
                                onChange={handleChange}
                              ></textarea>
                            </div>
                          </div>


                          <div className="col-lg-1 col-md-3 col-sm-6 d-flex align-items-center">
                            <button type="button" className="btn btn-sm btn-outline-danger" onClick={handleClear}>
                              Clear All
                            </button>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>

                  {selectedSIR.length > 0 &&
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="bg-success-light rounded p-2">
                          <h4 className="text-center">Total <strong>{selectedSIR.length}</strong> SIR selected.</h4>
                          {isShowArchiveForm ? <div className="px-3">
                            <p className="text-center fw-bold text-success">Are you sure, you want to unarchive the selected SIR.</p>
                            <center className="mt-3 mb-2">
                              <button className="btn btn-success btn-sm me-2"
                                disabled={isSubmitLoading}
                                onClick={handleArchiveSubmit}>
                                {isSubmitLoading ? (
                                  <span>
                                    <Spinner size="sm" className="me-2" />Confirm...
                                  </span>
                                ) : "Confirm"}
                              </button>
                              <button className="btn btn-outline-danger btn-sm"
                                disabled={isSubmitLoading}
                                onClick={toggleArchiveForm}>Cancel</button>
                            </center>
                          </div> : <>
                            <p className="text-center fw-bold text-success">To unarchive selected SIR, please click below to continue.</p>
                            <center>
                              <button className="btn btn-success btn-sm" onClick={toggleArchiveForm}>Continue</button>
                            </center>
                          </>}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="bg-danger-light rounded p-2">
                          <h4 className="text-center">Total <strong>{selectedSIR.length}</strong> SIR selected.</h4>
                          {isShowDeleteForm ? <div className="px-3">
                            <p className="text-center text-danger fw-bold">Are you sure, you want to permanently delete the selected SIR.</p>
                            <center className="mt-3 mb-2">
                              <button className="btn btn-primary btn-sm me-2"
                                disabled={isSubmitLoadingDel}
                                onClick={handleDeleteSubmit}>
                                {isSubmitLoadingDel ? (
                                  <span>
                                    <Spinner size="sm" className="me-2" />Confirm...
                                  </span>
                                ) : "Confirm"}
                              </button>
                              <button className="btn btn-outline-danger btn-sm"
                                disabled={isSubmitLoadingDel}
                                onClick={toggleDeleteForm}>Cancel</button>
                            </center>
                          </div> : <>
                            <p className="text-center fw-bold text-danger">To permanently delete selected SIR, please click below to continue.</p>
                            <center>
                              <button className="btn btn-danger btn-sm" onClick={toggleDeleteForm}>Continue</button>
                            </center>
                          </>}
                        </div>
                      </div>
                    </div>
                  }

                </div>

                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                  <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <table
                      id="empoloyees-tblwrapper"
                      className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                      <thead>
                        <tr>
                          <th>
                            <div className="form-check custom-checkbox checkbox-primary">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={selectedSIR.length === sirList.length && sirList.length !== 0}
                                onChange={handleSelectAll}
                              />
                            </div>
                          </th>
                          <th>SIR Number</th>
                          <th>Department</th>
                          <th>Unit</th>
                          <th>Description</th>
                          <th>Archive Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <TableLoaderEffect colLength={6} />
                        ) : (
                          sirList.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <div className="form-check custom-checkbox checkbox-primary">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={selectedSIR.includes(item.id)}
                                    onChange={() => toggleCheckbox(item.id)}
                                  />
                                </div>
                              </td>
                              <td>{item.sir_number}</td>
                              <td>{item.department?.name || ""}</td>
                              <td>{item.unit?.name || ""}</td>
                              <td> <span style={maxWidthStyle300}>{item.default_description}</span></td>
                              <td> <span style={maxWidthStyle300}>{item.archive_reason}</span></td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default ArchiveSIRPage;
