import React, { useState, useRef } from "react";
import { formatFileSize } from "../../../utils";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { bulkUploadDrawingFile } from "../../../services/DrawingService";


function UploadDrawingFiles() {
  const [selectedFile, setSelectedFile] = useState([])
  const [errorMessages, setErrorMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  const isValidFileName = (fileName) => {
    const regex = /^(PDR|CDBR|PS|RS|FDR|MISC)-.+?(SH\d+)?\.(TIF|DWG|PDF|TIFF)$/;
    return regex.test(fileName.toUpperCase());
  }

  const validFileName = (filelist) => {
    const fileNameList = []
    for (let file of filelist) {
      if (isValidFileName(file.name.toUpperCase())) {
        fileNameList.push(file)
      }
    }
    return fileNameList
  }

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const filelist = validFileName(file_list)
      if (filelist.length === 0) {
        toast.error("choose a valid file")
      }
      setSelectedFile([...filelist, ...selectedFile]);
    }
  }

  const fileInputRef = useRef(null);
  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files || [];
    handleFiles(droppedFile)
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist)
  };

  const handleFileRemove = (e, index) => {
    const updatedFiles = [...selectedFile]; // Create a copy of the current files array
    const removedfile = updatedFiles.splice(index, 1);
    const file_name = removedfile[0].name
    setSelectedFile(updatedFiles);
    if (errorMessages.length > 0) {
      const updatedErrorMessage = errorMessages.filter(item => !item.includes(file_name))
      setErrorMessages(updatedErrorMessage)
    }
    e.stopPropagation();
  }

  const handleUpload = async () => {
    try {
      setIsLoading(true)
      const reqBody = new FormData()
      selectedFile.forEach(item => {
        reqBody.append("file_list", item)
      })
      const resp = await bulkUploadDrawingFile(reqBody);
      toast.success(resp.data.message);
      setSelectedFile([])
    } catch (error) {
      if (error.response) {
        const { message, results } = error.response.data;
        setErrorMessages(results);
        toast.error(message);
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h5>Upload Drawing Files</h5>
              </div>
              <div className="form-group">
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  className={`dropzone ${selectedFile.length === 0 && 'no-files'}`}
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                >
                  <input
                    type="file"
                    ref={fileInputRef}
                    multiple
                    accept=".pdf, .dwg, .tif, .tiff"
                    onChange={handleFileInputChange}
                    style={{ display: "none" }}
                  />
                  {selectedFile.length ? (
                    <div className="file-list">
                      {selectedFile.map((item, index) => (
                        <div key={index} className="file-item">
                          <span className="file-name">{item.name} <small>{formatFileSize(item.size)}</small></span>
                          <span className="file-remove" onClick={(e) => handleFileRemove(e, index)}>
                            <i className="fa-regular fa-circle-xmark text-danger"></i>
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : <p>Drag and drop files here, or click to select files<br />only <strong>PDF ,DWG ,TIF</strong> files are allowed.</p>}
                </div>
                {errorMessages.length > 0 && (
                  <div className="d-flex align-items-start flex-column mt-4">
                    {errorMessages.map((item, index) => (
                      <h6 className="text-danger" key={index}>{item}</h6>
                    ))}
                  </div>
                )}

                <div className="d-flex align-items-center justify-content-center mt-4">
                  <button className="btn btn-primary btn-block w-50"
                    onClick={handleUpload}
                    disabled={isLoading || !selectedFile.length}
                  >{isLoading && (<Spinner size="sm" className="ms-2" />)} Upload</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadDrawingFiles;

