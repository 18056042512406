import axiosInstance from "./AxiosInstance";
const apiNotice = "api/notice";
const apiSlider = "api/slider";

export function getNotice() {
  return axiosInstance.get(`${apiNotice}`);
}

export function createNotice(data) {
  return axiosInstance.post(apiNotice, data);
}

export function updateNotice(data) {
  return axiosInstance.put(apiNotice, data);
}

export function deleteNotice(id) {
  return axiosInstance.delete(`${apiNotice}/${id}`);
}

export function getSliderList(page, page_size, is_published) {
  return axiosInstance.get(
    `${apiSlider}?page=${page}&page_size=${page_size}&publish=${is_published}`
  );
}

export function createSlider(data) {
  return axiosInstance.post(apiSlider, data);
}

export function updateSlider(data) {
  return axiosInstance.put(apiSlider, data);
}

export function deleteSlider(id) {
  return axiosInstance.delete(`${apiSlider}/${id}`);
}

export function getOpenNoticeList() {
  return axiosInstance.get("api/open_notice");
}

export function getOpenSliderList() {
  return axiosInstance.get("api/open_slider");
}

export function getOpenCountDashbaord() {
  return axiosInstance.get("api/open_count_dashboard");
}
