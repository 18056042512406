import axiosInstance from "./AxiosInstance";
const apiUrl = "api/user";  

export function getUserList(page, page_size, query) {
  return axiosInstance.get(
    `${apiUrl}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getUser(id) {
  return axiosInstance.get(`${apiUrl}/${id}`);
}

export function createUser(data) {
  return axiosInstance.post(apiUrl, data);
}

export function updateUser(data) {
  return axiosInstance.put(apiUrl, data);
}

export function deleteUser(id) {
  return axiosInstance.delete(`${apiUrl}/${id}`);
}

export function updateUserStatus(data) {
  return axiosInstance.post('api/user_status_update', data);
}

export function updateUserPassword(id,c_password,password) {
  return axiosInstance.post(
    `api/user_change_password`,{
      id: id,
      c_password: c_password,
      password: password
    }
  );
}

export function updateUserProfilePassword(data) {
  return axiosInstance.put("api/auth/profile",data);
}

export function updateUserProfile(data) {
  return axiosInstance.patch("api/auth/profile",data);
}

export function updateUserProfilePhoto(data) {
  return axiosInstance.post("api/auth/profile",data);
}


export function getUserDrawingLogList(user_id, page, page_size, query) {
  return axiosInstance.get(
    `api/user_drawing_log/${user_id}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getUserStandardLogList(user_id, page, page_size, query) {
  return axiosInstance.get(
    `api/user_standard_log/${user_id}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getUserManualLogList(user_id, page, page_size, query) {
  return axiosInstance.get(
    `api/user_manual_log/${user_id}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getUserSIRLogList(user_id, page, page_size, query) {
  return axiosInstance.get(`api/user_sir_log/${user_id}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getUserStabilityCertificateLogList(user_id, page, page_size, query) {
  return axiosInstance.get(`api/user_stability_certificate_log/${user_id}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getUserComplianceLogList(user_id, page, page_size, query) {
  return axiosInstance.get(`api/user_compliance_log/${user_id}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getUserLoginActivityList(user_id, page, page_size, query) {
  return axiosInstance.get(
    `api/user_login_activity/${user_id}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

// for login user

export function getCurrentUserDrawingLogList(page, page_size, query) {
  return axiosInstance.get(`api/user_drawing_log?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getCurrentUserStandardLogList(page, page_size, query) {
  return axiosInstance.get(`api/user_standard_log?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getCurrentUserManualLogList(page, page_size, query) {
  return axiosInstance.get(`api/user_manual_log?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getCurrentUserSIRLogList(page, page_size, query) {
  return axiosInstance.get(`api/user_sir_log?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getCurrentUserStabilityCertificateLogList(page, page_size, query) {
  return axiosInstance.get(`api/user_stability_certificate_log?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getCurrentUserComplianceLogList(page, page_size, query) {
  return axiosInstance.get(`api/user_compliance_log?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getCurrentUserLoginActivityList(page, page_size, query) {
  return axiosInstance.get( `api/user_login_activity?page=${page}&page_size=${page_size}&query=${query}`);
}


export function getNewUserRequestList(page, page_size, query) {
  return axiosInstance.get(
    `api/new_user_request?page=${page}&page_size=${page_size}&query=${query}`
  );
}
export function getTotalUserList(query) {
  return axiosInstance.get(`api/total_user?query=${query}`);
}

export function getOnlineUserList(query) {
  return axiosInstance.get(`api/online_user?query=${query}`);
}

export function getNewUserRequest(personnel_number) {
  return axiosInstance.get(`api/create_new_user_request/${personnel_number}`);
}

export function createNewUserRequest(data) {
  return axiosInstance.post("api/create_new_user_request", data);
}