import axiosInstance from "./AxiosInstance";
const apiUrl = "api/sir";  
const apiSC = "api/stability_certificate"
const apiCompliance = "api/compliance"
const apiDocument = "api/document"

export function getSIRList(page, page_size, query) {
  return axiosInstance.get(
    `${apiUrl}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getSIR(id) {
  return axiosInstance.get(`${apiUrl}/${id}`);
}

export function createSIR(data) {
  return axiosInstance.post(apiUrl, data);
}

export function updateSIR(data) {
  return axiosInstance.put(apiUrl, data);
}


export function updateSIRStatus(data) {
  return axiosInstance.post('api/sir_archive', data);
}

export function getSIRLogList(id, page, page_size, query) {
  return axiosInstance.get(
    `api/sir_log/${id}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getBulkSIRList(
  query,
  department_id,
  unit_id
) {
  return axiosInstance.get(
    `api/sir_bulk_archive?query=${query}&department_id=${department_id}&unit_id=${unit_id}`
  );
}

export function updateBulkSIRList(data) {
  return axiosInstance.put("api/sir_bulk_archive", data);
}

export function archiveSIRList(
  query,
  department_id,
  unit_id,
  description,
) {
  return axiosInstance.get(
    `api/sir_archive?query=${query}&department_id=${department_id}&unit_id=${unit_id}&description=${description}`
  );
}

export function removeArchiveSIRList(data) {
  return axiosInstance.put("api/sir_archive", data);
}

export function permanentDeleteSIRList(data) {
  return axiosInstance.post("api/sir_permanent_delete", data);
}

export function approveSIRList() {
  return axiosInstance.get("api/sir_approve");
}

export function approveSIR(data) {
  return axiosInstance.post("api/sir_approve", data);
}

export function bulkApproveSIR(data) {
  return axiosInstance.put("api/sir_approve", data);
}

//Stability Certification
export function getStabilityCertificationList(page, page_size, query) {
  return axiosInstance.get(
    `${apiSC}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getStabilityCertification(id) {
  return axiosInstance.get(`${apiSC}/${id}`);
}

export function createStabilityCertification(data) {
  return axiosInstance.post(apiSC, data);
}

export function updateStabilityCertification(data) {
  return axiosInstance.put(apiSC, data);
}


export function updateStabilityCertificationStatus(data) {
  return axiosInstance.post('api/stability_certificate_archive', data);
}

export function getStabilityCertificationLogList(id, page, page_size, query) {
  return axiosInstance.get(
    `api/stability_certificate_log/${id}?page=${page}&page_size=${page_size}&query=${query}`
  );
}


export function getBulkStabilityCertificationList(
  query,
  department_id,
  unit_id,
) {
  return axiosInstance.get(
    `api/stability_certificate_bulk_archive?query=${query}&department_id=${department_id}&unit_id=${unit_id}`
  );
}

export function updateBulkStabilityCertificationList(data) {
  return axiosInstance.put("api/stability_certificate_bulk_archive", data);
}

export function archiveStabilityCertificationList(
  query,
  department_id,
  unit_id,
) {
  return axiosInstance.get(
    `api/stability_certificate_archive?query=${query}&department_id=${department_id}&unit_id=${unit_id}`
  );
}

export function removeArchiveStabilityCertificationList(data) {
  return axiosInstance.put("api/stability_certificate_archive", data);
}

export function permanentDeleteStabilityCertificationList(data) {
  return axiosInstance.post("api/stability_certificate_permanent_delete", data);
}

export function approveStabilityCertificationList() {
  return axiosInstance.get("api/stability_certificate_approve");
}

export function approveStabilityCertification(data) {
  return axiosInstance.post("api/stability_certificate_approve", data);
}

export function bulkApproveStabilityCertification(data) {
  return axiosInstance.put("api/stability_certificate_approve", data);
}


//Compliance
export function getComplianceList(page, page_size, query) {
  return axiosInstance.get(
    `${apiCompliance}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getCompliance(id) {
  return axiosInstance.get(`${apiCompliance}/${id}`);
}

export function createCompliance(data) {
  return axiosInstance.post(apiCompliance, data);
}

export function updateCompliance(data) {
  return axiosInstance.put(apiCompliance, data);
}


export function updateComplianceStatus(data) {
  return axiosInstance.post('api/compliance_archive', data);
}

export function getComplianceLogList(id, page, page_size, query) {
  return axiosInstance.get(
    `api/compliance_log/${id}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function downloadCompliancePDFFiles(id) {
  return axiosInstance.get(`api/compliance_file_download/${id}`, {
    responseType: "blob",
  });
}

export function getBulkComplianceList(
  query,
  department_id,
  unit_id,
) {
  return axiosInstance.get(
    `api/compliance_bulk_archive?query=${query}&department_id=${department_id}&unit_id=${unit_id}`
  );
}

export function updateBulkComplianceList(data) {
  return axiosInstance.put("api/compliance_bulk_archive", data);
}

export function archiveComplianceList(
  query,
  department_id,
  unit_id,
) {
  return axiosInstance.get(
    `api/compliance_archive?query=${query}&department_id=${department_id}&unit_id=${unit_id}`
  );
}

export function removeArchiveComplianceList(data) {
  return axiosInstance.put("api/compliance_archive", data);
}

export function permanentDeleteComplianceList(data) {
  return axiosInstance.post("api/compliance_permanent_delete", data);
}

export function approveComplianceList() {
  return axiosInstance.get("api/compliance_approve");
}

export function approveCompliance(data) {
  return axiosInstance.post("api/compliance_approve", data);
}

export function bulkApproveCompliance(data) {
  return axiosInstance.put("api/compliance_approve", data);
}


//document
export function getDocumentList(page, page_size, query) {
  return axiosInstance.get(
    `${apiDocument}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getDocument(id) {
  return axiosInstance.get(`${apiDocument}/${id}`);
}

export function createDocument(data) {
  return axiosInstance.post(apiDocument, data);
}

export function updateDocument(data) {
  return axiosInstance.put(apiDocument, data);
}


export function updateDocumentStatus(data) {
  return axiosInstance.post('api/document_archive', data);
}

export function getDocumentLogList(id, page, page_size, query) {
  return axiosInstance.get(
    `api/document_log/${id}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function downloadDocumentPDFFiles(id) {
  return axiosInstance.get(`api/document_file_download/${id}`, {
    responseType: "blob",
  });
}

export function getBulkDocumentList(query) {
  return axiosInstance.get(
    `api/document_bulk_archive?query=${query}`
  );
}

export function updateBulkDocumentList(data) {
  return axiosInstance.put("api/document_bulk_archive", data);
}


export function removeArchiveDocumentList(data) {
  return axiosInstance.put("api/document_archive", data);
}

export function permanentDeleteDocumentList(data) {
  return axiosInstance.post("api/document_permanent_delete", data);
}



