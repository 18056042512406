import {
  LOADING_TOGGLE_ACTION,
  LOGIN_CONFIRMED_ACTION,
  LOGIN_FAILED_ACTION,
  LOGOUT_ACTION,
  UPDATE_PROFILE,
  AUTH_CHECK_CONFIRMED_ACTION,
  AUTH_CHECK_FAILED_CONFIRMED_ACTION,
  NAVTOGGLE,
  GET_VISITOR_COUNT,
  UPDATE_LAST_ACTIVITY_TIME
} from "../actions/AuthActions";

import { toast } from "react-toastify";

const initialState = {
  user: null,
  isCheckingAuth: true,
  lastActivityTime:Date.now(),
  errorMessage: "",
  successMessage: "",
  showLoading: false,
  visitorCount:null
};

export function AuthReducer(state = initialState, action) {
  if (action.type === LOGIN_CONFIRMED_ACTION) {
    return {
      ...state,
      user: action.payload,
      lastActivityTime:Date.now(),
      isCheckingAuth: false,
      showLoading: false,
    };
  } else if (action.type === AUTH_CHECK_CONFIRMED_ACTION) {
    return {
      ...state,
      isCheckingAuth: action.payload,
    };
  }
  else if (action.type === AUTH_CHECK_FAILED_CONFIRMED_ACTION) {
    if(action.payload === null){
      return {
        ...state,
        user: null,
        isCheckingAuth: false,
      };
    }
    return {...state}
  } else if (action.type === UPDATE_PROFILE) {
    return {
      ...state,
      user: { ...state.user, ...action.payload },
      showLoading: false,
    };
  } else if (action.type === LOGOUT_ACTION) {
    return {
      ...state,
      isCheckingAuth: false,
      user: null,
    };
  } else if (action.type === LOGIN_FAILED_ACTION) {
    toast.error(action.payload);
    return {
      ...state,
      showLoading: false,
    };
  } else if (action.type === LOADING_TOGGLE_ACTION) {
    return {
      ...state,
      showLoading: action.payload,
    };
  } else if (action.type === GET_VISITOR_COUNT) {
    return {
      ...state,
      visitorCount: action.payload,
    };
  }
  else if (action.type === UPDATE_LAST_ACTIVITY_TIME) {
    return {
      ...state,
      lastActivityTime: Date.now(),
    };
  }
  return state;
}

const initial = false;
export function toggleMenu(state = initial, action) {
  if (action.type === NAVTOGGLE) {
    return !state;
  }
  return state;
}
