import axiosInstance from "./AxiosInstance";
const apiUrl = "api/drawing_log";

export function getLogList(page, page_size, query, drawing_type, status, user, start_date, end_date) {
  return axiosInstance.get(
    `${apiUrl}?page=${page}&page_size=${page_size}&query=${query}&drawing_type=${drawing_type}&status=${status}&user=${user}&start_date=${start_date}&end_date=${end_date}`
  );
}

  export function downloadLogExcel(query, drawing_type, status, user, start_date, end_date) {
  return axiosInstance.get(
    `api/download_drawing_log_excel?query=${query}&drawing_type=${drawing_type}&status=${status}&user=${user}&start_date=${start_date}&end_date=${end_date}`
  );
}

export function getStandardLogList(page, page_size, query, standard_type, status, user, start_date, end_date) {
  return axiosInstance.get(
    `api/standard_log?page=${page}&page_size=${page_size}&query=${query}&standard_type=${standard_type}&status=${status}&user=${user}&start_date=${start_date}&end_date=${end_date}`
  );
}

  export function downloadStandardLogExcel(query, standard_type, status, user, start_date, end_date) {
  return axiosInstance.get(
    `api/download_standard_log_excel?query=${query}&standard_type=${standard_type}&status=${status}&user=${user}&start_date=${start_date}&end_date=${end_date}`
  );
}

export function getManualLogList(page, page_size, query, manual_type, status, user, start_date, end_date) {
  return axiosInstance.get(
    `api/manual_log?page=${page}&page_size=${page_size}&query=${query}&manual_type=${manual_type}&status=${status}&user=${user}&start_date=${start_date}&end_date=${end_date}`
  );
}

  export function downloadManualLogExcel(query, manual_type, status, user, start_date, end_date) {
  return axiosInstance.get(
    `api/download_manual_log_excel?query=${query}&manual_type=${manual_type}&status=${status}&user=${user}&start_date=${start_date}&end_date=${end_date}`
  );
}

export function getSIRLogList(page, page_size, query, status, user, start_date, end_date) {
  return axiosInstance.get(
    `api/sir_log?page=${page}&page_size=${page_size}&query=${query}&status=${status}&user=${user}&start_date=${start_date}&end_date=${end_date}`
  );
}

  export function downloadSIRLogExcel(query, status, user, start_date, end_date) {
  return axiosInstance.get(
    `api/download_sir_log_excel?query=${query}&status=${status}&user=${user}&start_date=${start_date}&end_date=${end_date}`
  );
}

export function getStabilityCertificateLogList(page, page_size, query, status, user, start_date, end_date) {
  return axiosInstance.get(
    `api/stability_certificate_log?page=${page}&page_size=${page_size}&query=${query}&status=${status}&user=${user}&start_date=${start_date}&end_date=${end_date}`
  );
}

  export function downloadStabilityCertificateLogExcel(query, status, user, start_date, end_date) {
  return axiosInstance.get(
    `api/download_stability_certificate_log_excel?query=${query}&status=${status}&user=${user}&start_date=${start_date}&end_date=${end_date}`
  );
}

export function getComplianceLogList(page, page_size, query, status, user, start_date, end_date) {
  return axiosInstance.get(
    `api/compliance_log?page=${page}&page_size=${page_size}&query=${query}&status=${status}&user=${user}&start_date=${start_date}&end_date=${end_date}`
  );
}


export function getLoginLogReportList(page, page_size, query, status, user, start_date, end_date) {
  return axiosInstance.get(
    `api/login_logout_log?page=${page}&page_size=${page_size}&query=${query}&status=${status}&user=${user}&start_date=${start_date}&end_date=${end_date}`
  );
}

export function downloadLoginLogExcel(query, status, user, start_date, end_date) {
  return axiosInstance.get(
    `api/download_login_logout_log_excel?query=${query}&status=${status}&user=${user}&start_date=${start_date}&end_date=${end_date}`
  );
}

  export function downloadComplianceLogExcel(query, status, user, start_date, end_date) {
  return axiosInstance.get(
    `api/download_compliance_log_excel?query=${query}&status=${status}&user=${user}&start_date=${start_date}&end_date=${end_date}`
  );
}

export function exportDatatoExcel(export_data_type, start_date, end_date) {
  return axiosInstance.get(
    `api/export_data?export_data_type=${export_data_type}&start_date=${start_date}&end_date=${end_date}`
  );
}