import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DynamicSelectInput, {
  StaticSelectInput,
} from "../../constant/SelectInput";
import { useFormik } from "formik";
import { addDrawingSchema } from "../../constant/schemas/DrawingSchema";
import { getDrawingSizeList } from "../../../services/SearchService";
import { createDrawing } from "../../../services/DrawingService";
import AttachFilePage from "./AttachFile";
const createLabelValue = (name) => {
  return { label: name, value: name };
};

function AddDrawingPage() {
  const initialValues = {
    drawing_type: "PDR",
    drawing_number: "",
    supplier_name: "",
    vendor_number: "",
    client_number: "",
    package_number: "",
    drawing_size: "",
    work_order_number: "",
    drawing_file_type: "TIF",
    is_layout: "NO",
    date_of_registration: "",
    certification: "CERTIFIED",
    remarks: "",
    dwg_file: "NO",
    no_of_sheet: 1,
    description: [""],
    volume: "",
    department_id: "",
    unit_id: "",
    pdr_number: "",
    fdr_approved_date: "",
    letter_number: ""
  };
  const [drawingSizeOptions, setDrawingSizeOptions] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [dropZoneProp, setDropZoneProp] = useState({
    drawing_id: "",
    drawing_type: "",
    drawing_file_type: "",
    drawing_number: "",
    no_of_sheet: 1,
    is_dwg_file_present: false
  });
  const [isDropzone, setIsDropzone] = useState(false);
  const [copySelectedDesBox, setCopySelectedDesBox] = useState("");
  const [showCopyDesField, setShowCopyDesField] = useState(false);

  useEffect(() => {
    getDrawingSizeList()
      .then((resp) => {
        setDrawingSizeOptions(
          resp.data.results.map((item) => createLabelValue(item))
        );
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
      });
  }, []);
  const navigate = useNavigate();

  const createEmptyArray = (number) => Array.from({ length: number }, () => "");

  const removeEmptyDescription = (array, numToRemove) => {
    let count = 0;
    return array.filter((item) => {
      if (item === "" && count < numToRemove) {
        count++;
        return false;
      }
      return true;
    });
  };

  const handleDrawingType = (e) => {
    const value = e.target.value
    if (value === "PDR" || value === "CDBR") {
      setValues({ ...values, drawing_type: value, volume: "", pdr_number: "", fdr_approved_date: "", letter_number: "" });
    } else if (value === "RS") {
      setValues({ ...values, drawing_type: value, department_id: "", unit_id: "", pdr_number: "", fdr_approved_date: "", letter_number: "" });
    } else if (value === "PS") {
      setValues({ ...values, drawing_type: value, department_id: "", unit_id: "", volume: "", pdr_number: "", fdr_approved_date: "", letter_number: "" });
    } else if (value === "FDR") {
      setValues({ ...values, drawing_type: value, volume: "", unit_id: "", work_order_number: "", date_of_registration: "", client_number: "", vendor_number: "" });
    } else {
      setValues({ ...values, drawing_type: value, volume: "", pdr_number: "", fdr_approved_date: "", letter_number: "", work_order_number: "", date_of_registration: "", client_number: "", vendor_number: "", package_number: "" });
    }
  }

  const handleNumberChange = (e, operation = null) => {
    let updatedValue;
    if (operation === "increase") {
      updatedValue = parseInt(values.no_of_sheet || 0) + 1;
      setValues({
        ...values,
        no_of_sheet: updatedValue,
        description:
          updatedValue === values.description.length + 1
            ? [...values.description, ""]
            : [...values.description],
      });
    } else if (operation === "decrease" && values.no_of_sheet > 1) {
      updatedValue = parseInt(values.no_of_sheet || 0) - 1;
      setValues({
        ...values,
        no_of_sheet: updatedValue,
        description: removeEmptyDescription(
          values.description,
          values.description.length - updatedValue
        ),
      });
    } else {
      let value = e.target.value;
      if (!isNaN(value)) {
        if (Number(value) <= 0) {
          value = "";
        }
        let description = values.description;
        if (value > 0) {
          value = parseInt(value);
          if (value - description.length > 0) {
            const emptDesc = createEmptyArray(value - description.length);
            description = [...description, ...emptDesc];
          } else {
            description = removeEmptyDescription(
              description,
              description.length - value
            );
          }
        }
        setValues({
          ...values,
          no_of_sheet: value,
          description: description,
        });
      }
    }
  };

  const handleDescriptionChange = (e, index) => {
    const { value } = e.target;
    const descriptionList = [...values.description];
    descriptionList[index] = value;
    setValues({ ...values, description: descriptionList });
  };

  const handleRemoveDescription = (indexToRemove) => {
    setValues({
      ...values,
      description: values.description.filter(
        (_, index) => index !== indexToRemove
      ),
    });
  };

  const toggleCopyField = () => {
    setShowCopyDesField(!showCopyDesField);
  };

  const handleCopyDescription = () => {
    const sourceIndex = parseInt(copySelectedDesBox, 10);
    if (
      !isNaN(sourceIndex) &&
      sourceIndex > 0 &&
      sourceIndex <= values.description.length
    ) {
      const sourceDescription = values.description[sourceIndex - 1];
      const updatedDescription = values.description.map((desc, i) => {
        if (i !== sourceIndex - 1 && !desc.trim()) {
          return sourceDescription;
        }
        return desc;
      });
      setValues({
        ...values,
        description: updatedDescription,
      });
    }
    setCopySelectedDesBox("");
    toggleCopyField();
  };

  const handleSelectChange = (name, value, _) => {
    setValues({ ...values, [name]: value });
  };

  const handleFormSubmit = async (values, action) => {
    if (isSubmitLoading) {
      return false;
    }
    try {
      const shouldParse = (key) => ["package_number", "no_of_sheet"].includes(key);
      const parseValue = (value) =>
        !isNaN(value) && Number.isInteger(value)
          ? parseInt(value)
          : parseFloat(value);

      const reqBody = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
          key,
          shouldParse(key) ? parseValue(value) : value,
        ])
      );
      setIsSubmitLoading(true);
      const resp = await createDrawing(reqBody);
      const resp_data = resp.data.results;
      toast.success(resp.data.message);
      action.resetForm();
      setDropZoneProp({
        drawing_id: resp_data.id,
        drawing_type: resp_data.drawing_type,
        drawing_file_type: resp_data.drawing_file_type,
        drawing_number: resp_data.drawing_number,
        no_of_sheet: resp_data.no_of_sheet,
        is_dwg_file_present: resp_data.is_dwg_file_present,
      });
      setIsDropzone(true);
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
  };

  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: addDrawingSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header ">
                <div className="d-flex ">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-circle"
                    onClick={() => navigate("/drawing")}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                  <h4 className="mb-0 ms-2">Add Drawing</h4>
                </div>
              </div>
              <div className="card-body">
                {!isDropzone ? (
                  <Form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            Drawing Type
                            <strong className="text-danger"> *</strong>
                          </label>
                          <div className="col-sm-9">
                            <div>
                              <div className="form-check custom-checkbox mb-2 form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="drawing_type"
                                  id="PDRRadio"
                                  value="PDR"
                                  checked={values.drawing_type === "PDR"}
                                  onChange={handleDrawingType}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="PDRRadio"
                                >
                                  PDR
                                </label>
                              </div>
                              <div className="form-check custom-checkbox mb-2 form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="drawing_type"
                                  id="CDBRRadio"
                                  value="CDBR"
                                  checked={values.drawing_type === "CDBR"}
                                  onChange={handleDrawingType}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="CDBRRadio"
                                >
                                  CDBR
                                </label>
                              </div>
                              <div className="form-check custom-checkbox mb-2 form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="drawing_type"
                                  id="RSRadio"
                                  value="RS"
                                  checked={values.drawing_type === "RS"}
                                  onChange={handleDrawingType}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="RSRadio"
                                >
                                  RS
                                </label>
                              </div>
                              <div className="form-check custom-checkbox mb-2 form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="drawing_type"
                                  id="PSRadio"
                                  value="PS"
                                  checked={values.drawing_type === "PS"}
                                  onChange={handleDrawingType}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="PSRadio"
                                >
                                  PS
                                </label>
                              </div>
                              <div className="form-check custom-checkbox mb-2 form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="drawing_type"
                                  id="FDRadio"
                                  value="FDR"
                                  checked={values.drawing_type === "FDR"}
                                  onChange={handleDrawingType}
                                />
                                <label
                                  className="form-check-label" htmlFor="FDRadio">
                                  FDR
                                </label>
                              </div>
                              <div className="form-check custom-checkbox mb-2 form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="drawing_type"
                                  id="MISCradio"
                                  value="MISC"
                                  checked={values.drawing_type === "MISC"}
                                  onChange={handleDrawingType}
                                />
                                <label
                                  className="form-check-label" htmlFor="MISCradio">
                                  MISC
                                </label>
                              </div>
                            </div>
                            {errors.drawing_type && touched.drawing_type ? (
                              <div className="text-danger small mt-1">
                                {errors.drawing_type}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group row mb-3">
                          <label
                            htmlFor="drawing_number"
                            className="col-sm-3 col-form-label"
                          >
                            Drawing Number
                            <strong className="text-danger"> *</strong>
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              id="drawing_number"
                              placeholder="Enter Drawing Number"
                              name="drawing_number"
                              onBlur={handleBlur}
                              value={values.drawing_number}
                              onChange={handleChange}
                            />
                            {errors.drawing_number && touched.drawing_number ? (
                              <div className="text-danger small mt-1">
                                {errors.drawing_number}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {["PDR", "CDBR", "FDR", "MISC"].includes(values.drawing_type) && (
                          <div className="form-group row mb-3">
                            <label
                              htmlFor={"department_id"}
                              className="col-sm-3 col-form-label"
                            >
                              Department
                              <strong className="text-danger">{["PDR", "CDBR"].includes(values.drawing_type) && '*'}</strong>
                            </label>
                            <div className="col-sm-9">
                              <DynamicSelectInput
                                parentClassName="mb-0"
                                setParentInputValue={handleSelectChange}
                                endpoint_name="search/department"
                                name={"department_id"}
                                id={"department_id"}
                                placeholder={"Select Department"}
                                isRequired={true}
                              />
                              {errors.department_id &&
                                touched.department_id ? (
                                <div className="text-danger small mt-1">
                                  {errors.department_id}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}

                        {["PDR", "CDBR", "MISC"].includes(values.drawing_type) && (
                          <div className="form-group row mb-3">
                            <label
                              htmlFor={"unit_id"}
                              className="col-sm-3 col-form-label"
                            >
                              Unit <strong className="text-danger"> {values.drawing_type !== "MISC" && '*'}</strong>
                            </label>
                            <div className="col-sm-9">
                              <DynamicSelectInput
                                parentClassName="mb-0"
                                setParentInputValue={handleSelectChange}
                                endpoint_name="search/unit"
                                name={"unit_id"}
                                id={"unit_id"}
                                placeholder={"Select Unit"}
                                isRequired={true}
                              />
                              {errors.unit_id && touched.unit_id ? (
                                <div className="text-danger small mt-1">
                                  {errors.unit_id}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}

                        {values.drawing_type === "FDR" && (
                          <div>
                            <div className="form-group row mb-3">
                              <label
                                htmlFor="pdr_number"
                                className="col-sm-3 col-form-label"
                              >
                                PDR Number
                                <strong className="text-danger"></strong>
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="pdr_number"
                                  placeholder="Enter PDR Number"
                                  name="pdr_number"
                                  onBlur={handleBlur}
                                  value={values.pdr_number}
                                  onChange={handleChange}
                                />
                                {errors.pdr_number && touched.pdr_number ? (
                                  <div className="text-danger small mt-1">
                                    {errors.pdr_number}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                          </div>
                        )}
                        {values.drawing_type === "RS" && (
                          <div className="form-group row mb-3">
                            <label
                              htmlFor={"volume_id"}
                              className="col-sm-3 col-form-label"
                            >
                              Volume <strong className="text-danger"> *</strong>
                            </label>
                            <div className="col-sm-9">
                              <DynamicSelectInput
                                parentClassName="mb-0"
                                setParentInputValue={handleSelectChange}
                                endpoint_name="search/rs_sub_volume"
                                name={"volume"}
                                id={"volume"}
                                placeholder={"Select Volume"}
                                isRequired={true}
                              />
                              {errors.volume && touched.volume ? (
                                <div className="text-danger small mt-1">
                                  {errors.volume}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}

                        {["PDR", "CDBR", "RS", "PS"].includes(values.drawing_type) && (
                          <div className="form-group row mb-3">
                            <label
                              htmlFor="work_order_number"
                              className="col-sm-3 col-form-label"
                            >
                              Work Order Number
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="work_order_number"
                                placeholder="Enter Work Order Number"
                                name="work_order_number"
                                onBlur={handleBlur}
                                value={values.work_order_number}
                                onChange={handleChange}
                              />
                              {errors.work_order_number &&
                                touched.work_order_number ? (
                                <div className="text-danger small mt-1">
                                  {errors.work_order_number}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}

                        <div className="form-group row mb-3">
                          <label
                            htmlFor="supplier_name"
                            className="col-sm-3 col-form-label"
                          >
                            Supplier Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              id="supplier_name"
                              placeholder="Enter Supplier Name"
                              name="supplier_name"
                              value={values.supplier_name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.supplier_name && touched.supplier_name ? (
                              <div className="text-danger small mt-1">
                                {errors.supplier_name}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {["PDR", "CDBR", "RS", "PS"].includes(values.drawing_type) && (
                          <>
                            <div className="form-group row mb-3">
                              <label
                                htmlFor="vendor_number"
                                className="col-sm-3 col-form-label"
                              >
                                Vendor Number
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="vendor_number"
                                  placeholder="Enter Vender Number"
                                  name="vendor_number"
                                  value={values.vendor_number}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.vendor_number && touched.vendor_number ? (
                                  <div className="text-danger small mt-1">
                                    {errors.vendor_number}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="form-group row mb-3">
                              <label
                                htmlFor="client_number"
                                className="col-sm-3 col-form-label"
                              >
                                Client Number
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="client_number"
                                  placeholder="Enter Client Number"
                                  name="client_number"
                                  value={values.client_number}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.client_number && touched.client_number ? (
                                  <div className="text-danger small mt-1">
                                    {errors.client_number}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </>
                        )}

                        {["PDR", "CDBR", "RS", "PS", "FDR"].includes(values.drawing_type) && (
                          <div className="form-group row mb-3">
                            <label
                              htmlFor="package_number"
                              className="col-sm-3 col-form-label"
                            >
                              Package Number
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                id="package_number"
                                placeholder="Enter Package Number"
                                name="package_number"
                                value={values.package_number}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {errors.package_number && touched.package_number ? (
                                <div className="text-danger small mt-1">
                                  {errors.package_number}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )}
                        <div className="form-group row mb-3">
                          <label
                            htmlFor={"drawing_size"}
                            className="col-sm-3 col-form-label"
                          >
                            Size Of Drawing
                            <strong className="text-danger"> *</strong>
                          </label>
                          <div className="col-sm-9">
                            <StaticSelectInput
                              setParentInputValue={handleSelectChange}
                              optionsList={drawingSizeOptions}
                              name={"drawing_size"}
                              id={"drawing_size"}
                              placeholder={"Select Drawing Size"}
                              isRequired={true}
                            />
                            {errors.drawing_size && touched.drawing_size ? (
                              <div className="text-danger small mt-1">
                                {errors.drawing_size}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            File Type
                            <strong className="text-danger"> *</strong>
                          </label>
                          <div className="col-sm-9">
                            <div className="form-check custom-checkbox mb-2 form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="drawing_file_type"
                                id="TIFRadio"
                                value="TIF"
                                checked={values.drawing_file_type === "TIF"}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="TIFRadio"
                              >
                                TIF
                              </label>
                            </div>
                            <div className="form-check custom-checkbox mb-2 form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="drawing_file_type"
                                id="PDFRadio"
                                value="PDF"
                                checked={values.drawing_file_type === "PDF"}
                                onChange={handleChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="PDFRadio"
                              >
                                PDF
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            Attach DWG File
                            <strong className="text-danger"> *</strong>
                          </label>
                          <div className="col-sm-9">
                            <div className="form-check custom-checkbox mb-2 form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="dwg_file"
                                id="yes"
                                value="YES"
                                checked={values.dwg_file === "YES"}
                                onChange={handleChange}
                              />
                              <label className="form-check-label" htmlFor="yes">
                                YES
                              </label>
                            </div>
                            <div className="form-check custom-checkbox mb-2 form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="dwg_file"
                                id="no"
                                value="NO"
                                checked={values.dwg_file === "NO"}
                                onChange={handleChange}
                              />
                              <label className="form-check-label" htmlFor="no">
                                NO
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="form-group row mb-3">
                          <label className="col-sm-3 col-form-label">
                            Is Layout
                            <strong className="text-danger"> *</strong>
                          </label>
                          <div className="col-sm-9">
                            <div className="form-check custom-checkbox mb-2 form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="is_layout"
                                id="yes"
                                value="YES"
                                checked={values.is_layout === "YES"}
                                onChange={handleChange}
                              />
                              <label className="form-check-label" htmlFor="yes">
                                YES
                              </label>
                            </div>
                            <div className="form-check custom-checkbox mb-2 form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="is_layout"
                                id="no"
                                value="NO"
                                checked={values.is_layout === "NO"}
                                onChange={handleChange}
                              />
                              <label className="form-check-label" htmlFor="no">
                                NO
                              </label>
                            </div>
                          </div>
                        </div>


                        {/* display this two filed */}
                        {["PDR", "CDBR", "RS", "PS"].includes(values.drawing_type) && (
                          <>
                            <div className="form-group row mb-3">
                              <label
                                htmlFor="date_of_registration"
                                className="col-sm-3 col-form-label"
                              >
                                Registration Date
                                <strong className="text-danger"> *</strong>
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="date"
                                  className="form-control"
                                  name="date_of_registration"
                                  id="date_of_registration"
                                  value={values.date_of_registration}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.date_of_registration &&
                                  touched.date_of_registration ? (
                                  <div className="text-danger small mt-1">
                                    {errors.date_of_registration}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="form-group row mb-3">
                              <label className="col-sm-3 col-form-label">
                                Certification
                                <strong className="text-danger"> *</strong>
                              </label>
                              <div className="col-sm-9">
                                <div className="form-check custom-checkbox mb-2 form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="certification"
                                    id="certified"
                                    value="CERTIFIED"
                                    checked={values.certification === "CERTIFIED"}
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="certified"
                                  >
                                    CERTIFIED
                                  </label>
                                </div>
                                <div className="form-check custom-checkbox mb-2 form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="certification"
                                    id="not_certified"
                                    value="NOT CERTIFIED"
                                    checked={
                                      values.certification === "NOT CERTIFIED"
                                    }
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="not_certified"
                                  >
                                    NOT CERTIFIED
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {values.drawing_type === "FDR" && (
                          <>
                            <div className="form-group row mb-3">
                              <label
                                htmlFor="fdr_approved_date"
                                className="col-sm-3 col-form-label"
                              >
                                Approved Date
                                <strong className="text-danger"></strong>
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="date"
                                  className="form-control"
                                  name="fdr_approved_date"
                                  id="fdr_approved_date"
                                  value={values.fdr_approved_date}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.fdr_approved_date &&
                                  touched.fdr_approved_date ? (
                                  <div className="text-danger small mt-1">
                                    {errors.fdr_approved_date}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="form-group row mb-3">
                              <label
                                htmlFor="date_of_registration"
                                className="col-sm-3 col-form-label"
                              >
                                Letter No. & Date
                                <strong className="text-danger"></strong>
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="letter_number"
                                  id="letter_number"
                                  placeholder="Enter letter no. & date"
                                  value={values.letter_number}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.letter_number &&
                                  touched.letter_number ? (
                                  <div className="text-danger small mt-1">
                                    {errors.letter_number}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </>
                        )}

                        <div className="form-group row mb-3">
                          <label
                            htmlFor="remarks"
                            className="col-sm-3 col-form-label"
                          >
                            Remarks <strong className="text-danger"> *</strong>
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              type="text"
                              className="form-control"
                              id="remarks"
                              placeholder="Enter Remarks"
                              name="remarks"
                              value={values.remarks}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            ></textarea>
                            {errors.remarks && touched.remarks ? (
                              <div className="text-danger small mt-1">
                                {errors.remarks}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group row mb-3">
                          <label
                            htmlFor="no_of_sheet"
                            className="col-sm-3 col-form-label"
                          >
                            Number Of Sheet
                            <strong className="text-danger"> *</strong>
                          </label>
                          <div className="col-sm-9">
                            <div className="input-group tp-input-group">
                              <input
                                type="text"
                                className="form-control border-end-0"
                                id="no_of_sheet"
                                placeholder="Enter Number of Sheet"
                                name="no_of_sheet"
                                value={values.no_of_sheet}
                                onChange={(e) => handleNumberChange(e)}
                              />

                              <div className="input-group-text px-2 py-0">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-circle tp-btn me-2"
                                  onClick={(e) =>
                                    handleNumberChange(e, "increase")
                                  }
                                >
                                  <i className="fa-solid fa-plus"></i>
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-circle tp-btn"
                                  onClick={(e) =>
                                    handleNumberChange(e, "decrease")
                                  }
                                >
                                  <i className="fa-solid fa-minus"></i>
                                </button>
                              </div>
                            </div>
                            {errors.no_of_sheet && touched.no_of_sheet && (
                              <div className="text-danger small mt-1">
                                {errors.no_of_sheet}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className=""
                          style={{ maxHeight: "120vh", overflowY: "auto" }}
                        >
                          {values.description.map((item, index) => (
                            <div className="form-group mb-3" key={index}>
                              <div className="d-flex align-items-center justify-content-between">
                                <label
                                  htmlFor={`description${index}`}
                                  className="form-label"
                                >
                                  Description of Sheet {index + 1}
                                  <strong className="text-danger"> *</strong>
                                </label>
                                {index === 0 &&
                                  (showCopyDesField ? (
                                    <div className="d-flex align-items-center mb-2">
                                      <input
                                        type="number"
                                        className="form-control me-2 w-50"
                                        id="copyBoxNumber"
                                        placeholder="Description No."
                                        value={copySelectedDesBox}
                                        onChange={(e) =>
                                          setCopySelectedDesBox(e.target.value)
                                        }
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-primary me-2"
                                        onClick={handleCopyDescription}
                                      >
                                        Copy
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-danger"
                                        onClick={toggleCopyField}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-sm tp-btn btn-primary mb-2"
                                      onClick={toggleCopyField}
                                    >
                                      Copy to All
                                    </button>
                                  ))}
                              </div>
                              <div className="input-group">
                                <textarea
                                  type="text"
                                  className="form-control"
                                  id={`description${index}`}
                                  placeholder="Enter Description"
                                  name={`description.${index}`}
                                  value={item}
                                  onChange={(e) =>
                                    handleDescriptionChange(e, index)
                                  }
                                ></textarea>
                                {values.no_of_sheet <
                                  values.description.length && (
                                    <div className="input-group-text px-2 py-0">
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-circle tp-btn"
                                        onClick={() =>
                                          handleRemoveDescription(index)
                                        }
                                      >
                                        <i className="fa-solid fa-xmark"></i>
                                      </button>
                                    </div>
                                  )}
                              </div>
                              {errors.description &&
                                touched.description &&
                                errors.description[index] && (
                                  <div className="text-danger small mt-1">
                                    {errors.description[index]}
                                  </div>
                                )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-center mt-2">
                      <button
                        type="submit"
                        className="btn btn-primary me-1 w-50"
                        disabled={isSubmitLoading}
                      >
                        {isSubmitLoading ? (
                          <span>
                            <Spinner animation="border" size="sm" /> Attach File
                          </span>
                        ) : (
                          "Attach File"
                        )}
                      </button>
                    </div>
                  </Form>
                ) : (
                  <AttachFilePage drawingData={dropZoneProp} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddDrawingPage;
