import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatFileSize } from "../../../utils";
import { Modal, Spinner, Form } from "react-bootstrap"
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getManual,
  approveManual,
} from "../../../services/ManualService";
import { useParams } from "react-router-dom";
import { Placeholder } from "react-bootstrap";
import ManualLogPage from "./ManualLog";
import EditCatalougeModal from "./Catalouge/EditCatalougeModal";
import EditProjectReportModal from "./ProjectReport/EditProjectReportModal";
import EditReferenceBookModal from "./ReferenceBook/EditReferenceBookModal";
import EditCommonManualModal from "./CommonManual/EditCommonManual";
import EditTenderDocumentModal from "./TenderDocument/EditTenderDocumnetModal";
import EditManualModal from "./Manuals/EditManualModal";
import ArchiveManualModal from "./ArchiveManual";
import EditPSDModal from "./ProjectSubmittedDrawings/EditPSDModal";
import FileDownloader from "./DownloadBtn";
const ManualsDetailsLoading = () => {
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="w-100 px-2 mt-3">
            <Placeholder animation="glow">
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
            </Placeholder>
          </div>
        </div>
      </div>
    </div>
  );
};

function ManualDetails({ userRole }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isApprovedLoading, setIsApprovedLoading] = useState(false);
  const [isApprovedCheck, setIsApprovedCheck] = useState(false);


  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    getManual(id)
      .then((resp) => {
        const resp_data = resp.data.result;
        setData(resp_data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [id]);

  const toggleModal = () => {
    if (showModal) {
      setIsApprovedCheck(false);
      setIsApprovedLoading(false);
    }
    setShowModal(!showModal);
  };

  const handleApprovedSubmit = async (e) => {
    e.preventDefault();
    if (!isApprovedCheck) {
      return;
    }
    try {
      setIsApprovedLoading(true);
      const resp = await approveManual({ id: data.id });
      toast.success(resp.data.message);
      setData({ ...data, is_approved: true });
      toggleModal();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsApprovedLoading(false);
  };

  const ApproveBTN = ({ isApproved = false }) => {
    return (
      <div className="d-flex align-items-center justify-content-between mb-3">
        <span>Status </span>
        {isApproved === true ? (
          <span className={`badge badge-sm ms-2 light border-0 badge-success`}>APPROVED</span>
        ) : (
          <span className={`badge badge-sm ms-2 light border-0 badge-info ${!isApproved && "cursor-pointer"}`}
            onClick={() => {
              if (!isApproved) { toggleModal(); }
            }}
          >PENDING</span>
        )}
      </div>)
  }


  const DownloadPDFComponent = ({ id, file }) => {
    return (
      <div className="col-sm-12 col-md-6 border-start pb-0">
        {!file ? (
          <div className="text-center ">
            No PDF File Present
          </div>
        ) : (
          <div className="text-center mt-5">
            <div className="fs-4 mb-3">View PDF File</div>
            <div className="">
              {file ? (
                <>
                  <span className="ms-2 me-2">{file.name}</span>
                  <span>{formatFileSize(file.size)}</span>
                </>
              ) : null}
            </div>
            <FileDownloader id={id} fileName={file.name} />
          </div>
        )}
      </div>
    )
  }

  const DownloadDWGZipComponent = ({ id, file, file_type, userRole }) => {
    return (
      <div className="col-sm-12 col-md-6 border-start pb-0">
        {!file ? (
          <div className="text-center ">
            No File Present
          </div>
        ) : (
          <div className="text-center mt-5">
            <div className="fs-4 mb-3">Download ZIP File</div>
            <div className="">
              {file ? (
                <>
                  <span className="ms-2 me-2">{file.name}</span>
                  <span>{formatFileSize(file.size)}</span>
                </>
              ) : null}
            </div>
            {userRole !== "User" ? <FileDownloader id={id} fileName={file.name} isDownload={true} />
              : file_type === "PDF" ? <FileDownloader id={id} fileName={file.name} isDownload={true} /> : "Contact Admin"}
          </div>
        )
        }
      </div >
    )
  }

  const DisplayKeyValDiv = ({ heading, value = null }) => {
    return (
      <div className="d-flex align-items-center justify-content-between mb-3">
        <span>{heading} :</span>
        <span className="fw-semibold">{value || "None"}</span>
      </div>)
  }


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5">
            {isLoading ? (
              <ManualsDetailsLoading />
            ) : data ? (
              <div>
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex  align-items-center">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-circle"
                          onClick={() => navigate(`/documents?manual_type=${data.manual_type}`)}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <h4 className="mb-0 ms-2">Document Details</h4>
                      </div>
                      <div>
                        {userRole !== "User" && (
                          data.manual_type === "MANUALS" ? (
                            <EditManualModal data={data} setData={setData} />
                          ) : data.manual_type === "REFERENCE BOOK" ? (
                            <EditReferenceBookModal
                              data={data}
                              setData={setData}
                            />
                          ) : data.manual_type === "TENDER DOCUMENT" ? (
                            <EditTenderDocumentModal
                              data={data}
                              setData={setData}
                            />
                          ) : data.manual_type === "CATALOUGE" ? (
                            <EditCatalougeModal data={data} setData={setData} />
                          ) : data.manual_type === "PROJECT REPORT" ? (
                            <EditProjectReportModal
                              data={data}
                              setData={setData}
                            />
                          ) : data.manual_type === "PROJECT SUBMITTED DRAWINGS" ? (
                            <EditPSDModal
                              data={data}
                              setData={setData}
                            />
                          ) : (
                            <EditCommonManualModal
                              data={data}
                              setData={setData}
                              type={data.manual_type}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {data ? (
                      data.manual_type === "MANUALS" ? (
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <DisplayKeyValDiv heading={"Document Type"} value={data.manual_type} />
                            <DisplayKeyValDiv heading={"Manual Number"} value={data.manual_no} />

                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Supplier :</span>
                              <span className="fw-semibold">
                                {data.supplier || "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Package Number :</span>
                              <span className="fw-semibold">
                                {data.package_no || "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Letter Number :</span>
                              <span className="fw-semibold">
                                {data.letter_no || "None"}
                              </span>
                            </div>

                            <ApproveBTN isApproved={data.is_approved} />

                            <div className="d-flex align-items-centser justify-content-between mb-3">
                              <span>Registration Date :</span>
                              <span className="fw-semibold">
                                {data.registration_date || "None"}
                              </span>
                            </div>
                          </div>
                          <DownloadPDFComponent id={data.id} file={data.file} />

                          <div className="col-sm-12 col-md-12 border-top pt-0">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                              <span className="me-5">Description:</span>
                              <span className="fw-semibold">
                                {data.description || "None"}
                              </span>
                            </div>
                          </div>
                        </div>

                      ) : data.manual_type === "PROJECT REPORT" ? (
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Document Type :</span>
                              <span className="fw-semibold">
                                {data.manual_type}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>PR Number :</span>
                              <span className="fw-semibold">
                                {data.manual_no}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Supplier:</span>
                              <span className="fw-semibold">
                                {data.supplier || "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span className="me-5">Capacity :</span>
                              <span className="fw-semibold">
                                {data.capacity || "None"}
                              </span>
                            </div>
                            <ApproveBTN isApproved={data.is_approved} />
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Year :</span>
                              <span className="fw-semibold">
                                {data.year || "None"}
                              </span>
                            </div>
                          </div>
                          {/* col end */}
                          <DownloadPDFComponent id={data.id} file={data.file} />

                          <div className="col-sm-12 col-md-12 border-top pt-0">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                              <span className="me-5">Remarks:</span>
                              <span className="fw-semibold">
                                {data.remarks || "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                              <span className="me-5">Description:</span>
                              <span className="fw-semibold">
                                {data.description || "None"}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : data.manual_type === "CATALOUGE" ? (

                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Document Type :</span>
                              <span className="fw-semibold">
                                {data.manual_type}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Catalouge Number :</span>
                              <span className="fw-semibold">
                                {data.manual_no}
                              </span>
                            </div>
                            <ApproveBTN isApproved={data.is_approved} />
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Source :</span>
                              <span className="fw-semibold">{data.source || "None"}</span>
                            </div>
                          </div>
                          <DownloadPDFComponent id={data.id} file={data.file} />
                          <div className="col-sm-12 col-md-12 border-top pt-0">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                              <span className="me-5">Description:</span>
                              <span className="fw-semibold">
                                {data.description || "None"}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : data.manual_type === "REFERENCE BOOK" ? (
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Document Type :</span>
                              <span className="fw-semibold">
                                {data.manual_type}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Book Number :</span>
                              <span className="fw-semibold">
                                {data.manual_no}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Editor :</span>
                              <span className="fw-semibold">{data.editor}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Source :</span>
                              <span className="fw-semibold">{data.source}</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Author :</span>
                              <span className="fw-semibold">{data.author}</span>
                            </div>
                            <ApproveBTN isApproved={data.is_approved} />

                          </div>
                          <DownloadPDFComponent id={data.id} file={data.file} />
                          <div className="col-sm-12 col-md-12 border-top pt-0">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                              <span className="me-5">Description:</span>
                              <span className="fw-semibold">
                                {data.description || "None"}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : data.manual_type === "TENDER DOCUMENT" ? (
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Document Type :</span>
                              <span className="fw-semibold">
                                {data.manual_type}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>TD Number :</span>
                              <span className="fw-semibold">
                                {data.manual_no}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Department :</span>
                              <span className="fw-semibold">
                                {data.department
                                  ? data.department.name
                                  : "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Unit :</span>
                              <span className="fw-semibold">
                                {data.unit
                                  ? data.unit.name
                                  : "None"}
                              </span>
                            </div>
                            <ApproveBTN isApproved={data.is_approved} />
                          </div>
                          <DownloadPDFComponent id={data.id} file={data.file} />
                          <div className="col-sm-12 col-md-12 border-top pt-0">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                              <span className="me-5">Description:</span>
                              <span className="fw-semibold">
                                {data.description || "None"}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : data.manual_type === "PROJECT SUBMITTED DRAWINGS" ? (
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Document Type :</span>
                              <span className="fw-semibold">
                                {data.manual_type}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>PSD Number :</span>
                              <span className="fw-semibold">
                                {data.manual_no}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Department :</span>
                              <span className="fw-semibold">
                                {data.department ? data.department.name : "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Unit :</span>
                              <span className="fw-semibold">
                                {data.unit ? data.unit.name : "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Title :</span>
                              <span className="fw-semibold">
                                {data.title || "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>File Type :</span>
                              <span className="fw-semibold">
                                {data.file_type || "None"}
                              </span>
                            </div>
                            <ApproveBTN isApproved={data.is_approved} />
                          </div>
                          <DownloadDWGZipComponent id={data.id} file={data.file} file_type={data.file_type} userRole={userRole} />
                          <div className="col-sm-12 col-md-12 border-top pt-0">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                              <span className="me-5">Description:</span>
                              <span className="fw-semibold">
                                {data.description || "None"}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span className="me-5">Document Type :</span>
                              <span className="fw-semibold">
                                {data.manual_type}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>{data.manual_type} No. :</span>
                              <span className="fw-semibold">
                                {data.manual_no}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Supplier :</span>
                              <span className="fw-semibold">
                                {data.supplier}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Department :</span>
                              <span className="fw-semibold">
                                {data.department ? data.department.name : "None"}
                              </span>
                            </div>
                            <ApproveBTN isApproved={data.is_approved} />
                          </div>
                          <DownloadPDFComponent id={data.id} file={data.file} />
                          <div className="col-sm-12 col-md-12 border-top pt-0">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                              <span className="me-5">Description:</span>
                              <span className="fw-semibold">
                                {data.description || "None"}
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
                {userRole === "SuperAdmin" && (
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-3 d-flex align-items-center justify-content-between">
                        <ArchiveManualModal data={data} setData={setData} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="card">
                <div className="card-body">
                  <h4 className="text-center text-danger">
                    ⚠️ Manual Not Found!
                    <br /> ID {id}
                  </h4>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-7">
            <div className="card">
              <ManualLogPage />
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal fade modal-sm"
        id="exampleModal1"
        show={showModal}
        onHide={toggleModal}
        centered={true}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Approve Standards
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleApprovedSubmit}>
              <div className="mb-3">
                <div className="form-check custom-checkbox mb-2 form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="approve"
                    checked={isApprovedCheck}
                    id="approve"
                    value="Approve"
                    onChange={(e) => setIsApprovedCheck(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="approve">
                    Approve
                  </label>
                </div>
              </div>
              <button
                type="submit"
                disabled={!isApprovedCheck || isApprovedLoading}
                className="btn btn-block btn-sm btn-primary mt-3 float-end">
                {isApprovedLoading && (<Spinner animation="border" size="sm" className="me-2" />)} Approve
              </button>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user?.role || "Admin",
  };
};
export default connect(mapStateToProps)(ManualDetails);
