
import dashboardIcon from '../../images/Icons/dashboardIcon.png'
import standardIcon from '../../images/Icons/standardIcon.png'
import drawingIcon from '../../images/Icons/drawingIcon.png'
import documentIcon from '../../images/Icons/documentIcon.jpg'
import SIIcon from '../../images/Icons/SIIcon.png'
import userIcon from '../../images/Icons/userIcon.png'
import departmentIcon from '../../images/Icons/departmentIcon.png'
import bulkuploadIcon from '../../images/Icons/bulkuploadIcon.png'
import bulkarchiveIcon from '../../images/Icons/bulkarchiveIcon.png'
import MISReportIcon from '../../images/Icons/MISreportIcon.png'
import noticeIcon from '../../images/Icons/noticeIcon.png'
import profileIcon from '../../images/Icons/profileIcon.png'
import logoutIcon from '../../images/Icons/logoutIcon.png'
import clockIcon from '../../images/Icons/clockIcon.png'
export const SVGICON = {
    MenuDrawing: <img src={drawingIcon} alt='dashbard' width="21px" height="21px" className='me-2' />,
    MenuDashboard: <img src={dashboardIcon} alt='dashbard' width="21px" height="21px" className='me-2' />,
    MenuStandard: <img src={standardIcon} alt='dashbard' width="21px" height="21px" className='me-2' />,
    MenuManual: <img src={documentIcon} alt='dashbard' width="21px" height="21px" className='me-2' />,
    MenuSI: <img src={SIIcon} alt='dashbard' width="21px" height="21px" className='me-2' />,
    MenuUsers: <img src={userIcon} alt='dashbard' width="21px" height="21px" className='me-2' />,
    MenuDepartment: <img src={departmentIcon} alt='dashbard' width="21px" height="21px" className='me-2' />,
    MenuBulkUpload: <img src={bulkuploadIcon} alt='dashbard' width="21px" height="21px" className='me-2' />,
    MenuBulkArchive: <img src={bulkarchiveIcon} alt='dashbard' width="21px" height="21px" className='me-2' />,
    MenuNotice: <img src={noticeIcon} alt='dashbard' width="21px" height="21px" className='me-2' />,
    MenuMISReport: <img src={MISReportIcon} alt='dashbard' width="21px" height="21px" className='me-2' />,
    MenuProfile: <img src={profileIcon} alt='dashbard' width="25px" height="25px" />,
    MenuLogout: <img src={logoutIcon} alt='dashbard' width="25px" height="25px" />,
    MenuClock: <img src={clockIcon} alt='dashbard' width="35px" height="35px" />,
}
const appTheme = {
    SVGICON
}
export default appTheme;