import React, { useState } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import { updateManual } from "../../../../services/ManualService";
import DynamicSelectInput from "../../../constant/SelectInput";
import { formatFileSize } from "../../../../utils";
import { toast } from "react-toastify";

function EditCommonManualModal({ data, setData, type }) {
  const [manuals, setManuals] = useState({
    id: "",
    manual_number: "",
    supplier: "",
    department:"",
    description: "",
    file: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const toggleModal = () => {
    if (showModal) {
      setManuals(manuals);
    } else {
      setManuals({
        id: data.id,
        manual_number: data.manual_no,
        supplier: data.supplier || "" ,
        department: data.department ? data.department.department_id : "",
        description: data.description || "" ,
        file: "",
        file_data: data.file,
      });
    }
    setShowModal(!showModal);
  };

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const file = file_list[0];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (file_ext !== "PDF") {
        toast.error("Please choose a valid PDF file.");
      } else {
        setManuals({ ...manuals, file: file });
      }
    }
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setManuals({ ...manuals, [name]: value });
  };
  
  const handleSelectChange = (name, value, _) => {
    setManuals({ ...manuals, [name]: value });
  }; 
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitLoading(true);
      const reqBody = new FormData();
      Object.entries(manuals).forEach(([fieldName, value]) => {
        if(fieldName !== "file_data"){
          reqBody.append(fieldName, value);
        }
      });
      reqBody.append("manual_type", { type });
      const resp = await updateManual(reqBody);
      const { results, message } = resp.data;
      setData(results);
      toast.success(message);
      toggleModal();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
  };

  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm cursor-pointer fw-bolder"
        onClick={toggleModal}
      >
        <i className="fa-solid fa-pen-to-square"></i> Edit
      </button>
      <Modal
        show={showModal}
        onHide={toggleModal}
        className="modal fade modal-lg"
        placement="end"
      >
        <div className="modal-content">
          <div className="modal-header border-bottom">
            <h5 className="modal-title" id="#gridSystemModal">
              Edit {type}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {manuals && (
              <Form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="manual_number" className="form-label">
                    {type} Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="manual_number"
                    placeholder={`Enter ${type} Number`}
                    name="manual_number"
                    value={manuals.manual_number}
                    onChange={handleChange}
                    readonly
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="supplier" className="form-label">
                    Supplier
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="supplier"
                    placeholder="Enter Supplier"
                    name="supplier"
                    value={manuals.supplier}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="department" className="form-label">
                    Department
                  </label>
                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/department"
                    name={"department"}
                    id={"department"}
                    placeholder={"Select Department"}
                    isRequired={true}
                    defaultValue={data.department ? { 
                          value: data.department.department_id,
                          label: data.department.name
                        } : null}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Enter Description"
                    name="description"
                    value={manuals.description}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div class="mb-3">
                  <label for="file" class="form-label">
                    {manuals.file_data ? "Upload New File" : "Upload File"}
                    {manuals.file_data ? (
                      <>
                        <span className="ms-2 me-2 text-dark">
                          {manuals.file_data.name}
                        </span>
                        <span className="text-dark">
                          {formatFileSize(manuals.file_data.size)}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </label>
                  <input
                    class="form-control"
                    type="file"
                    id="file"
                    name="file"
                    onChange={handleFileInputChange}
                    accept=".pdf"
                  />
                </div>
                
                <div className="d-flex align-items-center justify-content-center mt-2">
                <button type="submit" disabled={isSubmitLoading} className="btn btn-primary me-1 w-50">
                {isSubmitLoading ? (<span><Spinner size="sm" className="me-2" />Updating...</span>) : "Update"}
                  </button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditCommonManualModal;
