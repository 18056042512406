import axiosInstance from "./AxiosInstance";

export function getSelectOptionList(
  endPoint,
  page = 1,
  page_size = 20,
  query = ""
) {
  return axiosInstance.get(
    `api/${endPoint}`
  );
}

export function getDrawingSizeList() {
  return axiosInstance.get("api/drawing_size");
}
