import Select from "react-select";
import React, { useState, useEffect } from "react";
import { getSelectOptionList } from "../../../../services/SearchService";

const RSNGroupSelectInput = ({
  volume_id, setParentInputValue, defaultValue, isSetEmpty = false, isClearable = false
}) => {
  const [selectOption, setSelectOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectValue, setSelectValue] = useState(null);

  useEffect(() => {
    if (volume_id) {
      setIsLoading(true);
      getSelectOptionList(`search/rsn_group/${volume_id}`)
        .then((resp) => {
          if (resp) {
            setSelectOption(resp.data.results);
            setSelectValue(defaultValue)
            setIsLoading(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
            setIsLoading(false);
          } else {
            console.log("error",error);
          }
        });
    }
  }, [volume_id]);

  useEffect(() => {
    if (isSetEmpty) {
      setSelectValue(null)
      setSelectOption([])
    }
  }, [isSetEmpty])



  const handleSelectValueChange = (data) => {
    setSelectValue(data);
    if (data) {
      setParentInputValue("group", data.value, data); // Notify parent component of select value change
    } else {
      setParentInputValue("group", "", data); // Notify parent component of select value change
    }
    // Notify parent component of select value change
  };

  return (
    <div>
      <Select
        value={selectValue}
        onChange={(data) => handleSelectValueChange(data)}
        placeholder={"Select Group"}
        isLoading={isLoading}
        isClearable={isClearable}
        id={"rsn_group"}
        name={"rsn_group"}
        options={selectOption}
        classNamePrefix="select"
        className="react-select"
      />
    </div>
  );
};

export default RSNGroupSelectInput;