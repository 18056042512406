import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { editDrawingSchema } from "../../constant/schemas/EditDrawingSchema";
import DynamicSelectInput, {
  StaticSelectInput,
} from "../../constant/SelectInput";
import { Form, Modal, Spinner } from "react-bootstrap";
import { updateDrawing, editDrawing } from "../../../services/DrawingService";
import { useNavigate } from "react-router-dom";
import { getDrawingSizeList } from "../../../services/SearchService";
import ReviseDWGFileUpload from "./ReviseDWGFileUpload";

const initialDrawingEditInput = {
  supplier_name: "",
  vendor_number: "",
  client_number: "",
  revision: "",
  package_number: "",
  drawing_size: "",
  work_order_number: "",
  drawing_file_type: "",
  dwg_file: "",
  drawing_type: "",
  is_layout: "",
  certification: "",
  remarks: "",
  no_of_sheet: "",
  description: [],
  volume: "",
  department_id: "",
  unit_id: "",
  pdr_number: "",
  fdr_approved_date: "",
  letter_number: ""
};

const createLabelValue = (name) => ({ label: name, value: name });

function EditDrawingPage() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [drawingSizeOptions, setDrawingSizeOptions] = useState([]);
  const [copySelectedDesBox, setCopySelectedDesBox] = useState("");
  const [showCopyDesField, setShowCopyDesField] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAttachButton, setIsAttachButton] = useState(false);
  const [isAttachDWG, setIsAttachDWG] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [attachDWGData, setAttachDWGData] = useState({
    drawing_id: "",
    drawing_type: "",
    drawing_number: "",
    no_of_sheet: 1,
  });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getDrawingSizeList()
      .then((resp) => {
        setDrawingSizeOptions(
          resp.data.results.map((item) => createLabelValue(item))
        );
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
      });
  }, []);

  useEffect(() => {
    if (id) {
      setIsLoading(true);

      editDrawing(id)
        .then((resp) => {
          const data = resp.data.results;
          setValues({
            supplier_name: data.supplier_name || "",
            vendor_number: data.vendor_number || "",
            drawing_number: data.drawing_number,
            client_number: data.client_number || "",
            drawing_type: data.drawing_type,
            drawing_file_type: data.drawing_file_type,
            package_number: data.package_number || "",
            drawing_size: data.drawing_size,
            work_order_number: data.work_order_number || "",
            is_layout: data.is_layout ? "YES" : "NO",
            revision: data.revision_version,
            dwg_file: data.is_dwg_file_present ? "YES" : "NO",
            certification: data.certification,
            remarks: data.remarks,
            pdr_number: data.pdr_number || "",
            fdr_approved_date: data.fdr_approved_date || "",
            letter_number: data.letter_number || "",
            volume: data.volume ? data.volume.sub_volume_no : null,
            department_id: data.department
              ? data.department.department_id
              : null,
            unit_id: data.unit ? data.unit.unit_id : null,
            no_of_sheet: data.no_of_sheet,
            description: data.description.map((item) => item),
          });
          setData(data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [id]);

  const createEmptyArray = (number) =>
    Array.from({ length: number }, () => {
      return { id: null, description: "" };
    });

  const removeNullIdAndEmptyDescription = (array, numToRemove) => {
    let count = 0;
    return array.filter((item) => {
      if (item.id === null && item.description === "" && count < numToRemove) {
        count++;
        return false;
      }
      return true;
    });
  };

  const handleNumberChange = (e, operation = null) => {
    let updatedValue;
    if (operation === "increase") {
      updatedValue = parseInt(values.no_of_sheet || 0) + 1;
      setValues({
        ...values,
        no_of_sheet: updatedValue,
        description:
          updatedValue === values.description.length + 1
            ? [...values.description, { id: null, description: "" }]
            : [...values.description],
      });
    } else if (operation === "decrease" && values.no_of_sheet > 1) {
      updatedValue = parseInt(values.no_of_sheet || 0) - 1;
      setValues({
        ...values,
        no_of_sheet: updatedValue,
        description: removeNullIdAndEmptyDescription(
          values.description,
          values.description.length - updatedValue
        ),
      });
    } else {
      let value = e.target.value;
      if (!isNaN(value)) {
        if (Number(value) <= 0) {
          value = "";
        }
        let description = values.description;
        if (value > 0) {
          value = parseInt(value);
          if (value - description.length > 0) {
            const emptDesc = createEmptyArray(value - description.length);
            description = [...description, ...emptDesc];
          } else {
            description = removeNullIdAndEmptyDescription(
              description,
              description.length - value
            );
          }
        }
        setValues({
          ...values,
          no_of_sheet: value,
          description: description,
        });
      }
    }
  };

  const handleDescriptionChange = (e, index) => {
    const { value } = e.target;
    const descriptionList = [...values.description];
    descriptionList[index] = { ...descriptionList[index], description: value };
    setValues({ ...values, description: descriptionList });
  };

  const handleRemoveDescription = (indexToRemove) => {
    setValues({
      ...values,
      description: values.description.filter(
        (_, index) => index !== indexToRemove
      ),
    });
  };

  const toggleCopyField = () => {
    setShowCopyDesField(!showCopyDesField);
  };

  const handleCopyDescription = () => {
    const sourceIndex = parseInt(copySelectedDesBox, 10) - 1;
    const sourceDescription = values.description[sourceIndex]?.description;

    if (sourceDescription) {
      const updatedDescription = values.description.map((item, index) => {
        if (
          index !== sourceIndex &&
          (item.description === null || item.description === "")
        ) {
          return { ...item, description: sourceDescription };
        }
        return item;
      });
      setValues({ ...values, description: updatedDescription });
      setCopySelectedDesBox("");
      toggleCopyField();
    }
  };

  const handleSelectChange = (name, value, _) => {
    setValues({ ...values, [name]: value });
  };

  const handleDwgChange = (e) => {
    const value = e.target.value;
    setValues({ ...values, dwg_file: value });
    const initialDwgFileValue = data.is_dwg_file_present ? "YES" : "NO";

    if (value !== initialDwgFileValue) {
      if (value === "NO") {
        setIsModalOpen(true);
        setIsAttachButton(false);
      } else if (value === "YES") {
        setIsAttachButton(true);
        setIsModalOpen(false);
      }
    } else {
      setIsAttachButton(false);
      setIsModalOpen(false);
    }
  };

  const handleFormSubmit = async (e) => {
    try {
      setIsSubmitLoading(true);
      const resp = await updateDrawing(id, values);
      const resp_data = resp.data.results;
      toast.success(resp.data.message);
      setAttachDWGData({
        drawing_id: resp_data.id,
        drawing_type: resp_data.drawing_type,
        drawing_number: resp_data.drawing_number,
        no_of_sheet: resp_data.no_of_sheet,
      });
      if (isAttachButton) {
        setIsAttachDWG(true)
      }
      else {
        navigate(`/drawing/${id}`);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
  };

  const {
    values,
    errors,
    touched,
    setValues,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialDrawingEditInput,
    validationSchema: editDrawingSchema,
    onSubmit: handleFormSubmit,
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-header ">
                <div className="d-flex align-items-center ">
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-circle me-2"
                    onClick={() => navigate(`/drawing/${id}`)}
                  >
                    <i className="fa-solid fa-arrow-left"></i>
                  </button>
                  <h4 className="mb-0 me-3">Edit Drawing</h4>
                </div>
              </div>
              <div className="card-body">
                {!isAttachDWG ? (
                  isLoading ? (
                    <div className="my-5 text-center">
                      
                      <h1>Loading....</h1>
                    </div>
                  ) : (
                    data && (
                      <Form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-xl-6">
                            <div className="form-group row mb-3">
                              <label className="col-sm-3 col-form-label">
                                Drawing Type
                                <strong className="text-danger"> *</strong>
                              </label>
                              <div className="col-sm-9">
                                <div>
                                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="drawing_type"
                                      id="PDRRadio"
                                      value="PDR"
                                      checked={values.drawing_type === "PDR"}
                                      disabled={true}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="PDRRadio"
                                    >
                                      PDR
                                    </label>
                                  </div>
                                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="drawing_type"
                                      id="CDBRRadio"
                                      value="CDBR"
                                      checked={values.drawing_type === "CDBR"}
                                      disabled={true}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="CDBRRadio"
                                    >
                                      CDBR
                                    </label>
                                  </div>
                                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="drawing_type"
                                      id="RSRadio"
                                      value="RS"
                                      checked={values.drawing_type === "RS"}
                                      disabled={true}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="RSRadio"
                                    >
                                      RS
                                    </label>
                                  </div>
                                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="drawing_type"
                                      id="PSRadio"
                                      value="PS"
                                      checked={values.drawing_type === "PS"}
                                      disabled={true}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="PSRadio"
                                    >
                                      PS
                                    </label>
                                  </div>
                                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="drawing_type"
                                      id="FDRadio"
                                      value="FDR"
                                      checked={values.drawing_type === "FDR"}
                                      disabled={true}
                                    />
                                    <label
                                      className="form-check-label" htmlFor="FDRadio">
                                      FDR
                                    </label>
                                  </div>
                                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="drawing_type"
                                      id="MISCradio"
                                      value="MISC"
                                      checked={values.drawing_type === "MISC"}
                                      disabled={true}
                                    />
                                    <label
                                      className="form-check-label" htmlFor="MISCradio">
                                      MISC
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row mb-3">
                              <label
                                htmlFor="drawing_number"
                                className="col-sm-3 col-form-label"
                              >
                                Drawing Number
                                <strong className="text-danger"> *</strong>
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="drawing_number"
                                  placeholder="Enter Drawing Number"
                                  name="drawing_number"
                                  value={values.drawing_number}
                                  disabled={true}
                                  readOnly={true}
                                />
                              </div>
                            </div>

                            {["PDR", "CDBR", "FDR", "MISC"].includes(values.drawing_type) && (
                              <div className="form-group row mb-3">
                                <label
                                  htmlFor={"department_id"}
                                  className="col-sm-3 col-form-label"
                                >
                                  Department
                                  <strong className="text-danger">{["PDR", "CDBR"].includes(values.drawing_type) && '*'}</strong>
                                </label>
                                <div className="col-sm-9">
                                  <DynamicSelectInput
                                    parentClassName="mb-0"
                                    setParentInputValue={handleSelectChange}
                                    endpoint_name="search/department"
                                    name={"department_id"}
                                    id={"department_id"}
                                    placeholder={"Select Department"}
                                    isRequired={true}
                                    defaultValue={data.department ? {
                                      value: data.department.department_id,
                                      label: data.department.name,
                                    } : null}
                                  />
                                  {errors.department_id &&
                                    touched.department_id ? (
                                    <div className="text-danger small mt-1">
                                      {errors.department_id}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )}

                            {["PDR", "CDBR", "MISC"].includes(values.drawing_type) && (
                              <div className="form-group row mb-3">
                                <label
                                  htmlFor={"unit_id"}
                                  className="col-sm-3 col-form-label"
                                >
                                  Unit <strong className="text-danger"> {values.drawing_type !== "MISC" && '*'}</strong>
                                </label>
                                <div className="col-sm-9">
                                  <DynamicSelectInput
                                    parentClassName="mb-0"
                                    setParentInputValue={handleSelectChange}
                                    endpoint_name="search/unit"
                                    name={"unit_id"}
                                    id={"unit_id"}
                                    placeholder={"Select Unit"}
                                    isRequired={true}
                                    defaultValue={data.unit ? {
                                      value: data.unit.unit_id,
                                      label: data.unit.name,
                                    } : null}
                                  />
                                  {errors.unit_id && touched.unit_id ? (
                                    <div className="text-danger small mt-1">
                                      {errors.unit_id}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )}

                            {values.drawing_type === "FDR" && (
                              <div>
                                <div className="form-group row mb-3">
                                  <label
                                    htmlFor="pdr_number"
                                    className="col-sm-3 col-form-label"
                                  >
                                    PDR Number
                                    <strong className="text-danger"></strong>
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="pdr_number"
                                      placeholder="Enter PDR Number"
                                      name="pdr_number"
                                      onBlur={handleBlur}
                                      value={values.pdr_number}
                                      onChange={handleChange}
                                    />
                                    {errors.pdr_number && touched.pdr_number ? (
                                      <div className="text-danger small mt-1">
                                        {errors.pdr_number}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>

                              </div>
                            )}
                            {values.drawing_type === "RS" && (
                              <div className="form-group row mb-3">
                                <label
                                  htmlFor={"volume_id"}
                                  className="col-sm-3 col-form-label"
                                >
                                  Volume <strong className="text-danger"> *</strong>
                                </label>
                                <div className="col-sm-9">
                                  <DynamicSelectInput
                                    parentClassName="mb-0"
                                    setParentInputValue={handleSelectChange}
                                    endpoint_name="search/rs_sub_volume"
                                    name={"volume"}
                                    id={"volume"}
                                    placeholder={"Select Volume"}
                                    isRequired={true}
                                    defaultValue={data.volume ? {
                                      value: data.volume.sub_volume_no,
                                      label: data.volume.sub_volume_no,
                                    } : null}
                                  />
                                  {errors.volume && touched.volume ? (
                                    <div className="text-danger small mt-1">
                                      {errors.volume}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )}

                            {["PDR", "CDBR", "RS", "PS"].includes(values.drawing_type) && (
                              <div className="form-group row mb-3">
                                <label
                                  htmlFor="work_order_number"
                                  className="col-sm-3 col-form-label"
                                >
                                  Work Order Number
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="work_order_number"
                                    placeholder="Enter Work Order Number"
                                    name="work_order_number"
                                    onBlur={handleBlur}
                                    value={values.work_order_number}
                                    onChange={handleChange}
                                  />
                                  {errors.work_order_number &&
                                    touched.work_order_number ? (
                                    <div className="text-danger small mt-1">
                                      {errors.work_order_number}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )}

                            <div className="form-group row mb-3">
                              <label
                                htmlFor="supplier_name"
                                className="col-sm-3 col-form-label"
                              >
                                Supplier Name
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="supplier_name"
                                  placeholder="Enter Supplier Name"
                                  name="supplier_name"
                                  value={values.supplier_name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.supplier_name && touched.supplier_name ? (
                                  <div className="text-danger small mt-1">
                                    {errors.supplier_name}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            {["PDR", "CDBR", "RS", "PS"].includes(values.drawing_type) && (
                              <>
                                <div className="form-group row mb-3">
                                  <label
                                    htmlFor="vendor_number"
                                    className="col-sm-3 col-form-label"
                                  >
                                    Vendor Number
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="vendor_number"
                                      placeholder="Enter Vender Number"
                                      name="vendor_number"
                                      value={values.vendor_number}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors.vendor_number && touched.vendor_number ? (
                                      <div className="text-danger small mt-1">
                                        {errors.vendor_number}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="form-group row mb-3">
                                  <label
                                    htmlFor="client_number"
                                    className="col-sm-3 col-form-label"
                                  >
                                    Client Number
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="client_number"
                                      placeholder="Enter Client Number"
                                      name="client_number"
                                      value={values.client_number}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors.client_number && touched.client_number ? (
                                      <div className="text-danger small mt-1">
                                        {errors.client_number}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </>
                            )}

                            {["PDR", "CDBR", "RS", "PS", "FDR"].includes(values.drawing_type) && (
                              <div className="form-group row mb-3">
                                <label
                                  htmlFor="package_number"
                                  className="col-sm-3 col-form-label"
                                >
                                  Package Number
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="package_number"
                                    placeholder="Enter Package Number"
                                    name="package_number"
                                    value={values.package_number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  {errors.package_number && touched.package_number ? (
                                    <div className="text-danger small mt-1">
                                      {errors.package_number}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            )}
                            <div className="form-group row mb-3">
                              <label
                                htmlFor="revision"
                                className="col-sm-3 col-form-label"
                              >
                                Revision
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="number"
                                  className="form-control"
                                  id="revision"
                                  placeholder="Enter Revision"
                                  name="revision"
                                  value={values.revision}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                {errors.revision && touched.revision ? (
                                  <div className="text-danger small mt-1">
                                    {errors.revision}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="form-group row mb-3">
                              <label
                                htmlFor={"drawing_size"}
                                className="col-sm-3 col-form-label"
                              >
                                Size Of Drawing
                                <strong className="text-danger"> *</strong>
                              </label>
                              <div className="col-sm-9">
                                <StaticSelectInput
                                  setParentInputValue={handleSelectChange}
                                  optionsList={drawingSizeOptions}
                                  name={"drawing_size"}
                                  id={"drawing_size"}
                                  placeholder={"Select Drawing Size"}
                                  isRequired={true}
                                  defaultValue={{
                                    value: data.drawing_size,
                                    label: data.drawing_size,
                                  }}
                                />
                                {errors.drawing_size && touched.drawing_size ? (
                                  <div className="text-danger small mt-1">
                                    {errors.drawing_size}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="form-group row mb-3">
                              <label className="col-sm-3 col-form-label">
                                File Type
                                <strong className="text-danger"> *</strong>
                              </label>
                              <div className="col-sm-9">
                                <div className="form-check custom-checkbox mb-2 form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="drawing_file_type"
                                    id="TIFRadio"
                                    value="TIF"
                                    checked={values.drawing_file_type === "TIF"}
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="TIFRadio"
                                  >
                                    TIF
                                  </label>
                                </div>
                                <div className="form-check custom-checkbox mb-2 form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="drawing_file_type"
                                    id="PDFRadio"
                                    value="PDF"
                                    checked={values.drawing_file_type === "PDF"}
                                    onChange={handleChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="PDFRadio"
                                  >
                                    PDF
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="form-group row mb-3">
                              <label className="col-sm-3 col-form-label">
                                Attach DWG File
                                <strong className="text-danger"> *</strong>
                              </label>
                              <div className="col-sm-9">
                                <div className="form-check custom-checkbox mb-2 form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="dwg_file"
                                    id="yes"
                                    value="YES"
                                    checked={values.dwg_file === "YES"}
                                    onChange={handleDwgChange}
                                  />
                                  <label className="form-check-label" htmlFor="yes">
                                    YES
                                  </label>
                                </div>
                                <div className="form-check custom-checkbox mb-2 form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="dwg_file"
                                    id="no"
                                    value="NO"
                                    checked={values.dwg_file === "NO"}
                                    onChange={handleDwgChange}
                                  />
                                  <label className="form-check-label" htmlFor="no">
                                    NO
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="form-group row mb-3">
                              <label className="col-sm-3 col-form-label">
                                Is Layout
                                <strong className="text-danger"> *</strong>
                              </label>
                              <div className="col-sm-9">
                                <div className="form-check custom-checkbox mb-2 form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="is_layout"
                                    id="yes"
                                    value="YES"
                                    checked={values.is_layout === "YES"}
                                    onChange={handleChange}
                                  />
                                  <label className="form-check-label" htmlFor="yes">
                                    YES
                                  </label>
                                </div>
                                <div className="form-check custom-checkbox mb-2 form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="is_layout"
                                    id="no"
                                    value="NO"
                                    checked={values.is_layout === "NO"}
                                    onChange={handleChange}
                                  />
                                  <label className="form-check-label" htmlFor="no">
                                    NO
                                  </label>
                                </div>
                              </div>
                            </div>


                            {/* display this two filed */}
                            {["PDR", "CDBR", "RS", "PS"].includes(values.drawing_type) && (
                              <>
                                <div className="form-group row mb-3">
                                  <label className="col-sm-3 col-form-label">
                                    Certification
                                    <strong className="text-danger"> *</strong>
                                  </label>
                                  <div className="col-sm-9">
                                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="certification"
                                        id="certified"
                                        value="CERTIFIED"
                                        checked={values.certification === "CERTIFIED"}
                                        onChange={handleChange}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="certified"
                                      >
                                        CERTIFIED
                                      </label>
                                    </div>
                                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="certification"
                                        id="not_certified"
                                        value="NOT CERTIFIED"
                                        checked={
                                          values.certification === "NOT CERTIFIED"
                                        }
                                        onChange={handleChange}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="not_certified"
                                      >
                                        NOT CERTIFIED
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            {values.drawing_type === "FDR" && (
                              <>
                                <div className="form-group row mb-3">
                                  <label
                                    htmlFor="fdr_approved_date"
                                    className="col-sm-3 col-form-label"
                                  >
                                    Approved Date
                                    <strong className="text-danger"></strong>
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      type="date"
                                      className="form-control"
                                      name="fdr_approved_date"
                                      id="fdr_approved_date"
                                      value={values.fdr_approved_date}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors.fdr_approved_date &&
                                      touched.fdr_approved_date ? (
                                      <div className="text-danger small mt-1">
                                        {errors.fdr_approved_date}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>

                                <div className="form-group row mb-3">
                                  <label
                                    htmlFor="date_of_registration"
                                    className="col-sm-3 col-form-label"
                                  >
                                    Letter No. & Date
                                    <strong className="text-danger"></strong>
                                  </label>
                                  <div className="col-sm-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="letter_number"
                                      id="letter_number"
                                      placeholder="Enter letter no. & date"
                                      value={values.letter_number}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                    {errors.letter_number &&
                                      touched.letter_number ? (
                                      <div className="text-danger small mt-1">
                                        {errors.letter_number}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="form-group row mb-3">
                              <label
                                htmlFor="remarks"
                                className="col-sm-3 col-form-label"
                              >
                                Remarks <strong className="text-danger"> *</strong>
                              </label>
                              <div className="col-sm-9">
                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="remarks"
                                  placeholder="Enter Remarks"
                                  name="remarks"
                                  value={values.remarks}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                ></textarea>
                                {errors.remarks && touched.remarks ? (
                                  <div className="text-danger small mt-1">
                                    {errors.remarks}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6">
                            <div className="form-group row mb-3">
                              <label
                                htmlFor="no_of_sheet"
                                className="col-sm-3 col-form-label"
                              >
                                Number Of Sheet
                                <strong className="text-danger"> *</strong>
                              </label>
                              <div className="col-sm-9">
                                <div className="input-group tp-input-group">
                                  <input
                                    type="text"
                                    className="form-control border-end-0"
                                    id="no_of_sheet"
                                    placeholder="Enter Number of Sheet"
                                    name="no_of_sheet"
                                    value={values.no_of_sheet}
                                    onBlur={handleBlur}
                                    onChange={(e) => handleNumberChange(e)}
                                  />
                                  <div className="input-group-text px-2 py-0">
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-circle tp-btn me-2"
                                      onClick={(e) =>
                                        handleNumberChange(e, "increase")
                                      }
                                    >
                                      <i className="fa-solid fa-plus"></i>
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-circle tp-btn"
                                      onClick={(e) =>
                                        handleNumberChange(e, "decrease")
                                      }
                                    >
                                      <i className="fa-solid fa-minus"></i>
                                    </button>
                                  </div>
                                </div>
                                {errors.no_of_sheet && touched.no_of_sheet && (
                                  <div className="text-danger small mt-1">
                                    {errors.no_of_sheet}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              style={{ maxHeight: "120vh", overflowY: "auto" }}
                            >
                              {values.description.map((item, index) => (
                                <div className="form-group mb-3" key={index}>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <label
                                      htmlFor={`description${index}`}
                                      className="form-label"
                                    >
                                      Description of Sheet {index + 1}
                                      <strong className="text-danger"> *</strong>
                                    </label>
                                    {index === 0 &&
                                      (showCopyDesField ? (
                                        <div className="d-flex align-items-center mb-2">
                                          <input
                                            type="text"
                                            className="form-control me-2"
                                            id="copyBoxNumber"
                                            placeholder="Enter Description Box Number"
                                            value={copySelectedDesBox}
                                            onChange={(e) =>
                                              setCopySelectedDesBox(
                                                e.target.value
                                              )
                                            }
                                          />
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-primary me-2"
                                            onClick={handleCopyDescription}
                                          >
                                            Copy
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-danger"
                                            onClick={toggleCopyField}
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn btn-sm tp-btn btn-primary mb-2"
                                          onClick={toggleCopyField}
                                        >
                                          Copy to All
                                        </button>
                                      ))}
                                  </div>
                                  <div className="input-group tp-input-group">
                                    <textarea
                                      rows="2"
                                      className="form-control"
                                      id={`description${index}`}
                                      placeholder="Enter Description"
                                      name={`description${index}`}
                                      value={item.description}
                                      onChange={(e) =>
                                        handleDescriptionChange(e, index)
                                      }
                                      onBlur={handleBlur}
                                    ></textarea>
                                    {values.no_of_sheet <
                                      values.description.length && (
                                        <div className="input-group-text px-2 py-0">
                                          <button
                                            type="button"
                                            className="btn btn-danger btn-circle tp-btn"
                                            onClick={() =>
                                              handleRemoveDescription(index)
                                            }
                                          >
                                            <i className="fa-solid fa-xmark"></i>
                                          </button>
                                        </div>
                                      )}
                                  </div>
                                  {errors.description &&
                                    touched.description &&
                                    errors.description[index]?.description && (
                                      <div className="text-danger small mt-1">
                                        {errors.description[index]?.description}
                                      </div>
                                    )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-2">
                          <button
                            type="submit"
                            disabled={isSubmitLoading}
                            className="btn btn-primary me-1 w-50"
                          >
                            {isSubmitLoading ? (
                              <span>
                                <Spinner size="sm" className="me-2" />
                              </span>
                            ) : ""}
                            {isAttachButton ? "Attach DWG File" : "Update"}
                          </button>
                        </div>
                      </Form>
                    )
                  )) : <ReviseDWGFileUpload drawingData={attachDWGData} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal modal-sm fade"
        id="exampleModal1"
        show={isModalOpen}
        centered={true}
        backdrop="static"
        keyboard={false}
        onHide={() => setIsModalOpen(false)}
      >
        <div className="modal-content">
          <div className="modal-body">
            <p className="text-center">All The DWG File Will Be Deleted</p>
            <div className="d-flex align-items-center justify-content-center mt-2">
              <button
                type="button"
                onClick={() => setIsModalOpen(false)}
                className="btn btn-primary"
              >Continue</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditDrawingPage;
