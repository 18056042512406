import React, { useState } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import { updateManual } from "../../../../services/ManualService";
import { formatFileSize } from "../../../../utils";
import DynamicSelectInput from "../../../constant/SelectInput";
import { toast } from "react-toastify";

function EditPSDModal({ data, setData }) {
  const [manuals, setManuals] = useState({
    id: "",
    manual_number: "",
    department: "",
    title: "",
    unit: "",
    description: "",
    file_type:"PDF",
    file: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const toggleModal = () => {
    if (showModal) {
      setManuals(manuals);
    } else {
      setManuals({
        id: data.id,
        manual_number: data.manual_no,
        department: data.department ? data.department.department_id : "",
        unit: data.unit ? data.unit.unit_id : "",
        description: data.description || "",
        title: data.title || "",
        file_type: data.file_type || "PDF",
        file: "",
        file_data: data.file || data.dwg_zip_file || null,
      });
    }
    setShowModal(!showModal);
  };

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const file = file_list[0];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (!["PDF", "DWG", "ZIP", "RAR"].includes(file_ext)) {
        toast.error("Please choose a valid PDF file.");
      } else {
        setManuals({ ...manuals, file: file });
      }
    }
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist);
  };

  const handleSelectChange = (name, value, _) => {
    setManuals({ ...manuals, [name]: value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setManuals({ ...manuals, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitLoading(true);
      const reqBody = new FormData();
      Object.entries(manuals).forEach(([fieldName, value]) => {
        if (fieldName !== "file_data") {
          reqBody.append(fieldName, value);
        }
      });
      reqBody.append("manual_type", "PROJECT REPORT");
      const resp = await updateManual(reqBody);
      const { results, message } = resp.data;
      setData(results);
      toast.success(message);
      toggleModal();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
  };

  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm cursor-pointer fw-bolder"
        onClick={toggleModal}
      >
        <i className="fa-solid fa-pen-to-square"></i> Edit
      </button>
      <Modal
        show={showModal}
        onHide={toggleModal}
        className="modal fade modal-lg"
        placement="end"
      >
        <div className="modal-content">
          <div className="modal-header border-bottom">
            <h5 className="modal-title" id="#gridSystemModal">
              Edit PROJECT SUBMITTED DRAWINGS
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {manuals && (
              <Form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="manual_number" className="form-label">
                    PSD Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="manual_number"
                    placeholder="Enter PSD Number"
                    name="manual_number"
                    value={manuals.manual_number}
                    readOnly
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="department_id" className="form-label">
                    Department
                  </label>
                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/department"
                    name={"department"}
                    id={"department"}
                    placeholder={"Select Department"}
                    isRequired={true}
                    defaultValue={data.department ? {value: data.department.department_id, label: data.department.name}: null}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="unit" className="form-label">
                    Unit
                  </label>
                  <DynamicSelectInput
                    setParentInputValue={handleSelectChange}
                    endpoint_name="search/unit"
                    name={"unit"}
                    id={"unit"}
                    placeholder={"Select Unit"}
                    isRequired={true}
                    defaultValue={data.unit ? {value: data.unit.unit_id, label: data.unit.name}: null}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="title" className="form-label">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Enter Title"
                    name="title"
                    value={manuals.title}
                    onChange={handleChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="description" className="form-label">
                    Description
                  </label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="description"
                    placeholder="Enter Description"
                    name="description"
                    value={manuals.description}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="form-group mb-3 d-flex">
                  <label className="form-label">
                    Zip File Type
                  </label>
                  <div className=" ms-3">
                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="file_type"
                        id="PDFRadio"
                        value="PDF"
                        defaultChecked={manuals.file_type === "PDF"}
                        disabled
                      />
                      <label
                        className="form-check-label"
                        htmlFor="PDFRadio"
                      >
                        PDF
                      </label>
                    </div>
                    <div className="form-check custom-checkbox mb-2 form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="file_type"
                        id="TIFRadio"
                        value="DWG"
                        defaultChecked={manuals.file_type === "DWG"}
                        disabled
                      />
                      <label
                        className="form-check-label"
                        htmlFor="TIFRadio"
                      >
                        DWG
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="pdfFile" className="form-label">Upload New File</label>
                  <input
                    type="file"
                    className="form-control"
                    id="file"
                    name="file"
                    onChange={handleFileInputChange}
                    accept=".zip, .rar"
                  />
                  {manuals.file_data ? (
                    <div className="text-warning mt-1 fst-italic">
                      Existing File: {manuals.file_data.name}
                      <span className="ms-2">
                        ({formatFileSize(manuals.file_data.size)})
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <button
                    type="submit"
                    disabled={isSubmitLoading}
                    className="btn btn-primary me-1 w-50">
                    {isSubmitLoading ? (<span><Spinner size="sm" className="me-2" />Updateing...</span>) : "Update"}
                  </button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditPSDModal;
