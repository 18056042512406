import axiosInstance from "./AxiosInstance";
const apiDepartment = "api/department";
const apiUnit = "api/unit";
const apiVolume = "api/rs_volume";
const apiSubVolume = "api/rs_sub_volume";
const apiRSNVolume = "api/rsn_volume";
const apiGroup = "api/rsn_group";
const apiTitle = "api/ipss";

//Department
export function getDepartmentList(page, page_size, query) {
  return axiosInstance.get(
    `${apiDepartment}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getDepartment(id) {
  return axiosInstance.get(`${apiDepartment}/${id}`);
}

export function createDepartment(data) {
  return axiosInstance.post(apiDepartment, data);
}

export function updateDepartment(data) {
  return axiosInstance.put(apiDepartment, data);
}

export function deleteDepartment(id, replace_id) {
  return axiosInstance.delete(`${apiDepartment}?id=${id}&replace_id=${replace_id}`);
}

//Unit
export function getUnitList(page, page_size, query) {
  return axiosInstance.get(
    `${apiUnit}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getUnit(id) {
  return axiosInstance.get(`${apiUnit}/${id}`);
}

export function createUnit(data) {
  return axiosInstance.post(apiUnit, data);
}

export function updateUnit(data) {
  return axiosInstance.put(apiUnit, data);
}

export function deleteUnit(id, replace_id) {
  return axiosInstance.delete(`${apiUnit}?id=${id}&replace_id=${replace_id}`);
}

//Volume
export function getVolumeList(page, page_size, query) {
  return axiosInstance.get(
    `${apiVolume}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getVolume(id) {
  return axiosInstance.get(`${apiVolume}/${id}`);
}

export function createVolume(data) {
  return axiosInstance.post(apiVolume, data);
}

export function updateVolume(data) {
  return axiosInstance.put(apiVolume, data);
}

export function deleteVolume(id, replace_id) {
  return axiosInstance.delete(`${apiVolume}?id=${id}&replace_id=${replace_id}`);
}

// sub vloume

export function getSubVolumeList(page, page_size, query) {
  return axiosInstance.get(
    `${apiSubVolume}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getSubVolume(id) {
  return axiosInstance.get(`${apiSubVolume}/${id}`);
}

export function createSubVolume(data) {
  return axiosInstance.post(apiSubVolume, data);
}

export function updateSubVolume(data) {
  return axiosInstance.put(apiSubVolume, data);
}

export function deleteSubVolume(id, replace_id) {
  return axiosInstance.delete(`${apiSubVolume}?id=${id}&replace_id=${replace_id}`);
}


//RSNVolume
export function getRSNVolumeList(page, page_size, query) {
  return axiosInstance.get(
    `${apiRSNVolume}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getRSNVolume(id) {
  return axiosInstance.get(`${apiRSNVolume}/${id}`);
}

export function createRSNVolume(data) {
  return axiosInstance.post(apiRSNVolume, data);
}

export function updateRSNVolume(data) {
  return axiosInstance.put(apiRSNVolume, data);
}

export function deleteRSNVolume(id, replace_id) {
  return axiosInstance.delete(`${apiRSNVolume}?id=${id}&replace_id=${replace_id}`);
}


//RSNGroup
export function getGroupList(page, page_size, query) {
  return axiosInstance.get(
    `${apiGroup}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getGroup(id) {
  return axiosInstance.get(`${apiGroup}/${id}`);
}

export function createGroup(data) {
  return axiosInstance.post(apiGroup, data);
}

export function updateGroup(data) {
  return axiosInstance.put(apiGroup, data);
}

export function deleteGroup(id, replace_id) {
  return axiosInstance.delete(`${apiGroup}?id=${id}&replace_id=${replace_id}`);
}

//IPSStitle
export function getTitleList(page, page_size, query) {
  return axiosInstance.get(
    `${apiTitle}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function getTitle(id) {
  return axiosInstance.get(`${apiTitle}/${id}`);
}

export function createTitle(data) {
  return axiosInstance.post(apiTitle, data);
}

export function updateTitle(data) {
  return axiosInstance.put(apiTitle, data);
}

export function deleteTitle(id, replace_id) {
  return axiosInstance.delete(`${apiTitle}?id=${id}&replace_id=${replace_id}`);
}
