import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner, Collapse } from "react-bootstrap";
import { toast } from "react-toastify";

import TableLoaderEffect from "../../constant/TableLoader";
import DynamicSelectInput, { StaticSelectInput, } from "../../constant/SelectInput";
import { getBulkDrawingList, updateBulkDrawingList } from "../../../services/DrawingService";

const maxWidthStyle = {
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "block"
}

const maxWidthStyle300 = {
    maxWidth: "30rem",
    minWidth: "10rem",
    whiteSpace: "normal",
    display: "block"
}


const DrawingBulkArchivePage = () => {
    const [drawingList, setDrawingList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [openADSearch, setOpenADSearch] = useState(false);
    const [advanceFilterInput, setAdvanceFilterInput] = useState({
        work_order_number: "",
        description: "",
        department_id: "",
        unit_id: "",
        drawing_type: ""
    });
    const [isClearAll, setIsClearAll] = useState(false);
    const [selectedDrawings, setSelectedDrawings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchLoading, setIsSearchLoading] = useState(false);
    const [isShowArchiveForm, setIsShowArchiveForm] = useState(false);
    const [archiveReason, setArchiveReason] = useState("");
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);


    const navigate = useNavigate();
    useEffect(() => {
        setIsLoading(true);
        getBulkDrawingList(searchQuery,
            advanceFilterInput.department_id,
            advanceFilterInput.unit_id,
            advanceFilterInput.drawing_type,
            advanceFilterInput.work_order_number,
            advanceFilterInput.description
        ).then((resp) => {
            setDrawingList(resp.data.results);
            setIsLoading(false);
            setIsSearchLoading(false)
        })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data.message);
                    setIsLoading(false);
                    setIsSearchLoading(false)
                } else {
                    console.log(error);
                }
            });
    }, [searchQuery, advanceFilterInput.work_order_number, advanceFilterInput.description,
        advanceFilterInput.department_id,
        advanceFilterInput.unit_id,
        advanceFilterInput.drawing_type]);


    const handleSearchChange = (e) => {
        const { value } = e.target;
        if (value) {
            setIsSearchLoading(true)
        }
        setSearchQuery(value);
    };


    const toggleADSearch = () => {
        setOpenADSearch(!openADSearch);
    };

    const toggleArchiveForm = () => {
        setIsShowArchiveForm(!isShowArchiveForm);
    };


    const toggleCheckbox = (id) => {
        setSelectedDrawings((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((item) => item !== id);
            } else {
                return [...prevSelected, id];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectedDrawings.length === drawingList.length) {
            setSelectedDrawings([]);
        } else {
            setSelectedDrawings(drawingList.map((item) => item.id));
        }
    };

    const handleSelectChange = (name, value, _) => {
        const newFilter = { ...advanceFilterInput, [name]: value }
        setAdvanceFilterInput(newFilter);
        setIsClearAll(false)
    };

    const handleArchiveSubmit = () => {
        setIsSubmitLoading(true);
        updateBulkDrawingList({ drawing_list: selectedDrawings, archive_reason: archiveReason }).then((resp) => {
            toast.success(resp.data.message);
            setIsSubmitLoading(false);
            setSelectedDrawings([])
            toggleArchiveForm()
            navigate('/archive-drawing')
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data.message);
                toast.error(error.response.data.message);
                setIsSubmitLoading(false);
                setSelectedDrawings([])
                toggleArchiveForm()
            } else {
                console.log(error);
            }
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        const newFilter = { ...advanceFilterInput, [name]: value }
        setAdvanceFilterInput(newFilter);
        setIsClearAll(false)
    };

    const handleClear = () => {
        setIsClearAll(true)
        setAdvanceFilterInput({
            work_order_number: "",
            description: "",
            department_id: "",
            unit_id: "",
            drawing_type: ""
        });
    };



    return (
        <>
            <div className="px-3 py-3 border-top">
                <div className="tbl-caption d-flex justify-content-between text-wrap align-items-start align-items-sm-center flex-column flex-sm-row">
                    <h4 className="mb-md-0">Bulk Archive Drawings</h4>
                    <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                        <div className="me-3 mb-3 mb-md-0">
                            <div className="input-group tp-input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    value={searchQuery}
                                    aria-describedby="basic-search"
                                    onChange={handleSearchChange}
                                />
                                <div className="input-group-text" id="basic-search">
                                    {isSearchLoading && (
                                        <Spinner variant="primary" size="sm" />
                                    )}
                                    {!isSearchLoading &&
                                        searchQuery.length > 0 ? (
                                        <i
                                            className="fa-solid fa-xmark c-pointer text-primary"
                                            onClick={() => {
                                                setSearchQuery("");
                                            }}
                                        ></i>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <button
                            className="btn btn-outline-primary me-2 mb-3 mb-md-0"
                            onClick={toggleADSearch}
                        >
                            Advance Search
                        </button>
                    </div>
                </div>
                <div className="mt-4">
                    <Collapse in={openADSearch} className="border rounded px-3 pt-3">
                        <div id="collapse-example">
                            <div className="row">
                                <div className="col-lg-2 col-md-3 col-sm-6 ">
                                    <DynamicSelectInput
                                        setParentInputValue={handleSelectChange}
                                        endpoint_name="search/department"
                                        labelHint={false}
                                        isClearable={true}
                                        label={"Department"}
                                        name={"department_id"}
                                        id={"department_id"}
                                        isSetEmpty={isClearAll}
                                    />
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6 ">
                                    <DynamicSelectInput
                                        setParentInputValue={handleSelectChange}
                                        endpoint_name="search/unit"
                                        labelHint={false}
                                        isClearable={true}
                                        label={"Unit"}
                                        name={"unit_id"}
                                        id={"unit_id"}
                                        isSetEmpty={isClearAll}
                                    />
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6 ">
                                    <StaticSelectInput
                                        optionsList={[
                                            {
                                                label: "PDR",
                                                value: "PDR",
                                            },
                                            {
                                                label: "CDBR",
                                                value: "CDBR",
                                            },
                                            {
                                                label: "RS",
                                                value: "RS",
                                            },
                                            {
                                                label: "PS",
                                                value: "PS",
                                            },
                                            {
                                                label: "FDR",
                                                value: "FDR",
                                            },
                                            {
                                                label: "MISC",
                                                value: "MISC",
                                            },
                                        ]}
                                        setParentInputValue={handleSelectChange}
                                        name={"drawing_type"}
                                        isClearable={true}
                                        labelHint={false}
                                        defaultValue={
                                            advanceFilterInput.drawing_type
                                                ? {
                                                    label: advanceFilterInput.drawing_type,
                                                    value: advanceFilterInput.drawing_type,
                                                }
                                                : null
                                        }
                                        id={"drawing_type"}
                                        label={"Drawing Type"}
                                        isSetEmpty={isClearAll}
                                    />
                                </div>
                                <div className="col-lg-2 col-md-3 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="work_order_number" className="form-label">
                                            Work Order Number
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="work_order_number"
                                            placeholder="Enter Work Order No."
                                            name="work_order_number"
                                            value={advanceFilterInput.work_order_number}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="description" className="form-label">
                                            Description
                                        </label>
                                        <textarea
                                            className="form-control"
                                            id="description"
                                            placeholder="Enter Description"
                                            name="description"
                                            value={advanceFilterInput.description}
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                </div>


                                <div className="col-lg-1 col-md-3 col-sm-6 d-flex align-items-center">
                                    <button type="button" className="btn btn-sm btn-outline-danger" onClick={handleClear}>
                                        Clear All
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </div>

                {selectedDrawings.length > 0 &&
                    <div className="row mt-3">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">
                            <div className="bg-danger-light rounded p-2">
                                <h4 className="text-center">Total <strong>{selectedDrawings.length}</strong> Drawings selected.</h4>
                                {isShowArchiveForm ? <div className="px-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="archive_reason">Archive Reason</label>
                                        <textarea
                                            row="6"
                                            style={{ height: "50px" }}
                                            className="form-control"
                                            id="archive_reason"
                                            placeholder="Enter Archive Reason"
                                            value={archiveReason}
                                            onChange={(e) =>
                                                setArchiveReason(e.target.value)
                                            }
                                        ></textarea>
                                    </div>
                                    <center className="mt-3 mb-2">
                                        <button className="btn btn-primary btn-sm me-2"
                                            disabled={isSubmitLoading || !archiveReason.length}
                                            onClick={handleArchiveSubmit}>
                                            {isSubmitLoading ? (
                                                <span>
                                                    <Spinner size="sm" className="me-2" />Archive...
                                                </span>
                                            ) : "Archive"}
                                        </button>
                                        <button className="btn btn-outline-danger btn-sm"
                                            disabled={isSubmitLoading}
                                            onClick={toggleArchiveForm}>Cancel</button>
                                    </center>
                                </div> : <>
                                    <p className="text-center">To archive selected drawings, please click below to continue.</p>
                                    <center>
                                        <button className="btn btn-primary btn-sm" onClick={toggleArchiveForm}>Continue</button>
                                    </center>
                                </>}
                            </div>
                        </div>
                        <div className="col-sm-3"></div>

                    </div>
                }

            </div>

            <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                <div
                    id="employee-tbl_wrapper"
                    className="dataTables_wrapper no-footer"
                >
                    <table
                        id="empoloyees-tblwrapper"
                        className="table ItemsCheckboxSec dataTable no-footer mb-0"
                    >
                        <thead>
                            <tr>
                                <th>
                                    <div className="form-check custom-checkbox checkbox-primary">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={selectedDrawings.length === drawingList.length && drawingList.length !== 0}
                                            onChange={handleSelectAll}
                                        />
                                    </div>
                                </th>
                                <th>Drawing Number</th>
                                <th>Department / Volume</th>
                                <th>Unit / Sub-volume</th>
                                <th>Work Order No</th>
                                <th>Revision</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <TableLoaderEffect colLength={6} />
                            ) : (
                                drawingList.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="form-check custom-checkbox checkbox-primary">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={selectedDrawings.includes(item.id)}
                                                    onChange={() => toggleCheckbox(item.id)}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <span
                                                className="text-primary cursor-pointer fw-bolder"
                                                onClick={() =>
                                                    navigate(`/drawing/${item.id}`)
                                                }
                                            >{item.drawing_type}-{item.drawing_number}</span>
                                        </td>
                                        {item.drawing_type === "RS" ? (<>
                                            <td>{item.volume?.volume_name || ""}</td>
                                            <td><span style={maxWidthStyle}>{item.volume?.name || ""}</span></td>
                                        </>) : (<>
                                            <td>{item.department?.name || ""}</td>
                                            <td>{item.unit?.name || ""}</td>
                                        </>)}
                                        <td>{item.work_order_number || "None"}</td>
                                        <td>{item.revision_version}</td>
                                        <td> <span style={maxWidthStyle300}>{item.default_description}</span></td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};


export default DrawingBulkArchivePage;
