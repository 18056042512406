import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { getManualList, createManual } from "../../../services/ManualService";
import { connect, useDispatch } from "react-redux";
import Pagination from "../../constant/Pagination";
import ManualsIndexPage from "./Manuals";
import ReferenceBookPage from "./ReferenceBook";
import TenderDocumentPage from "./TenderDocument";
import CatalougePage from "./Catalouge";
import ProjectReportPage from "./ProjectReport";
import CommonManualPage from "./CommonManual";
import ManualsForm from "./Manuals/ManualsForm";
import ReferenceBookForm from "./ReferenceBook/Form";
import TenderDocumentForm from "./TenderDocument/TenderDocumentForm";
import CatalougeForm from "./Catalouge/Form";
import ProjectReportForm from "./ProjectReport/ProjectReportForm";
import CommonManualForm from "./CommonManual/CommonManualForm";
import ProjectSubmittedDrawingsPage from "./ProjectSubmittedDrawings";
import PSDForm from "./ProjectSubmittedDrawings/PSDForm";

import { Spinner, Modal, Tab, Nav, NavDropdown } from "react-bootstrap";
import { handleLoadingAction } from "../../../store/actions/DrawingActions";


const MainheaderCompo = (props) => {
  const { activeKey, documentType, userRole, manualFilter } = props;
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [rsnDropdown, setRsnDropDown] = useState({rsn_volume:null, group:null});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitLoading, setIsSUbmitLoading] = useState(false);
  const recordsPage = 10
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const documentTypeKey = () => activeKey.toLowerCase().replace(/ /g, '_');

  useEffect(() => {
    if (activeKey === documentType) {
      const {currentPage, searchQuery} = manualFilter[documentTypeKey()]
      setIsLoading(true);
      getManualList(
        currentPage,
        recordsPage,
        searchQuery,
        documentType,
      ).then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
        setIsSearchLoading(false);
      }).catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setData([])
          setTotal(0)
        } else {
          console.log(error);
        }
        setIsLoading(false);
        setIsSearchLoading(false);
      });
    }
  }, [activeKey]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const updateFilter = (currentPage, perPage=10, searchQuery=null) => {
    const key = documentTypeKey()
    const documentTypeFilter = manualFilter[key]
    if(searchQuery === null){
      searchQuery = documentTypeFilter.searchQuery
    }
    const newFilter = { ...manualFilter, [key]: {currentPage, searchQuery } }
    dispatch(handleLoadingAction({ manualFilter: newFilter }));
    setIsLoading(true);
      getManualList(
        currentPage,
        perPage,
        searchQuery,
        documentType
      ).then((resp) => {
        setData(resp.data.results);
        setTotal(resp.data.count);
        setIsLoading(false);
        setIsSearchLoading(false);
      }).catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setData([])
          setTotal(0)
        } else {
          console.log(error);
        }
        setIsLoading(false);
        setIsSearchLoading(false);
      });
  }

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setIsSearchLoading(true);
    updateFilter(1, recordsPage, value, null, null)
  };

  const handlePagination = (page) => {
    const new_page = page.selected + 1;
    updateFilter(new_page)
  };

  const handleGoTo = (page_no) => {
    updateFilter(page_no)
  };

  const handleAllRecords = () =>{
    updateFilter(1, total)
  }

  const documentComponent = () => {
    if (activeKey === "MANUALS") {
      return <ManualsIndexPage data={data} isLoading={isLoading} userRole={userRole} />;
    } else if (activeKey === "REFERENCE BOOK") {
      return <ReferenceBookPage data={data} isLoading={isLoading} userRole={userRole} />;
    } else if (activeKey === "TENDER DOCUMENT") {
      return <TenderDocumentPage data={data} isLoading={isLoading} userRole={userRole} />;
    } else if (activeKey === "CATALOUGE") {
      return <CatalougePage data={data} isLoading={isLoading} userRole={userRole} />;
    } else if (activeKey === "PROJECT REPORT") {
      return <ProjectReportPage data={data} isLoading={isLoading} userRole={userRole} />;
    } else if (activeKey === "PROJECT SUBMITTED DRAWINGS") {
      return <ProjectSubmittedDrawingsPage data={data} isLoading={isLoading} userRole={userRole} />;
    } else {
      return (
        <CommonManualPage
          data={data}
          isLoading={isLoading}
          type={activeKey}
          userRole={userRole}
        />
      );
    }
  };

  const handleSubmit = async (e, form_data) => {
    e.preventDefault();
    try {
      setIsSUbmitLoading(true);
      const reqBody = new FormData();
      Object.entries(form_data).forEach(([fieldName, value]) => {
        reqBody.append(fieldName, value);
      });
      reqBody.append("manual_type", activeKey);
      const resp = await createManual(reqBody);
      const { message, results } = resp.data;
      toast.success(message);
      setData([results, ...data]);
      toggleModal();
      setIsSUbmitLoading(false);
      navigate(`/standards/${results.id}`);
    } catch (error) {
      toast.error(error.response.data.message);
      setIsSUbmitLoading(false);
    }
  };



  return (
    <>
      <div className="card-body p-0">
        <div className="tbl-caption d-flex justify-content-between text-wrap flex-column  flex-sm-row align-items-start align-items-sm-center p-3">
          <div className="d-flex align-items-center">
            <h4 className="heading fw-bold mb-0 me-3">{activeKey} DOCUMENT</h4>
          </div>
          <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
            <div className="me-3 mb-3 mb-md-0">
              <div className="input-group tp-input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={manualFilter[documentTypeKey()].searchQuery}
                  aria-describedby="basic-search"
                  onChange={handleSearchChange}
                />
                <div className="input-group-text" id="basic-search">
                  {isSearchLoading && <Spinner variant="primary" size="sm" />}
                  {!isSearchLoading && manualFilter[documentTypeKey()].searchQuery.length > 0 && (
                    <i
                      className="fa-solid fa-xmark c-pointer text-primary"
                      onClick={() => {
                        updateFilter(1, recordsPage, "", null, null);
                      }}
                    ></i>
                  )}
                </div>
              </div>
            </div>
            {userRole !== "User" && (
              <button className="btn btn-primary" onClick={toggleModal}>
                Add {activeKey}
              </button>
            )}
          </div>
        </div>
        {/* selected compo */}
        {documentComponent()}
        {isLoading ? (
          <div style={{ height: "4rem" }}></div>
        ) : (
          <Pagination
            currentPage={manualFilter[documentTypeKey()].currentPage}
            recordsPage={recordsPage}
            dataLength={total}
            handleGoTo={handleGoTo}
            handlePagination={handlePagination}
            handleAllRecords={ 
              manualFilter[documentTypeKey()].searchQuery.length > 0 ? handleAllRecords : undefined
            }
          />
        )}
      </div>
      <Modal
        show={isModalOpen}
        onHide={toggleModal}
        className="modal fade modal-lg"
        placement="end"
      >
        <div className="modal-content">
          <div className="modal-header border-bottom">
            <h5 className="modal-title" id="#gridSystemModal">
              Add{" "}
              {activeKey === "REFERENCE BOOK"
                ? "RB"
                : activeKey === "TENDER DOCUMENT"
                  ? "TD"
                  : activeKey === "TECHNICAL CALCULATION"
                    ? "TC"
                    : activeKey === "TECHNICAL SPECIFICATION"
                      ? "TS"
                      : activeKey === "TECHNICAL REPORT"
                        ? "TP"
                        : activeKey === "PROJECT REPORT"
                          ? "PR"
                          : activeKey}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {activeKey === "MANUALS" ? (
              <ManualsForm
                handleSubmit={handleSubmit}
                isLoading={isSubmitLoading}
              />
            ) : activeKey === "REFERENCE BOOK" ? (
              <ReferenceBookForm
                handleSubmit={handleSubmit}
                isLoading={isSubmitLoading}
              />
            ) : activeKey === "TENDER DOCUMENT" ? (
              <TenderDocumentForm
                handleSubmit={handleSubmit}
                isLoading={isSubmitLoading}
              />
            ) : activeKey === "CATALOUGE" ? (
              <CatalougeForm
                handleSubmit={handleSubmit}
                isLoading={isSubmitLoading}
              />
            ) : activeKey === "PROJECT REPORT" ? (
              <ProjectReportForm
                handleSubmit={handleSubmit}
                isLoading={isSubmitLoading}
              />
            ) : activeKey === "PROJECT SUBMITTED DRAWINGS" ? (
              <PSDForm
                handleSubmit={handleSubmit}
                isLoading={isSubmitLoading}
              />
            ) : (
              <CommonManualForm
                handleSubmit={handleSubmit}
                isLoading={isSubmitLoading}
                type={activeKey}
              />
            )}
          </div>
        </div>
      </Modal>

    </>
  );
};

const ManualPage = ({ userRole, documentPerm, manualFilter }) => {
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("MANUALS");
  const handleSelect = (eventKey) => setActiveKey(eventKey);
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const documentType = queryParams.get("manual_type");
    if (documentType) {
      setActiveKey(documentType);
      navigate(location.pathname, { replace: true });
    }
  }, [location.search]);

  const getDocumentPermData = () => {
    if (userRole !== "User") {
      return ["MANUALS", "REFERENCE BOOK", "TENDER DOCUMENT", "CATALOUGE", "TECHNICAL CALCULATION", "TECHNICAL SPECIFICATION", "TECHNICAL REPORT", "PROJECT REPORT", "PROJECT SUBMITTED DRAWINGS"]
    }
    const perm = []
    if (documentPerm?.reference_book) {
      perm.push('REFERENCE BOOK')
    }
    if (documentPerm?.tender_document) {
      perm.push('TENDER DOCUMENT')
    }
    if (documentPerm?.catalouge) {
      perm.push('CATALOUGE')
    }
    if (documentPerm?.technical_calculation) {
      perm.push('TECHNICAL CALCULATION')
    }   
    if (documentPerm?.technical_specification) {
      perm.push('TECHNICAL SPECIFICATION')
    } 
    if (documentPerm?.technical_report) {
      perm.push('TECHNICAL REPORT')
    }    
    if (documentPerm?.project_report) {
      perm.push('PROJECT REPORT')
    }
    if (documentPerm?.project_submitted_drawings) {
      perm.push('PROJECT SUBMITTED DRAWINGS')
    }
    return perm
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="custom-btn-tab">
              <Tab.Container activeKey={activeKey} onSelect={handleSelect}>
                <Nav
                  as="ul"
                  className="nav-pills mb-2 light border-bottom px-4 pt-3"
                >
                  <Nav.Item as="li">
                    <Nav.Link eventKey="MANUALS" className="pb-3 fw-semibold fs-5">
                      MANUALS
                    </Nav.Link>
                  </Nav.Item>
  
                  {getDocumentPermData().length > 0 &&
                    <NavDropdown
                      title="OTHERS"
                      id="nav-dropdown"
                      className={`custom-nav-dropdown ${[
                        "REFERENCE BOOK", "TENDER DOCUMENT", "CATALOUGE", 
                        "TECHNICAL CALCULATION", "TECHNICAL SPECIFICATION","TECHNICAL REPORT", 
                        "PROJECT REPORT", "PROJECT SUBMITTED DRAWINGS"].includes(activeKey)
                        ? "active"
                        : ""
                        }`}
                    >
                      {getDocumentPermData().filter(it => it !== "MANUALS").map(item => (
                        <NavDropdown.Item eventKey={item} key={item}>{item}</NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  }
                </Nav>
                <Tab.Content className="">
                  <Tab.Pane eventKey={"MANUALS"}>
                    <MainheaderCompo
                      activeKey={activeKey}
                      documentType={"MANUALS"}
                      manualFilter={manualFilter}
                      userRole={userRole} />
                  </Tab.Pane>
                  {getDocumentPermData().filter(it => it !== "MANUALS").map(item => (
                    <Tab.Pane eventKey={item} key={item}>
                      <MainheaderCompo
                        activeKey={activeKey}
                        documentType={item}
                        manualFilter={manualFilter}
                        userRole={userRole} />
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user?.role || "Admin",
    documentPerm: state.auth.user?.document_permission || {},
    manualFilter: state.drawing?.manualFilter || {}
  };
};

export default connect(mapStateToProps)(ManualPage);
