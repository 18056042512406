import React from "react";
import TableLoaderEffect from "../../../constant/TableLoader";
import { useNavigate } from "react-router-dom";
import { viewPDFFile } from "..";
const maxWidthStyle300 = {
  maxWidth: "30rem",
  minWidth: "10rem",
  whiteSpace: "normal",
  display: "block",
};

const RSNPage = ({ data, isLoading, userRole }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="">
        <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
          <div
            id="employee-tbl_wrapper"
            className="dataTables_wrapper no-footer"
          >
            <table
              id="empoloyees-tblwrapper"
              className="table ItemsCheckboxSec dataTable no-footer mb-0 table-striped"
            >
              <thead>
                <tr>
                  <th>RSN Number</th>
                  <th>Volume</th>
                  <th>Group</th>
                  <th>No Of Sheet</th>
                  {userRole !== "User" && <th>Status</th>}
                  <th>Description</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <TableLoaderEffect colLength={userRole === "User" ? 6 : 7} />
                ) : (
                  data.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <span
                          onClick={() => navigate(`/standards/${item.id}`)}
                          className="text-primary cursor-pointer fw-bolder"
                        >
                          {item.standard_no}
                        </span>
                      </td>
                      <td>{item.volume ? item.volume.name : "None"}</td>
                      <td> 
                        <span style={maxWidthStyle300}>
                        {item.group ? item.group.name : "None"}
                        </span>
                        </td>
                      <td> {item.no_of_sheet}</td>
                      {userRole !== "User" &&
                        <td>
                          {item.is_approved ? (
                            <span className={`badge light border-0 badge-success badge-sm`}>APPROVED</span>
                          ) : (
                            <span className={`badge light border-0 badge-info badge-sm`}>PENDING</span>
                          )}
                        </td>
                      }
                      <td>
                        <span style={maxWidthStyle300}>

                          {item.description}
                        </span>
                      </td>
                      <td>
                        {item.is_file ?
                          <button className="btn btn-outline-primary btn-sm mb-3 mb-md-0"
                            onClick={() => viewPDFFile(item.id)}
                          ><span><i className="fa-solid fa-eye me-2" /> View</span>
                          </button> : "File NP"
                        }
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default RSNPage;
