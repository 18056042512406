import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

/// Css
import "./index.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

/// Dashboard
import Home from "./components/Dashboard/Home";

/// Pages
import Error404 from "./pages/Error404";
import Error403 from "./pages/Error403";
import HomePage from "./components/HomePage";

import logo from "../images/logo/logo.png";
import UserPage from "./components/User";
import AddUser from "./components/User/AddUser";
import ShowDetail from "./components/User/ShowDetail";
import DepartmentUnitPage from "./components/Department&Unit";
import AddDrawingPage from "./components/Drawing/AddDrawing";
import DrawingListPage from "./components/Drawing";
import DrawingDetailPage from "./components/Drawing/ShowDetail";
import UploadDrawingFiles from "./components/BulkUpload/UploadDrawingFiles";
import MISReportPage from "./components/MISReport";
import ProfileDetails from "./components/Profile";
import PendingDrawingPage from "./components/PendingDrawing";
import EditDrawingPage from "./components/Drawing/EditDrawing";
import BulkArchivePage from "./components/BulkArchive";
import StandardPage from "./components/Standards/index";
import ManualPage from "./components/Manuals";
import StandardDetail from "./components/Standards/ShowDetails";
import ArchiveDrawingPage from "./components/ArciveDrawing";
import ManualDetails from "./components/Manuals/ShowDetails";
import ArchiveStandardPage from "./components/ArchiveStandard";
import ArchiveManualPage from "./components/ArchiveManual"
import PendingStandardPage from "./components/PendingStandards";
import PendingManualPage from "./components/PendingManual";
import UploadDrawingPage from "./components/BulkUpload/DrawingData";
import UploadStandardPage from "./components/BulkUpload/StandardData";
import UploadManualPage from "./components/BulkUpload/Manual";
import NoticePage from "./components/Notice";
import SIPage from "./components/SI"
import NewUserRequestListPage from "./components/User/NewUserRequestList";
import TotalUserListPage from "./components/User/TotalUser";
import OnlineUserListPage from "./components/User/OnlineUser";

import LogPage from "./components/SI/SILog";
import ArchiveSIRPage from "./components/ArchiveSIR";
import ArchiveCompliancePage from "./components/ArchiveCompliance";
import ArchiveSCPage from "./components/ArchiveStabilityCertificate";
import PendingSIRPage from "./components/PendingSIR";
import PendingStabilityCertificatePage from "./components/PendingStabilityCertificate";
import PendingCompliancePage from "./components/PendingCompliance";



const allroutes = [
  // Dashboard
  { url: "dashboard", element: <Home />, accessRole: ["SuperAdmin", "Admin", "User"] },
  { url: "profile", element: <ProfileDetails />, accessRole: ["SuperAdmin", "Admin", "User"] },
  { url: "mis-report", element: <MISReportPage />, accessRole: ["SuperAdmin", "Admin"] },
  { url: "user", element: <UserPage />, accessRole: ["SuperAdmin", "Admin"] },
  { url: "add-user", element: <AddUser />, accessRole: ["SuperAdmin", "Admin"] },
  { url: "user/:id", element: <ShowDetail />, accessRole: ["SuperAdmin", "Admin"] },
  { url: "new_user_request", element: <NewUserRequestListPage />, accessRole: ["SuperAdmin", "Admin"] },
  { url: "total_user", element: <TotalUserListPage />, accessRole: ["SuperAdmin", "Admin", "User"] },
  { url: "online_user", element: <OnlineUserListPage />, accessRole: ["SuperAdmin", "Admin", "User"] },
  { url: "drawing", element: <DrawingListPage />, accessRole: ["SuperAdmin", "Admin", "User"] },
  { url: "drawing/:id", element: <DrawingDetailPage />, accessRole: ["SuperAdmin", "Admin", "User"] },
  { url: "add-drawing", element: <AddDrawingPage />, accessRole: ["SuperAdmin", "Admin"] },
  { url: "edit-drawing/:id", element: <EditDrawingPage />, accessRole: ["SuperAdmin", "Admin"] },
  { url: "department-unit", element: <DepartmentUnitPage />, accessRole: ["SuperAdmin", "Admin"] },
  { url: "upload-drawing-data", element: <UploadDrawingPage />, accessRole: ["SuperAdmin", "Admin"] },
  { url: "upload-drawing-files", element: <UploadDrawingFiles />, accessRole: ["SuperAdmin", "Admin"] },
  { url: "upload-standards", element: <UploadStandardPage />, accessRole: ["SuperAdmin", "Admin"] },
  { url: "upload-documents", element: <UploadManualPage />, accessRole: ["SuperAdmin", "Admin"] },
  { url: "pending-drawing", element: <PendingDrawingPage />, accessRole: ["SuperAdmin"] },
  { url: "pending-standard", element: <PendingStandardPage />, accessRole: ["SuperAdmin"] },
  { url: "pending-documents", element: <PendingManualPage />, accessRole: ["SuperAdmin"] },
  { url: "archive-drawing", element: <ArchiveDrawingPage />, accessRole: ["SuperAdmin"] },
  { url: "archive-standard", element: <ArchiveStandardPage />, accessRole: ["SuperAdmin"] },
  { url: "archive-documents", element: <ArchiveManualPage />, accessRole: ["SuperAdmin"] },
  { url: "bulk-archive", element:<BulkArchivePage/>, accessRole: ["SuperAdmin"]},
  { url: "standards", element:<StandardPage />, accessRole: ["SuperAdmin", "Admin", "User"] },
  { url: "standards/:id", element: <StandardDetail />, accessRole: ["SuperAdmin", "Admin", "User"] },
  { url: "documents", element:<ManualPage />, accessRole: ["SuperAdmin", "Admin", "User"] },
  { url: "documents/:id", element: <ManualDetails />, accessRole: ["SuperAdmin", "Admin", "User"] },
  { url: "notice", element: <NoticePage />, accessRole: ["SuperAdmin"] },
  { url: "si", element: <SIPage />, accessRole: ["SuperAdmin", "Admin", "User"] },
  { url: "si/:id", element: <LogPage />, accessRole: ["SuperAdmin", "Admin", "User"] },
  { url: "archive-sir", element: <ArchiveSIRPage />, accessRole: ["SuperAdmin"] },
  { url: "archive-stability-certificate", element: <ArchiveSCPage />, accessRole: ["SuperAdmin"] },
  { url: "archive-compliance", element: <ArchiveCompliancePage />, accessRole: ["SuperAdmin"] },
  { url: "pending-sir", element: <PendingSIRPage />, accessRole: ["SuperAdmin"] },
  { url: "pending-stability-certificate", element: <PendingStabilityCertificatePage />, accessRole: ["SuperAdmin"] },
  { url: "pending-compliance", element: <PendingCompliancePage />, accessRole: ["SuperAdmin"] },

];

const FlashScreen = () => {
  return (
    <div className="flash-screen">
      <div className="flash-body">
        <img src={logo} alt="logo" />
      </div>
    </div>
  );
};

const Markup = ({ isAuthenticated, isCheckingAuth, role, sideMenu, is_view_manual, is_view_standard }) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.screen.height);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const NotFound = () => <Error404 />;

  function MainLayout() {
    return (
      <div id="main-wrapper" className={`show ${sideMenu ? "menu-toggle" : ""}`}>
        <Nav />
        <div
          className="content-body"
          style={{
            minHeight: windowHeight,
            position: "relative",
          }}>
          <Outlet />
          <Footer />
        </div>
      </div>
    );
  }

  const HandleUserAuthentication = ({ data }) => {
    if (!isAuthenticated) {
      return <Navigate to="/" />;
    }
    else if (data.accessRole.includes(role)) {
      return data.element
    } return <Error403 />
  };

  return isCheckingAuth ? (
    <FlashScreen />
  ) : (
    <>
      <Routes>
        <Route
          path="/"
          exact
          element={isAuthenticated ? <Navigate to="/dashboard" /> : <HomePage />}
        />
        <Route element={<MainLayout />}>
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={<HandleUserAuthentication data={data} />}
            />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.user?.role || "User",
    sideMenu: state.sideMenu,
    isAuthenticated: state.auth.user,
    isCheckingAuth: state.auth.isCheckingAuth,
    is_view_standard: state.auth.user?.is_view_standard || false,
    is_view_manual: state.auth.user?.is_view_manual || false,
  };
};

export default connect(mapStateToProps)(Markup);
