import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { updateStandard } from "../../../../services/StandardService";
import { formatFileSize } from "../../../../utils";
import DynamicSelectInput from "../../../constant/SelectInput";
import RSNGroupSelectInput from "./RSNGroupSelect";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function EditRSNModal({ data, setData }) {
  const [standards, setStandards] = useState({
    id: "",
    standard_number: "",
    rsn_volume: "",
    group: "",
    no_of_sheet: "",
    description: "",
    file: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isClearGroup, setIsClearGroup] = useState(false);

  const toggleModal = () => {
    if (showModal) {
      setStandards(standards);
    } else {
      setStandards({
        id: data.id,
        standard_number: data.standard_no,
        rsn_volume: data.volume ? data.volume.id : "",
        group: data.group ? data.group.id : "",
        no_of_sheet: data.no_of_sheet || "",
        description: data.description || "",
        file: "",
        file_data: data.file,
      });
    }
    setShowModal(!showModal);
  };

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const file = file_list[0];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (file_ext !== "PDF") {
        toast.error("Please choose a valid PDF file.");
      } else {
        setStandards({ ...standards, file: file });
      }
    }
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStandards({ ...standards, [name]: value });
  };

  const handleSelectChange = (name, value, _) => {
    if(name === "rsn_volume"){
      setIsClearGroup(true);
      setStandards({ ...standards,group:"", [name]: value });
    }
    else if(name === "group"){
      setIsClearGroup(false);
      setStandards({ ...standards, [name]: value });
    }
    else{
      setStandards({ ...standards, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitLoading(true);
      const reqBody = new FormData();
      Object.entries(standards).forEach(([fieldName, value]) => {
        if (fieldName !== "file_data") {
          reqBody.append(fieldName, value);
        }
      });
      reqBody.append("standard_type", "RSN");
      const resp = await updateStandard(reqBody);
      const { results, message } = resp.data;
      setData(results);
      toast.success(message);
      toggleModal();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
  };

  return (
    <>
      <button
        className="btn btn-outline-primary btn-sm cursor-pointer fw-bolder"
        onClick={toggleModal}
      >
        <i className="fa-solid fa-pen-to-square"></i> Edit
      </button>
      <Modal
        show={showModal}
        onHide={toggleModal}
        className="modal fade modal-lg"
        placement="end"
      >
        <div className="modal-content">
          <div className="modal-header border-bottom">
            <h5 className="modal-title" id="#gridSystemModal">
              Edit RSN
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            {standards && (
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xl-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="standard_number" className="form-label">
                        RSN Number <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="standard_number"
                        placeholder="Enter RSN Number"
                        name="standard_number"
                        value={standards.standard_number}
                        onChange={handleChange}
                        readOnly
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="rsn_volume" className="form-label">
                        Volume
                      </label>
                      <DynamicSelectInput
                        setParentInputValue={handleSelectChange}
                        endpoint_name="search/rsn_volume"
                        name={"rsn_volume"}
                        id={"rsn_volume"}
                        placeholder={"Select Volume"}
                        isRequired={true}
                        defaultValue={
                          data.volume
                            ? { value: data.volume.id, label: data.volume.name }
                            : null
                        }
                      />
                    </div>
                    <div className="mb-3">
                      <RSNGroupSelectInput
                        volume_id={standards.rsn_volume}
                        setParentInputValue={handleSelectChange}
                        defaultValue={
                          data.group
                            ? { value: data.group.id, label: data.group.name }
                            : null
                        }
                        isSetEmpty={isClearGroup} 
                      />
                    </div>
                  </div>

                  <div className="col-xl-6 col-sm-12 mx-auto">
                    <div className="mb-3">
                      <label htmlFor="no_of_sheet" className="form-label">
                        No. Of Sheet
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="no_of_sheet"
                        placeholder="Enter No. Of Sheet"
                        name="no_of_sheet"
                        value={standards.no_of_sheet}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="description"
                        placeholder="Enter Description"
                        name="description"
                        value={standards.description}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div class="mb-3">
                      <label for="file" class="form-label">
                        {standards.file_data
                          ? "Upload New File"
                          : "Upload File"}
                        {standards.file_data ? (
                          <>
                            <span className="ms-2 me-2 text-dark">
                              {standards.file_data.name}
                            </span>
                            <span className="text-dark">
                              {formatFileSize(standards.file_data.size)}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleFileInputChange}
                        accept=".pdf"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-2">
                  <button
                    type="submit"
                    className="btn btn-primary me-1 w-50"
                    disabled={isSubmitLoading}
                  >
                    {isSubmitLoading ? (
                      <span>
                        <Spinner size="sm" className="me-2" />
                        Updating...
                      </span>
                    ) : (
                      "Update"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditRSNModal;
