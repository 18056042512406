import React, { useState, useEffect } from "react";
import { StaticSelectInput } from "../../constant/SelectInput";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  approveManualList,
  bulkApproveManual,
} from "../../../services/ManualService";
import { getManualCount } from "../../../services/ManualService";
import { Modal } from "react-bootstrap";
import CommonManualTable from "./Manuals/CommonManual";
import { manualDropdownOptions } from "../../constant/Variable";
import ManualTable from "./Manuals/Manuals";
import ReferenceBookTable from "./Manuals/ReferenceBook";
import TenderDocumentTable from "./Manuals/TenderDocument";
import CatalougeTable from "./Manuals/Catalouge";
import ProjectReportTable from "./Manuals/ProjectReport";

const PendingManualPage = () => {
  const [selectedOption, setSelectedOption] = useState("MANUALS");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedManuals, setSelectedManuals] = useState([]);
  const [manualTypeCount, setManualTypeCount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    approveManualList(selectedOption)
      .then((resp) => {
        setData(resp.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
        } else {
          console.log(error);
        }
      });
  }, [selectedOption]);

  useEffect(() => {
    getManualCount("Pending")
      .then((resp) => {
        setManualTypeCount(resp.data.results);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
      });
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleCheckbox = (id) => {
    setSelectedManuals((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

 
  const handleSelect = (_name, value, _) => {
    setSelectedOption(value);
  };

  const handleSelectAll = () => {
    if (selectedManuals.length === data.length) {
      setSelectedManuals([]);
    } else {
      setSelectedManuals(data.map((item) => item.id));
    }
  };

  const handleApprove = () => {
    bulkApproveManual({ manual_id_list: selectedManuals })
      .then((resp) => {
        setData(data.filter((item) => !selectedManuals.includes(item.id)));
        setSelectedManuals([]);
        toast.success(resp.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="tbl-caption d-flex justify-content-between text-wrap flex-column  flex-sm-row align-items-start align-items-sm-center p-3">
                  <div className="row w-75 mt-3">
                    <div className="col-md-4 mt-2 ">
                      <div className="d-flex  align-items-center">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-circle"
                          onClick={() => navigate("/dashboard")}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <h4 className="mb-0 ms-2">Pending Documents</h4>
                      </div>
                    </div>

                    <div className="col-md-5 ">
                    <StaticSelectInput
                        setParentInputValue={handleSelect}
                        optionsList={manualDropdownOptions}
                        name="manual_type"
                        id="manual_type"
                        defaultValue={{
                          label: selectedOption,
                          value: selectedOption,
                        }}
                        isRequired={true}
                      />
                    </div>
                    <div className="col-md-2 text-primary c-pointer fs-3 mt-1" onClick={toggleModal}>
                        <i class="fa-solid fa-circle-info"></i>
                      </div>
                  </div>

                  <div className="d-flex align-items-start align-items-sm-center flex-column flex-sm-row">
                    <span className="badge badge-lg badge-circle badge-outline-info me-3">
                      selected {selectedManuals.length}
                    </span>
                    {selectedManuals.length > 0 && (
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={handleApprove}
                      >
                        Approve
                      </button>
                    )}
                  </div>
                </div>

                {selectedOption === "MANUALS" ? (
                  <ManualTable
                    selectedManuals={selectedManuals}
                    data={data}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                ) : selectedOption === "REFERENCE BOOK" ? (
                  <ReferenceBookTable
                    selectedManuals={selectedManuals}
                    data={data}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                ) : selectedOption === "TENDER DOCUMENT" ? (
                  <TenderDocumentTable
                    selectedManuals={selectedManuals}
                    data={data}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                ) : selectedOption === "CATALOUGE" ? (
                  <CatalougeTable
                    selectedManuals={selectedManuals}
                    data={data}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                ) : selectedOption === "PROJECT REPORT" ? (
                  <ProjectReportTable
                    selectedManuals={selectedManuals}
                    data={data}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                ) : (
                  <CommonManualTable
                    selectedManuals={selectedManuals}
                    data={data}
                    type={selectedOption}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal fade"
        id="exampleModal1"
        show={showModal}
        onHide={toggleModal}
      >
        <div className="modal-content">
          <div className="modal-body">
            <table
              className="table table-sm table-bordered"
            >
              <thead>
                <tr>
                  <th>Document Type</th>
                  <th>Count</th>
                </tr>
              </thead>
              {manualTypeCount && (
                <tbody>
                  <tr>
                    <td>MANUALS</td>
                    <td>{manualTypeCount.manuals}</td>
                  </tr>
                  <tr>
                    <td>REFERENCE BOOK</td>
                    <td>{manualTypeCount.rb}</td>
                  </tr>
                  <tr>
                    <td>CATALOUGE</td>
                    <td>{manualTypeCount.catalouge}</td>
                  </tr>
                  <tr>
                    <td>PROJECT REPORT</td>
                    <td>{manualTypeCount.pr}</td>
                  </tr>
                  <tr>
                    <td>TECHNICAL CALCULATION</td>
                    <td>{manualTypeCount.tc}</td>
                  </tr>
                  <tr>
                    <td>TECHNICAL SPECIFICATION</td>
                    <td>{manualTypeCount.ts}</td>
                  </tr>
                  <tr>
                    <td>TECHNICAL REPORT</td>
                    <td>{manualTypeCount.tr}</td>
                  </tr>
                  <tr>
                    <td>TENDER DOCUMENT</td>
                    <td>{manualTypeCount.td}</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PendingManualPage;
