import React, { useState } from "react";
import { toast } from "react-toastify";
import { Modal, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { updateUserProfile } from "../../../services/UserService";
import { editUserSchema } from "../../constant/schemas/EditProfileSchema";
import { useDispatch } from "react-redux";
import { updateProfileAction } from "../../../store/actions/AuthActions";

function EditProfileModal({ email, phone_number, designation }) {
    const initialValues = {
        email: "",
        phone_number: "",
        designation: "",
    };
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const formikProps = useFormik({
        initialValues,
        validationSchema: editUserSchema,
        onSubmit: async (values) => {
            try {
                const resp = await updateUserProfile(values);
                const { message } = resp.data
                dispatch(updateProfileAction({ ...values }))
                toast.success(message);
                toggleModal(false);
                formikProps.resetForm();
            } catch (error) {
                toast.error(error.response.data.message);
            }
        },
    });
    const { values, errors, touched, handleChange, setValues, handleBlur, handleSubmit } = formikProps;

    const toggleModal = (show) => {
        if (!show) {
            formikProps.resetForm();
        } else {
            setValues({
                email: email,
                phone_number: phone_number,
                designation: designation,
            });
        }
        setShowModal(show);
    };


    return (
        <>
            <button className="btn btn-outline-primary btn-sm"
                onClick={() => toggleModal(true)}>
                <i className="fa-solid fa-pen-to-square"></i> Edit
            </button>
            <Modal
                className="modal fade "
                id="exampleModal1"
                show={showModal}
                centered={true}
                onHide={() => toggleModal(false)}
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel1">
                            Edit Profile
                        </h1>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => toggleModal(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <Form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">
                                    Email Id
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="Enter Email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.email && touched.email ? (
                                    <div className="text-danger small mt-1">
                                        {errors.email}
                                    </div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="phone_number" className="form-label">
                                    Phone Number
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="phone_number"
                                    name="phone_number"
                                    placeholder="Enter Phone Number"
                                    value={values.phone_number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.phone_number && touched.phone_number ? (
                                    <div className="text-danger small mt-1">
                                        {errors.phone_number}
                                    </div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="designation" className="form-label">
                                    Designation
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="designation"
                                    name="designation"
                                    placeholder="Enter Designation"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.designation}
                                />
                                {errors.designation && touched.designation ? (
                                    <div className="text-danger small mt-1">
                                        {errors.designation}
                                    </div>
                                ) : null}
                            </div>

                            <button
                                type="submit"
                                className="btn btn-block btn-primary mt-3 float-end"
                            >
                                Update
                            </button>
                        </Form>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default EditProfileModal;
