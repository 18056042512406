import * as Yup from "yup";

export const editDrawingSchema = Yup.object().shape({
  revision: Yup.number()
    .min(0, "version must be greater than Zero"),
  vendor_number: Yup.string()
    .min(1),
  client_number: Yup.string()
  .min(1),
  work_order_number: Yup.string().min(1),
  package_number: Yup.string()
    .min(1, "Package number must be greater than one"),
  drawing_size: Yup.string().required("Please Select drawing size"),
  supplier_name: Yup.string()
    .min(1)
    .max(250),
  remarks: Yup.string().min(1).required("Please enter remarks"),
  no_of_sheet: Yup.number()
    .min(1, "Number of sheets must be at least 1")
    .required("Number of sheets is required"),
    description: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required("Description is required")
      })
    ).required("Description is required"),
});
