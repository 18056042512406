import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Tab, Nav, Placeholder, NavDropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { getUser } from "../../../services/UserService";
import EditUserModal from "./EditUser";
import ChangePassword from "./ChangePassword";
import DeactivateUserModal from "./DeactivateUser";
import ViewPermission from "./ViewPermission";
import DrawingLogPage from "./DrawingLog";
import LoginActivityPage from "./LoginActivity";
import ManualLogPage from "./ManualLog";
import StandardLogPage from "./StandardLog";
import SIRLogPage from "./SIRLog";
import StabilityCertificateLogPage from "./StabilityCertificateLog";
import ComplianceLogPage from "./ComplianceLog";
import { getUserNameIcon } from "../../../utils";

const UserDetailsLoading = () => {
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="w-100 px-2 mt-3">
            <Placeholder animation="glow">
              <Placeholder xs={12} style={{ height: "20px" }} className="rounded my-2" />
              <Placeholder xs={12} style={{ height: "20px" }} className="rounded my-2" />
              <Placeholder xs={12} style={{ height: "20px" }} className="rounded my-2" />
              <Placeholder xs={12} style={{ height: "20px" }} className="rounded my-2" />
              <Placeholder xs={12} style={{ height: "20px" }} className="rounded my-2" />
              <Placeholder xs={12} style={{ height: "20px" }} className="rounded my-2" />
              <Placeholder xs={12} style={{ height: "20px" }} className="rounded my-2" />
              <Placeholder xs={12} style={{ height: "20px" }} className="rounded my-2" />
              <Placeholder xs={12} style={{ height: "20px" }} className="rounded my-2" />
              <Placeholder xs={12} style={{ height: "20px" }} className="rounded my-2" />
              <Placeholder xs={12} style={{ height: "20px" }} className="rounded my-2" />
            </Placeholder>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-item-center justify-content-between">

            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder xs={12} style={{ height: "20px" }} className="rounded my-2" />
                <Placeholder xs={12} style={{ height: "20px" }} className="rounded my-2" />
              </Placeholder>
            </div>

            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder xs={12} style={{ height: "20px" }} className="rounded my-2" />
                <Placeholder xs={12} style={{ height: "20px" }} className="rounded my-2" />
              </Placeholder>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}



function ShowDetail({ userRole }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("login_activity")

  const navigate = useNavigate();
  const { id } = useParams();

  const handleSelect = (eventKey) => setActiveKey(eventKey);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const resp = await getUser(id);
        const resp_data = resp.data.results;
        setData(resp_data);
      } catch (error) {
        console.error("Error fetching user:", error);
        setData(null)
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            {isLoading ? (
              <UserDetailsLoading />
            ) : data !== null ? (
              <div>
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex  align-items-center">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-circle"
                          onClick={() => navigate("/user")}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <h4 className="mb-0 ms-2">User Details</h4>
                      </div>
                      <div>
                        {(userRole === "Admin" && data?.role === "User") || userRole === "SuperAdmin" ?
                          <EditUserModal data={data} setData={setData} userRole={userRole} /> : null}
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {data ? (
                      <div>
                        <div className="border-bottom p-2">
                          <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                            <div>
                              {data?.profile_photo ? (
                                <div className="profile-pic-lg">
                                  <img src={`${process.env.REACT_APP_BASE_URL}${data.profile_photo}`} />
                                </div>
                              ) : (
                                <div className="avatar-lg-text">
                                  {getUserNameIcon(data?.full_name)}
                                </div>
                              )}
                            </div>
                            <ul>
                              <li className="my-3">
                                <span className="fw-normal">Personnel Number:</span>
                                <span className="fw-bold ms-2">{data?.personnel_number}</span>
                              </li>
                              <li className="my-3">
                                <span className="fw-normal">Full Name:</span>
                                <span className="fw-bold ms-2">{data?.full_name}</span>
                              </li>
                              <li className="my-3">
                                <span className="fw-normal">Email:</span>
                                <span className="fw-bold ms-2">{data?.email}</span>
                              </li>
                              <li className="my-3">
                                <span className="fw-normal">Phone Number:</span>
                                <span className="fw-bold ms-2">{data?.phone_number}</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <ul>
                          <li className="my-3">
                            <span className="fw-bold">Role:</span> <span
                              className={`badge light border-0 ${data.role === "Admin" ? 'badge-primary' : 'badge-info'} badge-sm ms-2`}>
                              {data.role}
                            </span>
                          </li>
                          <li className="my-3">
                            <span className="fw-bold">Status: </span>
                            <span
                              className={`badge light border-0 ${data.status === "Active" ? 'badge-success' : 'badge-danger'} badge-sm ms-2`}>
                              {data.status}
                            </span>
                          </li>
                          <li className="my-3">
                            <span className="fw-bold">Designation:</span>
                            {data.designation}
                          </li>
                          <li className="my-3">
                            <span className="fw-bold">Department:</span>
                            {data.department?.name}
                          </li>

                          {data.role === "User" && (<>
                            <li className="my-3 border-top">
                              <div className="d-flex align-items-center justify-content-between mt-3">
                                <h4 className="fw-semibold mb-0">Permission</h4>
                                <ViewPermission 
                                  drawing_permission={data.drawing_permission}
                                  standard_permission={data.standard_permission}
                                  document_permission={data.document_permission}
                                />
                              </div>
                              <ul>
                                <li className="my-3">
                                  <span className="fw-bold">Drawing Permission:</span> 
                                  <span className="ms-2 fs-5">
                                    {Object.keys(data.drawing_permission).filter(key => data.drawing_permission[key]).length}
                                    </span>
                                </li>
                                <li className="my-3">
                                  <span className="fw-bold">Standard Permission:</span> 
                                  <span className="ms-2 fs-5">
                                    {Object.keys(data.standard_permission).filter(key => data.standard_permission[key]).length}
                                    </span>
                                </li>

                                <li className="my-3">
                                  <span className="fw-bold">Document Permission:</span> 
                                  <span className="ms-2 fs-5">
                                    {Object.keys(data.document_permission).filter(key => data.document_permission[key]).length}
                                    </span>
                                </li>
                              </ul>
                            </li>
                          </>)}

                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
                {(userRole === "Admin" && data?.role === "User") || userRole === "SuperAdmin" ?
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between my-2 py-2">
                        <h5 className="mb-md-0">Change User Password</h5>
                        <ChangePassword />
                      </div>
                      <div className="d-flex align-items-center justify-content-between border-top my-2 py-3">
                        <DeactivateUserModal data={{ id, status: data?.status || "Active" }} setData={setData} />
                      </div>
                    </div>
                  </div>
                  : null}
              </div>
            ) : (
              <div className="card">
                <div className="card-body">
                  <h4 className="text-center text-danger">⚠️ User Not Found!<br /> ID {id}</h4>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-8">
            <div className="card">
              <div className="custom-tab-1">
                <Tab.Container activeKey={activeKey} onSelect={handleSelect}>
                  <Nav
                    as="ul"
                    className="nav-pills mb-2 light border-bottom px-4 pt-3"
                  >
                    <Nav.Item as="li">
                      <Nav.Link eventKey="login_activity" className="pb-3 fw-semibold fs-5">
                        Login Activity
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link eventKey="drawing_log" className="pb-3 fw-semibold fs-5">
                        Drawing Log
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link eventKey="standard_log" className="pb-3 fw-semibold fs-5">
                        Standard Log
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link eventKey="manual_log" className="pb-3 fw-semibold fs-5">
                        Document Log
                      </Nav.Link>
                    </Nav.Item>
                    <NavDropdown title="SI Log" id="nav-dropdown" className={`custom-nav-dropdown ${["sir", "sc", "compliance"].includes(activeKey) ? 'active' : ''}`}>
                      <NavDropdown.Item eventKey="sir">SIR</NavDropdown.Item>
                      <NavDropdown.Item eventKey="sc">Stability Certificate</NavDropdown.Item>
                      <NavDropdown.Item eventKey="compliance">Compliance</NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                  <Tab.Content className="">
                    <Tab.Pane eventKey={"login_activity"}><LoginActivityPage /></Tab.Pane>
                    <Tab.Pane eventKey={"drawing_log"}><DrawingLogPage /></Tab.Pane>
                    <Tab.Pane eventKey={"standard_log"}><StandardLogPage /></Tab.Pane>
                    <Tab.Pane eventKey={"manual_log"}><ManualLogPage /></Tab.Pane>
                    <Tab.Pane eventKey={"sir"}><SIRLogPage /></Tab.Pane>
                    <Tab.Pane eventKey={"sc"}><StabilityCertificateLogPage /></Tab.Pane>
                    <Tab.Pane eventKey={"compliance"}><ComplianceLogPage /></Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user?.role || "Admin"
  };
};

export default connect(mapStateToProps)(ShowDetail);
