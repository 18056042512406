import axiosInstance from "./AxiosInstance";
const apiUrl = "api/manual";  

export function getManualList(page, page_size, query, manual_type) {
  return axiosInstance.get(
    `${apiUrl}?page=${page}&page_size=${page_size}&query=${query}&manual_type=${manual_type}`
  );
}

export function getManual(id) {
  return axiosInstance.get(`${apiUrl}/${id}`);
}

export function createManual(data) {
  return axiosInstance.post(apiUrl, data);
}

export function updateManual(data) {
  return axiosInstance.put(apiUrl, data);
}

export function uploadFile(id, file) {
  return axiosInstance.post(`api/manual_file_upload/${id}` , file)
}

export function downloadManualPDFFiles(id) {
  return axiosInstance.get(`api/manual_file_download/${id}`, {
    responseType: "blob",
  });
}

export function updateManualStatus(data) {
  return axiosInstance.post('api/manual_archive', data);
}


export function getManualLogList(id, page, page_size, query) {
  return axiosInstance.get(
    `api/manual_log/${id}?page=${page}&page_size=${page_size}&query=${query}`
  );
}

export function approveManual(data) {
  return axiosInstance.post("api/manual_approve", data);
}

export function getBulkManualList(query, manual_type) {
  return axiosInstance.get(
    `api/manual_bulk_archive?query=${query}&manual_type=${manual_type}`
  );
}

export function updateBulkManualList(data) {
  return axiosInstance.put("api/manual_bulk_archive", data);
}

export function archiveManualList(query, manual_type) {
  return axiosInstance.get(
    `api/manual_archive?query=${query}&manual_type=${manual_type}`
  );
}

export function removeArchiveManualList(data) {
  return axiosInstance.put("api/manual_archive", data);
}

export function permanentDeleteManualList(data) {
  return axiosInstance.post("api/manual_permanent_delete", data);
}

export function approveManualList(manual_type) {
  return axiosInstance.get(`api/manual_approve?manual_type=${manual_type}`);
}

export function bulkApproveManual(data) {
  return axiosInstance.put("api/manual_approve", data);
}

export function bulkUploadManualData(data) {
  return axiosInstance.post('api/manual_bulk_upload' , data)
}

export function getManualCount(status_type) {
  return axiosInstance.get(`api/manual_count?status_type=${status_type}`);
}