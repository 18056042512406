import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

function ReferenceBookForm({ handleSubmit, isLoading }) {
  const [manuals, setManuals] = useState({
    manual_number: "",
    editor: "",
    source: "",
    author: "",
    description: "",
    file: "",
  });

  const [manualsInputError, setManualsInputError] = useState({
    manual_number: false,
  });

  const handleFiles = (file_list) => {
    if (file_list.length) {
      const file = file_list[0];
      const file_ext = file.name.split(".").pop().toUpperCase();
      if (file_ext !== "PDF") {
        toast.error("Please choose a valid PDF file.");
      } else {
        setManuals({ ...manuals, file: file });
      }
    }
  };

  const handleFileInputChange = (e) => {
    const filelist = e.target.files || [];
    handleFiles(filelist);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setManuals({ ...manuals, [name]: value });

    if (name === "manual_number") {
      setManualsInputError({
        ...manualsInputError,
        manual_number: value.trim() === "",
      });
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (manuals.manual_number.trim() === "") {
      setManualsInputError({ ...manualsInputError, manual_number: true });
    } else {
      handleSubmit(e, manuals);
    }
  };

  return (
    <Form onSubmit={handleSubmitForm}>
      <div className="row">
        <div className="col-xl-6 col-sm-12">
          <div className="mb-3">
            <label htmlFor="manual_number" className="form-label">
              Book Number <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${
                manualsInputError.manual_number && "is-invalid"
              }`}
              id="manual_number"
              placeholder="Enter Book Number"
              name="manual_number"
              value={manuals.manual_number}
              onChange={handleChange}
            />
            {manualsInputError.manual_number && (
              <div className="invalid-feedback mt-2">Book No. is required.</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="editor" className="form-label">
              Editor
            </label>
            <input
              type="text"
              className="form-control"
              id="editor"
              placeholder="Enter Editor"
              name="editor"
              value={manuals.editor}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              Description
            </label>
            <textarea
              type="text"
              className="form-control"
              id="description"
              placeholder="Enter Description"
              name="description"
              value={manuals.description}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className="col-xl-6 col-sm-12 mx-auto">
          <div className="mb-3">
            <label htmlFor="source" className="form-label">
              Source
            </label>
            <input
              type="text"
              className="form-control"
              id="source"
              placeholder="Enter Source"
              name="source"
              value={manuals.source}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="author" className="form-label">
              Author
            </label>
            <input
              type="text"
              className="form-control"
              id="author"
              placeholder="Enter Author"
              name="author"
              value={manuals.author}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="pdfFile" className="form-label">
              Upload File
            </label>
            <input
              type="file"
              className="form-control"
              id="file"
              name="file"
              onChange={handleFileInputChange}
              accept=".pdf"
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-2">
        <button
          type="submit"
          disabled={isLoading}
          className="btn btn-primary me-1 w-50"
        >
         {isLoading ? (<span><Spinner size="sm" className="me-2" />Creating...</span>) : "Create"}
        </button>
      </div>
    </Form>
  );
}

export default ReferenceBookForm;
