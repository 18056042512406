export const manualDropdownOptions = [
    { label: "MANUALS", value: "MANUALS" },
    { label: "REFERENCE BOOK", value: "REFERENCE BOOK" },
    { label: "TENDER DOCUMENT", value: "TENDER DOCUMENT" },
    { label: "CATALOUGE", value: "CATALOUGE" },
    { label: "TECHNICAL CALCULATION", value: "TECHNICAL CALCULATION" },
    { label: "TECHNICAL SPECIFICATION", value: "TECHNICAL SPECIFICATION" },
    { label: "TECHNICAL REPORT", value: "TECHNICAL REPORT" },
    { label: "PROJECT REPORT", value: "PROJECT REPORT" },
    { label: "PROJECT SUBMITTED DRAWINGS", value: "PROJECT SUBMITTED DRAWINGS" },
  ];


  export const standardDropdownOptions = [
    { label: "BIS", value: "BIS" },
    { label: "IPSS", value: "IPSS" },
    { label: "RSN", value: "RSN" },
    { label: "AWWA", value: "AWWA" },
    { label: "ASTM", value: "ASTM" },
    { label: "API", value: "API" },
    { label: "BRITISH", value: "BRITISH" },
    { label: "DIN(GERMAN)", value: "DIN(GERMAN)" },
    { label: "GOST(RUSSIAN)", value: "GOST(RUSSIAN)" },
    { label: "IEC", value: "IEC" },
    { label: "ISO", value: "ISO" },
    { label: "IRST", value: "IRST" },
    { label: "PSN", value: "PSN" },
  ];
  