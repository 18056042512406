import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner, Modal } from "react-bootstrap";
import { getManualCount } from "../../../services/ManualService";
import { manualDropdownOptions } from "../../constant/Variable";
import { StaticSelectInput } from "../../constant/SelectInput";
import {
  archiveManualList,
  removeArchiveManualList,
  permanentDeleteManualList,
} from "../../../services/ManualService";
import CommonManualArchivePage from "./Manual/CommonManual";
import ManualBulkArchivePage from "./Manual/Manuals";
import ReferenceBookArchivePage from "./Manual/ReferenceBook";
import TenderDocumentArchivePage from "./Manual/TenderDocument";
import CatalougeArchivePage from "./Manual/Catalouge";
import ProjectReportArchivePage from "./Manual/ProjectReport";


const dropdownOptions = [
  { label: "MANUALS", value: "MANUALS" },
  { label: "REFERENCE BOOK", value: "REFERENCE BOOK" },
  { label: "TENDER DOCUMENT", value: "TENDER DOCUMENT" },
  { label: "CATALOUGE", value: "CATALOUGE" },
  { label: "TECHNICAL CALCULATION", value: "TECHNICAL CALCULATION" },
  { label: "TECHNICAL SPECIFICATION", value: "TECHNICAL SPECIFICATION" },
  { label: "TECHNICAL REPORT", value: "TECHNICAL REPORT" },
  { label: "PROJECT REPORT", value: "PROJECT REPORT" },
];

const ArchiveManualPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultSelectedOption = queryParams.get("manual_type") || "MANUALS";
  const [selectedOption, setSelectedOption] = useState(defaultSelectedOption);
  const [manualList, setManualList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedManuals, setSelectedManuals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isShowArchiveForm, setIsShowArchiveForm] = useState(false);
  const [isShowDeleteForm, setIsShowDeleteForm] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSubmitLoadingDel, setIsSubmitLoadingDel] = useState(false);
  const [manualTypeCount, setManualTypeCount] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const manualType = queryParams.get("manual_type");
    if(manualType){
      setSelectedOption(manualType);
      navigate(location.pathname, { replace: true }); 
    }
  }, [location.search]);

  useEffect(() => {
    setIsLoading(true);
    archiveManualList(searchQuery, selectedOption)
      .then((resp) => {
        setManualList(resp.data.results);
        setIsLoading(false);
        setIsSearchLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          setIsLoading(false);
          setIsSearchLoading(false);
        } else {
          console.log(error);
        }
      });
  }, [searchQuery, selectedOption]);

  useEffect(() => {
    setSelectedManuals([]);
  }, [selectedOption]);

  useEffect(() => {
    getManualCount("Archived")
      .then((resp) => {
        setManualTypeCount(resp.data.results);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
        } else {
          console.log(error);
        }
      });
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (value) {
      setIsSearchLoading(true);
    }
    setSearchQuery(value);
  };

  const toggleArchiveForm = () => {
    setIsShowArchiveForm(!isShowArchiveForm);
  };

  const toggleDeleteForm = () => {
    setIsShowDeleteForm(!isShowDeleteForm);
  };

  const toggleCheckbox = (id) => {
    setSelectedManuals((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((item) => item !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleSelect = (name, selectedValue, _) => {
    setSelectedOption(selectedValue);
  };

  const handleSelectAll = () => {
    if (selectedManuals.length === manualList.length) {
      setSelectedManuals([]);
    } else {
      setSelectedManuals(manualList.map((item) => item.id));
    }
  };

  const handleArchiveSubmit = () => {
    if (isSubmitLoadingDel || isSubmitLoading) {
      return;
    }
    setIsSubmitLoading(true);
    removeArchiveManualList({ manual_id_list: selectedManuals })
      .then((resp) => {
        toast.success(resp.data.message);
        setIsSubmitLoading(false);
        setSelectedManuals([]);
        toggleArchiveForm();
        navigate(`/documents?manual_type=${selectedOption}`);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
          console.log(error.response.data.message);
          setIsSubmitLoading(false);
          setSelectedManuals([]);
          toggleArchiveForm();
        } else {
          console.log(error);
        }
      });
  };

  const handleDeleteSubmit = () => {
    if (isSubmitLoadingDel || isSubmitLoading) {
      return;
    }
    setIsSubmitLoadingDel(true);
    permanentDeleteManualList({ manual_id_list: selectedManuals })
      .then((resp) => {
        toast.success(resp.data.message);
        setIsSubmitLoadingDel(false);
        setSelectedManuals([]);
        toggleDeleteForm();
        navigate(`/documents?manual_type=${selectedOption}`);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
          console.log(error.response.data.message);
          setIsSubmitLoadingDel(false);
          setSelectedManuals([]);
          toggleDeleteForm();
        } else {
          console.log(error);
        }
      });
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="px-3 py-3">
                  <div className="tbl-caption d-flex justify-content-between text-wrap align-items-start align-items-sm-center flex-column flex-sm-row">
                  <div className="d-flex  align-items-center">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-circle"
                        onClick={() => navigate("/dashboard")}
                      >
                        <i className="fa-solid fa-arrow-left"></i>
                      </button>
                      <h5 className="mb-0 ms-2 fw-bold me-4">Archived Documents</h5>
                      <div style={{ width: "200px" }} className="me-3">
                        <StaticSelectInput
                          setParentInputValue={handleSelect}
                          parentClassName="mb-0"
                          optionsList={manualDropdownOptions}
                          name="manual_type"
                          id="manual_type"
                          defaultValue={{
                            label: selectedOption,
                            value: selectedOption,
                          }}
                          isRequired={true}
                        />
                      </div>
                      <div className="text-primary c-pointer fs-3" onClick={toggleModal}>
                        <i class="fa-solid fa-circle-info"></i>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="input-group tp-input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            value={searchQuery}
                            aria-describedby="basic-search"
                            onChange={handleSearchChange}
                          />
                          <div className="input-group-text" id="basic-search">
                            {isSearchLoading && (
                              <Spinner variant="primary" size="sm" />
                            )}
                            {!isSearchLoading && searchQuery.length > 0 ? (
                              <i
                                className="fa-solid fa-xmark c-pointer text-primary"
                                onClick={() => {
                                  setSearchQuery("");
                                }}
                              ></i>
                            ) : null}
                          </div>
                        </div>
                    </div>
                  </div>
                  {selectedManuals.length > 0 && (
                    <div className="row mt-3">
                      <div className="col-sm-6">
                        <div className="bg-success-light rounded p-2">
                          <h4 className="text-center">
                            Total <strong>{selectedManuals.length}</strong>
                            Manuals selected.
                          </h4>
                          {isShowArchiveForm ? (
                            <div className="px-3">
                              <p className="text-center fw-bold text-success">
                                Are you sure, you want to unarchive the selected
                                manuals.
                              </p>
                              <center className="mt-3 mb-2">
                                <button
                                  className="btn btn-success btn-sm me-2"
                                  disabled={isSubmitLoading}
                                  onClick={handleArchiveSubmit}
                                >
                                  {isSubmitLoading ? (
                                    <span>
                                      <Spinner size="sm" className="me-2" />
                                      Confirm...
                                    </span>
                                  ) : (
                                    "Confirm"
                                  )}
                                </button>
                                <button
                                  className="btn btn-outline-danger btn-sm"
                                  disabled={isSubmitLoading}
                                  onClick={toggleArchiveForm}
                                >
                                  Cancel
                                </button>
                              </center>
                            </div>
                          ) : (
                            <>
                              <p className="text-center fw-bold text-success">
                                To unarchive selected manuals, please click
                                below to continue.
                              </p>
                              <center>
                                <button
                                  className="btn btn-success btn-sm"
                                  onClick={toggleArchiveForm}
                                >
                                  Continue
                                </button>
                              </center>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="bg-danger-light rounded p-2">
                          <h4 className="text-center">
                            Total <strong>{selectedManuals.length}</strong>
                            Manuals selected.
                          </h4>
                          {isShowDeleteForm ? (
                            <div className="px-3">
                              <p className="text-center text-danger fw-bold">
                                Are you sure, you want to permanently delete the
                                selected manuals.
                              </p>
                              <center className="mt-3 mb-2">
                                <button
                                  className="btn btn-primary btn-sm me-2"
                                  disabled={isSubmitLoadingDel}
                                  onClick={handleDeleteSubmit}
                                >
                                  {isSubmitLoadingDel ? (
                                    <span>
                                      <Spinner size="sm" className="me-2" />
                                      Confirm...
                                    </span>
                                  ) : (
                                    "Confirm"
                                  )}
                                </button>
                                <button
                                  className="btn btn-outline-danger btn-sm"
                                  disabled={isSubmitLoadingDel}
                                  onClick={toggleDeleteForm}
                                >
                                  Cancel
                                </button>
                              </center>
                            </div>
                          ) : (
                            <>
                              <p className="text-center fw-bold text-danger">
                                To permanently delete selected manuals, please
                                click below to continue.
                              </p>
                              <center>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={toggleDeleteForm}
                                >
                                  Continue
                                </button>
                              </center>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {selectedOption === "MANUALS" ? (
                  <ManualBulkArchivePage
                    selectedManuals={selectedManuals}
                    manualList={manualList}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                ) : selectedOption === "REFERENCE BOOK" ? (
                  <ReferenceBookArchivePage
                    selectedManuals={selectedManuals}
                    manualList={manualList}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                ) : selectedOption === "TENDER DOCUMENT" ? (
                  <TenderDocumentArchivePage
                    selectedManuals={selectedManuals}
                    manualList={manualList}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                ) : selectedOption === "CATALOUGE" ? (
                  <CatalougeArchivePage
                    selectedManuals={selectedManuals}
                    manualList={manualList}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                ) : selectedOption === "PROJECT REPORT" ? (
                  <ProjectReportArchivePage
                    selectedManuals={selectedManuals}
                    manualList={manualList}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                ) : (
                  <CommonManualArchivePage
                    selectedManuals={selectedManuals}
                    manualList={manualList}
                    type={selectedOption}
                    isLoading={isLoading}
                    toggleCheckbox={toggleCheckbox}
                    handleSelectAll={handleSelectAll}
                    navigate={navigate}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal fade"
        id="exampleModal1"
        show={showModal}
        onHide={toggleModal}
      >
        <div className="modal-content">
          <div className="modal-body">
            <table
              className="table table-sm table-bordered"
            >
              <thead>
                <tr>
                  <th>Manual Type</th>
                  <th>Count</th>
                </tr>
              </thead>
              {manualTypeCount && (
                <tbody>
                  <tr>
                    <td>MANUALS</td>
                    <td>{manualTypeCount.manuals}</td>
                  </tr>
                  <tr>
                    <td>REFERENCE BOOK</td>
                    <td>{manualTypeCount.rb}</td>
                  </tr>
                  <tr>
                    <td>CATALOUGE</td>
                    <td>{manualTypeCount.catalouge}</td>
                  </tr>
                  <tr>
                    <td>PROJECT REPORT</td>
                    <td>{manualTypeCount.pr}</td>
                  </tr>
                  <tr>
                    <td>TECHNICAL CALCULATION</td>
                    <td>{manualTypeCount.tc}</td>
                  </tr>
                  <tr>
                    <td>TECHNICAL SPECIFICATION</td>
                    <td>{manualTypeCount.ts}</td>
                  </tr>
                  <tr>
                    <td>TECHNICAL REPORT</td>
                    <td>{manualTypeCount.tr}</td>
                  </tr>
                  <tr>
                    <td>TENDER DOCUMENT</td>
                    <td>{manualTypeCount.td}</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ArchiveManualPage;
