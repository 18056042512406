import React from 'react';
const FileDownloader = ({ id, isDownload = false, fileName = "" }) => {
    const viewDrawingFile = async () => {
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/api/manual_file_download/${id}`
            window.open(url, '_blank');
        } catch (error) {
            console.error('Error fetching or opening file:', error);
        }
    };


    return (
        <button className="btn btn-outline-primary btn-sm mb-3 mb-md-0"
            onClick={() => viewDrawingFile()}>
            {isDownload ? <span><i className="fa-solid fa-download me-2" /> Download</span> : <span> <i className="fa-solid fa-eye me-2" /> View</span>}
        </button>
    )
};

export default FileDownloader;