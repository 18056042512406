import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { navtoggle } from "../../../store/actions/AuthActions";
import logoText from "../../../images/logo/RSP_logo_text.png";
import logo from "../../../images/logo/logo.png";

const NavHader = () => {
  const dispatch = useDispatch();
  const sideMenu = useSelector((state) => state.sideMenu);
  const handleToogle = () => {
    dispatch(navtoggle());
  };
  return (
    <>
      <div className="nav-header ">
        <Link to="/dashboard" className="brand-logo">
          <img src={logo} alt="logo" style={{ width: "3rem" }} />
          <span className="brand-title">
            <img src={logoText} alt="logo" style={{ width: "9rem" }} />
          </span>
        </Link>
          <div
            className="nav-control"
            onClick={() => {
              handleToogle();
            }}
          >
            <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          </div>
      </div>
      <div className="nav-header-bar"></div>
    </>
  );
};


export default NavHader;
