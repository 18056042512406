import axiosInstance from "./AxiosInstance";
export function login(personnel_number, password) {
  const postData = {
    personnel_number,
    password,
  };
  return axiosInstance.post(`api/auth/login`, postData);
}

export function logoutApi() {
  return axiosInstance.get('api/auth/logout');
}

export function getVisitorCountApi() {
  return axiosInstance.get('api/visitor_count');
}

export function whoami() {
  return axiosInstance.get("api/auth/authorized");
}
