import React from "react";
import Notice from "./Notice";
import Slider from "./Slider";

const NoticePage = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6">
          <Notice />
          </div>
          <div className="col-xl-6">
          <Slider />
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticePage;
