import React, { useState } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import { updateUserStatus } from "../../../services/UserService";
import { toast } from "react-toastify";

function DeactivateUserModal({ data, setData }) {
  const [confirmMSG, setConfirmMSG] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const toggleModal = (show) => {
    setShowModal(show);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitLoading(true);
      const resp = await updateUserStatus({
        id: data.id,
        status: data.status === "Active" ? "INACTIVE" : "ACTIVE",
      });
      toast.success(resp.data.message);
      setData(resp.data.results);
      setConfirmMSG("");
      toggleModal(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsSubmitLoading(false);
  };

  return (
    <>
      <h5 className="mb-md-0">
        {data.status === "Active" ? "Deactivate" : "Activate"} User
      </h5>
      <button
        className={`btn ${data.status === "Active" ? 'btn-danger' : 'btn-success'} btn-sm cursor-pointer fw-bolder`}
        onClick={() => toggleModal(true)}
      >
        {data.status === "Active" ? "Deactivate" : "Activate"}
      </button>
      <Modal
        className="modal modal-sm fade"
        id="exampleModal1"
        show={showModal}
        centered={true}
        onHide={() => toggleModal(false)}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              {data.status === "Active" ? "Deactivate" : "Activate"} User
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={() => toggleModal(false)}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleOnSubmit}>
              <div className="row">
                <div className="mb-3">
                  <label htmlFor="deactivate" className="form-label">
                  {`Type "${data.status === "Active" ? "DEACTIVATE" : "ACTIVATE"}" to confirm`}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="confirmMSG"
                    name="confirmMSG"
                    placeholder={`Type ${
                      data.status === "Active" ? "DEACTIVATE" : "ACTIVATE"
                    }`}
                    value={confirmMSG}
                    onChange={(e) => setConfirmMSG(e.target.value)}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
                disabled={
                  isSubmitLoading ||
                  !(data.status === "Active"
                    ? confirmMSG === "DEACTIVATE"
                    : confirmMSG === "ACTIVATE")
                }
              >
                {isSubmitLoading ? (
                  <span>
                    <Spinner size="sm" className="ms-2" />
                    {data.status === "Active" ? "Deactivate" : "Activate"}
                  </span>
                ) : data.status === "Active" ? (
                  "Deactivate"
                ) : (
                  "Activate"
                )}
              </button>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DeactivateUserModal;
