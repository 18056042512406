import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatFileSize } from "../../../utils";
import { Modal, Spinner, Form } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getStandard,
  downloadStandardPDFFiles,
  approveStandard,
} from "../../../services/StandardService";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Placeholder } from "react-bootstrap";
import EditBisModal from "./BIS/EditBISModal";
import EditIPSSModal from "./IPSS/EditIPSSModal";
import EditRSNModal from "./RSN/EditRSNModal";
import EditCommonStandardModal from "./CommonStandards/EditCommonStandard";
import StandardLogPage from "./StandardLog";
import ArchiveStandardModal from "./ArchiveStandard";

const StandardDetailsLoading = () => {
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="w-100 px-2 mt-3">
            <Placeholder animation="glow">
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
            </Placeholder>
          </div>
        </div>
      </div>
    </div>
  );
};

function StandardDetail({ userRole }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isApprovedLoading, setIsApprovedLoading] = useState(false);
  const [isApprovedCheck, setIsApprovedCheck] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    getStandard(id)
      .then((resp) => {
        const resp_data = resp.data.result;
        setData(resp_data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [id]);

  const viewPDFFile = async (id) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/api/standard_file_download/${id}`
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error fetching or opening file:', error);
    }
  };

  const toggleModal = () => {
    if (showModal) {
      setIsApprovedCheck(false);
      setIsApprovedLoading(false);
    }
    setShowModal(!showModal);
  };

  const handleApprovedSubmit = async (e) => {
    e.preventDefault();
    if (!isApprovedCheck) {
      return;
    }
    try {
      setIsApprovedLoading(true);
      const resp = await approveStandard({ id: data.id });
      toast.success(resp.data.message);
      setData({ ...data, is_approved: true });
      toggleModal();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsApprovedLoading(false);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5">
            {isLoading ? (
              <StandardDetailsLoading />
            ) : data ? (
              <div>
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex  align-items-center">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-circle"
                          onClick={() =>
                            navigate(
                              `/standards?standard_type=${data.standard_type}`
                            )
                          }
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <h4 className="mb-0 ms-2">Standard Details</h4>
                      </div>
                      <div>
                        {userRole !== "User" &&
                          (data.standard_type === "BIS" ? (
                            <EditBisModal data={data} setData={setData} />
                          ) : data.standard_type === "IPSS" ? (
                            <EditIPSSModal data={data} setData={setData} />
                          ) : data.standard_type === "RSN" ? (
                            <EditRSNModal data={data} setData={setData} />
                          ) : (
                            <EditCommonStandardModal
                              data={data}
                              setData={setData}
                              type={data.standard_type}
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {data ? (
                      data.standard_type === "BIS" ? (
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Standard Type :</span>
                              <span className="fw-semibold">
                                {data.standard_type}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>BIS No. :</span>
                              <span className="fw-semibold">
                                {data.standard_no}
                              </span>
                            </div>

                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Part No. :</span>
                              <span className="fw-semibold">
                                {data.part_no || "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Section No. :</span>
                              <span className="fw-semibold">
                                {data.section_no || "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Document Year :</span>
                              <span className="fw-semibold">
                                {data.document_year || "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Division. :</span>
                              <span className="fw-semibold">
                                {data.division || "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Division Code :</span>
                              <span className="fw-semibold">
                                {data.division_code || "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Status </span>
                              {data.is_approved === true ? (
                                <span
                                  className={`badge badge-sm ms-2 light border-0 badge-success`}
                                >
                                  Approved
                                </span>
                              ) : (
                                <span
                                  className={`badge badge-sm ms-2 light border-0 badge-info ${userRole === "SuperAdmin" &&
                                    !data.is_approved &&
                                    "cursor-pointer"
                                    }`}
                                  onClick={() => {
                                    if (
                                      !data.is_approved &&
                                      userRole === "SuperAdmin"
                                    ) {
                                      toggleModal();
                                    }
                                  }}
                                >
                                  Pending
                                </span>
                              )}
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Committee Code :</span>
                              <span className="fw-semibold">
                                {data.committee_code || "None"}
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 border-start pb-0">
                            {!data.file ? (
                              <div className="text-center ">
                                No PDF File Present
                              </div>
                            ) : (
                              <div className="text-center mt-5">
                                <div className="fs-4 mb-3">View PDF File</div>
                                <div className="">
                                  {data.file ? (
                                    <>
                                      <span className="ms-2 me-2">
                                        {data.file.name}
                                      </span>
                                      <span>
                                        {formatFileSize(data.file.size)}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm mt-3"
                                  onClick={() => viewPDFFile(data.id)}
                                >
                                  <span className="d-flex align-items-center">
                                    <i className="fa-regular fa-eye me-2"></i>
                                    <span>
                                      {isSubmitLoading ? "Loading" : "View"}
                                    </span>
                                  </span>
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-12 border-top pt-0">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                              <span>Committee Title :</span>
                              <span className="fw-semibold">
                                {data.committee_title || "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span className="me-5">Description:</span>
                              <span className="fw-semibold">
                                {data.description || "None"}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : data.standard_type === "RSN" ? (
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Standard Type :</span>
                              <span className="fw-semibold">
                                {data.standard_type}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>RSN No. :</span>
                              <span className="fw-semibold">
                                {data.standard_no}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span className="me-5">Volume:</span>
                              <span className="fw-semibold">
                                {data.volume ? data.volume.name : "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Status </span>
                              {data.is_approved === true ? (
                                <span
                                  className={`badge badge-sm ms-2 light border-0 badge-success`}
                                >
                                  Approved
                                </span>
                              ) : (
                                <span
                                  className={`badge badge-sm ms-2 light border-0 badge-info ${userRole === "SuperAdmin" &&
                                    !data.is_approved &&
                                    "cursor-pointer"
                                    }`}
                                  onClick={() => {
                                    if (
                                      !data.is_approved &&
                                      userRole === "SuperAdmin"
                                    ) {
                                      toggleModal();
                                    }
                                  }}
                                >
                                  Pending
                                </span>
                              )}
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Number Of Sheet :</span>
                              <span className="fw-semibold">
                                {data.no_of_sheet || "None"}
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 border-start pb-0">
                            {!data.file ? (
                              <div className="text-center ">
                                No PDF File Present
                              </div>
                            ) : (
                              <div className="text-center">
                                <div className="fs-4 mb-3">View PDF File</div>
                                <div className="">
                                  {data.file ? (
                                    <>
                                      <span className="ms-2 me-2">
                                        {data.file.name}
                                      </span>
                                      <span>
                                        {formatFileSize(data.file.size)}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm mt-3"
                                  onClick={() => viewPDFFile(data.id)}
                                >
                                  <span className="d-flex align-items-center">
                                    <i className="fa-regular fa-eye me-2"></i>
                                    <span>
                                      {isSubmitLoading ? "Loading" : "View"}
                                    </span>
                                  </span>
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-12 border-top pt-0">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                              <span className="me-5">Group:</span>
                              <span className="fw-semibold">
                                {data.group ? data.group.name : "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span className="me-5">Description:</span>
                              <span className="fw-semibold">
                                {data.description || "None"}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : data.standard_type === "IPSS" ? (
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Standard Type :</span>
                              <span className="fw-semibold">
                                {data.standard_type}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>IPSS No. :</span>
                              <span className="fw-semibold">
                                {data.standard_no}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span className="me-5">Title:</span>
                              <span className="fw-semibold">
                                {data.title ? data.title.title : "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>File Availability :</span>
                              <span className="fw-semibold">
                                {data.file_availability ? (
                                  <span className="fw-semibold text-success">
                                    YES
                                  </span>
                                ) : (
                                  <span className="fw-semibold text-danger">
                                    NO
                                  </span>
                                )}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Status </span>
                              {data.is_approved === true ? (
                                <span
                                  className={`badge badge-sm ms-2 light border-0 badge-success`}
                                >
                                  Approved
                                </span>
                              ) : (
                                <span
                                  className={`badge badge-sm ms-2 light border-0 badge-info ${userRole === "SuperAdmin" &&
                                    !data.is_approved &&
                                    "cursor-pointer"
                                    }`}
                                  onClick={() => {
                                    if (
                                      !data.is_approved &&
                                      userRole === "SuperAdmin"
                                    ) {
                                      toggleModal();
                                    }
                                  }}
                                >
                                  Pending
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 border-start pb-0">
                            {!data.file ? (
                              <div className="text-center ">
                                No PDF File Present
                              </div>
                            ) : (
                              <div className="text-center">
                                <div className="fs-4 mb-3">View PDF File</div>
                                <div className="">
                                  {data.file ? (
                                    <>
                                      <span className="ms-2 me-2">
                                        {data.file.name}
                                      </span>
                                      <span>
                                        {formatFileSize(data.file.size)}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm mt-3"
                                  onClick={() => viewPDFFile(data.id)}
                                >
                                  <span className="d-flex align-items-center">
                                    <i className="fa-regular fa-eye me-2"></i>
                                    <span>
                                      {isSubmitLoading ? "Loading" : "View"}
                                    </span>
                                  </span>
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-12 border-top pt-0">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                              <span className="me-5">Description:</span>
                              <span className="fw-semibold">
                                {data.description || "None"}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-sm-12 col-md-6">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Standard Type :</span>
                              <span className="fw-semibold">
                                {data.standard_type}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>{data.standard_type} No. :</span>
                              <span className="fw-semibold">
                                {data.standard_no}
                              </span>
                            </div>

                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Part No. :</span>
                              <span className="fw-semibold">
                                {data.part_no || "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Section No. :</span>
                              <span className="fw-semibold">
                                {data.section_no || "None"}
                              </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Status </span>
                              {data.is_approved === true ? (
                                <span
                                  className={`badge badge-sm ms-2 light border-0 badge-success`}
                                >
                                  Approved
                                </span>
                              ) : (
                                <span
                                  className={`badge badge-sm ms-2 light border-0 badge-info ${userRole === "SuperAdmin" &&
                                    !data.is_approved &&
                                    "cursor-pointer"
                                    }`}
                                  onClick={() => {
                                    if (
                                      !data.is_approved &&
                                      userRole === "SuperAdmin"
                                    ) {
                                      toggleModal();
                                    }
                                  }}
                                >
                                  Pending
                                </span>
                              )}
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                              <span>Document Year :</span>
                              <span className="fw-semibold">
                                {data.document_year || "None"}
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 border-start pb-0">
                            {!data.file ? (
                              <div className="text-center ">
                                No PDF File Present
                              </div>
                            ) : (
                              <div className="text-center mt-5">
                                <div className="fs-4 mb-3">View PDF File</div>
                                <div className="">
                                  {data.file ? (
                                    <>
                                      <span className="ms-2 me-2">
                                        {data.file.name}
                                      </span>
                                      <span>
                                        {formatFileSize(data.file.size)}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm mt-3"
                                  onClick={() => viewPDFFile(data.id)}
                                >
                                  <span className="d-flex align-items-center">
                                    <i className="fa-regular fa-eye me-2"></i>
                                    <span>
                                      {isSubmitLoading ? "Loading" : "View"}
                                    </span>
                                  </span>
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-12 border-top pt-0">
                            <div className="d-flex align-items-center justify-content-between mt-3 mb-3">
                              <span className="me-5">Description:</span>
                              <span className="fw-semibold">
                                {data.description || "None"}
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
                {userRole === "SuperAdmin" && (
                  <div className="card">
                    <div className="card-body">
                      <div className="mb-3 d-flex align-items-center justify-content-between">
                        <ArchiveStandardModal data={data} setData={setData} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="card">
                <div className="card-body">
                  <h4 className="text-center text-danger">
                    ⚠️ Standard Not Found!
                    <br /> ID {id}
                  </h4>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-7">
            <div className="card">
              <StandardLogPage />
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal fade modal-sm"
        id="exampleModal1"
        show={showModal}
        onHide={toggleModal}
        centered={true}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel1">
              Approve Standards
            </h1>
            <button
              type="button"
              className="btn-close"
              onClick={toggleModal}
            ></button>
          </div>
          <div className="modal-body">
            <Form onSubmit={handleApprovedSubmit}>
              <div className="mb-3">
                <div className="form-check custom-checkbox mb-2 form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="approve"
                    checked={isApprovedCheck}
                    id="approve"
                    value="Approve"
                    onChange={(e) => setIsApprovedCheck(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="approve">
                    Approve
                  </label>
                </div>
              </div>
              <button
                type="submit"
                disabled={!isApprovedCheck || isApprovedLoading}
                className="btn btn-block btn-sm btn-primary mt-3 float-end"
              >
                {isApprovedLoading ? (
                  <span>
                    <Spinner animation="border" size="sm" className="me-2" />
                    Approve
                  </span>
                ) : (
                  "Approve"
                )}
              </button>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user?.role || "Admin",
  };
};
export default connect(mapStateToProps)(StandardDetail);
